import classNames from "classnames";
import ArrowIcon from "./ArrowIcon";

const CollapseToggler = ({ addClass, collapseOpened, setCollapseOpened, children }) => {

  const btnClass = classNames({
    "collapse-toggler": true,
    "collapse-toggler_opened": collapseOpened,
    [`${addClass}`]: addClass,
  });

  return (
    <button
      type="button"
      className={btnClass}
      onClick={() => setCollapseOpened(!collapseOpened)}
    >
      {children}
      <ArrowIcon />
    </button>
  );
};

export default CollapseToggler;