import mocImg from "../../../assets/img/card-main.jpg";
import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import ArrowIcon from "./ArrowIcon";
import "swiper/scss";

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__prev"
      type="button"
      onClick={() => swiper.slidePrev()}
    >
      {children}
    </button>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__next"
      type="button"
      onClick={() => swiper.slideNext()}
    >
      {children}
    </button>
  );
};

const Slider = () => {
  return (
    <div className="slider">
      <Swiper slidesPerView={1}>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>

        <div className="slider__nav">
          <SwiperButtonPrev>
            <ArrowIcon />
          </SwiperButtonPrev>
          <SwiperButtonNext>
            <ArrowIcon />
          </SwiperButtonNext>
        </div>
      </Swiper>
    </div>
  );
};

export default Slider;
