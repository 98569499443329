import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import mocImg from "../../../../assets/img/card-horizontal.jpg";
import { useState } from "react";
import PurchaseCard from "../../../ui/PurchaseCard/PurchaseCard";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import WantToBuyModal from "../../../modals/WantToBuyModal";

const CardHorizontalSells = () => {
  const [modalIsOpen, openModal, closeModal] = useModal();

  const [purchaseCardRemoveOpened, setPurchaseCardRemoveOpened] =
    useState(false);
  const [purchaseCardChangeOpened, setPurchaseCardChangeOpened] =
    useState(false);

  const cardClass = classNames({
    "card card_h card_sells": true,
  });

  return (
    <article className={cardClass}>
      <img className="card__img" src={mocImg} alt="" />

      <div className="card__body">
        <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
          <Label labelType="tertiary">ID 42424242</Label>

          {/* test onClick */}
          <Label labelType="tertiary" onClick={openModal}>
            На продаже
          </Label>
        </div>

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Цифровой токен</span>
            <span className="sell-info__key">ЖК «Аист»</span>
            <p className="sell-info__value">СК «Красная стрела»</p>

            <span className="sell-info__key">Санкт-Петербург</span>
            <p className="sell-info__value">Красносельский район</p>

            <span className="sell-info__key">1 токен ≈ 185 273 ₽</span>
            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">2 токена</span>
            <p className="sell-info__value">Цифрового метра</p>

            <span className="sell-info__title">Цена</span>
            <span className="sell-info__key">1 токен = 185 273 ₽</span>
            <p className="sell-info__value">Ваша цена продажи</p>
          </div>
        </div>

        <div className="card__btns">
          <Button
            size={"xs"}
            addClass="card__buy-btn"
            buttonType="secondary"
            onClick={() => setPurchaseCardRemoveOpened(true)}
          >
            Убрать с продажи
          </Button>
          <Button
            size={"xs"}
            addClass="card__buy-btn"
            onClick={() => setPurchaseCardChangeOpened(true)}
          >
            Изменить
          </Button>
        </div>
      </div>

      <PurchaseCard
        isHorizontal
        isMySells
        addClass="card__purchase"
        isOpened={purchaseCardRemoveOpened}
        setIsOpened={setPurchaseCardRemoveOpened}
        buttonText="Убрать с продажи"
      />

      <PurchaseCard
        isHorizontal
        isMySells
        addClass="card__purchase"
        isOpened={purchaseCardChangeOpened}
        setIsOpened={setPurchaseCardChangeOpened}
        buttonText="Изменить"
      />

      {
        // text modal
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <WantToBuyModal closeModal={closeModal} />
      </Modal>
    </article>
  );
};

export default CardHorizontalSells;
