import CloseButton from "../../ui/CloseButton/CloseButton";
import StepLogin from "./StepLogin";
import { useState } from "react";
import "../index.scss";
import StepForm from "./StepForm";
import classNames from "classnames";
const RegisterModal = ({ closeModal, changePhoneRequest = false }) => {
  const [codeFilled, setCodeFilled] = useState(false);

  const modalClass = classNames({
    modal: true,
    modal_lg: codeFilled,
  });

  return (
    <div className={modalClass}>
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">
        {!changePhoneRequest ? "Личный кабинет" : "Смена номера"}
      </span>

      {!codeFilled ? (
        <StepLogin
          codeFilled={codeFilled}
          setCodeFilled={setCodeFilled}
          changePhoneRequest={changePhoneRequest}
        />
      ) : (
        <StepForm />
      )}
    </div>
  );
};

export default RegisterModal;
