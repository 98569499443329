import classNames from "classnames";
import "./index.scss";

import heartSvg from "../../../assets/img/icons/heart.svg";
import heartFilledSvg from "../../../assets/img/icons/heart-filled.svg";
import { useState } from "react";



const LikeButton = () => {
  const btnClass = classNames({
    "like-btn": true,
  });

  const [liked, setLiked] = useState(false);

  return (
    <button type="button" className={btnClass} onClick={(e) => {
      e.preventDefault();
      setLiked(!liked)}
      }>
      <img src={liked ? heartFilledSvg : heartSvg} alt="" />
    </button>
  );
};

export default LikeButton;
