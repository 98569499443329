import Button from "../../ui/Button/Button";
import SectionTitle from "../../ui/SectionTitle/SectionTitle";
import "./index.scss";

const JkInfo = () => {
  return (
    <section className="jk-info">
      <SectionTitle>ЖК «Аист»</SectionTitle>

      <div className="text-block">
        <p>
          Жилой комплекс комфорт-класса «АИСТ» расположен в обжитой части
          Красносельского района Санкт-Петербурга, на проспекте Ветеранов
          (перекресток с улицей Тамбасова), в окружении трех больших парков.
        </p>
        <p>
          ЖК «АИСТ» включает в себя жилую часть – 153 квартиры с 3 по 22 этаж,
          коммерческие помещения на 1 и 2 этаже, большой
          физкультурно-оздоровительный комплекс и подземный паркинг, в который
          можно спуститься из жилой части дома на одном из трех бесшумных
          скоростных лифтов. Вход в жилую часть будет обособлен и закрыт для
          посторонних.
        </p>
      </div>

      <Button size={"m"} buttonType="secondary" addClass="jk-info__btn">
        Подробнее
      </Button>
    </section>
  );
};

export default JkInfo;
