import Container from "./Container/Container";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../ui/ScrollToTop/ScrollToTop";

const Layout = () => {
  return (
    <div className="App">
      <Header />
      <main className="main">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />

      <ScrollToTop />
    </div>
  );
};

export default Layout;
