import classNames from "classnames";
import Button from "../Button/Button";
import { useState } from "react";
import { BellSvg, UserSvg, DotsSvg } from "./Icons";

import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import RegisterModal from "../../modals/RegisterModal/RegisterModal";

import "./index.scss";
import { Link } from "react-router-dom";

const UserMenu = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [regModalIsOpen, openRegModal, closeRegModal] = useModal();

  const userMenuClass = classNames({
    "user-menu": true,
  });

  return (
    <div className={userMenuClass}>
      {isLoggedIn ? (
        <div className="user-menu__interface">
          <Link to="user/notifications" className="user-menu__notification">
            <BellSvg />
            <span className="user-menu__notification-counter">99</span>
          </Link>

          <Link
            to="/settings/personal"
            type="button"
            className="user-menu__profile"
          >
            <UserSvg />
          </Link>

          <button
            type="button"
            className="user-menu__more"
            onClick={() => setIsLoggedIn(false)} // test
          >
            <DotsSvg />
          </button>
        </div>
      ) : (
        <div className="user-menu__buttons">
          <Button buttonType="secondary" size={"m"} onClick={openRegModal}>
            Регистрация
          </Button>
          <Button
            buttonType="primary"
            size={"m"}
            onClick={() => setIsLoggedIn(true)} // test
          >
            Вход
          </Button>
        </div>
      )}

      <Modal
        isOpen={regModalIsOpen}
        onRequestClose={closeRegModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <RegisterModal closeModal={closeRegModal} />
      </Modal>
    </div>
  );
};

export default UserMenu;
