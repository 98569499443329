import classNames from "classnames";
import "./index.scss";



const Container = ({children}) => {
  const containerClass = classNames({
    "container": true,
  });

  return (
    <div className={containerClass}>{children}</div>
  );
};

export default Container;
