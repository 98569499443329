export const BannerSvg = () => (
  <svg
    width="291"
    height="203"
    viewBox="0 0 291 203"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="banner__pic"
  >
    <path
      d="M41.174 137.249C40.3005 135.778 38.8499 134.411 36.8103 133.223C29.6796 129.112 18.1199 129.112 10.9769 133.223C8.94538 134.395 7.49079 135.778 6.6172 137.249H5.62988V140.968C5.7599 143.559 7.53548 146.125 10.9648 148.1C18.0996 152.212 29.6552 152.212 36.7982 148.1C40.2314 146.125 42.0193 143.559 42.1492 140.968V137.245L41.174 137.249Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M41.174 137.249C40.3005 135.778 38.8499 134.411 36.8103 133.223C29.6796 129.112 18.1199 129.112 10.9769 133.223C8.94538 134.395 7.49079 135.778 6.6172 137.249H5.62988V140.968C5.7599 143.559 7.53548 146.125 10.9648 148.1C18.0996 152.212 29.6552 152.212 36.7982 148.1C40.2314 146.125 42.0193 143.559 42.1492 140.968V137.245L41.174 137.249Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M23.8977 130.141C19.221 130.141 14.5525 131.167 10.9769 133.223C8.94538 134.395 7.49079 135.777 6.6172 137.249H5.62988V140.968C5.7599 143.558 7.53548 146.125 10.9648 148.1C14.5322 150.156 19.2088 151.185 23.8855 151.185L23.8977 130.141Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M13.9182 131.848C12.9056 132.225 11.9258 132.685 10.9887 133.223C8.95713 134.394 7.50251 135.777 6.62895 137.249H5.6416V140.967C5.77164 143.558 7.54723 146.125 10.9765 148.1C11.9175 148.638 12.9014 149.098 13.9182 149.474V131.848Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M33.873 131.848C34.8889 132.224 35.8713 132.684 36.8105 133.223C38.8421 134.394 40.301 135.777 41.1745 137.249H42.1618V140.967C42.0318 143.558 40.2399 146.125 36.8105 148.1C35.8713 148.638 34.8889 149.098 33.873 149.474V131.848Z"
      fill="currentColor"
    />
    <path
      d="M10.9887 129.809C3.85793 133.921 3.85793 140.583 10.9887 144.694C17.2337 148.291 26.8675 148.749 33.9575 146.04L34.2419 145.931C35.1305 145.581 35.993 145.168 36.8221 144.694C42.7705 141.264 43.7619 136.061 39.7881 132.043C38.9063 131.168 37.91 130.417 36.8262 129.809C29.6791 125.697 18.1114 125.697 10.9887 129.809Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M10.9887 129.809C3.85793 133.921 3.85793 140.583 10.9887 144.694C17.2337 148.291 26.8675 148.749 33.9575 146.04L34.2419 145.931C35.1305 145.581 35.993 145.168 36.8221 144.694C42.7705 141.264 43.7619 136.061 39.7881 132.043C38.9063 131.168 37.91 130.417 36.8262 129.809C29.6791 125.697 18.1114 125.697 10.9887 129.809Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M39.7767 132.047C39.8499 132.136 42.3487 134.764 41.6863 138.535C41.0527 142.151 35.9778 145.197 34.2305 145.926C35.1191 145.577 35.9814 145.163 36.8107 144.69C42.7591 141.264 43.7505 136.061 39.7767 132.047Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M8.01509 142.456C7.93791 142.37 5.44315 139.743 6.10543 135.968C6.73523 132.351 11.7938 129.306 13.5613 128.576C12.6709 128.925 11.8072 129.338 10.9771 129.813C5.02871 133.235 4.04136 138.437 8.01509 142.456Z"
      fill="#F5F5F5"
    />
    <path
      d="M23.9017 147.022C33.2793 147.022 40.8815 142.641 40.8815 137.238C40.8815 131.834 33.2793 127.453 23.9017 127.453C14.524 127.453 6.92188 131.834 6.92188 137.238C6.92188 142.641 14.524 147.022 23.9017 147.022Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M23.9017 147.022C33.2793 147.022 40.8815 142.641 40.8815 137.238C40.8815 131.834 33.2793 127.453 23.9017 127.453C14.524 127.453 6.92188 131.834 6.92188 137.238C6.92188 142.641 14.524 147.022 23.9017 147.022Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M40.1082 140.157C41.4735 137.639 41.0305 134.865 38.7754 132.509C38.3313 132.052 37.8503 131.631 37.3371 131.252C36.8961 130.926 36.4377 130.624 35.9638 130.348C35.6915 130.19 35.4154 130.04 35.1267 129.898C35.8338 131.058 35.5739 132.298 31.5757 132.53C23.746 132.988 33.664 140.453 33.664 140.453C33.664 140.453 38.7104 138.02 40.1082 140.157Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M39.3049 138.092C40.479 137.963 41.0602 136.754 40.601 135.4C40.1417 134.046 38.8254 133.052 37.6511 133.182C36.4769 133.312 35.8918 134.52 36.351 135.874C36.81 137.229 38.1266 138.226 39.3049 138.092Z"
      fill="white"
    />
    <path
      d="M26.9242 144.313C26.5179 141.413 13.8329 133.385 13.8329 133.385C13.8329 133.385 11.0171 132.258 11.9517 130.291C11.4833 130.558 11.0302 130.85 10.5946 131.167C10.298 131.378 10.0257 131.597 9.78197 131.82C5.9992 135.088 5.99511 139.382 9.78197 142.654C10.0379 142.873 10.3142 143.092 10.5946 143.307C10.9996 143.6 11.4201 143.87 11.8542 144.118C12.2849 144.37 12.7359 144.605 13.199 144.824C13.5688 144.998 13.9548 145.164 14.3449 145.314C14.7349 145.464 15.129 145.61 15.5353 145.744C16.7729 146.147 18.0405 146.45 19.3262 146.652L20.1754 146.778C21.4327 146.938 22.699 147.016 23.9663 147.013C25.5194 147.011 27.0698 146.884 28.6024 146.632C28.6024 146.632 26.5587 146.239 26.9242 144.313Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M26.7416 144.313C26.3558 141.413 13.6544 133.385 13.6544 133.385C13.6544 133.385 10.8345 132.258 11.7731 130.291C11.3034 130.558 10.849 130.85 10.412 131.167C10.1194 131.378 9.84313 131.597 9.59934 131.82C5.81657 135.088 5.81251 139.382 9.59934 142.654C9.85533 142.873 10.1316 143.092 10.412 143.307C10.817 143.6 11.2375 143.87 11.6716 144.118C12.1022 144.37 12.5532 144.605 13.0164 144.824C13.3903 144.998 13.7722 145.164 14.1622 145.314C14.5523 145.464 14.9464 145.61 15.3527 145.744C16.5904 146.146 17.858 146.45 19.1436 146.652L19.9969 146.778C21.2541 146.938 22.5204 147.016 23.7878 147.013C25.3409 147.011 26.8913 146.884 28.4239 146.632C28.4239 146.632 26.3799 146.239 26.7416 144.313Z"
      fill="white"
    />
    <path
      d="M14.9517 132.084C10.2141 134.813 10.019 139.168 14.3747 142.03C14.5575 142.152 14.7526 142.274 14.9517 142.391C19.8924 145.23 27.9008 145.23 32.8293 142.391C37.7581 139.553 37.7702 134.93 32.8293 132.084C32.6303 131.966 32.423 131.853 32.2119 131.747C27.2426 129.242 19.6893 129.351 14.9517 132.084Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M14.9517 132.084C10.2141 134.813 10.019 139.168 14.3747 142.03C14.5575 142.152 14.7526 142.274 14.9517 142.391C19.8924 145.23 27.9008 145.23 32.8293 142.391C37.7581 139.553 37.7702 134.93 32.8293 132.084C32.6303 131.966 32.423 131.853 32.2119 131.747C27.2426 129.242 19.6893 129.351 14.9517 132.084Z"
      fill="#1D1D1D"
    />
    <path
      d="M23.9005 144.203C30.5786 144.203 35.9923 141.085 35.9923 137.237C35.9923 133.39 30.5786 130.271 23.9005 130.271C17.2223 130.271 11.8086 133.39 11.8086 137.237C11.8086 141.085 17.2223 144.203 23.9005 144.203Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M23.9005 144.203C30.5786 144.203 35.9923 141.085 35.9923 137.237C35.9923 133.39 30.5786 130.271 23.9005 130.271C17.2223 130.271 11.8086 133.39 11.8086 137.237C11.8086 141.085 17.2223 144.203 23.9005 144.203Z"
      fill="#1D1D1D"
    />
    <path
      d="M15.3539 134.253C20.0753 131.532 27.7302 131.532 32.4515 134.253C34.3857 135.364 35.5233 136.758 35.8767 138.206C35.5233 139.654 34.3857 141.044 32.4515 142.164C27.7302 144.884 20.0753 144.884 15.3539 142.164C13.4239 141.048 12.2822 139.654 11.9287 138.206C12.2822 136.758 13.428 135.364 15.3539 134.253Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M15.3539 134.253C20.0753 131.532 27.7302 131.532 32.4515 134.253C34.3857 135.364 35.5233 136.758 35.8767 138.206C35.5233 139.654 34.3857 141.044 32.4515 142.164C27.7302 144.884 20.0753 144.884 15.3539 142.164C13.4239 141.048 12.2822 139.654 11.9287 138.206C12.2822 136.758 13.428 135.364 15.3539 134.253Z"
      fill="#1D1D1D"
    />
    <path
      d="M31.5157 139.345C31.5157 139.11 31.5157 138.153 31.5157 137.906C31.5157 137.314 31.1094 136.742 30.2276 136.227H30.195C28.57 135.295 26.8959 135.416 25.4006 135.789C24.7627 135.947 24.1614 136.154 23.6088 136.345C22.9758 136.572 22.33 136.761 21.6747 136.912C21.3755 136.984 21.0679 137.014 20.7605 137.002C20.4972 136.988 20.2397 136.919 20.0048 136.799L20.0658 136.758C20.1226 136.718 20.1876 136.682 20.2527 136.641C20.417 136.547 20.5865 136.463 20.7605 136.39C21.1829 136.204 21.6379 136.103 22.0994 136.092C22.5609 136.082 23.02 136.162 23.4504 136.329L24.9984 134.768V133.336C23.6314 132.872 22.1578 132.818 20.7605 133.182C20.2989 133.301 19.8477 133.457 19.4116 133.649L17.8635 132.756L16.0107 133.823V135.254L16.6446 135.619C16.4752 135.866 16.3807 136.156 16.3724 136.454C16.3724 136.694 16.3724 137.642 16.3724 137.886C16.3724 138.474 16.7787 139.046 17.6888 139.56C18.4669 140.012 19.345 140.264 20.2445 140.294C20.4152 140.294 20.5858 140.294 20.7565 140.294C21.694 140.226 22.6172 140.027 23.4991 139.702L24.0354 139.52L22.4752 141.081V142.512C23.4368 142.876 24.4625 143.041 25.4899 142.997C26.5173 142.954 27.5252 142.702 28.4521 142.257L29.9839 143.137L31.8326 142.07V140.639L31.1988 140.274C31.3942 140.003 31.5044 139.679 31.5157 139.345ZM27.6151 139.224C27.4756 139.304 27.3318 139.375 27.1843 139.439C26.2204 139.85 25.1329 139.866 24.1573 139.483C25.6079 138.972 26.8186 138.526 27.8506 139.078C27.7776 139.11 27.7003 139.163 27.6151 139.212V139.224Z"
      fill="currentColor"
    />
    <path
      d="M25.4006 135.757C24.7627 135.915 24.1614 136.122 23.6088 136.312C22.9765 136.55 22.3307 136.751 21.6747 136.912C21.3755 136.984 21.0679 137.014 20.7605 137.002C20.4499 136.985 20.1477 136.895 19.8789 136.738C19.2856 136.394 19.2003 135.895 20.0658 135.327C20.1226 135.287 20.1876 135.25 20.2527 135.21C20.4161 135.114 20.5858 135.03 20.7605 134.958C21.1829 134.772 21.6379 134.671 22.0994 134.661C22.5609 134.65 23.02 134.731 23.4504 134.897L24.9984 133.336C23.6314 132.872 22.1578 132.818 20.7605 133.182C20.2989 133.301 19.8477 133.457 19.4116 133.649L17.8635 132.756L16.0107 133.823L17.5466 134.707C15.9905 135.887 15.9579 137.111 17.7092 138.121C18.4872 138.572 19.3653 138.825 20.2648 138.855C20.4355 138.855 20.6062 138.855 20.7768 138.855C21.7143 138.787 22.6376 138.587 23.5194 138.263C25.295 137.671 26.7333 136.973 27.932 137.663C28.7447 138.125 28.5089 138.705 27.6312 139.212C27.4918 139.291 27.3482 139.363 27.2005 139.427C26.7143 139.634 26.1917 139.743 25.6627 139.747C25.1339 139.75 24.6097 139.648 24.1208 139.447L22.4955 141.069C23.4575 141.431 24.4833 141.595 25.5106 141.55C26.5379 141.506 27.5455 141.254 28.4724 140.809L30.0042 141.693L31.8529 140.627L30.3088 139.751C31.8447 138.563 32.0235 137.27 30.2276 136.219H30.195C28.5739 135.262 26.8959 135.384 25.4006 135.757Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M25.4016 135.758C24.7637 135.916 24.1624 136.122 23.6098 136.313C22.9775 136.551 22.3317 136.751 21.6757 136.913C21.3765 136.984 21.0689 137.014 20.7615 137.002C20.4509 136.985 20.1487 136.895 19.8798 136.739C19.2866 136.394 19.2013 135.895 20.0667 135.328C20.1236 135.287 20.1886 135.251 20.2536 135.21C20.4171 135.115 20.5868 135.031 20.7615 134.959C21.1839 134.773 21.6389 134.672 22.1004 134.661C22.5619 134.651 23.0209 134.731 23.4513 134.898L24.9994 133.337C23.6323 132.872 22.1588 132.819 20.7615 133.183C20.2998 133.301 19.8487 133.457 19.4126 133.649L17.8645 132.757L16.0117 133.823L17.5476 134.707C15.9914 135.887 15.9589 137.112 17.7101 138.121C18.4882 138.573 19.3663 138.825 20.2658 138.855C20.4365 138.855 20.6071 138.855 20.7778 138.855C21.7153 138.787 22.6385 138.588 23.5204 138.263C25.296 137.671 26.7343 136.974 27.933 137.663C28.7457 138.125 28.5099 138.705 27.6322 139.212C27.4928 139.292 27.3492 139.364 27.2015 139.427C26.7153 139.635 26.1927 139.744 25.6637 139.747C25.1349 139.751 24.6106 139.649 24.1217 139.447L22.4965 141.069C23.4585 141.432 24.4842 141.596 25.5116 141.551C26.5389 141.506 27.5465 141.254 28.4734 140.81L30.0052 141.694L31.8539 140.627L30.3098 139.751C31.8457 138.563 32.0245 137.27 30.2286 136.22H30.196C28.5749 135.263 26.8969 135.384 25.4016 135.758Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M16.0166 133.819L17.8816 132.972L19.4134 133.645L17.8653 132.753L16.0166 133.819Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M20.5449 133.239C21.8634 132.976 23.225 133.022 24.5227 133.373L23.4501 134.885L24.9981 133.324C23.5577 132.84 22.0029 132.811 20.5449 133.239Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M30.196 136.195H30.2283C32.0241 137.246 31.8536 138.539 30.3097 139.727C31.6707 138.247 31.427 137.213 29.944 136.357C28.461 135.502 26.5714 135.514 25.4014 135.737C26.8966 135.384 28.5748 135.263 30.196 136.195Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M30.3101 139.751L31.4843 140.647L29.9893 141.697L31.838 140.631L30.3101 139.751Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M27.1847 139.427C26.2704 139.886 25.2145 139.978 24.2348 139.682L22.4795 141.073L24.1048 139.451C24.5939 139.652 25.1183 139.753 25.6471 139.749C26.1761 139.745 26.6988 139.635 27.1847 139.427Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M21.6758 136.913C20.977 137.188 19.9693 137.144 19.4574 136.706C18.9454 136.268 19.2664 135.753 20.0668 135.327C19.2014 135.895 19.2867 136.394 19.8799 136.738C20.1488 136.894 20.451 136.985 20.7616 137.002C21.069 137.014 21.3766 136.984 21.6758 136.913Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M17.5306 134.711C17.4006 134.829 16.2873 135.867 16.7789 136.901C17.299 138.004 18.9324 138.713 20.2488 138.855C19.35 138.827 18.4719 138.578 17.6931 138.129C15.9419 137.115 15.9744 135.891 17.5306 134.711Z"
      fill="#F5F5F5"
    />
    <path
      d="M38.4711 132.209C37.5976 130.737 36.1428 129.371 34.1074 128.183C26.9724 124.071 15.417 124.071 8.27399 128.183C6.24244 129.355 4.78376 130.737 3.9102 132.209H2.92285V135.932C3.05695 138.523 4.83254 141.089 8.26182 143.064C15.3967 147.176 26.9521 147.176 34.0951 143.064C37.5244 141.089 39.3123 138.523 39.4463 135.932V132.209H38.4711Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M38.4711 132.209C37.5976 130.737 36.1428 129.371 34.1074 128.183C26.9724 124.071 15.417 124.071 8.27399 128.183C6.24244 129.355 4.78376 130.737 3.9102 132.209H2.92285V135.932C3.05695 138.523 4.83254 141.089 8.26182 143.064C15.3967 147.176 26.9521 147.176 34.0951 143.064C37.5244 141.089 39.3123 138.523 39.4463 135.932V132.209H38.4711Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M21.1907 125.101C16.514 125.101 11.8455 126.127 8.27399 128.183C6.24244 129.355 4.78376 130.737 3.9102 132.209H2.92285V135.932C3.05695 138.523 4.83254 141.089 8.26182 143.064C11.8292 145.12 16.5018 146.15 21.1785 146.15L21.1907 125.101Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M11.2116 126.808C10.1965 127.186 9.21403 127.645 8.27399 128.183C6.24244 129.355 4.78376 130.737 3.9102 132.209H2.92285V135.931C3.05695 138.522 4.83254 141.089 8.26182 143.064C9.20185 143.601 10.1843 144.061 11.1994 144.438L11.2116 126.808Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M31.167 126.808C32.1836 127.185 33.1675 127.645 34.1086 128.183C36.1402 129.355 37.5988 130.737 38.4723 132.209H39.4599V135.931C39.3258 138.522 37.5379 141.089 34.1086 143.068C33.1665 143.603 32.1828 144.061 31.167 144.438V126.808Z"
      fill="currentColor"
    />
    <path
      d="M8.27399 124.769C1.13914 128.88 1.13914 135.542 8.27399 139.654C14.5149 143.25 24.1486 143.708 31.2387 141L31.5192 140.891C32.4094 140.541 33.2732 140.128 34.1033 139.654C40.0517 136.223 41.039 131.021 37.0652 127.003C36.1819 126.13 35.1856 125.379 34.1033 124.769C26.9724 120.657 15.4088 120.657 8.27399 124.769Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M8.27399 124.769C1.13914 128.88 1.13914 135.542 8.27399 139.654C14.5149 143.25 24.1486 143.708 31.2387 141L31.5192 140.891C32.4094 140.541 33.2732 140.128 34.1033 139.654C40.0517 136.223 41.039 131.021 37.0652 127.003C36.1819 126.13 35.1856 125.379 34.1033 124.769C26.9724 120.657 15.4088 120.657 8.27399 124.769Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M37.0706 127.003C37.1479 127.092 39.6426 129.72 38.9802 133.49C38.3505 137.108 33.292 140.153 31.5244 140.883C32.4149 140.533 33.2784 140.12 34.1087 139.646C40.0572 136.224 41.0565 131.021 37.0706 127.003Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M5.30124 137.416C5.22404 137.331 2.7293 134.703 3.38753 130.928C4.02136 127.311 9.09621 124.266 10.8433 123.536C9.95396 123.884 9.09156 124.298 8.26325 124.773C2.32704 128.195 1.33969 133.398 5.30124 137.416Z"
      fill="#F5F5F5"
    />
    <path
      d="M21.1956 141.981C30.5733 141.981 38.1755 137.601 38.1755 132.197C38.1755 126.793 30.5733 122.413 21.1956 122.413C11.8179 122.413 4.21582 126.793 4.21582 132.197C4.21582 137.601 11.8179 141.981 21.1956 141.981Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M21.1956 141.981C30.5733 141.981 38.1755 137.601 38.1755 132.197C38.1755 126.793 30.5733 122.413 21.1956 122.413C11.8179 122.413 4.21582 126.793 4.21582 132.197C4.21582 137.601 11.8179 141.981 21.1956 141.981Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M37.3987 135.117C38.7641 132.599 38.3172 129.825 36.0662 127.474C35.6222 127.015 35.1411 126.593 34.6277 126.213C34.1846 125.887 33.7249 125.586 33.2505 125.308C32.9783 125.15 32.7019 125 32.4135 124.858C33.1203 126.018 32.8604 127.259 28.8621 127.49C21.0366 127.948 30.9546 135.413 30.9546 135.413C30.9546 135.413 36.0051 132.98 37.3987 135.117Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M36.5865 133.053C37.7607 132.923 38.3417 131.719 37.8866 130.36C37.4315 129.002 36.1069 128.013 34.9327 128.142C33.7584 128.272 33.1774 129.48 33.6325 130.835C34.0877 132.189 35.4245 133.187 36.5865 133.053Z"
      fill="white"
    />
    <path
      d="M24.2192 139.272C23.8332 136.373 11.1319 128.345 11.1319 128.345C11.1319 128.345 8.31208 127.218 9.25065 125.251C8.78099 125.518 8.32656 125.81 7.8895 126.127C7.59696 126.338 7.32067 126.557 7.07688 126.78C3.29411 130.048 3.29005 134.342 7.07688 137.614C7.33285 137.837 7.60916 138.052 7.8895 138.267C8.29456 138.56 8.71503 138.83 9.14907 139.078C9.57976 139.333 10.0308 139.564 10.494 139.783C10.8678 139.958 11.2497 140.124 11.6398 140.274C12.0298 140.424 12.428 140.57 12.8303 140.704C14.0679 141.106 15.3355 141.409 16.6212 141.612L17.4744 141.738C18.7316 141.897 19.9979 141.976 21.2653 141.973C22.8184 141.971 24.3688 141.843 25.9015 141.592C25.9015 141.592 23.8576 141.182 24.2192 139.272Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M24.0399 139.272C23.6539 136.373 10.9486 128.345 10.9486 128.345C10.9486 128.345 8.13279 127.218 9.06733 125.251C8.59896 125.518 8.14589 125.81 7.71024 126.127C7.41364 126.338 7.14142 126.557 6.89762 126.78C3.11891 130.048 3.1108 134.342 6.89762 137.614C7.15359 137.837 7.42987 138.052 7.71024 138.267C8.11405 138.561 8.53462 138.832 8.96981 139.078C9.40051 139.333 9.85152 139.564 10.3147 139.783C10.6845 139.958 11.0705 140.124 11.4605 140.274C11.8506 140.424 12.2447 140.57 12.651 140.704C13.8886 141.106 15.1562 141.41 16.4419 141.612L17.2911 141.738C18.5483 141.897 19.8146 141.976 21.082 141.973C22.6351 141.971 24.1855 141.844 25.7179 141.592C25.7179 141.592 23.6742 141.182 24.0399 139.272Z"
      fill="white"
    />
    <path
      d="M12.246 127.043C7.50838 129.772 7.31742 134.127 11.669 136.989C11.8559 137.111 12.0469 137.233 12.25 137.35C17.1908 140.189 25.1952 140.189 30.1279 137.35C35.0605 134.512 35.0726 129.89 30.1279 127.043C29.9247 126.926 29.7216 126.812 29.5061 126.706C24.5491 124.201 16.9876 124.31 12.246 127.043Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M12.246 127.043C7.50838 129.772 7.31742 134.127 11.669 136.989C11.8559 137.111 12.0469 137.233 12.25 137.35C17.1908 140.189 25.1952 140.189 30.1279 137.35C35.0605 134.512 35.0726 129.89 30.1279 127.043C29.9247 126.926 29.7216 126.812 29.5061 126.706C24.5491 124.201 16.9876 124.31 12.246 127.043Z"
      fill="#1D1D1D"
    />
    <path
      d="M21.2003 139.163C27.8783 139.163 33.292 136.044 33.292 132.197C33.292 128.35 27.8783 125.231 21.2003 125.231C14.5221 125.231 9.1084 128.35 9.1084 132.197C9.1084 136.044 14.5221 139.163 21.2003 139.163Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M21.2003 139.163C27.8783 139.163 33.292 136.044 33.292 132.197C33.292 128.35 27.8783 125.231 21.2003 125.231C14.5221 125.231 9.1084 128.35 9.1084 132.197C9.1084 136.044 14.5221 139.163 21.2003 139.163Z"
      fill="#1D1D1D"
    />
    <path
      d="M12.6467 129.209C17.3681 126.488 25.0271 126.488 29.7484 129.209C31.6783 130.32 32.8202 131.714 33.1695 133.162C32.8202 134.61 31.6783 136.001 29.7484 137.12C25.0271 139.84 17.3681 139.84 12.6467 137.12C10.7167 136.005 9.57502 134.61 9.22559 133.162C9.57502 131.719 10.7167 130.324 12.6467 129.209Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M12.6467 129.209C17.3681 126.488 25.0271 126.488 29.7484 129.209C31.6783 130.32 32.8202 131.714 33.1695 133.162C32.8202 134.61 31.6783 136.001 29.7484 137.12C25.0271 139.84 17.3681 139.84 12.6467 137.12C10.7167 136.005 9.57502 134.61 9.22559 133.162C9.57502 131.719 10.7167 130.324 12.6467 129.209Z"
      fill="#1D1D1D"
    />
    <path
      d="M28.81 134.293C28.81 134.058 28.81 133.101 28.81 132.854C28.81 132.262 28.4037 131.69 27.5178 131.175L27.4893 131.155C25.864 130.222 24.1861 130.344 22.6908 130.717C22.057 130.875 21.4719 131.082 20.9031 131.272C20.2744 131.506 19.6327 131.704 18.9812 131.864C18.682 131.936 18.3744 131.966 18.067 131.954C17.8038 131.938 17.5466 131.869 17.3113 131.751L17.3722 131.71L17.5632 131.593C17.7261 131.499 17.8944 131.415 18.067 131.341C18.4894 131.155 18.9443 131.054 19.4059 131.044C19.8674 131.033 20.3264 131.114 20.7568 131.28L22.3089 129.72V128.288C20.9405 127.824 19.4657 127.77 18.067 128.134C17.6069 128.253 17.1571 128.409 16.7221 128.6L15.17 127.708L13.3213 128.775V130.206L13.9551 130.571C13.784 130.817 13.6893 131.107 13.6829 131.406V132.858C13.6829 133.446 14.0892 134.017 15.0034 134.532C15.7803 134.983 16.6569 135.235 17.5551 135.266C17.7217 135.266 17.8964 135.266 18.0629 135.266C19.0018 135.198 19.9264 134.999 20.8096 134.674L21.3419 134.492L19.7817 136.053V137.484C20.744 137.848 21.7703 138.013 22.7984 137.969C23.8265 137.925 24.8349 137.673 25.7626 137.229L27.2944 138.109L29.1431 137.042V135.611L28.5092 135.246C28.7042 134.966 28.809 134.634 28.81 134.293ZM24.9093 134.172C24.7713 134.251 24.6289 134.323 24.4827 134.386C23.5188 134.798 22.4313 134.814 21.4557 134.431C22.9022 133.92 24.1129 133.474 25.145 134.026C25.0718 134.074 24.9947 134.123 24.9093 134.172Z"
      fill="currentColor"
    />
    <path
      d="M22.6938 130.717C22.0599 130.875 21.4748 131.082 20.906 131.272C20.2773 131.506 19.6356 131.704 18.9842 131.864C18.6849 131.936 18.3773 131.966 18.07 131.954C17.7606 131.937 17.4597 131.846 17.1923 131.69C16.595 131.345 16.5097 130.847 17.3752 130.279L17.5661 130.161C17.7282 130.066 17.8965 129.982 18.07 129.91C18.4923 129.724 18.9472 129.623 19.4088 129.613C19.8703 129.602 20.3294 129.683 20.7597 129.849L22.3119 128.288C20.9434 127.824 19.4686 127.77 18.07 128.134C17.6098 128.253 17.16 128.409 16.7251 128.6L15.1729 127.708L13.3242 128.775L14.856 129.659C13.3039 130.839 13.2714 132.063 15.0226 133.073C15.7995 133.524 16.6761 133.776 17.5743 133.807C17.7408 133.807 17.9155 133.807 18.0821 133.807C19.021 133.739 19.9456 133.539 20.8288 133.215C22.6044 132.623 24.0428 131.925 25.2373 132.615C26.0499 133.077 25.8182 133.657 24.9366 134.163C24.7986 134.243 24.6562 134.315 24.51 134.378C24.0231 134.586 23.4996 134.695 22.9701 134.699C22.4406 134.702 21.9157 134.6 21.4261 134.399L19.8008 136.02C20.7634 136.384 21.7899 136.548 22.818 136.503C23.8461 136.459 24.8544 136.206 25.7818 135.761L27.3136 136.645L29.1623 135.579L27.6305 134.699C29.1705 133.507 29.3452 132.213 27.5493 131.163L27.5208 131.143C25.867 130.222 24.189 130.344 22.6938 130.717Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M22.6957 130.717C22.0619 130.875 21.4768 131.082 20.908 131.273C20.2793 131.507 19.6376 131.704 18.9861 131.865C18.6869 131.936 18.3793 131.966 18.0719 131.954C17.7625 131.937 17.4617 131.846 17.1943 131.69C16.597 131.346 16.5117 130.847 17.3771 130.279L17.5681 130.162C17.7301 130.067 17.8985 129.983 18.0719 129.91C18.4943 129.724 18.9492 129.623 19.4107 129.613C19.8723 129.602 20.3313 129.683 20.7617 129.849L22.3138 128.288C20.9454 127.824 19.4706 127.771 18.0719 128.134C17.6117 128.253 17.162 128.409 16.727 128.6L15.1749 127.708L13.3262 128.775L14.858 129.659C13.3059 130.839 13.2734 132.063 15.0246 133.073C15.8014 133.524 16.678 133.776 17.5762 133.807C17.7428 133.807 17.9175 133.807 18.0841 133.807C19.0229 133.739 19.9475 133.54 20.8308 133.215C22.6063 132.623 24.0447 131.925 25.2393 132.615C26.0519 133.077 25.8202 133.657 24.9386 134.164C24.8006 134.243 24.6581 134.315 24.5119 134.379C24.0251 134.586 23.5016 134.695 22.9721 134.699C22.4425 134.702 21.9176 134.6 21.428 134.399L19.8028 136.021C20.7654 136.384 21.7918 136.548 22.8199 136.504C23.848 136.459 24.8564 136.207 25.7838 135.761L27.3156 136.645L29.1643 135.579L27.6325 134.699C29.1723 133.507 29.3472 132.213 27.5511 131.163L27.5228 131.143C25.869 130.222 24.191 130.344 22.6957 130.717Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M13.3096 128.779L15.1786 127.932L16.7104 128.605L15.1583 127.713L13.3096 128.779Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M17.8447 128.199C19.1618 127.936 20.5222 127.982 21.8185 128.333L20.7417 129.854L22.2938 128.292C20.8555 127.807 19.3022 127.774 17.8447 128.199Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M27.4938 131.155L27.5223 131.175C29.3181 132.226 29.1476 133.519 27.6035 134.711C28.9688 133.227 28.721 132.193 27.2418 131.337C25.7629 130.482 23.8696 130.498 22.6953 130.717C24.1905 130.344 25.8685 130.223 27.4938 131.155Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M27.6031 134.707L28.7773 135.611L27.2861 136.657L29.1349 135.591L27.6031 134.707Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M24.4816 134.387C23.5676 134.846 22.5115 134.938 21.5318 134.642L19.7725 136.033L21.3977 134.411C21.8875 134.612 22.4126 134.713 22.9421 134.709C23.4717 134.705 23.995 134.595 24.4816 134.387Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M18.969 131.869C18.2701 132.145 17.2625 132.1 16.7546 131.662C16.2466 131.224 16.5636 130.709 17.3599 130.283C16.4945 130.851 16.5798 131.35 17.1771 131.694C17.4445 131.851 17.7454 131.941 18.0548 131.958C18.3621 131.97 18.6697 131.94 18.969 131.869Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M14.8244 129.671C14.6944 129.789 13.5811 130.827 14.0728 131.861C14.5969 132.963 16.2262 133.673 17.5467 133.815C16.6486 133.784 15.772 133.532 14.9951 133.081C13.2398 132.075 13.2723 130.851 14.8244 129.671Z"
      fill="#F5F5F5"
    />
    <path
      d="M39.7603 125.669C38.8865 124.197 37.432 122.831 35.3963 121.643C28.2616 117.531 16.706 117.531 9.56305 121.643C7.5315 122.814 6.07282 124.197 5.19926 125.669H4.21191V129.391C4.34601 131.982 6.1216 134.549 9.55085 136.524C16.6857 140.635 28.2413 140.635 35.3843 136.524C38.8136 134.549 40.6012 131.982 40.7353 129.391V125.669H39.7603Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M39.7603 125.669C38.8865 124.197 37.432 122.831 35.3963 121.643C28.2616 117.531 16.706 117.531 9.56305 121.643C7.5315 122.814 6.07282 124.197 5.19926 125.669H4.21191V129.391C4.34601 131.982 6.1216 134.549 9.55085 136.524C16.6857 140.635 28.2413 140.635 35.3843 136.524C38.8136 134.549 40.6012 131.982 40.7353 129.391V125.669H39.7603Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M22.4797 118.557C17.8071 118.557 13.1345 119.587 9.56305 121.643C7.5315 122.814 6.07282 124.197 5.19926 125.669H4.21191V129.391C4.34601 131.982 6.1216 134.549 9.55085 136.524C13.1183 138.579 17.7949 139.609 22.4675 139.605L22.4797 118.557Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M12.5048 120.288C11.488 120.665 10.5041 121.125 9.56305 121.663C7.5315 122.835 6.07282 124.217 5.19926 125.689H4.21191V129.412C4.34601 132.003 6.1216 134.569 9.55085 136.544C10.4919 137.082 11.4758 137.542 12.4926 137.919L12.5048 120.288Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M32.4551 120.288C33.4719 120.665 34.4559 121.125 35.3969 121.663C37.4285 122.835 38.8871 124.217 39.7606 125.689H40.7479V129.412C40.6139 132.003 38.8263 134.569 35.3969 136.544C34.4559 137.082 33.4719 137.542 32.4551 137.919V120.288Z"
      fill="currentColor"
    />
    <path
      d="M9.56304 118.228C2.4282 122.336 2.4282 129.002 9.56304 133.109C15.804 136.706 25.4377 137.164 32.5279 134.459L32.8081 134.35C33.6986 133.999 34.5624 133.585 35.3925 133.109C41.3406 129.683 42.3282 124.48 38.3543 120.462C37.4718 119.588 36.4756 118.837 35.3925 118.228C28.2616 114.116 16.6979 114.116 9.56304 118.228Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M9.56304 118.228C2.4282 122.336 2.4282 129.002 9.56304 133.109C15.804 136.706 25.4377 137.164 32.5279 134.459L32.8081 134.35C33.6986 133.999 34.5624 133.585 35.3925 133.109C41.3406 129.683 42.3282 124.48 38.3543 120.462C37.4718 119.588 36.4756 118.837 35.3925 118.228C28.2616 114.116 16.6979 114.116 9.56304 118.228Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M38.3597 120.463C38.4367 120.548 40.9317 123.175 40.2693 126.95C39.6395 130.567 34.5808 133.608 32.8135 134.342C33.7039 133.992 34.5674 133.577 35.3975 133.101C41.346 129.683 42.3333 124.481 38.3597 120.463Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M6.60201 130.875C6.52483 130.79 4.03006 128.162 4.69235 124.387C5.32212 120.77 10.3807 117.725 12.1482 116.991C11.2578 117.342 10.3941 117.757 9.56402 118.232C3.61562 121.654 2.62828 126.857 6.60201 130.875Z"
      fill="#F5F5F5"
    />
    <path
      d="M22.4837 135.441C31.8614 135.441 39.4634 131.06 39.4634 125.657C39.4634 120.253 31.8614 115.872 22.4837 115.872C13.106 115.872 5.50391 120.253 5.50391 125.657C5.50391 131.06 13.106 135.441 22.4837 135.441Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M22.4837 135.441C31.8614 135.441 39.4634 131.06 39.4634 125.657C39.4634 120.253 31.8614 115.872 22.4837 115.872C13.106 115.872 5.50391 120.253 5.50391 125.657C5.50391 131.06 13.106 135.441 22.4837 135.441Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M38.6956 128.576C40.061 126.058 39.6138 123.284 37.3629 120.929C36.9188 120.471 36.4377 120.051 35.9246 119.672C35.4826 119.345 35.0225 119.044 34.5471 118.767C34.279 118.609 33.9988 118.459 33.7142 118.317C34.4172 119.473 34.1611 120.718 30.159 120.949C22.3335 121.407 32.2515 128.868 32.2515 128.868C32.2515 128.868 37.2979 126.439 38.6956 128.576Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M37.8871 126.512C39.0614 126.383 39.6423 125.174 39.1875 123.82C38.7324 122.466 37.4078 121.472 36.2336 121.602C35.0593 121.732 34.4783 122.94 34.9332 124.295C35.3883 125.649 36.7131 126.642 37.8871 126.512Z"
      fill="white"
    />
    <path
      d="M25.5063 132.732C25.1244 129.833 12.419 121.784 12.419 121.784C12.419 121.784 9.60324 120.657 10.5378 118.695C10.0681 118.961 9.6137 119.253 9.17663 119.57C8.89501 119.774 8.62378 119.992 8.36399 120.223C4.58122 123.491 4.57716 127.781 8.36399 131.053C8.6159 131.276 8.89221 131.496 9.17663 131.706C9.58267 131.997 10.003 132.268 10.4362 132.517C10.8669 132.769 11.3179 133.004 11.7852 133.223C12.1549 133.397 12.5368 133.559 12.9269 133.713C13.3169 133.868 13.7151 134.01 14.1174 134.143C15.3557 134.543 16.6231 134.847 17.9083 135.051L18.7615 135.173C20.0187 135.334 21.285 135.414 22.5524 135.412C24.1055 135.41 25.6559 135.283 27.1884 135.031C27.1884 135.031 25.1447 134.638 25.5063 132.732Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M25.3286 132.732C24.9223 129.833 12.2372 121.784 12.2372 121.784C12.2372 121.784 9.42145 120.657 10.356 118.695C9.88762 118.961 9.43455 119.253 8.9989 119.57C8.71653 119.773 8.44528 119.991 8.18625 120.223C4.40349 123.491 4.39943 127.781 8.18625 131.053C8.44225 131.276 8.71853 131.496 8.9989 131.706C9.40491 131.997 9.82531 132.268 10.2585 132.517C10.6891 132.769 11.1401 133.004 11.6034 133.223C11.9731 133.397 12.3591 133.559 12.7492 133.713C13.1392 133.868 13.5333 134.01 13.9397 134.143C15.1777 134.544 16.4452 134.847 17.7305 135.051C18.0109 135.096 18.2994 135.137 18.5838 135.173C19.8396 135.334 21.1045 135.414 22.3706 135.412C23.9238 135.411 25.4742 135.283 27.0067 135.031C27.0067 135.031 24.9629 134.638 25.3286 132.732Z"
      fill="white"
    />
    <path
      d="M13.5367 120.499C8.79505 123.232 8.60409 127.587 12.9557 130.449C13.1426 130.571 13.3335 130.693 13.5367 130.806C18.4775 133.644 26.4858 133.644 31.4146 130.806C36.3431 127.968 36.3551 123.345 31.4146 120.499C31.2114 120.385 31.0082 120.272 30.7927 120.166C25.8357 117.656 18.2743 117.77 13.5367 120.499Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M13.5367 120.499C8.79505 123.232 8.60409 127.587 12.9557 130.449C13.1426 130.571 13.3335 130.693 13.5367 130.806C18.4775 133.644 26.4858 133.644 31.4146 130.806C36.3431 127.968 36.3551 123.345 31.4146 120.499C31.2114 120.385 31.0082 120.272 30.7927 120.166C25.8357 117.656 18.2743 117.77 13.5367 120.499Z"
      fill="#1D1D1D"
    />
    <path
      d="M22.4864 132.618C29.1645 132.618 34.5782 129.5 34.5782 125.652C34.5782 121.805 29.1645 118.686 22.4864 118.686C15.8082 118.686 10.3945 121.805 10.3945 125.652C10.3945 129.5 15.8082 132.618 22.4864 132.618Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M22.4864 132.618C29.1645 132.618 34.5782 129.5 34.5782 125.652C34.5782 121.805 29.1645 118.686 22.4864 118.686C15.8082 118.686 10.3945 121.805 10.3945 125.652C10.3945 129.5 15.8082 132.618 22.4864 132.618Z"
      fill="#1D1D1D"
    />
    <path
      d="M13.9348 122.672C18.6602 119.951 26.3151 119.951 31.0366 122.672C32.9665 123.783 34.1081 125.178 34.4618 126.626C34.1081 128.073 32.9665 129.464 31.0366 130.579C26.3151 133.3 18.6602 133.3 13.9348 130.579C12.0048 129.468 10.8631 128.073 10.5137 126.626C10.8631 125.178 12.0048 123.783 13.9348 122.672Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M13.9348 122.672C18.6602 119.951 26.3151 119.951 31.0366 122.672C32.9665 123.783 34.1081 125.178 34.4618 126.626C34.1081 128.073 32.9665 129.464 31.0366 130.579C26.3151 133.3 18.6602 133.3 13.9348 130.579C12.0048 129.468 10.8631 128.073 10.5137 126.626C10.8631 125.178 12.0048 123.783 13.9348 122.672Z"
      fill="#1D1D1D"
    />
    <path
      d="M30.0862 127.753C30.0862 127.518 30.0862 126.561 30.0862 126.313C30.0862 125.721 29.6801 125.15 28.7943 124.635L28.7658 124.614C27.1405 123.678 25.4625 123.803 23.9672 124.177C23.3334 124.335 22.728 124.541 22.1795 124.728C21.5501 124.961 20.9086 125.16 20.2576 125.324C19.9599 125.396 19.6534 125.425 19.3475 125.409C19.0832 125.396 18.8245 125.328 18.5877 125.211L18.6486 125.17L18.8396 125.052C19.003 124.957 19.1727 124.873 19.3475 124.801C19.7691 124.615 20.2234 124.514 20.6843 124.504C21.1451 124.493 21.6035 124.574 22.0332 124.74L23.5853 123.179V121.748C22.2182 121.284 20.7447 121.23 19.3475 121.593C18.8858 121.712 18.4346 121.868 17.9985 122.06L16.4464 121.168L14.5977 122.23V123.665L15.2315 124.03C15.0604 124.276 14.9657 124.567 14.9593 124.866C14.9593 125.101 14.9593 126.054 14.9593 126.293C14.9593 126.885 15.3859 127.457 16.2798 127.972C17.0567 128.423 17.9332 128.675 18.8314 128.706C18.998 128.706 19.1728 128.706 19.3434 128.706C20.2809 128.638 21.2042 128.438 22.086 128.114L22.6183 127.931L21.058 129.492V130.924C22.0208 131.286 23.0472 131.45 24.0751 131.405C25.1031 131.361 26.1113 131.108 27.039 130.664L28.5708 131.548L30.4196 130.482V129.05L29.7857 128.685C29.976 128.411 30.0808 128.086 30.0862 127.753ZM26.1817 127.631C26.0435 127.71 25.9012 127.78 25.7551 127.842C24.7916 128.256 23.7032 128.272 22.728 127.887C24.1785 127.38 25.3853 126.934 26.4172 127.481L26.1817 127.631Z"
      fill="currentColor"
    />
    <path
      d="M23.9916 124.177C23.3578 124.335 22.7524 124.541 22.2039 124.728C21.5745 124.961 20.9329 125.16 20.282 125.324C19.9843 125.396 19.6778 125.425 19.3719 125.409C19.0612 125.395 18.7586 125.306 18.4902 125.15C17.8929 124.805 17.8076 124.302 18.673 123.739C18.7346 123.696 18.7983 123.657 18.864 123.621C19.0274 123.526 19.1971 123.442 19.3719 123.369C19.7932 123.183 20.2476 123.081 20.7086 123.071C21.1696 123.06 21.6281 123.141 22.0576 123.309L23.6097 121.748C22.2426 121.284 20.7692 121.23 19.3719 121.593C18.9102 121.712 18.459 121.868 18.0229 122.06L16.4708 121.168L14.6221 122.23L16.1539 123.114C14.5977 124.294 14.5652 125.519 16.3164 126.524C17.0917 126.979 17.9692 127.231 18.8681 127.258C19.0346 127.258 19.2093 127.258 19.38 127.258C20.3172 127.188 21.2402 126.989 22.1226 126.666C23.8982 126.074 25.3365 125.377 26.5311 126.066C27.3437 126.524 27.112 127.108 26.2303 127.615C26.0924 127.693 25.9498 127.764 25.8037 127.826C25.3171 128.034 24.7938 128.144 24.2643 128.148C23.7348 128.152 23.2098 128.051 22.7199 127.85L21.0946 129.472C22.0574 129.834 23.0837 129.998 24.1117 129.953C25.1397 129.909 26.1479 129.657 27.0756 129.213L28.6074 130.096L30.4561 129.03L28.9243 128.146C30.4643 126.958 30.6431 125.664 28.8429 124.614L28.8146 124.594C27.1567 123.678 25.4787 123.803 23.9916 124.177Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M23.9916 124.177C23.3578 124.335 22.7524 124.542 22.2039 124.728C21.5745 124.962 20.9329 125.161 20.282 125.325C19.9843 125.396 19.6778 125.425 19.3719 125.41C19.0612 125.396 18.7586 125.307 18.4902 125.15C17.8929 124.806 17.8076 124.303 18.673 123.739C18.7346 123.696 18.7983 123.657 18.864 123.621C19.0274 123.526 19.1971 123.442 19.3719 123.37C19.7932 123.183 20.2476 123.082 20.7086 123.071C21.1696 123.061 21.6281 123.142 22.0576 123.309L23.6097 121.748C22.2426 121.284 20.7692 121.23 19.3719 121.594C18.9102 121.712 18.459 121.868 18.0229 122.06L16.4708 121.168L14.6221 122.231L16.1539 123.115C14.5977 124.294 14.5652 125.519 16.3164 126.525C17.0917 126.979 17.9692 127.232 18.8681 127.258C19.0346 127.258 19.2093 127.258 19.38 127.258C20.3172 127.189 21.2402 126.99 22.1226 126.667C23.8982 126.075 25.3365 125.377 26.5311 126.066C27.3437 126.525 27.112 127.109 26.2303 127.616C26.0924 127.694 25.9498 127.764 25.8037 127.826C25.3171 128.035 24.7938 128.144 24.2643 128.148C23.7348 128.152 23.2098 128.051 22.7199 127.851L21.0946 129.473C22.0574 129.835 23.0837 129.998 24.1117 129.954C25.1397 129.909 26.1479 129.657 27.0756 129.213L28.6074 130.097L30.4561 129.031L28.9243 128.147C30.4643 126.959 30.6431 125.665 28.8429 124.615L28.8146 124.595C27.1567 123.678 25.4787 123.804 23.9916 124.177Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M14.5977 122.239L16.4667 121.391L17.9985 122.064L16.4464 121.172L14.5977 122.239Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M19.1152 121.658C20.4333 121.391 21.796 121.437 23.093 121.792L22.0163 123.313L23.5684 121.752C22.1295 121.261 20.5735 121.229 19.1152 121.658Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M28.782 124.615L28.8105 124.635C30.6104 125.685 30.4358 126.979 28.8916 128.167C30.2567 126.687 30.0089 125.653 28.53 124.797C27.0511 123.942 25.1576 123.954 23.9834 124.177C25.4786 123.804 27.1567 123.678 28.782 124.615Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M28.8902 128.167L30.0645 129.067L28.5732 130.117L30.422 129.051L28.8902 128.167Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M25.7697 127.842C24.8564 128.304 23.8 128.397 22.8199 128.102L21.0605 129.492L22.6858 127.87C23.176 128.07 23.7011 128.171 24.2306 128.166C24.7601 128.161 25.2833 128.051 25.7697 127.842Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M20.257 125.328C19.5582 125.604 18.5505 125.556 18.0426 125.118C17.5347 124.68 17.8517 124.169 18.648 123.743C17.7826 124.307 17.8679 124.809 18.4652 125.154C18.7336 125.311 19.0362 125.4 19.3469 125.414C19.6528 125.429 19.9593 125.4 20.257 125.328Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M16.117 123.127C15.9829 123.244 14.8696 124.278 15.3613 125.316C15.8854 126.419 17.5188 127.129 18.8352 127.271C17.9364 127.244 17.0589 126.991 16.2836 126.537C14.5283 125.535 14.5608 124.311 16.117 123.127Z"
      fill="#F5F5F5"
    />
    <path
      d="M35.5454 121.371C34.6719 119.899 33.2174 118.532 31.1817 117.344C24.0469 113.233 12.4914 113.233 5.3484 117.344C3.31684 118.516 1.85818 119.899 0.984604 121.371H0.0175781V125.097C0.147598 127.684 1.92319 130.251 5.35245 132.229C12.4873 136.337 24.0428 136.337 31.1858 132.229C34.6152 130.251 36.4028 127.684 36.5368 125.097V121.371H35.5454Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M35.5454 121.371C34.6719 119.899 33.2174 118.532 31.1817 117.344C24.0469 113.233 12.4914 113.233 5.3484 117.344C3.31684 118.516 1.85818 119.899 0.984604 121.371H0.0175781V125.097C0.147598 127.684 1.92319 130.251 5.35245 132.229C12.4873 136.337 24.0428 136.337 31.1858 132.229C34.6152 130.251 36.4028 127.684 36.5368 125.097V121.371H35.5454Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M18.2651 114.263C13.5925 114.263 8.91989 115.289 5.3484 117.345C3.31684 118.516 1.85818 119.899 0.984604 121.371H0.0175781V125.097C0.147598 127.684 1.92319 130.251 5.35245 132.23C8.91989 134.285 13.5965 135.311 18.2691 135.311L18.2651 114.263Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M8.29009 115.97C7.27406 116.349 6.29028 116.808 5.3484 117.345C3.31684 118.516 1.85818 119.899 0.984604 121.371H0.0175781V125.097C0.147598 127.684 1.92319 130.251 5.35246 132.23C6.29521 132.765 7.27883 133.224 8.29415 133.604L8.29009 115.97Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M28.2412 115.97C29.2573 116.349 30.241 116.808 31.1828 117.345C33.2144 118.516 34.6733 119.899 35.5468 121.371H36.5341V125.097C36.4 127.684 34.6121 130.251 31.1828 132.23C30.2402 132.765 29.2565 133.224 28.2412 133.604V115.97Z"
      fill="currentColor"
    />
    <path
      d="M5.34962 113.93C-1.78524 118.041 -1.78118 124.704 5.34962 128.815C11.5906 132.412 21.2243 132.87 28.3184 130.165C28.4132 130.133 28.5067 130.096 28.5989 130.056C29.4904 129.703 30.3542 129.284 31.183 128.803C37.1314 125.377 38.1187 120.17 34.1449 116.156C33.2616 115.283 32.2653 114.532 31.183 113.922C24.0481 109.822 12.4845 109.818 5.34962 113.93Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M5.34962 113.93C-1.78524 118.041 -1.78118 124.704 5.34962 128.815C11.5906 132.412 21.2243 132.87 28.3184 130.165C28.4132 130.133 28.5067 130.096 28.5989 130.056C29.4904 129.703 30.3542 129.284 31.183 128.803C37.1314 125.377 38.1187 120.17 34.1449 116.156C33.2616 115.283 32.2653 114.532 31.183 113.922C24.0481 109.822 12.4845 109.818 5.34962 113.93Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M34.1487 116.168C34.2261 116.254 36.7207 118.881 36.0625 122.656C35.4286 126.273 30.3539 129.318 28.6025 130.048C29.4925 129.698 30.355 129.282 31.1828 128.803C37.1312 125.389 38.1185 120.183 34.1487 116.168Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M2.38815 126.577C2.31095 126.492 -0.183805 123.864 0.478484 120.089C1.10827 116.472 6.16686 113.427 7.93433 112.698C7.044 113.046 6.18032 113.46 5.35016 113.934C-0.598247 117.36 -1.58559 122.563 2.38815 126.577Z"
      fill="#F5F5F5"
    />
    <path
      d="M18.2737 131.143C27.6514 131.143 35.2536 126.763 35.2536 121.359C35.2536 115.955 27.6514 111.575 18.2737 111.575C8.89604 111.575 1.29395 115.955 1.29395 121.359C1.29395 126.763 8.89604 131.143 18.2737 131.143Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M18.2737 131.143C27.6514 131.143 35.2536 126.763 35.2536 121.359C35.2536 115.955 27.6514 111.575 18.2737 111.575C8.89604 111.575 1.29395 115.955 1.29395 121.359C1.29395 126.763 8.89604 131.143 18.2737 131.143Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M34.4828 124.282C35.8479 121.764 35.401 118.986 33.15 116.634C32.707 116.176 32.2259 115.755 31.7117 115.377C31.2712 115.051 30.8128 114.749 30.3384 114.473C30.0661 114.315 29.7857 114.161 29.5013 114.019C30.2043 115.179 29.9482 116.42 25.9462 116.651C18.1205 117.109 28.0386 124.574 28.0386 124.574C28.0386 124.574 33.085 122.141 34.4828 124.282Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M33.6732 122.214C34.8475 122.084 35.4326 120.88 34.9736 119.522C34.5144 118.163 33.194 117.174 32.0197 117.308C30.8454 117.442 30.2644 118.642 30.7193 120C31.1744 121.359 32.499 122.348 33.6732 122.214Z"
      fill="white"
    />
    <path
      d="M21.2975 128.434C20.9115 125.535 8.2061 117.507 8.2061 117.507C8.2061 117.507 5.39033 116.379 6.32487 114.413C5.85578 114.68 5.4014 114.973 4.96372 115.289C4.67118 115.5 4.39896 115.718 4.1511 115.941C0.36833 119.21 0.364267 123.504 4.1511 126.776C4.40707 126.999 4.68338 127.214 4.96372 127.429C5.36976 127.72 5.79013 127.991 6.22329 128.24C6.65805 128.491 7.105 128.722 7.57224 128.941C7.94199 129.115 8.32799 129.282 8.71397 129.432C9.09998 129.582 9.50222 129.732 9.90854 129.862C11.1448 130.264 12.4111 130.567 13.6954 130.77C13.9798 130.814 14.2642 130.859 14.5486 130.896C15.8059 131.055 17.0721 131.134 18.3395 131.131C19.8925 131.13 21.443 131.004 22.9755 130.754C22.9755 130.754 20.9318 130.344 21.2975 128.434Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M21.1147 128.434C20.7287 125.535 8.02333 117.507 8.02333 117.507C8.02333 117.507 5.20757 116.379 6.1421 114.413C5.67373 114.679 5.22066 114.972 4.78501 115.289C4.49247 115.5 4.21619 115.718 3.9724 115.941C0.189619 119.21 0.185556 123.504 3.9724 126.776C4.22836 126.999 4.50465 127.214 4.78501 127.429C5.19105 127.72 5.61142 127.991 6.04458 128.24C6.47525 128.491 6.92629 128.722 7.38947 128.941C7.75922 129.115 8.1452 129.282 8.53526 129.432C8.92533 129.582 9.31945 129.732 9.72577 129.862C10.9635 130.264 12.231 130.567 13.5167 130.77C13.797 130.814 14.0855 130.859 14.3699 130.896C15.6258 131.055 16.8907 131.134 18.1568 131.131C19.7098 131.13 21.2602 131.004 22.7928 130.754C22.7928 130.754 20.7409 130.344 21.1147 128.434Z"
      fill="white"
    />
    <path
      d="M9.32383 116.205C4.58215 118.934 4.39119 123.289 8.74281 126.155C8.92971 126.277 9.12473 126.395 9.32383 126.512C14.2646 129.351 22.273 129.351 27.2015 126.512C32.13 123.674 32.1423 119.051 27.2015 116.205C27.0025 116.087 26.7952 115.978 26.58 115.868C21.6229 113.363 14.0614 113.472 9.32383 116.205Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M9.32383 116.205C4.58215 118.934 4.39119 123.289 8.74281 126.155C8.92971 126.277 9.12473 126.395 9.32383 126.512C14.2646 129.351 22.273 129.351 27.2015 126.512C32.13 123.674 32.1423 119.051 27.2015 116.205C27.0025 116.087 26.7952 115.978 26.58 115.868C21.6229 113.363 14.0614 113.472 9.32383 116.205Z"
      fill="#1D1D1D"
    />
    <path
      d="M18.2755 128.325C24.9536 128.325 30.3674 125.206 30.3674 121.359C30.3674 117.511 24.9536 114.393 18.2755 114.393C11.5973 114.393 6.18359 117.511 6.18359 121.359C6.18359 125.206 11.5973 128.325 18.2755 128.325Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M18.2755 128.325C24.9536 128.325 30.3674 125.206 30.3674 121.359C30.3674 117.511 24.9536 114.393 18.2755 114.393C11.5973 114.393 6.18359 117.511 6.18359 121.359C6.18359 125.206 11.5973 128.325 18.2755 128.325Z"
      fill="#1D1D1D"
    />
    <path
      d="M9.72194 118.374C14.4474 115.653 22.1023 115.653 26.8236 118.374C28.7537 119.485 29.8954 120.884 30.2488 122.327C29.8954 123.775 28.7537 125.166 26.8236 126.285C22.1023 129.006 14.4474 129.006 9.72194 126.285C7.79197 125.17 6.6543 123.775 6.30078 122.327C6.6543 120.884 7.79197 119.477 9.72194 118.374Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M9.72194 118.374C14.4474 115.653 22.1023 115.653 26.8236 118.374C28.7537 119.485 29.8954 120.884 30.2488 122.327C29.8954 123.775 28.7537 125.166 26.8236 126.285C22.1023 129.006 14.4474 129.006 9.72194 126.285C7.79197 125.17 6.6543 123.775 6.30078 122.327C6.6543 120.884 7.79197 119.477 9.72194 118.374Z"
      fill="#1D1D1D"
    />
    <path
      d="M25.8891 123.455C25.8891 123.22 25.8891 122.263 25.8891 122.015C25.8891 121.427 25.4827 120.852 24.597 120.337H24.5685C22.9433 119.4 21.2652 119.526 19.7699 119.899C19.1361 120.061 18.5307 120.264 17.9822 120.454C17.3535 120.688 16.7118 120.886 16.0603 121.046C15.762 121.114 15.456 121.143 15.1502 121.131C14.8862 121.116 14.6279 121.049 14.3904 120.933L14.4513 120.888L14.6423 120.77C14.8058 120.677 14.9755 120.594 15.1502 120.523C15.5716 120.336 16.0259 120.235 16.4869 120.224C16.9479 120.214 17.4065 120.295 17.8359 120.462L19.388 118.901V117.45C18.0209 116.985 16.5475 116.932 15.1502 117.296C14.6891 117.416 14.238 117.572 13.8012 117.762L12.2491 116.87L10.4004 117.936V119.368L11.0342 119.733C10.8631 119.978 10.7684 120.269 10.762 120.568C10.762 120.807 10.762 121.76 10.762 121.999C10.762 122.591 11.1683 123.159 12.0785 123.674C12.8538 124.128 13.7313 124.381 14.6301 124.408C14.8008 124.408 14.9714 124.408 15.142 124.408C16.0791 124.339 17.0021 124.141 17.8847 123.82L18.4169 123.633L16.8567 125.194V126.63C17.8195 126.992 18.8458 127.156 19.8738 127.111C20.9017 127.067 21.91 126.815 22.8376 126.37L24.3694 127.25L26.2181 126.184V124.748L25.5843 124.383C25.7756 124.111 25.8814 123.788 25.8891 123.455ZM21.9843 123.333C21.8451 123.408 21.7012 123.475 21.5537 123.532C20.5898 123.943 19.5023 123.959 18.5266 123.577C19.9772 123.065 21.1839 122.619 22.216 123.171C22.1418 123.229 22.0644 123.283 21.9843 123.333Z"
      fill="currentColor"
    />
    <path
      d="M19.7699 119.883C19.1361 120.045 18.5307 120.248 17.9822 120.438C17.3535 120.672 16.7118 120.87 16.0603 121.03C15.762 121.098 15.456 121.127 15.1502 121.115C14.8395 121.098 14.5374 121.008 14.2685 120.852C13.6753 120.511 13.5859 120.008 14.4513 119.441L14.6423 119.323C14.8066 119.229 14.9762 119.145 15.1502 119.072C15.5719 118.886 16.0261 118.785 16.487 118.774C16.9478 118.764 17.4062 118.844 17.8359 119.011L19.388 117.45C18.0209 116.985 16.5475 116.932 15.1502 117.296C14.6891 117.416 14.238 117.572 13.8012 117.762L12.2491 116.87L10.4004 117.936L11.9322 118.82C10.376 119.996 10.3435 121.221 12.0947 122.23C12.8716 122.681 13.7482 122.933 14.6463 122.964C14.817 122.964 14.9877 122.964 15.1583 122.964C16.0958 122.896 17.0191 122.697 17.9009 122.372C19.6765 121.78 21.1148 121.083 22.3094 121.772C23.122 122.234 22.8904 122.814 22.0087 123.321C21.8618 123.401 21.7098 123.471 21.5537 123.532C21.0657 123.737 20.5414 123.843 20.0117 123.843C19.482 123.843 18.9577 123.737 18.4698 123.532L16.8445 125.154C17.807 125.517 18.8332 125.681 19.8612 125.637C20.8891 125.593 21.8975 125.342 22.8254 124.898L24.3572 125.778L26.206 124.712L24.6741 123.832C26.214 122.644 26.3928 121.346 24.5929 120.296H24.5644C22.9433 119.384 21.2652 119.506 19.7699 119.883Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M19.7709 119.883C19.1371 120.045 18.5317 120.248 17.9832 120.438C17.3545 120.672 16.7128 120.87 16.0613 121.03C15.763 121.098 15.457 121.127 15.1512 121.115C14.8405 121.099 14.5383 121.008 14.2695 120.852C13.6763 120.511 13.5869 120.008 14.4523 119.441L14.6433 119.323C14.8076 119.23 14.9772 119.146 15.1512 119.072C15.5728 118.886 16.0271 118.785 16.488 118.775C16.9488 118.764 17.4072 118.844 17.8369 119.011L19.389 117.45C18.0219 116.986 16.5485 116.932 15.1512 117.296C14.69 117.416 14.239 117.572 13.8022 117.762L12.2501 116.87L10.4014 117.936L11.9332 118.82C10.377 119.996 10.3445 121.221 12.0957 122.23C12.8726 122.682 13.7492 122.933 14.6473 122.964C14.818 122.964 14.9886 122.964 15.1593 122.964C16.0968 122.897 17.0201 122.697 17.9019 122.372C19.6775 121.78 21.1158 121.083 22.3104 121.772C23.123 122.234 22.8914 122.814 22.0097 123.321C21.8628 123.401 21.7108 123.472 21.5547 123.532C21.0667 123.738 20.5423 123.844 20.0127 123.844C19.483 123.844 18.9587 123.738 18.4707 123.532L16.8455 125.154C17.808 125.517 18.8342 125.681 19.8622 125.638C20.8901 125.593 21.8985 125.342 22.8264 124.899L24.3582 125.779L26.207 124.712L24.6751 123.832C26.215 122.644 26.3938 121.347 24.5939 120.296H24.5654C22.9442 119.384 21.2662 119.506 19.7709 119.883Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M10.3828 117.941L12.2518 117.097L13.7836 117.766L12.2315 116.874L10.3828 117.941Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M14.9189 117.36C16.2374 117.097 17.5991 117.143 18.8967 117.494L17.82 119.015L19.3721 117.458C17.9333 116.968 16.3779 116.934 14.9189 117.36Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M24.5681 120.321H24.5965C26.3966 121.371 26.2217 122.668 24.6778 123.856C26.0429 122.372 25.7951 121.338 24.3162 120.483C22.8372 119.627 20.9438 119.643 19.7695 119.866C21.2648 119.506 22.9428 119.384 24.5681 120.321Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M24.6773 123.873L25.8514 124.773L24.3604 125.819L26.2092 124.752L24.6773 123.873Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M21.5539 123.532C20.6404 123.994 19.5836 124.085 18.6041 123.788L16.8447 125.179L18.47 123.557C18.9596 123.758 19.4847 123.86 20.0144 123.856C20.5441 123.852 21.0675 123.742 21.5539 123.532Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M16.0432 121.034C15.3443 121.306 14.3366 121.262 13.8288 120.824C13.3209 120.386 13.6378 119.871 14.4342 119.445C13.5687 120.013 13.6581 120.515 14.2513 120.856C14.5202 121.012 14.8224 121.103 15.133 121.12C15.4388 121.131 15.7448 121.102 16.0432 121.034Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M11.9041 118.836C11.7741 118.95 10.6568 119.988 11.1484 121.022C11.6725 122.129 13.3059 122.834 14.6224 122.98C13.7242 122.949 12.8476 122.697 12.0707 122.246C10.3154 121.237 10.348 120.012 11.9041 118.836Z"
      fill="#F5F5F5"
    />
    <path
      d="M39.4309 115.479C38.5574 114.007 37.1067 112.641 35.0672 111.453C27.9363 107.341 16.3768 107.341 9.23786 111.453C7.2063 112.624 5.74765 114.007 4.87406 115.479H3.88672V119.205C4.01673 121.792 5.79234 124.359 9.22568 126.338C16.3565 130.445 27.916 130.445 35.0548 126.338C38.4883 124.359 40.2762 121.792 40.4061 119.205V115.479H39.4309Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M39.4309 115.479C38.5574 114.007 37.1067 112.641 35.0672 111.453C27.9363 107.341 16.3768 107.341 9.23786 111.453C7.2063 112.624 5.74765 114.007 4.87406 115.479H3.88672V119.205C4.01673 121.792 5.79234 124.359 9.22568 126.338C16.3565 130.445 27.916 130.445 35.0548 126.338C38.4883 124.359 40.2762 121.792 40.4061 119.205V115.479H39.4309Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M22.1702 108.372C17.4935 108.372 12.825 109.397 9.25348 111.453C7.22193 112.625 5.76325 114.008 4.88969 115.48H3.90234V119.206C4.03236 121.793 5.80797 124.36 9.24131 126.338C12.8047 128.394 17.4813 129.42 22.158 129.42L22.1702 108.372Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M12.2077 110.078C11.1927 110.456 10.2102 110.916 9.27008 111.453C7.2385 112.625 5.77986 114.007 4.90629 115.479H3.91895V119.206C4.04896 121.793 5.82455 124.359 9.25788 126.338C10.1988 126.874 11.1812 127.333 12.1955 127.712L12.2077 110.078Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M32.1465 110.078C33.1615 110.456 34.1439 110.916 35.0842 111.453C37.1158 112.625 38.5744 114.007 39.4479 115.479H40.4352V119.206C40.3053 121.793 38.5174 124.359 35.0842 126.338C34.1432 126.874 33.161 127.333 32.1465 127.712V110.078Z"
      fill="currentColor"
    />
    <path
      d="M9.25042 108.039C2.11965 112.15 2.11965 118.812 9.25042 122.924C15.4955 126.521 25.1291 126.979 32.2192 124.274C32.3127 124.238 32.4102 124.205 32.5037 124.165C33.3924 123.815 34.2546 123.401 35.0837 122.928C41.0321 119.502 42.0235 114.295 38.0499 110.281C37.168 109.407 36.1717 108.655 35.0878 108.047C27.953 103.931 16.3853 103.927 9.25042 108.039Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M9.25042 108.039C2.11965 112.15 2.11965 118.812 9.25042 122.924C15.4955 126.521 25.1291 126.979 32.2192 124.274C32.3127 124.238 32.4102 124.205 32.5037 124.165C33.3924 123.815 34.2546 123.401 35.0837 122.928C41.0321 119.502 42.0235 114.295 38.0499 110.281C37.168 109.407 36.1717 108.655 35.0878 108.047C27.953 103.931 16.3853 103.927 9.25042 108.039Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M38.0511 110.277C38.1281 110.362 40.6228 112.989 39.9607 116.764C39.3268 120.381 34.2519 123.426 32.5049 124.156C33.3936 123.806 34.2558 123.393 35.0848 122.92C41.0333 119.497 42.0247 114.291 38.0511 110.277Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M6.28829 120.694C6.21108 120.608 3.71631 117.981 4.3786 114.206C5.0084 110.589 10.067 107.544 11.8344 106.814C10.9458 107.164 10.0835 107.577 9.25435 108.051C3.30188 111.465 2.31454 116.671 6.28829 120.694Z"
      fill="#F5F5F5"
    />
    <path
      d="M22.1751 125.251C31.5529 125.251 39.1548 120.871 39.1548 115.467C39.1548 110.064 31.5529 105.683 22.1751 105.683C12.7974 105.683 5.19531 110.064 5.19531 115.467C5.19531 120.871 12.7974 125.251 22.1751 125.251Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M22.1751 125.251C31.5529 125.251 39.1548 120.871 39.1548 115.467C39.1548 110.064 31.5529 105.683 22.1751 105.683C12.7974 105.683 5.19531 110.064 5.19531 115.467C5.19531 120.871 12.7974 125.251 22.1751 125.251Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M38.3827 118.39C39.7481 115.872 39.305 113.095 37.0502 110.743C36.608 110.283 36.1266 109.863 35.6117 109.486C35.1727 109.157 34.714 108.855 34.2384 108.582C33.9661 108.424 33.69 108.27 33.4013 108.128C34.1084 109.288 33.8485 110.528 29.8502 110.759C22.0247 111.218 31.9386 118.682 31.9386 118.682C31.9386 118.682 36.9932 116.233 38.3827 118.39Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M37.5784 116.323C38.7526 116.193 39.3336 114.988 38.8785 113.63C38.4234 112.272 37.0989 111.282 35.9246 111.416C34.7503 111.55 34.1652 112.75 34.6245 114.108C35.0837 115.467 36.4041 116.456 37.5784 116.323Z"
      fill="white"
    />
    <path
      d="M25.2104 122.543C24.8041 119.644 12.119 111.615 12.119 111.615C12.119 111.615 9.30329 110.488 10.2378 108.521C9.76943 108.788 9.31636 109.08 8.88071 109.397C8.58817 109.608 8.31188 109.827 8.06809 110.05C4.28532 113.318 4.28126 117.612 8.06809 120.884C8.32406 121.107 8.60037 121.322 8.88071 121.537C9.28675 121.828 9.70714 122.099 10.1403 122.348C10.571 122.599 11.022 122.831 11.4852 123.05C11.8549 123.224 12.2409 123.39 12.631 123.54C13.021 123.69 13.4152 123.836 13.8215 123.97C15.0592 124.372 16.3267 124.676 17.6124 124.878C17.8927 124.923 18.1812 124.968 18.4656 125.004C21.2655 125.36 24.1022 125.312 26.8885 124.862C26.8885 124.862 24.8325 124.453 25.2104 122.543Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M25.0151 122.543C24.6291 119.644 11.9278 111.615 11.9278 111.615C11.9278 111.615 9.10797 110.488 10.0465 108.521C9.57689 108.788 9.12246 109.08 8.6854 109.397C8.39285 109.608 8.11657 109.827 7.87278 110.05C4.09001 113.318 4.08595 117.612 7.87278 120.884C8.12469 121.107 8.40097 121.322 8.6854 121.537C9.09143 121.828 9.51183 122.099 9.94497 122.348C10.3757 122.599 10.8267 122.831 11.2939 123.05C11.6637 123.224 12.0456 123.39 12.4357 123.54C12.8257 123.69 13.2239 123.836 13.6262 123.97C14.8638 124.372 16.1314 124.676 17.4171 124.878C17.7015 124.923 17.9859 124.968 18.2703 125.004C21.0716 125.36 23.9096 125.312 26.6972 124.862C26.6972 124.862 24.6535 124.453 25.0151 122.543Z"
      fill="white"
    />
    <path
      d="M13.2241 110.313C8.48654 113.042 8.29149 117.397 12.6472 120.264C12.83 120.386 13.025 120.503 13.2241 120.621C18.1649 123.459 26.1734 123.459 31.1019 120.621C36.0304 117.782 36.0425 113.16 31.1019 110.313C30.9029 110.196 30.6956 110.086 30.4842 109.977C25.5232 107.471 17.9617 107.58 13.2241 110.313Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M13.2241 110.313C8.48654 113.042 8.29149 117.397 12.6472 120.264C12.83 120.386 13.025 120.503 13.2241 120.621C18.1649 123.459 26.1734 123.459 31.1019 120.621C36.0304 117.782 36.0425 113.16 31.1019 110.313C30.9029 110.196 30.6956 110.086 30.4842 109.977C25.5232 107.471 17.9617 107.58 13.2241 110.313Z"
      fill="#1D1D1D"
    />
    <path
      d="M22.1749 122.433C28.8529 122.433 34.2666 119.314 34.2666 115.467C34.2666 111.62 28.8529 108.501 22.1749 108.501C15.4967 108.501 10.083 111.62 10.083 115.467C10.083 119.314 15.4967 122.433 22.1749 122.433Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M22.1749 122.433C28.8529 122.433 34.2666 119.314 34.2666 115.467C34.2666 111.62 28.8529 108.501 22.1749 108.501C15.4967 108.501 10.083 111.62 10.083 115.467C10.083 119.314 15.4967 122.433 22.1749 122.433Z"
      fill="#1D1D1D"
    />
    <path
      d="M13.6254 112.483C18.3468 109.762 26.0016 109.762 30.7272 112.483C32.6571 113.594 33.7949 114.993 34.1483 116.436C33.7987 117.884 32.6571 119.274 30.7272 120.394C26.0016 123.114 18.3468 123.114 13.6254 120.394C11.6954 119.279 10.5537 117.884 10.2002 116.436C10.5537 114.993 11.6954 113.594 13.6254 112.483Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M13.6254 112.483C18.3468 109.762 26.0016 109.762 30.7272 112.483C32.6571 113.594 33.7949 114.993 34.1483 116.436C33.7987 117.884 32.6571 119.274 30.7272 120.394C26.0016 123.114 18.3468 123.114 13.6254 120.394C11.6954 119.279 10.5537 117.884 10.2002 116.436C10.5537 114.993 11.6954 113.594 13.6254 112.483Z"
      fill="#1D1D1D"
    />
    <path
      d="M29.7894 117.563C29.7894 117.328 29.7894 116.371 29.7894 116.124C29.7894 115.536 29.383 114.96 28.5013 114.445H28.4689C26.8437 113.508 25.1696 113.634 23.6744 114.007C23.0365 114.165 22.4351 114.372 21.8826 114.563C21.2552 114.797 20.6149 114.994 19.9648 115.155C19.6651 115.223 19.3577 115.251 19.0506 115.24C18.7878 115.225 18.5307 115.157 18.2948 115.041L18.3558 114.996C18.4127 114.956 18.4777 114.919 18.5427 114.879C18.7062 114.785 18.8759 114.702 19.0506 114.631C19.4724 114.444 19.9275 114.342 20.3893 114.331C20.8512 114.321 21.3104 114.402 21.7404 114.571L23.2884 113.01V111.574C21.9214 111.11 20.4478 111.056 19.0506 111.42C18.5894 111.54 18.1384 111.696 17.7016 111.886L16.1535 110.994L14.3008 112.061V113.492L14.9346 113.857C14.7652 114.103 14.6707 114.394 14.6624 114.692C14.6624 114.932 14.6624 115.88 14.6624 116.124C14.6624 116.716 15.089 117.283 15.9788 117.798C16.7554 118.253 17.6344 118.506 18.5346 118.532C18.7052 118.532 18.8759 118.532 19.0465 118.532C19.9836 118.464 20.9066 118.266 21.7891 117.944L22.3254 117.758L20.7408 119.303V120.738C21.7029 121.1 22.7286 121.264 23.7559 121.22C24.7832 121.175 25.7909 120.923 26.7178 120.478L28.2496 121.358L30.1022 120.292V118.861L29.4645 118.496C29.6635 118.224 29.7768 117.899 29.7894 117.563ZM25.8887 117.441C25.7498 117.522 25.606 117.594 25.4581 117.656C24.4957 118.068 23.4093 118.084 22.4352 117.701C23.8816 117.19 25.0924 116.744 26.1245 117.296C26.0487 117.351 25.9701 117.402 25.8887 117.45V117.441Z"
      fill="currentColor"
    />
    <path
      d="M23.6744 113.991C23.0365 114.149 22.4352 114.356 21.8826 114.547C21.2552 114.78 20.6149 114.978 19.9648 115.138C19.6651 115.207 19.3577 115.235 19.0506 115.224C18.7411 115.207 18.4401 115.117 18.1729 114.96C17.5756 114.62 17.4903 114.117 18.3558 113.549C18.4127 113.509 18.4777 113.472 18.5427 113.431C18.707 113.338 18.8766 113.254 19.0506 113.18C19.473 112.994 19.9279 112.893 20.3894 112.883C20.8509 112.872 21.31 112.953 21.7404 113.119L23.2884 111.558C21.9214 111.094 20.4478 111.04 19.0506 111.404C18.5894 111.524 18.1384 111.68 17.7016 111.87L16.1535 110.978L14.3008 112.045L15.8366 112.929C14.2805 114.105 14.252 115.329 15.9992 116.339C16.7772 116.79 17.6553 117.043 18.5549 117.073C18.7255 117.073 18.8962 117.073 19.0668 117.073C20.0043 117.005 20.9276 116.806 21.8094 116.481C23.585 115.889 25.0234 115.191 26.2221 115.881C27.0345 116.343 26.799 116.923 25.9213 117.43C25.7824 117.51 25.6385 117.582 25.4906 117.644C25.0044 117.852 24.4816 117.961 23.9527 117.965C23.4239 117.968 22.8997 117.866 22.4108 117.665L20.7855 119.287C21.7474 119.649 22.7729 119.814 23.8002 119.77C24.8275 119.726 25.8353 119.475 26.7625 119.031L28.2943 119.911L30.1469 118.845L28.6151 117.965C30.1551 116.777 30.3297 115.479 28.5339 114.429H28.5013C26.8354 113.492 25.1696 113.614 23.6744 113.991Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M23.6744 113.991C23.0365 114.149 22.4352 114.356 21.8826 114.547C21.2552 114.78 20.6149 114.978 19.9648 115.138C19.6651 115.207 19.3577 115.235 19.0506 115.224C18.7411 115.207 18.4401 115.117 18.1729 114.96C17.5756 114.62 17.4903 114.117 18.3558 113.549C18.4127 113.509 18.4777 113.472 18.5427 113.431C18.707 113.338 18.8766 113.254 19.0506 113.18C19.473 112.994 19.9279 112.893 20.3894 112.883C20.8509 112.872 21.31 112.953 21.7404 113.119L23.2884 111.558C21.9214 111.094 20.4478 111.04 19.0506 111.404C18.5894 111.524 18.1384 111.68 17.7016 111.87L16.1535 110.978L14.3008 112.045L15.8366 112.929C14.2805 114.105 14.252 115.329 15.9992 116.339C16.7772 116.79 17.6553 117.043 18.5549 117.073C18.7255 117.073 18.8962 117.073 19.0668 117.073C20.0043 117.005 20.9276 116.806 21.8094 116.481C23.585 115.889 25.0234 115.191 26.2221 115.881C27.0345 116.343 26.799 116.923 25.9213 117.43C25.7824 117.51 25.6385 117.582 25.4906 117.644C25.0044 117.852 24.4816 117.961 23.9527 117.965C23.4239 117.968 22.8997 117.866 22.4108 117.665L20.7855 119.287C21.7474 119.649 22.7729 119.814 23.8002 119.77C24.8275 119.726 25.8353 119.475 26.7625 119.031L28.2943 119.911L30.1469 118.845L28.6151 117.965C30.1551 116.777 30.3297 115.479 28.5339 114.429H28.5013C26.8354 113.492 25.1696 113.614 23.6744 113.991Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M14.2891 112.049L16.154 111.205L17.6899 111.874L16.1378 110.982L14.2891 112.049Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M18.8193 111.469C20.1378 111.206 21.4995 111.252 22.7972 111.603L21.7245 113.123L23.2725 111.562C21.8329 111.076 20.2781 111.043 18.8193 111.469Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M28.4606 114.429H28.4929C30.289 115.479 30.1182 116.777 28.5744 117.965C29.9354 116.481 29.6916 115.447 28.2086 114.591C26.7256 113.736 24.8362 113.752 23.666 113.975C25.1694 113.614 26.8353 113.492 28.4606 114.429Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M28.5837 117.981L29.758 118.881L28.2627 119.927L30.1155 118.861L28.5837 117.981Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M25.4575 117.656C24.5433 118.116 23.4874 118.207 22.5077 117.912L20.7402 119.303L22.3655 117.681C22.8563 117.883 23.3829 117.985 23.914 117.981C24.4451 117.977 24.9699 117.866 25.4575 117.656Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M19.9493 115.143C19.2504 115.414 18.2428 115.37 17.7308 114.932C17.2189 114.494 17.5399 113.979 18.3403 113.553C17.4748 114.121 17.5602 114.624 18.1575 114.964C18.4246 115.121 18.7256 115.211 19.0351 115.228C19.3422 115.239 19.6496 115.211 19.9493 115.143Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M15.8021 112.945C15.6721 113.058 14.5588 114.096 15.0504 115.13C15.5705 116.237 17.2039 116.943 18.5203 117.089C17.6208 117.059 16.7427 116.806 15.9646 116.355C14.2378 115.345 14.2378 114.121 15.8021 112.945Z"
      fill="#F5F5F5"
    />
    <path
      d="M40.4257 108.428C39.5519 106.96 38.0974 105.59 36.0617 104.402C28.9269 100.294 17.3714 100.294 10.2284 104.402C8.19688 105.578 6.73821 106.96 5.86464 108.428H4.89355V112.154C5.02357 114.746 6.79916 117.312 10.2284 119.287C17.3633 123.398 28.9187 123.398 36.0617 119.287C39.491 117.312 41.283 114.741 41.413 112.154V108.428H40.4257Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M40.4257 108.428C39.5519 106.96 38.0974 105.59 36.0617 104.402C28.9269 100.294 17.3714 100.294 10.2284 104.402C8.19688 105.578 6.73821 106.96 5.86464 108.428H4.89355V112.154C5.02357 114.746 6.79916 117.312 10.2284 119.287C17.3633 123.398 28.9187 123.398 36.0617 119.287C39.491 117.312 41.283 114.741 41.413 112.154V108.428H40.4257Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M23.1451 101.32C18.4725 101.32 13.7999 102.346 10.2284 104.402C8.19688 105.578 6.73821 106.96 5.86464 108.428H4.89355V112.154C5.02357 114.745 6.79916 117.312 10.2284 119.287C13.7959 121.342 18.4725 122.369 23.1451 122.369V101.32Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M13.1701 103.027C12.1541 103.405 11.1703 103.865 10.2284 104.401C8.19688 105.577 6.73821 106.96 5.86464 108.428H4.89355V112.154C5.02357 114.745 6.79916 117.312 10.2284 119.287C11.1703 119.823 12.1541 120.283 13.1701 120.661V103.027Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M33.124 103.027C34.1378 103.408 35.1199 103.867 36.0615 104.401C38.0931 105.577 39.5517 106.96 40.4255 108.428H41.4128V112.154C41.2828 114.741 39.4908 117.312 36.0615 119.287C35.1207 119.822 34.1383 120.282 33.124 120.661V103.027Z"
      fill="currentColor"
    />
    <path
      d="M10.2295 100.987C3.09463 105.099 3.09872 111.765 10.2295 115.872C16.4704 119.469 26.1082 119.927 33.1983 117.223L33.4826 117.113C34.3716 116.762 35.2338 116.347 36.0629 115.872C42.0113 112.446 42.9986 107.244 39.0247 103.226C38.142 102.352 37.1457 101.601 36.0629 100.991C28.9319 96.8799 17.3643 96.8799 10.2295 100.987Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M10.2295 100.987C3.09463 105.099 3.09872 111.765 10.2295 115.872C16.4704 119.469 26.1082 119.927 33.1983 117.223L33.4826 117.113C34.3716 116.762 35.2338 116.347 36.0629 115.872C42.0113 112.446 42.9986 107.244 39.0247 103.226C38.142 102.352 37.1457 101.601 36.0629 100.991C28.9319 96.8799 17.3643 96.8799 10.2295 100.987Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M39.0245 103.226C39.1018 103.311 41.5965 105.939 40.9382 109.714C40.3044 113.33 35.25 116.376 33.4824 117.11C34.3713 116.758 35.2336 116.344 36.0626 115.869C42.0111 112.446 42.9984 107.244 39.0245 103.226Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M7.26707 113.638C7.18986 113.549 4.69512 110.926 5.35741 107.151C5.98718 103.534 11.0458 100.489 12.8132 99.7588C11.9242 100.103 11.0606 100.51 10.2291 100.975C4.28066 104.418 3.29331 109.62 7.26707 113.638Z"
      fill="#F5F5F5"
    />
    <path
      d="M23.1536 118.2C32.5313 118.2 40.1335 113.82 40.1335 108.416C40.1335 103.012 32.5313 98.6318 23.1536 98.6318C13.7759 98.6318 6.17383 103.012 6.17383 108.416C6.17383 113.82 13.7759 118.2 23.1536 118.2Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M23.1536 118.2C32.5313 118.2 40.1335 113.82 40.1335 108.416C40.1335 103.012 32.5313 98.6318 23.1536 98.6318C13.7759 98.6318 6.17383 103.012 6.17383 108.416C6.17383 113.82 13.7759 118.2 23.1536 118.2Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M39.3608 111.339C40.7262 108.821 40.279 106.044 38.0281 103.692C37.5822 103.238 37.0999 102.822 36.5857 102.447C36.146 102.119 35.6875 101.817 35.2124 101.543C34.9401 101.385 34.6599 101.235 34.3753 101.089C35.0783 102.248 34.8225 103.489 30.8201 103.724C22.9946 104.178 32.9126 111.643 32.9126 111.643C32.9126 111.643 37.9631 109.202 39.3608 111.339Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M38.5538 109.276C39.728 109.142 40.3131 107.937 39.8539 106.583C39.3947 105.229 38.0783 104.235 36.9041 104.365C35.7298 104.495 35.1447 105.703 35.6039 107.057C36.0629 108.412 37.3998 109.405 38.5538 109.276Z"
      fill="white"
    />
    <path
      d="M26.1776 115.496C25.7916 112.592 13.0863 104.548 13.0863 104.548C13.0863 104.548 10.2705 103.421 11.205 101.454C10.7357 101.72 10.2826 102.014 9.84794 102.334C9.55134 102.545 9.27911 102.76 9.03532 102.983C5.25661 106.251 5.24849 110.545 9.03532 113.817C9.29129 114.04 9.5676 114.259 9.84794 114.47C10.2527 114.763 10.6732 115.034 11.1075 115.281C11.5382 115.532 11.9892 115.767 12.4524 115.982C12.8221 116.156 13.2082 116.323 13.5982 116.477C13.9883 116.631 14.3824 116.773 14.7887 116.882C16.0267 117.284 17.2941 117.589 18.5796 117.795C18.8599 117.839 19.1444 117.88 19.4288 117.916C20.6861 118.076 21.9523 118.155 23.2197 118.155C24.7728 118.154 26.3233 118.026 27.8558 117.774C27.8558 117.774 25.8119 117.401 26.1776 115.496Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M25.9945 115.496C25.6086 112.592 12.9073 104.548 12.9073 104.548C12.9073 104.548 10.0874 103.421 11.022 101.454C10.5532 101.721 10.1002 102.015 9.66489 102.334C9.37235 102.545 9.09606 102.76 8.85227 102.983C5.0695 106.251 5.06544 110.545 8.85227 113.817C9.10824 114.04 9.38452 114.259 9.66489 114.47C10.0709 114.761 10.4913 115.032 10.9245 115.281C11.3552 115.532 11.8062 115.767 12.2694 115.982C12.6391 116.156 13.0251 116.323 13.4152 116.477C13.8052 116.631 14.1993 116.773 14.6056 116.882C15.8438 117.284 17.1112 117.589 18.3966 117.795C18.6769 117.839 18.9654 117.88 19.2498 117.916C20.5071 118.076 21.7733 118.155 23.0407 118.155C24.5938 118.153 26.1443 118.026 27.6768 117.774C27.6768 117.774 25.6167 117.401 25.9945 115.496Z"
      fill="white"
    />
    <path
      d="M14.2027 103.258C9.46106 105.987 9.2701 110.346 13.6217 113.209C13.8086 113.33 14.0036 113.452 14.2027 113.565C19.1435 116.404 27.1519 116.404 32.0804 113.565C37.0091 110.727 37.0212 106.105 32.0804 103.258C31.8813 103.14 31.6741 103.031 31.4588 102.926C26.5018 100.42 18.9403 100.533 14.2027 103.258Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M14.2027 103.258C9.46106 105.987 9.2701 110.346 13.6217 113.209C13.8086 113.33 14.0036 113.452 14.2027 113.565C19.1435 116.404 27.1519 116.404 32.0804 113.565C37.0091 110.727 37.0212 106.105 32.0804 103.258C31.8813 103.14 31.6741 103.031 31.4588 102.926C26.5018 100.42 18.9403 100.533 14.2027 103.258Z"
      fill="#1D1D1D"
    />
    <path
      d="M23.1544 115.382C29.8326 115.382 35.2462 112.263 35.2462 108.416C35.2462 104.568 29.8326 101.449 23.1544 101.449C16.4762 101.449 11.0625 104.568 11.0625 108.416C11.0625 112.263 16.4762 115.382 23.1544 115.382Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M23.1544 115.382C29.8326 115.382 35.2462 112.263 35.2462 108.416C35.2462 104.568 29.8326 101.449 23.1544 101.449C16.4762 101.449 11.0625 104.568 11.0625 108.416C11.0625 112.263 16.4762 115.382 23.1544 115.382Z"
      fill="#1D1D1D"
    />
    <path
      d="M14.6049 105.431C19.3262 102.711 26.9812 102.711 31.7024 105.431C33.6367 106.547 34.7742 107.942 35.1277 109.389C34.7742 110.837 33.6367 112.227 31.7024 113.342C26.9812 116.063 19.3262 116.063 14.6049 113.342C12.6709 112.231 11.5332 110.832 11.1797 109.389C11.5332 107.942 12.6709 106.547 14.6049 105.431Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M14.6049 105.431C19.3262 102.711 26.9812 102.711 31.7024 105.431C33.6367 106.547 34.7742 107.942 35.1277 109.389C34.7742 110.837 33.6367 112.227 31.7024 113.342C26.9812 116.063 19.3262 116.063 14.6049 113.342C12.6709 112.231 11.5332 110.832 11.1797 109.389C11.5332 107.942 12.6709 106.547 14.6049 105.431Z"
      fill="#1D1D1D"
    />
    <path
      d="M30.768 110.516C30.768 110.281 30.768 109.32 30.768 109.073C30.768 108.485 30.3617 107.913 29.4758 107.398H29.4473C27.8223 106.461 26.1441 106.587 24.6529 106.96C24.0191 107.122 23.434 107.329 22.8652 107.515C22.2367 107.75 21.5949 107.948 20.9433 108.108C20.6439 108.177 20.3363 108.206 20.0291 108.193C19.7663 108.178 19.5093 108.11 19.2733 107.994L19.3302 107.953L19.5212 107.836C19.6846 107.741 19.8543 107.657 20.0291 107.584C20.4505 107.398 20.9048 107.296 21.3658 107.286C21.8268 107.275 22.2854 107.356 22.7148 107.524L24.2669 105.962V104.515C22.8998 104.051 21.4264 103.997 20.0291 104.361C19.5669 104.477 19.1155 104.633 18.6801 104.827L17.1321 103.931L15.2793 104.997V106.429L15.9131 106.794C15.745 107.041 15.6506 107.33 15.6409 107.629C15.6409 107.868 15.6409 108.821 15.6409 109.06C15.6409 109.652 16.0472 110.224 16.9574 110.735C17.7339 111.19 18.6129 111.442 19.5131 111.469C19.6837 111.469 19.8544 111.469 20.025 111.469C20.9623 111.399 21.8853 111.2 22.7676 110.877L23.304 110.694L21.7437 112.255V113.687C22.7058 114.049 23.7315 114.213 24.7588 114.169C25.786 114.124 26.7936 113.872 27.7206 113.427L29.2524 114.311L31.1011 113.245V111.814L30.4672 111.444C30.658 111.172 30.7628 110.849 30.768 110.516ZM26.8673 110.39C26.7279 110.47 26.584 110.542 26.4366 110.605C25.4728 111.017 24.3852 111.033 23.4096 110.65C24.8602 110.139 26.0709 109.693 27.099 110.244C27.025 110.298 26.9477 110.347 26.8673 110.39Z"
      fill="currentColor"
    />
    <path
      d="M24.6529 106.94C24.0191 107.102 23.434 107.309 22.8652 107.495C22.2367 107.73 21.5949 107.927 20.9433 108.087C20.6439 108.157 20.3363 108.186 20.0291 108.172C19.7188 108.157 19.4166 108.068 19.1474 107.913C18.5542 107.568 18.4648 107.065 19.3302 106.498L19.5212 106.384C19.6838 106.287 19.8536 106.203 20.0291 106.133C20.4505 105.946 20.9048 105.844 21.3658 105.834C21.8268 105.823 22.2854 105.904 22.7148 106.072L24.2669 104.511C22.8998 104.047 21.4264 103.993 20.0291 104.357C19.5669 104.473 19.1155 104.629 18.6801 104.823L17.1321 103.927L15.2793 104.993L16.8111 105.877C15.2549 107.057 15.2224 108.278 16.9736 109.287C17.7502 109.742 18.6292 109.995 19.5293 110.021C19.7 110.021 19.8706 110.021 20.0413 110.021C20.9783 109.953 21.9013 109.755 22.7839 109.433C24.5595 108.841 25.9979 108.14 27.1925 108.829C28.0049 109.291 27.7735 109.871 26.8958 110.378C26.7564 110.458 26.6126 110.53 26.4651 110.593C25.9792 110.801 25.4564 110.911 24.9276 110.915C24.3988 110.919 23.8744 110.818 23.3852 110.617L21.76 112.239C22.722 112.602 23.7477 112.765 24.7751 112.721C25.8025 112.676 26.8101 112.424 27.7367 111.98L29.2685 112.86L31.1173 111.797L29.5855 110.913C31.1255 109.725 31.3043 108.432 29.5043 107.382H29.4758C27.8223 106.441 26.1441 106.563 24.6529 106.94Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M24.6529 106.94C24.0191 107.102 23.434 107.309 22.8652 107.495C22.2367 107.73 21.5949 107.928 20.9433 108.087C20.6439 108.157 20.3363 108.186 20.0291 108.173C19.7188 108.157 19.4166 108.068 19.1474 107.913C18.5542 107.568 18.4648 107.066 19.3302 106.498L19.5212 106.384C19.6838 106.288 19.8536 106.204 20.0291 106.133C20.4505 105.946 20.9048 105.844 21.3658 105.834C21.8268 105.824 22.2854 105.904 22.7148 106.072L24.2669 104.511C22.8998 104.047 21.4264 103.993 20.0291 104.357C19.5669 104.473 19.1155 104.63 18.6801 104.823L17.1321 103.927L15.2793 104.994L16.8111 105.878C15.2549 107.057 15.2224 108.278 16.9736 109.288C17.7502 109.743 18.6292 109.995 19.5293 110.022C19.7 110.022 19.8706 110.022 20.0413 110.022C20.9783 109.953 21.9013 109.755 22.7839 109.434C24.5595 108.842 25.9979 108.14 27.1925 108.829C28.0049 109.292 27.7735 109.871 26.8958 110.378C26.7564 110.458 26.6126 110.53 26.4651 110.593C25.9792 110.801 25.4564 110.911 24.9276 110.915C24.3988 110.919 23.8744 110.818 23.3852 110.618L21.76 112.239C22.722 112.602 23.7477 112.766 24.7751 112.721C25.8025 112.676 26.8101 112.424 27.7367 111.98L29.2685 112.86L31.1173 111.798L29.5855 110.914C31.1255 109.726 31.3043 108.432 29.5043 107.382H29.4758C27.8223 106.441 26.1441 106.563 24.6529 106.94Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M15.2627 104.997L17.1317 104.154L18.6635 104.827L17.1155 103.931L15.2627 104.997Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M19.7988 104.418C21.1173 104.154 22.479 104.2 23.7766 104.552L22.704 106.096L24.252 104.535C22.8153 104.039 21.2599 103.998 19.7988 104.418Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M29.4477 107.378H29.4762C31.2761 108.428 31.1015 109.721 29.5576 110.909C30.9227 109.425 30.679 108.391 29.196 107.54C27.7127 106.688 25.8234 106.696 24.6533 106.919C26.1445 106.563 27.8227 106.441 29.4477 107.378Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M29.5582 110.93L30.7322 111.83L29.2412 112.88L31.0899 111.814L29.5582 110.93Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M26.4287 110.605C25.5153 111.067 24.4584 111.158 23.4789 110.861L21.7236 112.255L23.3489 110.633C23.8384 110.833 24.3628 110.934 24.8917 110.929C25.4205 110.924 25.943 110.814 26.4287 110.605Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M20.927 108.091C20.224 108.367 19.2205 108.318 18.7085 107.88C18.1965 107.443 18.5175 106.932 19.3139 106.502C18.4485 107.069 18.5379 107.572 19.1311 107.917C19.4002 108.072 19.7024 108.161 20.0128 108.176C20.32 108.19 20.6276 108.161 20.927 108.091Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M16.7835 105.894C16.6535 106.011 15.5402 107.045 16.0318 108.079C16.5519 109.186 18.1853 109.896 19.5017 110.038C18.6016 110.011 17.7226 109.759 16.946 109.304C15.1948 108.294 15.2273 107.074 16.7835 105.894Z"
      fill="#F5F5F5"
    />
    <path
      d="M36.6681 102.338C35.7946 100.866 34.3401 99.4992 32.3044 98.3112C25.1696 94.1997 13.6141 94.1997 6.4711 98.3112C4.43952 99.483 2.98088 100.866 2.10731 102.338H1.12402V106.064C1.25404 108.651 3.02962 111.218 6.4589 113.196C13.5937 117.304 25.1493 117.304 32.2924 113.196C35.7214 111.218 37.5134 108.651 37.6434 106.06V102.334L36.6681 102.338Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M36.6681 102.338C35.7946 100.866 34.3401 99.4992 32.3044 98.3112C25.1696 94.1997 13.6141 94.1997 6.4711 98.3112C4.43952 99.483 2.98088 100.866 2.10731 102.338H1.12402V106.064C1.25404 108.651 3.02962 111.218 6.4589 113.196C13.5937 117.304 25.1493 117.304 32.2924 113.196C35.7214 111.218 37.5134 108.651 37.6434 106.06V102.334L36.6681 102.338Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M19.3878 95.2292C14.7152 95.2292 10.0426 96.255 6.4711 98.3109C4.43952 99.4828 2.98088 100.865 2.10731 102.337H1.12402V106.064C1.25404 108.651 3.02962 111.217 6.4589 113.196C10.0263 115.248 14.703 116.278 19.3756 116.278L19.3878 95.2292Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M9.4128 96.9365C8.39676 97.3151 7.41299 97.7747 6.4711 98.3111C4.43952 99.483 2.98088 100.866 2.10731 102.338H1.12402V106.064C1.25404 108.651 3.02962 111.218 6.4589 113.196C7.40089 113.731 8.38467 114.19 9.4006 114.567L9.4128 96.9365Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M29.3672 96.9365C30.3814 97.3159 31.3639 97.7754 32.3047 98.3111C34.3363 99.483 35.7951 100.866 36.6686 102.338H37.6559V106.064C37.526 108.655 35.734 111.222 32.3047 113.2C31.3639 113.735 30.3814 114.193 29.3672 114.571V96.9365Z"
      fill="currentColor"
    />
    <path
      d="M6.4707 94.8971C-0.664139 99.0086 -0.660076 105.671 6.4707 109.782C12.7117 113.379 22.3494 113.837 29.4395 111.128L29.724 111.019C30.6127 110.669 31.4749 110.256 32.304 109.782C38.2524 106.352 39.2397 101.15 35.2703 97.1352C34.3883 96.2607 33.392 95.5092 32.3081 94.9012C25.1733 90.7856 13.6056 90.7856 6.4707 94.8971Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M6.4707 94.8971C-0.664139 99.0086 -0.660076 105.671 6.4707 109.782C12.7117 113.379 22.3494 113.837 29.4395 111.128L29.724 111.019C30.6127 110.669 31.4749 110.256 32.304 109.782C38.2524 106.352 39.2397 101.15 35.2703 97.1352C34.3883 96.2607 33.392 95.5092 32.3081 94.9012C25.1733 90.7856 13.6056 90.7856 6.4707 94.8971Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M35.2708 97.1353C35.344 97.2203 37.8387 99.8478 37.1807 103.623C36.5468 107.24 31.4922 110.285 29.7246 111.015C30.6133 110.665 31.4757 110.252 32.3048 109.778C38.2532 106.352 39.2405 101.149 35.2708 97.1353Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M3.50827 107.544C3.43107 107.459 0.93631 104.831 1.5986 101.056C2.22838 97.4395 7.28696 94.3943 9.05443 93.6646C8.16335 94.0119 7.29957 94.4253 6.47028 94.9013C0.521867 98.3236 -0.465475 103.53 3.50827 107.544Z"
      fill="#F5F5F5"
    />
    <path
      d="M19.3968 112.11C28.7744 112.11 36.3766 107.729 36.3766 102.325C36.3766 96.9214 28.7744 92.541 19.3968 92.541C10.0191 92.541 2.41699 96.9214 2.41699 102.325C2.41699 107.729 10.0191 112.11 19.3968 112.11Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M19.3968 112.11C28.7744 112.11 36.3766 107.729 36.3766 102.325C36.3766 96.9214 28.7744 92.541 19.3968 92.541C10.0191 92.541 2.41699 96.9214 2.41699 102.325C2.41699 107.729 10.0191 112.11 19.3968 112.11Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M35.6028 105.245C36.9682 102.731 36.5211 99.9734 34.2701 97.6015C33.8263 97.1424 33.3452 96.7206 32.8318 96.3404C32.39 96.0154 31.9318 95.7135 31.4585 95.4362C31.1862 95.278 30.906 95.1281 30.6214 94.9861C31.3244 96.1456 31.0683 97.3865 27.0663 97.6177C19.2407 98.0759 29.1587 105.541 29.1587 105.541C29.1587 105.541 34.2051 103.108 35.6028 105.245Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M34.794 103.181C35.9683 103.051 36.5533 101.847 36.0941 100.489C35.6351 99.1301 34.3186 98.1408 33.1443 98.2746C31.97 98.4084 31.385 99.6087 31.8442 100.967C32.3032 102.325 33.6197 103.315 34.794 103.181Z"
      fill="white"
    />
    <path
      d="M22.4198 109.401C22.0338 106.502 9.32845 98.4736 9.32845 98.4736C9.32845 98.4736 6.51268 97.3466 7.44722 95.3799C6.97885 95.6464 6.52578 95.9389 6.09013 96.2557C5.79353 96.4666 5.5213 96.6855 5.27751 96.9085C1.4988 100.177 1.49067 104.471 5.27751 107.743C5.53348 107.966 5.80979 108.181 6.09013 108.396C6.49393 108.69 6.91451 108.961 7.3497 109.207C7.78039 109.462 8.2314 109.693 8.69459 109.912C9.06434 110.087 9.45034 110.253 9.84041 110.403C10.2305 110.553 10.6246 110.699 11.0309 110.833C12.2684 111.235 13.5361 111.539 14.8218 111.741C15.1021 111.785 15.3866 111.83 15.671 111.867C16.9282 112.026 18.1945 112.105 19.4619 112.102C21.015 112.1 22.5654 111.973 24.0979 111.721C24.0979 111.721 22.0541 111.311 22.4198 109.401Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M22.2348 109.401C21.8488 106.502 9.14751 98.4736 9.14751 98.4736C9.14751 98.4736 6.32769 97.3466 7.2622 95.3799C6.79444 95.6475 6.34143 95.9397 5.90513 96.2557C5.61259 96.4666 5.33628 96.6855 5.09252 96.9085C1.30973 100.177 1.30567 104.471 5.09252 107.743C5.34848 107.966 5.62477 108.181 5.90513 108.396C6.3102 108.688 6.73064 108.959 7.1647 109.207C7.5954 109.462 8.04641 109.693 8.5096 109.912C8.87934 110.087 9.26535 110.253 9.65539 110.403C10.0454 110.553 10.4396 110.699 10.8459 110.833C12.0836 111.235 13.3512 111.538 14.6368 111.741C14.9212 111.785 15.2056 111.83 15.4901 111.867C16.7473 112.027 18.0136 112.105 19.2809 112.102C20.8341 112.1 22.3844 111.972 23.917 111.721C23.917 111.721 21.8732 111.311 22.2348 109.401Z"
      fill="white"
    />
    <path
      d="M10.4449 97.1757C5.70325 99.9047 5.51229 104.259 9.86388 107.126C10.0508 107.244 10.2458 107.365 10.4449 107.483C15.3857 110.321 23.3941 110.321 28.3228 107.483C33.2513 104.645 33.2634 100.022 28.3228 97.1757C28.1235 97.0583 27.9165 96.9489 27.701 96.8392C22.744 94.3375 15.1825 94.4388 10.4449 97.1757Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M10.4449 97.1757C5.70325 99.9047 5.51229 104.259 9.86388 107.126C10.0508 107.244 10.2458 107.365 10.4449 107.483C15.3857 110.321 23.3941 110.321 28.3228 107.483C33.2513 104.645 33.2634 100.022 28.3228 97.1757C28.1235 97.0583 27.9165 96.9489 27.701 96.8392C22.744 94.3375 15.1825 94.4388 10.4449 97.1757Z"
      fill="#1D1D1D"
    />
    <path
      d="M19.3956 109.292C26.0737 109.292 31.4874 106.173 31.4874 102.326C31.4874 98.4784 26.0737 95.3596 19.3956 95.3596C12.7174 95.3596 7.30371 98.4784 7.30371 102.326C7.30371 106.173 12.7174 109.292 19.3956 109.292Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M19.3956 109.292C26.0737 109.292 31.4874 106.173 31.4874 102.326C31.4874 98.4784 26.0737 95.3596 19.3956 95.3596C12.7174 95.3596 7.30371 98.4784 7.30371 102.326C7.30371 106.173 12.7174 109.292 19.3956 109.292Z"
      fill="#1D1D1D"
    />
    <path
      d="M10.8471 99.3411C15.5684 96.6203 23.2234 96.6203 27.9449 99.3411C29.8789 100.452 31.0164 101.851 31.3701 103.295C31.0164 104.742 29.8789 106.133 27.9449 107.252C23.2234 109.973 15.5684 109.973 10.8471 107.252C8.91304 106.137 7.77536 104.742 7.42188 103.295C7.77536 101.847 8.91304 100.452 10.8471 99.3411Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M10.8471 99.3411C15.5684 96.6203 23.2234 96.6203 27.9449 99.3411C29.8789 100.452 31.0164 101.851 31.3701 103.295C31.0164 104.742 29.8789 106.133 27.9449 107.252C23.2234 109.973 15.5684 109.973 10.8471 107.252C8.91304 106.137 7.77536 104.742 7.42188 103.295C7.77536 101.847 8.91304 100.452 10.8471 99.3411Z"
      fill="#1D1D1D"
    />
    <path
      d="M27.0101 104.422C27.0101 104.187 27.0101 103.229 27.0101 102.982C27.0101 102.39 26.6038 101.818 25.718 101.304L25.6897 101.283C24.0644 100.351 22.3863 100.472 20.8951 100.845C20.2613 101.003 19.6762 101.21 19.1073 101.401C18.4788 101.635 17.8371 101.833 17.1855 101.993C16.8857 102.061 16.5784 102.089 16.2713 102.078C16.0085 102.063 15.7514 101.996 15.5155 101.879L15.5724 101.835L15.7634 101.717C15.9277 101.624 16.0973 101.54 16.2713 101.466C16.693 101.28 17.1472 101.179 17.6081 101.168C18.0689 101.158 18.5273 101.238 18.957 101.405L20.5091 99.8438V98.4126C19.142 97.9484 17.6686 97.8948 16.2713 98.2583C15.8102 98.3785 15.3591 98.5346 14.9223 98.7247L13.3743 97.8325L11.5215 98.899V100.33L12.1553 100.695C11.9859 100.942 11.8914 101.232 11.8831 101.531C11.8831 101.77 11.8831 102.719 11.8831 102.962C11.8831 103.554 12.2894 104.122 13.1995 104.637C13.9776 105.088 14.8557 105.34 15.7553 105.37C15.9259 105.37 16.0966 105.37 16.2672 105.37C17.2047 105.303 18.128 105.103 19.0098 104.778L19.5461 104.596L17.9859 106.157V107.589C18.9475 107.952 19.9732 108.117 21.0006 108.074C22.0281 108.03 23.0359 107.778 23.9628 107.333L25.4946 108.213L27.3433 107.146V105.715L26.7094 105.35C26.8992 105.077 27.0037 104.754 27.0101 104.422ZM23.1095 104.3C22.9701 104.38 22.8263 104.452 22.6788 104.515C21.715 104.926 20.6274 104.942 19.6518 104.559C21.1023 104.049 22.3131 103.603 23.3411 104.154C23.272 104.199 23.1786 104.251 23.1095 104.3Z"
      fill="currentColor"
    />
    <path
      d="M20.8951 100.845C20.2613 101.003 19.6762 101.21 19.1073 101.401C18.4788 101.635 17.8371 101.833 17.1855 101.993C16.8857 102.061 16.5784 102.089 16.2713 102.078C15.9606 102.061 15.6584 101.971 15.3896 101.814C14.7964 101.474 14.707 100.971 15.5724 100.403L15.7634 100.286C15.9268 100.191 16.0965 100.107 16.2713 100.034C16.693 99.8487 17.1472 99.7474 17.6081 99.7372C18.0689 99.7267 18.5273 99.8069 18.957 99.9735L20.5091 98.4126C19.142 97.9484 17.6686 97.8948 16.2713 98.2583C15.8102 98.3785 15.3591 98.5346 14.9223 98.7247L13.3743 97.8325L11.5215 98.899L13.0533 99.7831C11.4971 100.959 11.4646 102.183 13.2158 103.193C13.9939 103.645 14.8719 103.897 15.7715 103.927C15.9421 103.927 16.1128 103.927 16.2835 103.927C17.221 103.859 18.1442 103.66 19.0261 103.335C20.8017 102.743 22.24 102.046 23.4346 102.735C24.2472 103.197 24.0156 103.777 23.138 104.284C22.9986 104.363 22.8548 104.435 22.7073 104.499C22.2197 104.703 21.6962 104.809 21.1673 104.809C20.6385 104.809 20.1149 104.703 19.6274 104.499L18.0022 106.121C18.964 106.484 19.9898 106.648 21.0173 106.604C22.0448 106.559 23.0524 106.306 23.979 105.861L25.5108 106.745L27.3594 105.679L25.8276 104.799C27.3677 103.607 27.5464 102.313 25.7465 101.263L25.718 101.243C24.0644 100.351 22.3863 100.472 20.8951 100.845Z"
      fill="currentColor"
    />
    <g opacity="0.2">
      <path
        d="M20.8961 100.846C20.2622 101.004 19.6772 101.211 19.1083 101.401C18.4798 101.636 17.8381 101.834 17.1865 101.993C16.8867 102.061 16.5794 102.09 16.2723 102.079C15.9616 102.061 15.6594 101.971 15.3906 101.815C14.7973 101.474 14.7079 100.972 15.5734 100.404L15.7644 100.286C15.9278 100.191 16.0975 100.107 16.2723 100.035C16.6939 99.8489 17.1482 99.7479 17.609 99.7377C18.0699 99.7272 18.5283 99.8074 18.958 99.974L20.5101 98.413C19.143 97.9489 17.6695 97.8953 16.2723 98.2587C15.8111 98.379 15.3601 98.5351 14.9233 98.7252L13.3752 97.833L11.5225 98.8995L13.0543 99.7836C11.4981 100.959 11.4656 102.184 13.2168 103.194C13.9949 103.645 14.8729 103.897 15.7725 103.927C15.9431 103.927 16.1138 103.927 16.2844 103.927C17.222 103.859 18.1452 103.66 19.0271 103.336C20.8026 102.743 22.241 102.046 23.4355 102.735C24.2482 103.198 24.0166 103.777 23.1389 104.284C22.9995 104.364 22.8557 104.436 22.7082 104.499C22.2207 104.704 21.6972 104.809 21.1683 104.809C20.6394 104.809 20.1159 104.704 19.6284 104.499L18.0032 106.121C18.965 106.484 19.9908 106.649 21.0183 106.604C22.0457 106.559 23.0534 106.307 23.98 105.862L25.5118 106.746L27.3604 105.679L25.8286 104.799C27.3687 103.607 27.5474 102.314 25.7475 101.264L25.719 101.243C24.0653 100.351 22.3873 100.473 20.8961 100.846Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M11.5068 98.9071L13.3759 98.0638L14.9077 98.7328L13.3596 97.8408L11.5068 98.9071Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M16.0391 98.3271C17.3575 98.0639 18.7192 98.1095 20.0169 98.4609L18.9442 99.9816L20.4923 98.4204C19.0526 97.9339 17.4979 97.9014 16.0391 98.3271Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M25.6891 101.284L25.7173 101.304C27.5175 102.354 27.3426 103.647 25.7987 104.839C27.1638 103.355 26.9201 102.321 25.4371 101.466C23.9541 100.61 22.0647 100.627 20.8945 100.846C22.3857 100.473 24.0638 100.351 25.6891 101.284Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M25.7984 104.839L26.9727 105.74L25.4814 106.786L27.3302 105.719L25.7984 104.839Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M22.6787 104.515C21.7645 104.974 20.7086 105.065 19.7289 104.77L17.9736 106.161L19.5989 104.539C20.088 104.74 20.6124 104.841 21.1413 104.837C21.6701 104.833 22.1928 104.723 22.6787 104.515Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M17.1692 102.001C16.4662 102.273 15.4627 102.228 14.9507 101.79C14.4387 101.352 14.7597 100.837 15.5561 100.412C14.6906 100.979 14.7801 101.482 15.3732 101.823C15.6421 101.979 15.9443 102.069 16.255 102.086C16.5621 102.098 16.8694 102.069 17.1692 102.001Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M13.0207 99.8037C12.8907 99.9173 11.7774 100.955 12.269 101.989C12.7891 103.096 14.4225 103.802 15.7389 103.948C14.8394 103.918 13.9613 103.665 13.1832 103.214C11.4361 102.204 11.4686 100.98 13.0207 99.8037Z"
      fill="#F5F5F5"
    />
    <path
      d="M101.292 106.614L124.686 93.1334V35.5365L101.292 49.0173V106.614Z"
      fill="#37474F"
    />
    <path
      d="M101.293 49.0176L75.7676 34.314V91.9057L101.293 106.615V49.0176Z"
      fill="#455A64"
    />
    <path
      d="M124.687 35.5366L99.167 20.833L75.7676 34.314L101.293 49.0176L124.687 35.5366Z"
      fill="#37474F"
    />
    <path
      d="M85.3242 97.4233V87.6157L91.9371 91.4092V101.212L85.3242 97.4233Z"
      fill="#E0E0E0"
    />
    <path
      d="M88.209 89.2717L88.8529 89.6426V99.4451L88.209 99.0742V89.2717Z"
      fill="#263238"
    />
    <path
      d="M98.6173 90.3588V84.8934L94.2402 82.3695V87.8351L98.6173 90.3588Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.2402 87.8351L95.3346 87.2082V83.0018L94.2402 82.3695V87.8351Z"
      fill="#263238"
    />
    <path
      d="M98.6173 89.0996L95.3346 87.208L94.2402 87.8352L98.6173 90.3589V89.0996Z"
      fill="#37474F"
    />
    <path
      d="M98.6173 100.83V95.3647L94.2402 92.8462V98.3118L98.6173 100.83Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.2402 98.3118L95.3346 97.6795V93.4734L94.2402 92.8462V98.3118Z"
      fill="#263238"
    />
    <path
      d="M98.6173 99.5708L95.3346 97.6794L94.2402 98.3115L98.6173 100.83V99.5708Z"
      fill="#37474F"
    />
    <path
      d="M98.6173 80.1071V74.6417L94.2402 72.1232V77.5885L98.6173 80.1071Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.2402 77.5885L95.3346 76.9565V72.7501L94.2402 72.1232V77.5885Z"
      fill="#263238"
    />
    <path
      d="M98.6173 78.8478L95.3346 76.9562L94.2402 77.5885L98.6173 80.107V78.8478Z"
      fill="#37474F"
    />
    <path
      d="M98.6173 69.8554V64.39L94.2402 61.8715V67.3368L98.6173 69.8554Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.2402 67.3368L95.3346 66.7048V62.4984L94.2402 61.8715V67.3368Z"
      fill="#263238"
    />
    <path
      d="M98.6173 68.5958L95.3346 66.7042L94.2402 67.3366L98.6173 69.8551V68.5958Z"
      fill="#37474F"
    />
    <path
      d="M98.6173 59.6089V54.1382L94.2402 51.6196V57.085L98.6173 59.6089Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.2402 57.085L95.3346 56.4529V52.2517L94.2402 51.6196V57.085Z"
      fill="#263238"
    />
    <path
      d="M98.6173 58.3444L95.3346 56.4528L94.2402 57.0851L98.6173 59.6087V58.3444Z"
      fill="#37474F"
    />
    <path
      d="M82.8265 81.2617V75.7961L78.4492 73.2725V78.7381L82.8265 81.2617Z"
      fill="#E0E0E0"
    />
    <path
      d="M78.4492 78.7381L79.5436 78.1109V73.9048L78.4492 73.2725V78.7381Z"
      fill="#263238"
    />
    <path
      d="M82.8265 80.0024L79.5436 78.1108L78.4492 78.7378L82.8265 81.2617V80.0024Z"
      fill="#37474F"
    />
    <path
      d="M82.8265 91.7332V86.2676L78.4492 83.749V89.2093L82.8265 91.7332Z"
      fill="#E0E0E0"
    />
    <path
      d="M78.4492 89.2093L79.5436 88.5823V84.376L78.4492 83.749V89.2093Z"
      fill="#263238"
    />
    <path
      d="M82.8265 90.4739L79.5436 88.5825L78.4492 89.2095L82.8265 91.7331V90.4739Z"
      fill="#37474F"
    />
    <path
      d="M82.8265 70.921V65.4554L78.4492 62.9369V68.4025L82.8265 70.921Z"
      fill="#E0E0E0"
    />
    <path
      d="M78.4492 68.4025L79.5436 67.7702V63.5638L78.4492 62.9369V68.4025Z"
      fill="#263238"
    />
    <path
      d="M82.8265 69.6619L79.5436 67.7703L78.4492 68.4026L82.8265 70.9211V69.6619Z"
      fill="#37474F"
    />
    <path
      d="M82.8265 60.586V55.1203L78.4492 52.5967V58.0623L82.8265 60.586Z"
      fill="#E0E0E0"
    />
    <path
      d="M78.4492 58.0623L79.5436 57.4351V53.229L78.4492 52.5967V58.0623Z"
      fill="#263238"
    />
    <path
      d="M82.8265 59.3265L79.5436 57.4349L78.4492 58.0619L82.8265 60.5858V59.3265Z"
      fill="#37474F"
    />
    <path
      d="M82.8265 50.245V44.7796L78.4492 42.2611V47.7265L82.8265 50.245Z"
      fill="#FAFAFA"
    />
    <path
      d="M78.4492 47.7265L79.5436 47.0944V42.8881L78.4492 42.2611V47.7265Z"
      fill="#263238"
    />
    <path
      d="M82.8265 48.9861L79.5436 47.0945L78.4492 47.7268L82.8265 50.2453V48.9861Z"
      fill="#37474F"
    />
    <path
      d="M85.3242 45.9452L87.8741 47.4134V84.1725L85.3242 82.7095V45.9452Z"
      fill="#FAFAFA"
    />
    <path
      d="M85.3242 45.9452L86.403 46.567V82.1033L85.3242 82.7095V45.9452Z"
      fill="#263238"
    />
    <path
      d="M86.403 82.103L87.8741 82.9181V84.1722L85.3242 82.7092L86.403 82.103Z"
      fill="#37474F"
    />
    <path
      d="M89.3818 48.2594L91.9369 49.7224V86.4867L89.3818 85.0185V48.2594Z"
      fill="#FAFAFA"
    />
    <path
      d="M89.3818 48.2594L90.4603 48.8812V84.4124L89.3818 85.0185V48.2594Z"
      fill="#263238"
    />
    <path
      d="M90.4603 84.4128L91.9369 85.2279V86.4872L89.3818 85.019L90.4603 84.4128Z"
      fill="#37474F"
    />
    <path
      d="M104 90.3588V84.8934L108.372 82.3695V87.8351L104 90.3588Z"
      fill="#E0E0E0"
    />
    <path
      d="M108.371 87.8351L107.277 87.2082L107.282 83.0018L108.371 82.3695V87.8351Z"
      fill="#455A64"
    />
    <path
      d="M104 89.0996L107.278 87.208L108.372 87.8352L104 90.3589V89.0996Z"
      fill="#263238"
    />
    <path
      d="M110.801 86.456V80.9903L115.178 78.4718V83.9374L110.801 86.456Z"
      fill="#E0E0E0"
    />
    <path
      d="M115.178 83.9374L114.084 83.3051V79.099L115.178 78.4718V83.9374Z"
      fill="#455A64"
    />
    <path
      d="M110.801 85.1964L114.084 83.3051L115.178 83.9371L110.801 86.4557V85.1964Z"
      fill="#263238"
    />
    <path
      d="M117.603 82.5576V77.092L121.98 74.5684V80.0337L117.603 82.5576Z"
      fill="#E0E0E0"
    />
    <path
      d="M121.98 80.0337L120.886 79.4068V75.2004L121.98 74.5684V80.0337Z"
      fill="#455A64"
    />
    <path
      d="M117.603 81.2933L120.885 79.4071L121.98 80.0341L117.603 82.5577V81.2933Z"
      fill="#263238"
    />
    <path
      d="M104 100.83V95.3647L108.372 92.8462V98.3118L104 100.83Z"
      fill="#E0E0E0"
    />
    <path
      d="M108.371 98.3118L107.277 97.6795L107.282 93.4734L108.371 92.8462V98.3118Z"
      fill="#455A64"
    />
    <path
      d="M104 99.5708L107.278 97.6794L108.372 98.3115L104 100.83V99.5708Z"
      fill="#263238"
    />
    <path
      d="M110.801 96.9324V91.4616L115.178 88.9431V94.4085L110.801 96.9324Z"
      fill="#E0E0E0"
    />
    <path
      d="M115.178 94.4085L114.084 93.7764V89.5752L115.178 88.9431V94.4085Z"
      fill="#455A64"
    />
    <path
      d="M110.801 95.6675L114.084 93.7759L115.178 94.4082L110.801 96.9319V95.6675Z"
      fill="#263238"
    />
    <path
      d="M117.603 93.0286V87.5632L121.98 85.0393V90.5049L117.603 93.0286Z"
      fill="#E0E0E0"
    />
    <path
      d="M121.98 90.5049L120.886 89.878V85.6716L121.98 85.0393V90.5049Z"
      fill="#455A64"
    />
    <path
      d="M117.603 91.7698L120.885 89.8782L121.98 90.5054L117.603 93.029V91.7698Z"
      fill="#263238"
    />
    <path
      d="M104 80.1071V74.6417L108.372 72.1232V77.5885L104 80.1071Z"
      fill="#E0E0E0"
    />
    <path
      d="M108.371 77.5885L107.277 76.9565L107.282 72.7501L108.371 72.1232V77.5885Z"
      fill="#455A64"
    />
    <path
      d="M104 78.8478L107.278 76.9562L108.372 77.5885L104 80.107V78.8478Z"
      fill="#263238"
    />
    <path
      d="M110.801 76.1621V70.6965L115.178 68.178V73.6436L110.801 76.1621Z"
      fill="#E0E0E0"
    />
    <path
      d="M115.178 73.6436L114.084 73.0113V68.8049L115.178 68.178V73.6436Z"
      fill="#455A64"
    />
    <path
      d="M110.801 74.9029L114.084 73.0116L115.178 73.6437L110.801 76.1622V74.9029Z"
      fill="#263238"
    />
    <path
      d="M117.603 72.2173V66.7517L121.98 64.2332V69.6988L117.603 72.2173Z"
      fill="#E0E0E0"
    />
    <path
      d="M121.98 69.6988L120.886 69.0664V64.8604L121.98 64.2332V69.6988Z"
      fill="#455A64"
    />
    <path
      d="M117.603 70.958L120.885 69.0664L121.98 69.6987L117.603 72.2173V70.958Z"
      fill="#263238"
    />
    <path
      d="M104 69.8554V64.39L108.372 61.8715V67.3368L104 69.8554Z"
      fill="#E0E0E0"
    />
    <path
      d="M108.371 67.3368L107.277 66.7048L107.282 62.4984L108.371 61.8715V67.3368Z"
      fill="#455A64"
    />
    <path
      d="M104 68.5958L107.278 66.7042L108.372 67.3366L104 69.8551V68.5958Z"
      fill="#263238"
    />
    <path
      d="M110.801 65.8687V60.4033L115.178 57.8794V63.3501L110.801 65.8687Z"
      fill="#E0E0E0"
    />
    <path
      d="M115.178 63.3501L114.084 62.7181V58.5117L115.178 57.8794V63.3501Z"
      fill="#455A64"
    />
    <path
      d="M110.801 64.6094L114.084 62.7178L115.178 63.3501L110.801 65.8686V64.6094Z"
      fill="#263238"
    />
    <path
      d="M117.603 61.882V56.4164L121.98 53.8927V59.3581L117.603 61.882Z"
      fill="#E0E0E0"
    />
    <path
      d="M121.98 59.3581L120.886 58.7311V54.5248L121.98 53.8927V59.3581Z"
      fill="#455A64"
    />
    <path
      d="M117.603 60.6222L120.885 58.7308L121.98 59.3578L117.603 61.8814V60.6222Z"
      fill="#263238"
    />
    <path
      d="M104 59.6089V54.1382L108.372 51.6196V57.085L104 59.6089Z"
      fill="#E0E0E0"
    />
    <path
      d="M108.371 57.085L107.277 56.4529L107.282 52.2517L108.371 51.6196V57.085Z"
      fill="#455A64"
    />
    <path
      d="M104 58.3444L107.278 56.4528L108.372 57.0851L104 59.6087V58.3444Z"
      fill="#263238"
    />
    <path
      d="M110.801 55.5749V50.1095L115.178 47.5856V53.0512L110.801 55.5749Z"
      fill="#E0E0E0"
    />
    <path
      d="M115.178 53.0512L114.084 52.4242V48.2179L115.178 47.5856V53.0512Z"
      fill="#455A64"
    />
    <path
      d="M110.801 54.3155L114.084 52.424L115.178 53.0511L110.801 55.5748V54.3155Z"
      fill="#263238"
    />
    <path
      d="M117.603 51.541V46.0757L121.98 43.5571V49.0225L117.603 51.541Z"
      fill="#E0E0E0"
    />
    <path
      d="M121.98 49.0225L120.886 48.3902V44.1841L121.98 43.5571V49.0225Z"
      fill="#455A64"
    />
    <path
      d="M117.603 50.282L120.885 48.3904L121.98 49.0227L117.603 51.5412V50.282Z"
      fill="#263238"
    />
    <path
      d="M80.0244 34.3137L99.167 23.2834L120.43 35.5365L101.293 46.5667L80.0244 34.3137Z"
      fill="#F0F0F0"
    />
    <path
      d="M99.167 23.2834V25.7341L118.304 36.7643L120.43 35.5365L99.167 23.2834Z"
      fill="#455A64"
    />
    <path
      d="M99.167 23.2834L80.0244 34.3137L82.1503 35.5365L99.167 25.7341V23.2834Z"
      fill="#263238"
    />
    <path
      d="M248.91 106.614L225.516 93.1334V35.5365L248.915 49.0173L248.91 106.614Z"
      fill="#455A64"
    />
    <path
      d="M248.915 49.0176L274.436 34.314V91.9057L248.91 106.615L248.915 49.0176Z"
      fill="#37474F"
    />
    <path
      d="M225.516 35.5366L251.041 20.833L274.434 34.314L248.915 49.0176L225.516 35.5366Z"
      fill="#37474F"
    />
    <path
      d="M264.884 97.4233V87.6157L258.266 91.4092L258.271 101.212L264.884 97.4233Z"
      fill="#E0E0E0"
    />
    <path
      d="M261.995 89.2717L261.355 89.6426V99.4451L261.995 99.0742V89.2717Z"
      fill="#263238"
    />
    <path
      d="M251.585 90.3588V84.8934L255.962 82.3695V87.8351L251.585 90.3588Z"
      fill="#E0E0E0"
    />
    <path
      d="M255.963 87.8351L254.868 87.2082V83.0018L255.963 82.3695V87.8351Z"
      fill="#263238"
    />
    <path
      d="M251.585 89.0996L254.868 87.208L255.962 87.8352L251.585 90.3589V89.0996Z"
      fill="#455A64"
    />
    <path
      d="M251.585 100.83V95.3647L255.962 92.8462V98.3118L251.585 100.83Z"
      fill="#E0E0E0"
    />
    <path
      d="M255.963 98.3118L254.868 97.6795V93.4734L255.963 92.8462V98.3118Z"
      fill="#263238"
    />
    <path
      d="M251.585 99.5708L254.868 97.6794L255.962 98.3115L251.585 100.83V99.5708Z"
      fill="#455A64"
    />
    <path
      d="M251.585 80.1071V74.6417L255.962 72.1232V77.5885L251.585 80.1071Z"
      fill="#E0E0E0"
    />
    <path
      d="M255.963 77.5885L254.868 76.9565V72.7501L255.963 72.1232V77.5885Z"
      fill="#263238"
    />
    <path
      d="M251.585 78.8478L254.868 76.9562L255.962 77.5885L251.585 80.107V78.8478Z"
      fill="#455A64"
    />
    <path
      d="M251.585 69.8554V64.39L255.962 61.8715V67.3368L251.585 69.8554Z"
      fill="#E0E0E0"
    />
    <path
      d="M255.963 67.3368L254.868 66.7048V62.4984L255.963 61.8715V67.3368Z"
      fill="#263238"
    />
    <path
      d="M251.585 68.5958L254.868 66.7042L255.962 67.3366L251.585 69.8551V68.5958Z"
      fill="#455A64"
    />
    <path
      d="M251.585 59.6089V54.1382L255.962 51.6196V57.085L251.585 59.6089Z"
      fill="#E0E0E0"
    />
    <path
      d="M255.963 57.085L254.868 56.4529V52.2517L255.963 51.6196V57.085Z"
      fill="#263238"
    />
    <path
      d="M251.585 58.3444L254.868 56.4528L255.962 57.0851L251.585 59.6087V58.3444Z"
      fill="#455A64"
    />
    <path
      d="M267.381 81.2617V75.7961L271.755 73.2725V78.7381L267.381 81.2617Z"
      fill="#E0E0E0"
    />
    <path
      d="M271.755 78.7381L270.666 78.1109V73.9048L271.755 73.2725V78.7381Z"
      fill="#263238"
    />
    <path
      d="M267.381 80.0024L270.663 78.1108L271.755 78.7378L267.381 81.2617V80.0024Z"
      fill="#455A64"
    />
    <path
      d="M267.381 91.7332V86.2676L271.755 83.749V89.2093L267.381 91.7332Z"
      fill="#E0E0E0"
    />
    <path
      d="M271.755 89.2093L270.666 88.5823V84.376L271.755 83.749V89.2093Z"
      fill="#263238"
    />
    <path
      d="M267.381 90.4739L270.663 88.5825L271.755 89.2095L267.381 91.7331V90.4739Z"
      fill="#455A64"
    />
    <path
      d="M267.381 70.921V65.4554L271.755 62.9369V68.4025L267.381 70.921Z"
      fill="#E0E0E0"
    />
    <path
      d="M271.755 68.4025L270.666 67.7702V63.5638L271.755 62.9369V68.4025Z"
      fill="#263238"
    />
    <path
      d="M267.381 69.6619L270.663 67.7703L271.755 68.4026L267.381 70.9211V69.6619Z"
      fill="#455A64"
    />
    <path
      d="M267.381 60.586V55.1203L271.755 52.5967V58.0623L267.381 60.586Z"
      fill="#E0E0E0"
    />
    <path
      d="M271.755 58.0623L270.666 57.4351V53.229L271.755 52.5967V58.0623Z"
      fill="#263238"
    />
    <path
      d="M267.381 59.3265L270.663 57.4349L271.755 58.0619L267.381 60.5858V59.3265Z"
      fill="#455A64"
    />
    <path
      d="M267.381 50.245V44.7796L271.755 42.2611V47.7265L267.381 50.245Z"
      fill="#FAFAFA"
    />
    <path
      d="M271.755 47.7265L270.666 47.0944V42.8881L271.755 42.2611V47.7265Z"
      fill="#263238"
    />
    <path
      d="M267.381 48.9861L270.663 47.0945L271.755 47.7268L267.381 50.2453V48.9861Z"
      fill="#455A64"
    />
    <path
      d="M264.885 45.9452L262.329 47.4134V84.1725L264.885 82.7095V45.9452Z"
      fill="#FAFAFA"
    />
    <path
      d="M264.885 45.9452L263.801 46.567V82.1033L264.885 82.7095V45.9452Z"
      fill="#263238"
    />
    <path
      d="M263.801 82.103L262.329 82.9181V84.1722L264.885 82.7092L263.801 82.103Z"
      fill="#455A64"
    />
    <path
      d="M260.821 48.2594L258.271 49.7224V86.4867L260.821 85.0185V48.2594Z"
      fill="#FAFAFA"
    />
    <path
      d="M260.821 48.2594L259.742 48.8812V84.4124L260.821 85.0185V48.2594Z"
      fill="#263238"
    />
    <path
      d="M259.742 84.4128L258.271 85.2279V86.4872L260.821 85.019L259.742 84.4128Z"
      fill="#455A64"
    />
    <path
      d="M246.206 90.3588V84.8934L241.829 82.3695V87.8351L246.206 90.3588Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.829 87.8351L242.923 87.2082V83.0018L241.829 82.3695V87.8351Z"
      fill="#37474F"
    />
    <path
      d="M246.206 89.0996L242.923 87.208L241.829 87.8352L246.206 90.3589V89.0996Z"
      fill="#263238"
    />
    <path
      d="M239.401 86.456V80.9903L235.029 78.4718V83.9374L239.401 86.456Z"
      fill="#E0E0E0"
    />
    <path
      d="M235.029 83.9374L236.118 83.3051V79.099L235.029 78.4718V83.9374Z"
      fill="#37474F"
    />
    <path
      d="M239.401 85.1964L236.118 83.3051L235.029 83.9371L239.401 86.4557V85.1964Z"
      fill="#263238"
    />
    <path
      d="M232.599 82.5576V77.092L228.222 74.5684V80.0337L232.599 82.5576Z"
      fill="#E0E0E0"
    />
    <path
      d="M228.222 80.0337L229.316 79.4068V75.2004L228.222 74.5684V80.0337Z"
      fill="#37474F"
    />
    <path
      d="M232.599 81.2933L229.316 79.4071L228.222 80.0341L232.599 82.5577V81.2933Z"
      fill="#263238"
    />
    <path
      d="M246.206 100.83V95.3647L241.829 92.8462V98.3118L246.206 100.83Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.829 98.3118L242.923 97.6795V93.4734L241.829 92.8462V98.3118Z"
      fill="#37474F"
    />
    <path
      d="M246.206 99.5708L242.923 97.6794L241.829 98.3115L246.206 100.83V99.5708Z"
      fill="#263238"
    />
    <path
      d="M239.401 96.9324V91.4616L235.029 88.9431V94.4085L239.401 96.9324Z"
      fill="#E0E0E0"
    />
    <path
      d="M235.029 94.4085L236.118 93.7764V89.5752L235.029 88.9431V94.4085Z"
      fill="#37474F"
    />
    <path
      d="M239.401 95.6675L236.118 93.7759L235.029 94.4082L239.401 96.9319V95.6675Z"
      fill="#263238"
    />
    <path
      d="M232.599 93.0286V87.5632L228.222 85.0393V90.5049L232.599 93.0286Z"
      fill="#E0E0E0"
    />
    <path
      d="M228.222 90.5049L229.316 89.878V85.6716L228.222 85.0393V90.5049Z"
      fill="#37474F"
    />
    <path
      d="M232.599 91.7698L229.316 89.8782L228.222 90.5054L232.599 93.029V91.7698Z"
      fill="#263238"
    />
    <path
      d="M246.206 80.1071V74.6417L241.829 72.1232V77.5885L246.206 80.1071Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.829 77.5885L242.923 76.9565V72.7501L241.829 72.1232V77.5885Z"
      fill="#37474F"
    />
    <path
      d="M246.206 78.8478L242.923 76.9562L241.829 77.5885L246.206 80.107V78.8478Z"
      fill="#263238"
    />
    <path
      d="M239.401 76.1621V70.6965L235.029 68.178V73.6436L239.401 76.1621Z"
      fill="#E0E0E0"
    />
    <path
      d="M235.029 73.6436L236.118 73.0113V68.8049L235.029 68.178V73.6436Z"
      fill="#37474F"
    />
    <path
      d="M239.401 74.9029L236.118 73.0116L235.029 73.6437L239.401 76.1622V74.9029Z"
      fill="#263238"
    />
    <path
      d="M232.599 72.2173V66.7517L228.222 64.2332V69.6988L232.599 72.2173Z"
      fill="#E0E0E0"
    />
    <path
      d="M228.222 69.6988L229.316 69.0664V64.8604L228.222 64.2332V69.6988Z"
      fill="#37474F"
    />
    <path
      d="M232.599 70.958L229.316 69.0664L228.222 69.6987L232.599 72.2173V70.958Z"
      fill="#263238"
    />
    <path
      d="M246.206 69.8554V64.39L241.829 61.8715V67.3368L246.206 69.8554Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.829 67.3368L242.923 66.7048V62.4984L241.829 61.8715V67.3368Z"
      fill="#37474F"
    />
    <path
      d="M246.206 68.5958L242.923 66.7042L241.829 67.3366L246.206 69.8551V68.5958Z"
      fill="#263238"
    />
    <path
      d="M239.401 65.8687V60.4033L235.029 57.8794V63.3501L239.401 65.8687Z"
      fill="#E0E0E0"
    />
    <path
      d="M235.029 63.3501L236.118 62.7181V58.5117L235.029 57.8794V63.3501Z"
      fill="#37474F"
    />
    <path
      d="M239.401 64.6094L236.118 62.7178L235.029 63.3501L239.401 65.8686V64.6094Z"
      fill="#263238"
    />
    <path
      d="M232.599 61.882V56.4164L228.222 53.8927V59.3581L232.599 61.882Z"
      fill="#E0E0E0"
    />
    <path
      d="M228.222 59.3581L229.316 58.7311V54.5248L228.222 53.8927V59.3581Z"
      fill="#37474F"
    />
    <path
      d="M232.599 60.6222L229.316 58.7308L228.222 59.3578L232.599 61.8814V60.6222Z"
      fill="#263238"
    />
    <path
      d="M246.206 59.6089V54.1382L241.829 51.6196V57.085L246.206 59.6089Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.829 57.085L242.923 56.4529V52.2517L241.829 51.6196V57.085Z"
      fill="#37474F"
    />
    <path
      d="M246.206 58.3444L242.923 56.4528L241.829 57.0851L246.206 59.6087V58.3444Z"
      fill="#263238"
    />
    <path
      d="M239.401 55.5749V50.1095L235.029 47.5856V53.0512L239.401 55.5749Z"
      fill="#E0E0E0"
    />
    <path
      d="M235.029 53.0512L236.118 52.4242V48.2179L235.029 47.5856V53.0512Z"
      fill="#37474F"
    />
    <path
      d="M239.401 54.3155L236.118 52.424L235.029 53.0511L239.401 55.5748V54.3155Z"
      fill="#263238"
    />
    <path
      d="M232.599 51.541V46.0757L228.222 43.5571V49.0225L232.599 51.541Z"
      fill="#E0E0E0"
    />
    <path
      d="M228.222 49.0225L229.316 48.3902V44.1841L228.222 43.5571V49.0225Z"
      fill="#37474F"
    />
    <path
      d="M232.599 50.282L229.316 48.3904L228.222 49.0227L232.599 51.5412V50.282Z"
      fill="#263238"
    />
    <path
      d="M270.183 34.3137L251.041 23.2834L229.772 35.5365L248.915 46.5667L270.183 34.3137Z"
      fill="#F0F0F0"
    />
    <path
      d="M251.041 23.2834V25.7341L231.898 36.7643L229.772 35.5365L251.041 23.2834Z"
      fill="#263238"
    />
    <path
      d="M251.04 23.2834L270.183 34.3137L268.051 35.5365L251.04 25.7341V23.2834Z"
      fill="#455A64"
    />
    <path
      d="M176.867 156.201L227.771 126.872C228.113 126.691 228.399 126.421 228.598 126.09C228.798 125.759 228.903 125.38 228.903 124.994C228.903 124.608 228.798 124.229 228.598 123.898C228.399 123.567 228.113 123.297 227.771 123.116L176.862 93.7868C176.511 93.6091 176.123 93.5166 175.729 93.5166C175.335 93.5166 174.946 93.6091 174.595 93.7868L123.691 123.095C123.35 123.276 123.064 123.546 122.864 123.877C122.665 124.208 122.56 124.587 122.56 124.973C122.56 125.359 122.665 125.738 122.864 126.069C123.064 126.4 123.35 126.67 123.691 126.852L174.6 156.181C174.95 156.362 175.337 156.458 175.731 156.461C176.125 156.465 176.515 156.376 176.867 156.201Z"
      fill="currentColor"
    />
    <path
      d="M176.867 156.201L227.771 126.872C228.113 126.691 228.399 126.421 228.598 126.09C228.798 125.759 228.903 125.38 228.903 124.994C228.903 124.608 228.798 124.229 228.598 123.898C228.399 123.567 228.113 123.297 227.771 123.116L176.862 93.7868C176.511 93.6091 176.123 93.5166 175.729 93.5166C175.335 93.5166 174.946 93.6091 174.595 93.7868L123.691 123.095C123.35 123.276 123.064 123.546 122.864 123.877C122.665 124.208 122.56 124.587 122.56 124.973C122.56 125.359 122.665 125.738 122.864 126.069C123.064 126.4 123.35 126.67 123.691 126.852L174.6 156.181C174.95 156.362 175.337 156.458 175.731 156.461C176.125 156.465 176.515 156.376 176.867 156.201Z"
      fill="currentColor"
    />
    <path
      d="M176.867 156.201L227.771 126.872C228.113 126.691 228.399 126.421 228.598 126.09C228.798 125.759 228.903 125.38 228.903 124.994C228.903 124.608 228.798 124.229 228.598 123.898C228.399 123.567 228.113 123.297 227.771 123.116L176.862 93.7868C176.511 93.6091 176.123 93.5166 175.729 93.5166C175.335 93.5166 174.946 93.6091 174.595 93.7868L123.691 123.095C123.35 123.276 123.064 123.546 122.864 123.877C122.665 124.208 122.56 124.587 122.56 124.973C122.56 125.359 122.665 125.738 122.864 126.069C123.064 126.4 123.35 126.67 123.691 126.852L174.6 156.181C174.95 156.362 175.337 156.458 175.731 156.461C176.125 156.465 176.515 156.376 176.867 156.201Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M176.867 156.201L227.771 126.872C228.113 126.691 228.399 126.421 228.598 126.09C228.798 125.759 228.903 125.38 228.903 124.994C228.903 124.608 228.798 124.229 228.598 123.898C228.399 123.567 228.113 123.297 227.771 123.116L176.862 93.7868C176.511 93.6091 176.123 93.5166 175.729 93.5166C175.335 93.5166 174.946 93.6091 174.595 93.7868L123.691 123.095C123.35 123.276 123.064 123.546 122.864 123.877C122.665 124.208 122.56 124.587 122.56 124.973C122.56 125.359 122.665 125.738 122.864 126.069C123.064 126.4 123.35 126.67 123.691 126.852L174.6 156.181C174.95 156.362 175.337 156.458 175.731 156.461C176.125 156.465 176.515 156.376 176.867 156.201Z"
      fill="#1D1D1D"
    />
    <path
      d="M123.692 124.422L174.601 153.751C174.952 153.929 175.341 154.021 175.735 154.021C176.128 154.021 176.517 153.929 176.868 153.751L227.772 124.422C227.908 124.376 228.027 124.288 228.111 124.171C228.196 124.053 228.241 123.913 228.241 123.769C228.241 123.625 228.196 123.484 228.111 123.367C228.027 123.25 227.908 123.162 227.772 123.116L176.863 93.7868C176.512 93.6091 176.123 93.5166 175.729 93.5166C175.335 93.5166 174.947 93.6091 174.596 93.7868L123.692 123.095C123.547 123.136 123.42 123.223 123.329 123.342C123.239 123.462 123.189 123.608 123.189 123.758C123.189 123.908 123.239 124.055 123.329 124.174C123.42 124.294 123.547 124.381 123.692 124.422Z"
      fill="currentColor"
    />
    <path
      d="M184.239 143.373L220.393 122.541V35.5365L184.239 56.3691V143.373Z"
      fill="#37474F"
    />
    <path
      d="M175.732 149.503V61.2703L131.069 35.5365V123.769L175.732 149.503Z"
      fill="#455A64"
    />
    <path
      d="M175.732 112.738L131.069 87.0044V84.5538L126.812 87.0044L175.732 115.189V112.738Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M175.732 112.738L131.069 87.0044V84.5538L126.812 87.0044L175.732 115.189V112.738Z"
      fill="white"
    />
    <path
      d="M126.812 87.0042L175.732 115.189V117.639L126.812 89.4548V87.0042Z"
      fill="currentColor"
    />
    <path
      d="M175.73 112.738L184.239 107.837L186.365 109.065L175.73 115.189V112.738Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M175.73 112.738L184.239 107.837L186.365 109.065L175.73 115.189V112.738Z"
      fill="white"
    />
    <path
      d="M175.73 117.64L186.365 111.516V109.065L175.73 115.189V117.64Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M175.73 117.64L186.365 111.516V109.065L175.73 115.189V117.64Z"
      fill="#1D1D1D"
    />
    <path
      d="M175.73 61.2704L184.239 56.3691V107.837L175.73 112.738V61.2704Z"
      fill="#263238"
    />
    <path
      d="M175.73 117.639L184.239 112.738V144.601L175.73 149.503V117.639Z"
      fill="#263238"
    />
    <path
      d="M171.48 105.386V93.1334L162.972 88.2322V100.485L171.48 105.386Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M171.48 105.386V93.1334L162.972 88.2322V100.485L171.48 105.386Z"
      fill="white"
    />
    <path
      d="M162.972 100.485L165.098 99.2626V89.455L162.972 88.2322V100.485Z"
      fill="#263238"
    />
    <path
      d="M171.48 102.936L165.098 99.2627L162.972 100.485L171.48 105.387V102.936Z"
      fill="#263238"
    />
    <path
      d="M158.715 98.0344V85.7813L148.081 79.6523V91.9054L158.715 98.0344Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M158.715 98.0344V85.7813L148.081 79.6523V91.9054L158.715 98.0344Z"
      fill="white"
    />
    <path
      d="M150.207 90.6825L150.212 80.8801L148.081 79.6523V91.9054L150.207 90.6825Z"
      fill="#263238"
    />
    <path
      d="M158.715 95.5838V98.0345L148.081 91.9052L150.207 90.6826L158.715 95.5838Z"
      fill="#263238"
    />
    <path
      d="M189.555 131.732V119.479L200.189 113.355V125.608L189.555 131.732Z"
      fill="currentColor"
    />
    <path
      d="M198.062 124.38V114.577L200.188 113.355V125.608L198.062 124.38Z"
      fill="#263238"
    />
    <path
      d="M189.555 129.281V131.732L200.189 125.608L198.063 124.38L189.555 129.281Z"
      fill="#263238"
    />
    <path
      d="M189.555 111.312V99.0587L200.189 92.9294V105.182L189.555 111.312Z"
      fill="currentColor"
    />
    <path
      d="M198.062 103.96V94.1523L200.188 92.9294V105.182L198.062 103.96Z"
      fill="#263238"
    />
    <path
      d="M189.555 108.862V111.312L200.189 105.183L198.063 103.96L189.555 108.862Z"
      fill="#263238"
    />
    <path
      d="M189.555 90.8869V78.6339L200.189 72.5046V84.7577L189.555 90.8869Z"
      fill="currentColor"
    />
    <path
      d="M198.062 83.5351V73.7326L200.188 72.5046V84.7577L198.062 83.5351Z"
      fill="#263238"
    />
    <path
      d="M189.555 88.436V90.8866L200.189 84.7577L198.063 83.5348L189.555 88.436Z"
      fill="#263238"
    />
    <path
      d="M189.555 70.4615V58.2084L200.189 52.0795V64.3376L189.555 70.4615Z"
      fill="currentColor"
    />
    <path
      d="M198.062 63.1096V53.3072L200.188 52.0795V64.3376L198.062 63.1096Z"
      fill="#263238"
    />
    <path
      d="M189.555 68.0108V70.4614L200.189 64.3374L198.063 63.1096L189.555 68.0108Z"
      fill="#263238"
    />
    <path
      d="M204.676 123.021V110.768L215.315 104.645L215.31 116.898L204.676 123.021Z"
      fill="currentColor"
    />
    <path
      d="M213.186 115.67V105.867L215.317 104.645L215.311 116.898L213.186 115.67Z"
      fill="#263238"
    />
    <path
      d="M204.676 120.576V123.021L215.31 116.897L213.184 115.669L204.676 120.576Z"
      fill="#263238"
    />
    <path
      d="M204.676 102.601V90.3429L215.315 84.2191L215.31 96.4722L204.676 102.601Z"
      fill="currentColor"
    />
    <path
      d="M213.186 95.2496V85.4417L215.317 84.2191L215.311 96.4722L213.186 95.2496Z"
      fill="#263238"
    />
    <path
      d="M204.676 100.151V102.602L215.31 96.4726L213.184 95.2498L204.676 100.151Z"
      fill="#263238"
    />
    <path
      d="M204.676 82.1761V69.923L215.315 63.7941L215.31 76.0522L204.676 82.1761Z"
      fill="currentColor"
    />
    <path
      d="M213.186 74.8242V65.0218L215.317 63.7941L215.311 76.0522L213.186 74.8242Z"
      fill="#263238"
    />
    <path
      d="M204.676 79.7259V82.1765L215.31 76.0524L213.184 74.8247L204.676 79.7259Z"
      fill="#263238"
    />
    <path
      d="M204.676 61.7513V49.4982L215.315 43.3744L215.31 55.6274L204.676 61.7513Z"
      fill="currentColor"
    />
    <path
      d="M213.186 54.3994V44.597L215.317 43.3744L215.311 55.6274L213.186 54.3994Z"
      fill="#263238"
    />
    <path
      d="M204.676 59.3004V61.751L215.31 55.6272L213.184 54.3992L204.676 59.3004Z"
      fill="#263238"
    />
    <path
      d="M143.83 89.4551V77.202L135.321 72.3008V84.5538L143.83 89.4551Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M143.83 89.4551V77.202L135.321 72.3008V84.5538L143.83 89.4551Z"
      fill="white"
    />
    <path
      d="M137.447 83.3312L135.321 84.5538V72.3008L137.447 73.5288V83.3312Z"
      fill="#263238"
    />
    <path
      d="M143.83 87.0045L137.447 83.3311L135.321 84.5539L143.83 89.4551V87.0045Z"
      fill="#263238"
    />
    <path
      d="M148.081 112.858C148.081 109.399 150.463 107.963 153.401 109.656C156.338 111.348 158.715 115.523 158.715 118.988V139.7L148.081 133.571V112.858Z"
      fill="#263238"
    />
    <path
      d="M158.714 118.988C158.714 115.523 156.337 111.343 153.4 109.655C152.652 109.183 151.782 108.937 150.897 108.95C150.411 109.759 150.173 110.693 150.211 111.636V132.348L158.714 137.249V118.988Z"
      fill="#455A64"
    />
    <path
      d="M158.714 118.988C158.714 115.523 156.337 111.343 153.4 109.655C152.652 109.183 151.782 108.937 150.897 108.95C150.411 109.759 150.173 110.693 150.211 111.636V132.348L158.714 137.249V118.988Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M158.714 118.988C158.714 115.523 156.337 111.343 153.4 109.655C152.652 109.183 151.782 108.937 150.897 108.95C150.411 109.759 150.173 110.693 150.211 111.636V132.348L158.714 137.249V118.988Z"
      fill="white"
    />
    <path
      d="M154.935 110.81C154.608 110.503 154.258 110.222 153.888 109.969V115.476L150.223 113.355V114.625L153.888 116.751V132.631L154.935 133.231V117.347L158.715 119.526V119.003C158.715 118.753 158.715 118.481 158.679 118.246L154.935 116.093V110.81Z"
      fill="#455A64"
    />
    <path
      d="M175.731 61.2703V53.9185L128.938 26.9618V34.3136L175.731 61.2703Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M175.731 61.2703V53.9185L128.938 26.9618V34.3136L175.731 61.2703Z"
      fill="white"
    />
    <path
      d="M175.73 61.2703V53.9185L222.524 26.9618V34.3136L175.73 61.2703Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M175.73 61.2703V53.9185L222.524 26.9618V34.3136L175.73 61.2703Z"
      fill="#1D1D1D"
    />
    <path
      d="M135.321 48.1135C135.321 45.2292 137.227 43.9856 139.573 45.339C141.918 46.6923 143.83 50.1305 143.83 53.0148V72.3008L135.321 67.3996V48.1135Z"
      fill="#263238"
    />
    <path
      d="M139.573 45.3339C139.067 45.0312 138.496 44.852 137.908 44.8113C137.584 45.4595 137.426 46.1774 137.447 46.9013V66.1875L143.83 69.8451V53.0097C143.83 50.1254 141.924 46.6872 139.573 45.3339Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M139.573 45.3339C139.067 45.0312 138.496 44.852 137.908 44.8113C137.584 45.4595 137.426 46.1774 137.447 46.9013V66.1875L143.83 69.8451V53.0097C143.83 50.1254 141.924 46.6872 139.573 45.3339Z"
      fill="white"
    />
    <path
      d="M135.321 67.3996L137.447 66.1716L143.83 69.8449V72.3008L135.321 67.3996Z"
      fill="#263238"
    />
    <path
      d="M148.081 56.5101C148.081 53.0458 150.463 51.614 153.401 53.3072C156.338 55.0002 158.715 59.175 158.715 62.6393V80.8803L148.081 74.7511V56.5101Z"
      fill="#263238"
    />
    <path
      d="M158.714 62.6394C158.714 59.1751 156.337 54.9949 153.4 53.3073C152.652 52.8344 151.782 52.5891 150.897 52.6017C150.411 53.4113 150.173 54.3446 150.211 55.2876V73.5235L158.714 78.4298V62.6394Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M158.714 62.6394C158.714 59.1751 156.337 54.9949 153.4 53.3073C152.652 52.8344 151.782 52.5891 150.897 52.6017C150.411 53.4113 150.173 54.3446 150.211 55.2876V73.5235L158.714 78.4298V62.6394Z"
      fill="white"
    />
    <path
      d="M150.212 73.5237L148.081 74.7514L158.715 80.8806V78.43L150.212 73.5237Z"
      fill="#263238"
    />
    <path
      d="M162.972 64.0501C162.972 61.1658 164.872 59.9224 167.223 61.2758C169.574 62.6291 171.48 66.067 171.48 68.9513V88.2376L162.972 83.3364V64.0501Z"
      fill="#263238"
    />
    <path
      d="M167.224 61.2698C166.717 60.9679 166.147 60.789 165.559 60.7474C165.234 61.3951 165.075 62.1133 165.098 62.8374V82.1237L171.48 85.7813V68.9456C171.48 66.0613 169.574 62.6231 167.224 61.2698Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M167.224 61.2698C166.717 60.9679 166.147 60.789 165.559 60.7474C165.234 61.3951 165.075 62.1133 165.098 62.8374V82.1237L171.48 85.7813V68.9456C171.48 66.0613 169.574 62.6231 167.224 61.2698Z"
      fill="white"
    />
    <path
      d="M165.098 82.103L162.972 83.331L171.48 88.2322V85.7816L165.098 82.103Z"
      fill="#263238"
    />
    <path
      d="M162.972 120.393C162.972 117.509 164.872 116.265 167.223 117.619C169.574 118.972 171.48 122.41 171.48 125.295V147.052L162.972 142.151V120.393Z"
      fill="#263238"
    />
    <path
      d="M167.223 117.619C166.717 117.317 166.147 117.138 165.558 117.096C165.235 117.744 165.076 118.462 165.098 119.186V140.938L171.48 144.596V125.263C171.48 122.41 169.574 118.972 167.223 117.619Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M167.223 117.619C166.717 117.317 166.147 117.138 165.558 117.096C165.235 117.744 165.076 118.462 165.098 119.186V140.938L171.48 144.596V125.263C171.48 122.41 169.574 118.972 167.223 117.619Z"
      fill="white"
    />
    <path
      d="M162.972 142.151V134.799L171.48 139.7V147.052L162.972 142.151Z"
      fill="#37474F"
    />
    <path
      d="M162.972 134.799L165.098 133.571L171.475 137.25L171.48 139.701L162.972 134.799Z"
      fill="#263238"
    />
    <path
      d="M135.321 104.462C135.321 101.577 137.227 100.334 139.573 101.687C141.918 103.041 143.83 106.479 143.83 109.363V131.121L135.321 126.23V104.462Z"
      fill="#263238"
    />
    <path
      d="M139.573 101.671C139.066 101.371 138.496 101.192 137.908 101.149C137.584 101.797 137.426 102.515 137.447 103.239V124.997L143.83 128.654V109.321C143.83 106.473 141.924 103.035 139.573 101.671Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M139.573 101.671C139.066 101.371 138.496 101.192 137.908 101.149C137.584 101.797 137.426 102.515 137.447 103.239V124.997L143.83 128.654V109.321C143.83 106.473 141.924 103.035 139.573 101.671Z"
      fill="white"
    />
    <path
      d="M143.83 131.12V123.768L135.321 118.867V126.219L143.83 131.12Z"
      fill="#37474F"
    />
    <path
      d="M135.321 118.868L137.447 117.64L143.83 121.318V123.769L135.321 118.868Z"
      fill="#263238"
    />
    <path
      d="M148.081 133.571V131.12L158.715 137.25V139.7L148.081 133.571Z"
      fill="#37474F"
    />
    <path
      d="M148.081 131.12L150.212 129.898L158.715 134.799V137.25L148.081 131.12Z"
      fill="#263238"
    />
    <path
      d="M175.731 53.9185L222.524 26.9618L175.731 0L128.938 26.9618L175.731 53.9185Z"
      fill="currentColor"
    />
    <path
      opacity="0.7"
      d="M175.731 53.9185L222.524 26.9618L175.731 0L128.938 26.9618L175.731 53.9185Z"
      fill="white"
    />
    <path
      d="M175.732 49.0173L214.017 26.9618L175.732 4.90125L137.447 26.9618L175.732 49.0173Z"
      fill="currentColor"
    />
    <path
      d="M175.732 4.90125V12.2583L143.83 30.6353L137.447 26.9618L175.732 4.90125Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M175.732 4.90125V12.2583L143.83 30.6353L137.447 26.9618L175.732 4.90125Z"
      fill="#1D1D1D"
    />
    <path
      d="M175.73 4.90125L214.016 26.9618L207.633 30.6353L175.73 12.2583V4.90125Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M175.73 4.90125L214.016 26.9618L207.633 30.6353L175.73 12.2583V4.90125Z"
      fill="white"
    />
    <path
      d="M169.349 11.0303L158.715 17.1594L173.606 25.7339L184.24 19.61L169.349 11.0303Z"
      fill="#455A64"
    />
    <path
      d="M158.715 17.1594V24.5112L173.606 33.0856V25.734L158.715 17.1594Z"
      fill="#37474F"
    />
    <path
      d="M184.24 19.6102V26.9621L173.606 33.0859V25.7341L184.24 19.6102Z"
      fill="#263238"
    />
    <path
      d="M165.857 15.1427C163.925 16.2557 163.925 18.0584 165.857 19.1713C166.94 19.7202 168.138 20.0063 169.352 20.0063C170.567 20.0063 171.764 19.7202 172.847 19.1713C174.774 18.0584 174.774 16.2557 172.847 15.1427C171.764 14.5938 170.567 14.3077 169.352 14.3077C168.138 14.3077 166.94 14.5938 165.857 15.1427Z"
      fill="#37474F"
    />
    <path
      d="M167.717 16.292C166.811 16.7675 166.811 17.5461 167.717 18.0216C168.229 18.2591 168.788 18.3822 169.353 18.3822C169.918 18.3822 170.476 18.2591 170.989 18.0216C171.889 17.5461 171.889 16.7675 170.989 16.292C170.476 16.0545 169.918 15.9314 169.353 15.9314C168.788 15.9314 168.229 16.0545 167.717 16.292Z"
      fill="#263238"
    />
    <path
      d="M176.894 26.288L179.019 27.5106V32.4169"
      stroke="#455A64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M179.982 24.5112L182.108 25.7339V30.6351"
      stroke="#455A64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M233.349 99.1621V116.05C233.359 116.17 233.401 116.285 233.471 116.384C233.541 116.482 233.636 116.56 233.746 116.609C234.049 116.761 234.382 116.84 234.72 116.84C235.059 116.84 235.392 116.761 235.694 116.609C235.805 116.56 235.9 116.482 235.97 116.384C236.04 116.285 236.082 116.17 236.092 116.05V99.1726L233.349 99.1621Z"
      fill="#37474F"
    />
    <path
      d="M241.329 104.074C241.329 105.823 240.633 107.5 239.393 108.737C238.154 109.973 236.474 110.668 234.721 110.668C232.969 110.668 231.288 109.973 230.049 108.737C228.81 107.5 228.113 105.823 228.113 104.074C228.113 97.3333 231.072 89.9658 234.721 89.9658C238.37 89.9658 241.329 97.2392 241.329 104.074Z"
      fill="currentColor"
    />
    <path
      d="M241.33 104.074C241.33 103.901 241.33 103.729 241.33 103.551V103.379C241.279 104.097 240.998 104.78 240.529 105.328C239.278 106.895 236.518 108.599 233.67 107.418C228.884 105.417 228.753 99.768 229.816 95.0916C228.7 97.9571 228.127 101.004 228.125 104.079C228.125 105.828 228.821 107.505 230.06 108.742C231.3 109.978 232.98 110.673 234.733 110.673C236.485 110.673 238.166 109.978 239.405 108.742C240.644 107.505 241.34 105.828 241.34 104.079L241.33 104.074Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M241.33 104.074C241.33 103.901 241.33 103.729 241.33 103.551V103.379C241.279 104.097 240.998 104.78 240.529 105.328C239.278 106.895 236.518 108.599 233.67 107.418C228.884 105.417 228.753 99.768 229.816 95.0916C228.7 97.9571 228.127 101.004 228.125 104.079C228.125 105.828 228.821 107.505 230.06 108.742C231.3 109.978 232.98 110.673 234.733 110.673C236.485 110.673 238.166 109.978 239.405 108.742C240.644 107.505 241.34 105.828 241.34 104.079L241.33 104.074Z"
      fill="#1D1D1D"
    />
    <path
      d="M285.573 68.7527V85.6353C285.563 85.7557 285.519 85.8708 285.448 85.9692C285.378 86.0674 285.283 86.1451 285.17 86.1943C284.87 86.3463 284.538 86.4255 284.199 86.4255C283.863 86.4255 283.529 86.3463 283.228 86.1943C283.118 86.1451 283.023 86.0674 282.951 85.9692C282.882 85.8708 282.838 85.7557 282.825 85.6353V68.7527H285.573Z"
      fill="#37474F"
    />
    <path
      d="M277.596 73.6803C277.57 74.5615 277.721 75.4388 278.042 76.26C278.364 77.0815 278.846 77.8305 279.463 78.4625C280.079 79.0946 280.814 79.597 281.628 79.9402C282.442 80.2831 283.315 80.46 284.199 80.46C285.082 80.46 285.958 80.2831 286.772 79.9402C287.584 79.597 288.321 79.0946 288.938 78.4625C289.554 77.8305 290.037 77.0815 290.358 76.26C290.676 75.4388 290.831 74.5615 290.805 73.6803C290.805 66.9397 287.846 59.5723 284.201 59.5723C280.559 59.5723 277.596 66.83 277.596 73.6803Z"
      fill="currentColor"
    />
    <path
      d="M83.8481 118.849C88.2496 118.148 90.5123 116.014 92.0028 113.374C93.0828 111.444 93.5094 108.092 93.0181 105.618C92.7463 104.232 92.2617 102.896 91.5815 101.657C92.3647 99.6419 91.8677 97.3891 90.8793 95.4542C89.6157 92.9913 87.2181 91.1428 84.3181 91.5416C83.8427 91.6064 84.1884 92.0537 84.5772 92.2262C87.9038 93.7351 88.9245 96.2519 89.2322 98.7847C89.0003 98.5907 88.7568 98.4108 88.5033 98.246C87.1767 97.4814 85.6994 97.0139 84.1738 96.8762C82.6481 96.7383 81.1107 96.9336 79.6683 97.4483C77.0382 98.5261 76.6442 101.086 78.4424 103.134C79.7199 104.483 81.3994 105.385 83.2314 105.705C85.0635 106.026 86.9502 105.748 88.6112 104.912C88.9661 106.081 89.1123 107.304 89.0432 108.523C88.9674 109.69 88.6019 110.819 87.9796 111.81C87.3575 112.801 86.4982 113.621 85.479 114.198C84.4419 114.71 83.2984 114.97 82.1416 114.958C80.9671 114.937 79.81 114.671 78.7449 114.177C78.5962 114.13 78.4398 114.115 78.2849 114.131C78.1303 114.147 77.9803 114.195 77.8447 114.271C77.7091 114.347 77.5907 114.45 77.4964 114.574C77.4024 114.698 77.3346 114.839 77.2976 114.99C76.0717 118.563 81.4774 119.221 83.8481 118.849ZM87.3529 102.342C86.0569 103.021 84.183 103.355 82.822 102.838C82.051 102.609 81.3588 102.171 80.824 101.571C80.0517 100.553 81.1479 100.04 82.363 100.046C83.3174 100.007 84.2675 100.195 85.1349 100.595C86.0025 100.994 86.7624 101.592 87.3529 102.342Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M91.5812 101.657C92.1021 102.602 92.5066 103.606 92.7855 104.648C91.8316 105.826 90.494 106.633 89.0051 106.928C88.9394 106.244 88.8055 105.569 88.6055 104.912C89.9642 104.229 91.0231 103.07 91.5812 101.657Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M87.3529 102.341C87.2036 102.153 87.0431 101.973 86.8721 101.803C87.4248 101.486 87.9151 101.071 88.3194 100.579C88.7884 100.088 89.1053 99.4726 89.2322 98.8062C89.2286 99.5033 89.0557 100.189 88.7285 100.805C88.401 101.42 87.929 101.948 87.3529 102.341Z"
      fill="#1D1D1D"
    />
    <path
      d="M50.0094 172.531C50.0094 172.504 50.0094 172.483 50.0094 172.456V172.381C49.7556 170.624 50.7817 168.813 53.0984 167.444C57.3055 164.96 64.1747 164.884 68.4354 167.282C70.7793 168.597 71.8431 170.386 71.6325 172.132V172.213C71.6325 172.24 76.1311 171.583 76.1311 171.583L75.105 172.661C72.3445 175.62 70.5466 179.344 69.9477 183.342L69.3752 187.147V187.185C69.1969 188.263 68.3976 189.308 66.9827 190.144C63.7103 192.078 58.3423 192.132 55.0535 190.273C53.6224 189.464 52.8069 188.435 52.6017 187.368V187.309L50.0094 172.531Z"
      fill="currentColor"
    />
    <path
      opacity="0.1"
      d="M50.0094 172.531C50.0094 172.504 50.0094 172.483 50.0094 172.456V172.381C49.7556 170.624 50.7817 168.813 53.0984 167.444C57.3055 164.96 64.1747 164.884 68.4354 167.282C70.7793 168.597 71.8431 170.386 71.6325 172.132V172.213C71.6325 172.24 76.1311 171.583 76.1311 171.583L75.105 172.661C72.3445 175.62 70.5466 179.344 69.9477 183.342L69.3752 187.147V187.185C69.1969 188.263 68.3976 189.308 66.9827 190.144C63.7103 192.078 58.3423 192.132 55.0535 190.273C53.6224 189.464 52.8069 188.435 52.6017 187.368V187.309L50.0094 172.531Z"
      fill="white"
    />
    <path
      d="M72.556 159.214V159.139V159.063C72.3074 157.312 73.3661 155.55 75.6451 154.132C77.265 153.13 85.7165 153.054 90.604 152.985C93.5147 152.952 95.1079 152.64 94.8919 154.402V154.477C94.8919 154.504 98.078 150.473 98.078 150.473C95.6041 155.501 93.9141 160.877 93.0665 166.415L91.9323 173.83V173.873C91.7487 174.951 90.9551 175.991 89.5401 176.827C86.2675 178.761 80.9265 178.821 77.6107 176.956C76.1796 176.153 75.3587 175.118 75.1535 174.051V174.019L72.556 159.214Z"
      fill="currentColor"
    />
    <path
      d="M47.4011 164.103C47.4011 164.076 47.4011 164.049 47.4011 164.027V163.946C47.6171 162.189 46.5209 160.454 44.2096 159.096C42.5894 158.126 34.1216 158.217 29.2288 158.271C26.3235 158.298 24.7196 158.023 24.9735 159.775V159.856C24.9709 159.881 24.9709 159.906 24.9735 159.931L28.3648 179.133V179.171C28.57 180.249 29.3909 181.267 30.822 182.076C34.1378 183.935 39.4626 183.876 42.7515 181.946C44.1664 181.111 44.96 180.06 45.1437 178.988V178.934L47.4011 164.103Z"
      fill="currentColor"
    />
    <path
      d="M47.4011 164.103C47.4011 164.076 47.4011 164.049 47.4011 164.027V163.946C47.6171 162.189 46.5209 160.454 44.2096 159.096C42.5894 158.126 34.1216 158.217 29.2288 158.271C26.3235 158.298 24.7196 158.023 24.9735 159.775V159.856C24.9709 159.881 24.9709 159.906 24.9735 159.931L28.3648 179.133V179.171C28.57 180.249 29.3909 181.267 30.822 182.076C34.1378 183.935 39.4626 183.876 42.7515 181.946C44.1664 181.111 44.96 180.06 45.1437 178.988V178.934L47.4011 164.103Z"
      fill="currentColor"
    />
    <path
      d="M47.9356 108.76C52.7203 106.632 59.2438 105.748 64.8385 105.688C69.3784 105.639 73.8834 106.482 78.0966 108.17C82.3095 109.858 86.1483 112.358 89.3929 115.527C92.6376 118.696 95.2248 122.472 97.007 126.639C98.7889 130.806 99.7308 135.283 99.7788 139.813C99.9139 152.489 93.2985 164.377 83.8909 171.61C79.1561 175.103 74.0267 178.03 68.6081 180.33C61.5604 183.186 57.1215 183.493 50.7382 183.563C46.1986 183.613 41.6933 182.77 37.4804 181.082C33.2672 179.394 29.4287 176.893 26.184 173.725C22.9393 170.556 20.352 166.78 18.5699 162.613C16.7879 158.446 15.846 153.969 15.798 149.438C15.7008 140.692 18.9518 132.915 24.1469 126.69C26.3071 124.114 29.731 120.164 32.4474 118.159C37.7395 114.241 42.627 111.126 47.9356 108.76Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M47.9356 108.76C52.7203 106.632 59.2438 105.748 64.8385 105.688C69.3784 105.639 73.8834 106.482 78.0966 108.17C82.3095 109.858 86.1483 112.358 89.3929 115.527C92.6376 118.696 95.2248 122.472 97.007 126.639C98.7889 130.806 99.7308 135.283 99.7788 139.813C99.9139 152.489 93.2985 164.377 83.8909 171.61C79.1561 175.103 74.0267 178.03 68.6081 180.33C61.5604 183.186 57.1215 183.493 50.7382 183.563C46.1986 183.613 41.6933 182.77 37.4804 181.082C33.2672 179.394 29.4287 176.893 26.184 173.725C22.9393 170.556 20.352 166.78 18.5699 162.613C16.7879 158.446 15.846 153.969 15.798 149.438C15.7008 140.692 18.9518 132.915 24.1469 126.69C26.3071 124.114 29.731 120.164 32.4474 118.159C37.7395 114.241 42.627 111.126 47.9356 108.76Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M57.0415 164.402C57.8426 161.015 55.7409 157.622 52.3475 156.823C48.9541 156.024 45.5541 158.121 44.7532 161.507C43.9524 164.894 46.0539 168.287 49.4472 169.086C52.8406 169.885 56.2407 167.788 57.0415 164.402Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M20.205 147.164C20.2062 145.981 19.8736 144.822 19.2451 143.82C18.6166 142.817 17.7178 142.011 16.6516 141.495C16.0526 144.094 15.7662 146.755 15.7983 149.422C15.8099 150.649 15.8875 151.874 16.0305 153.092C17.2536 152.654 18.311 151.849 19.0582 150.788C19.8054 149.727 20.2059 148.461 20.205 147.164Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M78.6905 164.647C91.9214 153.739 99.6492 129.827 88.8485 115.001C92.2596 118.178 94.9888 122.014 96.8704 126.275C98.7521 130.537 99.747 135.135 99.7951 139.792C99.9299 152.467 93.3145 164.356 83.9072 171.588C79.1721 175.082 74.0427 178.008 68.6242 180.308C61.5767 183.164 57.1375 183.472 50.7545 183.542C45.9544 183.599 41.1954 182.656 36.7819 180.772C32.3683 178.888 28.3979 176.105 25.1243 172.601L24.7949 172.24C44.3225 185.962 65.465 175.555 78.6905 164.647Z"
      fill="currentColor"
    />
    <path
      d="M42.1894 121.204L36.8862 124.335C31.529 122.859 25.1567 123.861 21.1497 130.802C21.1497 130.802 19.5836 114.71 21.5925 110.237L23.137 109.326C24.0922 108.878 25.1717 108.765 26.1991 109.008C32.8037 110.07 38.8089 115.47 42.1894 121.204Z"
      fill="currentColor"
    />
    <path
      d="M36.887 124.341C29.9531 122.648 24.7093 124.88 21.1505 130.808C21.1505 130.808 14.8375 107.72 24.7201 109.887C31.4058 111.331 36.887 124.341 36.887 124.341Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M36.887 124.341C29.9531 122.648 24.7093 124.88 21.1505 130.808C21.1505 130.808 14.8375 107.72 24.7201 109.887C31.4058 111.331 36.887 124.341 36.887 124.341Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M26.853 122.142C33.2524 119.722 36.8867 124.341 36.8867 124.341C29.9528 122.649 24.709 124.879 21.1502 130.808C21.0086 128.938 21.4913 127.073 22.5229 125.506C23.5545 123.938 25.077 122.755 26.853 122.142Z"
      fill="currentColor"
    />
    <path
      d="M47.6279 130.366C53.5358 135.615 58.8606 142.298 62.8136 152.349L67.8952 149.368C69.7909 147.407 74.5972 130.846 64.9143 124.799C58.4501 120.768 47.6279 130.366 47.6279 130.366Z"
      fill="currentColor"
    />
    <path
      d="M47.6279 130.366C54.4065 136.351 59.6193 143.897 62.8136 152.349C62.8136 152.349 73.3551 133.686 63.5913 125.58C57.8884 120.859 47.6279 130.366 47.6279 130.366Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M47.6279 130.366C54.4065 136.351 59.6193 143.897 62.8136 152.349C62.8136 152.349 73.3551 133.686 63.5913 125.58C57.8884 120.859 47.6279 130.366 47.6279 130.366Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M47.6279 130.366C47.6279 130.366 53.5034 130.307 58.6392 137.167C63.4454 143.591 62.8136 152.349 62.8136 152.349C59.6193 143.897 54.4065 136.351 47.6279 130.366Z"
      fill="currentColor"
    />
    <path
      d="M22.2136 147.983C24.2658 146.771 27.2035 146.943 30.4762 148.781C36.7783 152.327 41.9518 160.702 42.0222 167.498C42.0599 170.731 40.9419 173.033 39.0464 174.164C38.1393 174.703 34.5859 176.81 33.6461 177.338C31.5939 178.529 28.6724 178.346 25.416 176.519C19.1138 172.979 13.9457 164.598 13.8701 157.802C13.8323 154.569 14.9501 152.273 16.8403 151.141C17.7691 150.597 21.3118 148.511 22.2136 147.983Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M16.8621 151.141C17.7694 150.602 21.312 148.511 22.2138 147.983C24.266 146.771 27.2038 146.943 30.4764 148.781C33.249 150.416 35.642 152.62 37.4969 155.248L16.8189 167.46C15.0368 164.313 13.9405 160.885 13.9081 157.759C13.8541 154.563 14.9882 152.273 16.8621 151.141Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M40.7858 161.413L20.9773 173.059C19.3419 171.392 17.9346 169.517 16.792 167.482L37.47 155.27C38.8436 157.162 39.9585 159.228 40.7858 161.413Z"
      fill="white"
    />
    <path
      d="M25.1734 151.923C18.8712 148.382 13.8327 151.017 13.8921 157.808C13.9515 164.598 19.1358 172.984 25.4381 176.525C31.7402 180.066 36.7789 177.43 36.7193 170.634C36.66 163.839 31.4757 155.458 25.1734 151.923Z"
      fill="currentColor"
    />
    <path
      d="M25.1734 151.923C18.8712 148.382 13.8327 151.017 13.8921 157.808C13.9515 164.598 19.1358 172.984 25.4381 176.525C31.7402 180.066 36.7789 177.43 36.7193 170.634C36.66 163.839 31.4757 155.458 25.1734 151.923Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M25.1734 151.923C18.8712 148.382 13.8327 151.017 13.8921 157.808C13.9515 164.598 19.1358 172.984 25.4381 176.525C31.7402 180.066 36.7789 177.43 36.7193 170.634C36.66 163.839 31.4757 155.458 25.1734 151.923Z"
      fill="white"
    />
    <path
      d="M29.3743 170.909C30.1466 171.346 30.7569 170.871 30.7461 169.831L30.6919 164.647C30.6788 164.129 30.5437 163.622 30.2979 163.166C30.0521 162.71 29.7025 162.319 29.2772 162.022C28.5103 161.591 27.8946 162.065 27.9054 163.1L27.9647 168.285C27.9776 168.802 28.1117 169.308 28.3564 169.764C28.6012 170.22 28.95 170.612 29.3743 170.909Z"
      fill="currentColor"
    />
    <path
      d="M30.6926 164.669L30.7468 169.853C30.7543 170.058 30.725 170.262 30.6603 170.457C30.5547 170.425 30.4532 170.382 30.3579 170.327C29.9342 170.031 29.5859 169.64 29.3411 169.185C29.0964 168.73 28.962 168.224 28.9484 167.708L28.8945 162.524C28.8881 162.319 28.9153 162.115 28.9754 161.92C29.0802 161.951 29.1816 161.992 29.2779 162.044C29.7033 162.34 30.0528 162.732 30.2986 163.188C30.5444 163.644 30.6795 164.151 30.6926 164.669Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M30.6926 164.669L30.7468 169.853C30.7543 170.058 30.725 170.262 30.6603 170.457C30.5547 170.425 30.4532 170.382 30.3579 170.327C29.9342 170.031 29.5859 169.64 29.3411 169.185C29.0964 168.73 28.962 168.224 28.9484 167.708L28.8945 162.524C28.8881 162.319 28.9153 162.115 28.9754 161.92C29.0802 161.951 29.1816 161.992 29.2779 162.044C29.7033 162.34 30.0528 162.732 30.2986 163.188C30.5444 163.644 30.6795 164.151 30.6926 164.669Z"
      fill="#1D1D1D"
    />
    <path
      d="M21.344 166.388C22.1109 166.819 22.7265 166.35 22.7157 165.31L22.6563 160.125C22.6432 159.608 22.5089 159.102 22.2641 158.646C22.0193 158.19 21.6709 157.798 21.2468 157.501C20.4746 157.07 19.8643 157.544 19.8751 158.579L19.9291 163.763C19.942 164.281 20.0768 164.788 20.3226 165.244C20.5684 165.7 20.9183 166.092 21.344 166.388Z"
      fill="currentColor"
    />
    <path
      d="M22.6461 160.147L22.7055 165.331C22.7077 165.536 22.6785 165.739 22.6191 165.935C22.5133 165.905 22.4117 165.861 22.3167 165.806C21.8917 165.51 21.5422 165.119 21.2965 164.664C21.0507 164.209 20.9155 163.703 20.9018 163.187L20.8478 158.002C20.8403 157.797 20.8696 157.593 20.9342 157.398C21.0396 157.428 21.1411 157.469 21.2367 157.522C21.6607 157.82 22.0092 158.212 22.2539 158.667C22.4987 159.123 22.633 159.63 22.6461 160.147Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M22.6461 160.147L22.7055 165.331C22.7077 165.536 22.6785 165.739 22.6191 165.935C22.5133 165.905 22.4117 165.861 22.3167 165.806C21.8917 165.51 21.5422 165.119 21.2965 164.664C21.0507 164.209 20.9155 163.703 20.9018 163.187L20.8478 158.002C20.8403 157.797 20.8696 157.593 20.9342 157.398C21.0396 157.428 21.1411 157.469 21.2367 157.522C21.6607 157.82 22.0092 158.212 22.2539 158.667C22.4987 159.123 22.633 159.63 22.6461 160.147Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.3"
      d="M34.4944 175.528C37.3188 171.561 35.3853 163.332 32.1507 158.368C34.8886 162.141 36.6762 166.657 36.7194 170.629C36.7949 177.425 31.7403 180.06 25.438 176.519C23.2786 175.259 21.356 173.632 19.7568 171.712C23.7531 176.39 31.4811 179.764 34.4944 175.528Z"
      fill="white"
    />
    <path
      d="M42.3248 149.681C41.9467 151.103 43.4806 149.988 44.9063 150.365C46.3318 150.743 47.1041 152.472 47.4875 151.05C47.6373 150.378 47.5207 149.673 47.1621 149.085C46.8038 148.497 46.2313 148.069 45.5643 147.893C44.8976 147.716 44.1882 147.803 43.5843 148.137C42.9805 148.47 42.5292 149.023 42.3248 149.681Z"
      fill="#263238"
    />
    <path
      d="M23.0024 139.867C22.1384 141.064 23.9691 140.568 25.1625 141.43C26.356 142.292 26.4641 144.178 27.3227 142.988C27.5408 142.705 27.7 142.382 27.7904 142.038C27.8811 141.693 27.9016 141.333 27.8503 140.98C27.7992 140.628 27.6777 140.289 27.4928 139.984C27.3078 139.679 27.0636 139.414 26.7744 139.205C26.4849 138.996 26.1564 138.847 25.8084 138.767C25.4603 138.687 25.0997 138.678 24.748 138.74C24.3962 138.802 24.0604 138.933 23.7606 139.127C23.4607 139.32 23.2029 139.572 23.0024 139.867Z"
      fill="#263238"
    />
    <path
      d="M49.4313 123.694L66.5073 113.605L62.6622 111.449L45.5918 121.533L49.4313 123.694Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M49.4313 123.694L66.5073 113.605L62.6622 111.449L45.5918 121.533L49.4313 123.694Z"
      fill="#1D1D1D"
    />
    <path
      d="M62.6622 111.449L62.6891 113.648L47.5089 122.61L45.5918 121.533L62.6622 111.449Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M62.6621 111.449L62.6891 113.648L64.6115 114.726L66.507 113.605L62.6621 111.449Z"
      fill="#1D1D1D"
    />
    <path
      d="M59.6499 91.1534L60.2278 89.9462L57.6679 88.4696C57.364 88.296 57.0394 88.162 56.7014 88.0707C56.5825 88.0385 56.4638 88.0115 56.3395 87.9898C55.7406 87.8908 55.1285 87.9075 54.5357 88.0385C54.3161 88.0838 54.0999 88.1433 53.8877 88.2163C53.6663 88.2863 53.4449 88.3724 53.2181 88.4696C52.922 88.6008 52.6336 88.7482 52.3541 88.9115C52.0421 89.0914 51.741 89.2893 51.4521 89.5043C51.2307 89.666 51.0093 89.8383 50.7933 90.0431C47.6126 92.7377 45.1123 97.4964 44.475 102.088C44.4316 102.401 44.3993 102.713 44.3723 103.02C44.3345 103.468 44.3184 103.904 44.3184 104.341C44.3184 104.697 44.3184 105.041 44.3615 105.376C44.4047 105.71 44.4208 105.941 44.4586 106.211C44.4966 106.48 44.5505 106.75 44.6099 106.987C44.766 107.674 45.017 108.336 45.3552 108.954C45.4253 109.078 45.4955 109.197 45.5712 109.31C45.8725 109.782 46.2567 110.196 46.7052 110.533C46.8403 110.636 46.9808 110.722 47.121 110.808L49.5458 112.204L50.086 111.088L50.518 110.975C51.1077 110.794 51.6732 110.542 52.2028 110.226C52.5437 110.026 52.8735 109.809 53.1912 109.574C53.6162 109.253 54.022 108.908 54.4063 108.539C57.2382 105.653 59.156 101.998 59.9199 98.0301C60.0334 97.4372 60.1143 96.8497 60.1682 96.2679C60.2116 95.7773 60.2332 95.2978 60.2332 94.8182C60.235 94.1302 60.179 93.4433 60.0658 92.7649C59.9777 92.2158 59.8384 91.6766 59.6499 91.1534Z"
      fill="currentColor"
    />
    <path
      opacity="0.25"
      d="M45.0684 99.3398L50.2635 102.336L50.9492 100.364L45.7487 97.3618C45.4873 98.0088 45.2602 98.669 45.0684 99.3398Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M57.6735 88.4588C57.3696 88.2853 57.0447 88.1513 56.7067 88.06C56.588 88.0277 56.4691 88.0008 56.3451 87.979C55.7461 87.8801 55.1338 87.8967 54.5413 88.0277L54.3037 88.0869L59.6555 91.1696L60.2277 89.9462L57.6735 88.4588Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M57.1172 96.036L57.7976 95.2114L50.005 90.7385C49.7564 90.981 49.5134 91.2396 49.2812 91.5036L57.1172 96.036Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M47.623 93.7185L54.3736 97.6096L55.1187 95.6696L48.8328 92.0479C48.3984 92.5818 47.9947 93.1395 47.623 93.7185Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M57.6726 88.4588C57.3691 88.2853 57.0442 88.1513 56.7062 88.06C56.5872 88.0277 56.4686 88.0008 56.3443 87.979C55.7456 87.8801 55.1333 87.8967 54.5405 88.0277C54.3209 88.0731 54.1046 88.1325 53.8925 88.2056C53.6711 88.2756 53.4497 88.3617 53.2229 88.4588C52.9268 88.5901 52.6383 88.7374 52.3589 88.9007C52.0468 89.0807 51.7458 89.2785 51.4569 89.4936C51.2355 89.6553 51.0141 89.8275 50.7981 90.0323C47.8387 92.5276 45.4625 96.839 44.6416 101.129L55.4857 107.38C57.7158 104.678 59.2391 101.465 59.9192 98.0301C60.0328 97.4372 60.1137 96.8498 60.1679 96.2679C60.211 95.7773 60.2326 95.2978 60.2326 94.8182C60.2344 94.1303 60.1784 93.4434 60.0651 92.7649C59.9752 92.2251 59.836 91.6948 59.6493 91.1804L60.2272 89.9462L57.6726 88.4588Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M49.5512 112.177L50.0913 111.061L50.5233 110.948C51.113 110.767 51.6786 110.515 52.2082 110.199C52.549 109.999 52.8788 109.782 53.1965 109.547C53.6216 109.226 54.0274 108.881 54.4114 108.512C54.784 108.151 55.1405 107.768 55.4916 107.359L44.6476 101.107C44.5829 101.425 44.5343 101.738 44.4858 102.056C44.437 102.374 44.41 102.681 44.383 102.988C44.3453 103.436 44.3291 103.872 44.3291 104.309C44.3291 104.664 44.3291 105.009 44.3723 105.343C44.4154 105.677 44.4316 105.909 44.4693 106.179C44.5074 106.448 44.5613 106.718 44.6206 106.955C44.7768 107.642 45.0277 108.304 45.366 108.922C45.4361 109.046 45.5062 109.164 45.582 109.277C45.8832 109.75 46.2675 110.164 46.7159 110.501C46.851 110.603 46.9915 110.689 47.1317 110.776L49.5512 112.177Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M45.3603 108.927C45.4307 109.051 45.5008 109.17 45.5766 109.283C45.8779 109.755 46.2621 110.17 46.7106 110.506C46.8457 110.609 46.9859 110.695 47.1264 110.781L49.5512 112.177L50.0911 111.061L50.1291 110.603L44.7285 107.499C44.8857 107.997 45.0976 108.476 45.3603 108.927Z"
      fill="#1D1D1D"
    />
    <path
      d="M62.8032 94.3168C62.6884 93.6158 62.4871 92.9317 62.2038 92.2799C61.8624 91.4677 61.322 90.7541 60.6324 90.2048C60.2115 89.8808 59.7307 89.6427 59.2175 89.5043L58.861 89.4289C58.2623 89.33 57.65 89.3464 57.0572 89.4774C56.8384 89.523 56.6219 89.5804 56.4092 89.6499C56.1878 89.7252 55.961 89.8116 55.7342 89.9085C55.4378 90.0387 55.1491 90.1864 54.8702 90.3504C54.5677 90.5283 54.2707 90.7223 53.9738 90.9379C53.6767 91.1534 53.5308 91.2721 53.3148 91.4769C50.1341 94.1715 47.6337 98.9356 46.9965 103.527C46.9534 103.84 46.9207 104.152 46.8938 104.46C46.856 104.902 46.8398 105.343 46.8398 105.78C46.8398 106.136 46.8398 106.48 46.883 106.815C46.9261 107.149 46.9372 107.381 46.9803 107.65C47.0235 107.919 47.072 108.189 47.1314 108.426C47.286 109.113 47.5349 109.775 47.8713 110.393C47.9414 110.517 48.0118 110.636 48.0873 110.749C48.3904 111.22 48.7741 111.634 49.2215 111.972C49.8706 112.458 50.6516 112.738 51.4625 112.775C52.0689 112.798 52.675 112.72 53.2555 112.544C53.8439 112.358 54.4092 112.107 54.9403 111.794C55.2824 111.595 55.6125 111.375 55.9286 111.137C56.3594 110.821 56.7672 110.475 57.1492 110.102C59.9775 107.216 61.893 103.563 62.6576 99.5984C62.7709 99.0056 62.8574 98.4181 62.9114 97.836C62.9545 97.3457 62.9761 96.8661 62.9761 96.3863C62.9748 95.693 62.917 95.0009 62.8032 94.3168Z"
      fill="currentColor"
    />
    <path
      opacity="0.05"
      d="M47.1532 102.552C47.0936 102.87 47.0397 103.188 46.9965 103.506C46.9534 103.824 46.9207 104.131 46.8938 104.438C46.856 104.88 46.8398 105.322 46.8398 105.758C46.8398 106.114 46.8398 106.459 46.883 106.793C46.9261 107.127 46.9372 107.359 46.9803 107.629C47.0235 107.898 47.072 108.168 47.1314 108.405C47.286 109.091 47.5349 109.753 47.8713 110.372C47.9414 110.496 48.0118 110.614 48.0873 110.727C48.3904 111.198 48.7741 111.613 49.2215 111.951C49.8706 112.437 50.6516 112.717 51.4625 112.754C52.0689 112.777 52.675 112.698 53.2555 112.522C53.8439 112.337 54.4092 112.086 54.9403 111.773C55.2439 111.597 55.5377 111.404 55.8205 111.196L47.1532 102.552Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.05"
      d="M47.2666 108.965C47.4169 109.454 47.6159 109.927 47.8607 110.377C47.9308 110.501 48.0012 110.619 48.0767 110.733C48.3797 111.204 48.7637 111.618 49.2109 111.956C49.7441 112.353 50.3674 112.612 51.0252 112.71L47.2666 108.965Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M48.2604 98.8169C48.0013 99.4636 47.7745 100.121 47.5801 100.789L57.02 110.21L57.1494 110.086C57.5565 109.692 57.9423 109.276 58.3052 108.841L48.2604 98.8169Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M51.3435 93.4868C50.911 94.022 50.5072 94.5797 50.1338 95.1575L60.5243 105.527C60.8646 104.89 61.1728 104.237 61.4476 103.57L51.3435 93.4868Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M52.5212 92.1829C52.2728 92.431 52.0296 92.6896 51.792 92.959L61.7232 102.881C61.8637 102.509 61.9934 102.137 62.1067 101.76L52.5212 92.1829Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M62.9268 95.6535C62.9268 95.4648 62.9268 95.2762 62.8834 95.1144V94.9312C62.8834 94.8666 62.8834 94.802 62.8564 94.7425C62.8294 94.6833 62.8187 94.4731 62.7971 94.3383C62.682 93.6373 62.4809 92.9532 62.1976 92.3011C61.8563 91.4891 61.3159 90.7756 60.6263 90.2263C60.2053 89.9023 59.7245 89.6642 59.2113 89.5258L58.8548 89.4504C58.2561 89.3515 57.6438 89.3679 57.0511 89.4989L56.8135 89.5581L62.9268 95.6535Z"
      fill="white"
    />
    <path
      d="M60.9187 97.6043C60.9187 101.84 58.4399 106.734 55.3077 108.814C55.178 108.9 55.0432 108.986 54.9081 109.062C51.5869 110.964 48.8867 108.949 48.8867 104.529C48.8867 100.11 51.5869 94.9796 54.8973 93.0665L55.2969 92.8509C58.4399 91.3202 60.9187 93.3628 60.9187 97.6043Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M60.9187 97.6043C60.9187 101.84 58.4399 106.734 55.3077 108.814C55.178 108.9 55.0432 108.986 54.9081 109.062C51.5869 110.964 48.8867 108.949 48.8867 104.529C48.8867 100.11 51.5869 94.9796 54.8973 93.0665L55.2969 92.8509C58.4399 91.3202 60.9187 93.3628 60.9187 97.6043Z"
      fill="#1D1D1D"
    />
    <path
      d="M60.6487 97.7554C60.6487 97.8899 60.6487 98.0301 60.6487 98.1703C60.4974 102.115 58.1915 106.545 55.2969 108.469L55.2157 108.518C55.1181 108.586 55.0172 108.649 54.9134 108.706C54.3566 109.037 53.7399 109.255 53.0989 109.348C52.4673 109.445 51.8211 109.341 51.2519 109.051C50.6828 108.76 50.2199 108.299 49.9289 107.731C49.4743 106.887 49.2193 105.95 49.1836 104.993C49.1836 104.788 49.1836 104.573 49.1836 104.352C49.1836 104.131 49.1836 103.948 49.1836 103.743C49.2106 103.312 49.2591 102.875 49.3295 102.438C49.8378 99.3804 51.3161 96.5658 53.5471 94.4087C53.8321 94.1447 54.1373 93.9032 54.4599 93.6864C54.6109 93.5841 54.7622 93.487 54.9134 93.4009C55.0647 93.3145 55.1726 93.2607 55.2969 93.1961L55.4048 93.1474C56.1363 92.7721 56.9689 92.6399 57.7811 92.7703C58.9905 93.002 59.9411 93.9612 60.3677 95.4972C60.568 96.2329 60.6626 96.9931 60.6487 97.7554Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M60.6487 97.7554C60.6487 97.8899 60.6487 98.0301 60.6487 98.1703C60.4974 102.115 58.1915 106.545 55.2969 108.469L55.2157 108.518C55.1181 108.586 55.0172 108.649 54.9134 108.706C54.3566 109.037 53.7399 109.255 53.0989 109.348C52.4673 109.445 51.8211 109.341 51.2519 109.051C50.6828 108.76 50.2199 108.299 49.9289 107.731C49.4743 106.887 49.2193 105.95 49.1836 104.993C49.1836 104.788 49.1836 104.573 49.1836 104.352C49.1836 104.131 49.1836 103.948 49.1836 103.743C49.2106 103.312 49.2591 102.875 49.3295 102.438C49.8378 99.3804 51.3161 96.5658 53.5471 94.4087C53.8321 94.1447 54.1373 93.9032 54.4599 93.6864C54.6109 93.5841 54.7622 93.487 54.9134 93.4009C55.0647 93.3145 55.1726 93.2607 55.2969 93.1961L55.4048 93.1474C56.1363 92.7721 56.9689 92.6399 57.7811 92.7703C58.9905 93.002 59.9411 93.9612 60.3677 95.4972C60.568 96.2329 60.6626 96.9931 60.6487 97.7554Z"
      fill="#1D1D1D"
    />
    <path
      d="M59.6876 97.6151C59.6876 97.4749 59.6876 97.3403 59.6876 97.2055C59.6966 96.4499 59.5985 95.6971 59.3961 94.9689C59.195 94.1736 58.7653 93.4541 58.1594 92.8995C58.032 92.8505 57.9 92.8144 57.7652 92.7918C56.9531 92.6614 56.1206 92.7936 55.3892 93.1689L55.281 93.2176C55.157 93.2821 55.0273 93.3467 54.8976 93.4223C54.7681 93.4977 54.5953 93.6056 54.444 93.7079C54.1216 93.9247 53.8165 94.1662 53.5314 94.4302C51.3005 96.5873 49.8221 99.4019 49.3136 102.46C49.2435 102.897 49.1949 103.333 49.168 103.764C49.168 103.969 49.168 104.174 49.168 104.373C49.168 104.573 49.168 104.81 49.168 105.015C49.2034 105.972 49.4585 106.909 49.9131 107.752C50.1116 108.101 50.3672 108.415 50.6692 108.679C51.1382 108.837 51.6377 108.881 52.1273 108.809C52.765 108.71 53.3776 108.488 53.9311 108.157L54.2388 107.968L54.3143 107.914C57.2307 105.996 59.5366 101.565 59.6876 97.6151Z"
      fill="currentColor"
    />
    <path
      opacity="0.25"
      d="M59.6876 97.6151C59.6876 97.4749 59.6876 97.3403 59.6876 97.2055C59.6966 96.4499 59.5985 95.6971 59.3961 94.9689C59.195 94.1736 58.7653 93.4541 58.1594 92.8995C58.032 92.8505 57.9 92.8144 57.7652 92.7918C56.9531 92.6614 56.1206 92.7936 55.3892 93.1689L55.281 93.2176C55.157 93.2821 55.0273 93.3467 54.8976 93.4223C54.7681 93.4977 54.5953 93.6056 54.444 93.7079C54.1216 93.9247 53.8165 94.1662 53.5314 94.4302C51.3005 96.5873 49.8221 99.4019 49.3136 102.46C49.2435 102.897 49.1949 103.333 49.168 103.764C49.168 103.969 49.168 104.174 49.168 104.373C49.168 104.573 49.168 104.81 49.168 105.015C49.2034 105.972 49.4585 106.909 49.9131 107.752C50.1116 108.101 50.3672 108.415 50.6692 108.679C51.1382 108.837 51.6377 108.881 52.1273 108.809C52.765 108.71 53.3776 108.488 53.9311 108.157L54.2388 107.968L54.3143 107.914C57.2307 105.996 59.5366 101.565 59.6876 97.6151Z"
      fill="#1D1D1D"
    />
    <path
      d="M58.861 89.4075C55.3885 89.014 52.564 91.8865 50.6793 94.4625C47.9792 98.4666 46.235 103.538 47.1745 108.388C47.4234 109.775 48.1466 111.032 49.2212 111.945C48.1063 111.051 47.3417 109.794 47.0612 108.394C46.1916 104.427 47.2826 100.256 49.1023 96.7044C51.079 93.2122 54.3786 88.9492 58.861 89.4075Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.35"
      d="M56.2314 98.8223L55.0811 98.1541L57.3115 96.2571L58.467 96.9199L56.2314 98.8223Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.35"
      d="M56.9446 99.2587C56.3559 98.8383 55.3244 99.1564 54.4604 99.4312L53.9203 99.6145C53.8651 99.7403 53.8355 99.8759 53.834 100.013C53.834 100.681 54.4062 100.504 55.6429 100.094C56.0608 99.9513 56.4884 99.8395 56.9227 99.76C56.9608 100.099 57.1983 100.412 58.1217 99.9215L56.9446 99.2587Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.45"
      d="M54.0881 107.984L52.9326 107.321V105.963L54.0881 106.626V107.984Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.35"
      d="M56.0377 102.827C56.0036 103.123 55.8931 103.405 55.7169 103.646C55.541 103.887 55.3052 104.078 55.0332 104.201C54.0719 104.74 53.6938 104.303 53.6129 103.856L53.4131 103.743C53.5754 103.691 53.7313 103.62 53.8775 103.533C54.2946 103.318 54.6261 102.969 54.8172 102.541C55.53 102.309 56.0377 102.218 56.0377 102.827Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.35"
      d="M51.7501 106.157C51.6229 106.082 51.4763 106.046 51.3289 106.055C51.3289 105.985 51.2855 105.914 51.2693 105.839L50.1191 105.176C50.15 105.376 50.2232 105.567 50.3341 105.736C50.4448 105.905 50.5907 106.049 50.7618 106.157L51.9119 106.82C51.9658 106.708 51.9789 106.58 51.9494 106.46C51.9198 106.339 51.8495 106.232 51.7501 106.157Z"
      fill="#1D1D1D"
    />
    <path
      d="M55.6165 100.094C54.3798 100.504 53.8073 100.682 53.8073 100.013C53.8091 99.876 53.8384 99.7404 53.8938 99.6145C53.0514 99.8731 52.6572 99.9054 52.6572 99.3505C52.6572 98.9409 52.9325 98.4342 53.6994 97.9923C54.4661 97.5504 54.9685 97.6312 55.0817 98.154L57.3121 96.2571L57.2798 96.1707C57.4203 96.1422 57.5551 96.0912 57.6794 96.02L56.7561 95.481L56.6695 95.4271C56.3973 95.2982 56.0942 95.248 55.7947 95.2815C55.3974 95.329 55.0096 95.4361 54.6443 95.5996L55.7947 94.9258L54.6282 94.2576L53.845 94.7102L52.765 95.3246V96.6828C51.2045 97.8198 50.2269 99.3021 50.2269 100.908C50.1905 101.158 50.2185 101.414 50.3084 101.65C50.398 101.887 50.5467 102.097 50.7398 102.261L50.8264 102.309L51.7878 102.848C51.8158 102.702 51.797 102.551 51.7336 102.417C52.0392 102.376 52.341 102.309 52.6356 102.218C53.8073 101.867 54.8495 101.312 54.8495 102.174C54.8488 102.305 54.8249 102.434 54.7794 102.557C55.4922 102.325 55.9999 102.234 55.9999 102.843C55.9658 103.139 55.8553 103.421 55.6791 103.662C55.5032 103.903 55.2677 104.094 54.9954 104.217C54.0341 104.756 53.6563 104.319 53.5751 103.872L53.3753 103.759L52.425 103.22L50.0811 105.203L51.2314 105.866C51.2684 106.037 51.3283 106.202 51.4097 106.357C51.9244 106.337 52.4299 106.212 52.8947 105.99V106.529L54.7201 106.276V104.918C56.2968 103.759 57.3879 102.191 57.3879 100.558C57.3951 100.279 57.3512 100.001 57.2582 99.7386C56.6978 99.7855 56.1458 99.9049 55.6165 100.094Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M55.6165 100.094C54.3798 100.504 53.8073 100.682 53.8073 100.013C53.8091 99.876 53.8384 99.7404 53.8938 99.6145C53.0514 99.8731 52.6572 99.9054 52.6572 99.3505C52.6572 98.9409 52.9325 98.4342 53.6994 97.9923C54.4661 97.5504 54.9685 97.6312 55.0817 98.154L57.3121 96.2571L57.2798 96.1707C57.4203 96.1422 57.5551 96.0912 57.6794 96.02L56.7561 95.481L56.6695 95.4271C56.3973 95.2982 56.0942 95.248 55.7947 95.2815C55.3974 95.329 55.0096 95.4361 54.6443 95.5996L55.7947 94.9258L54.6282 94.2576L53.845 94.7102L52.765 95.3246V96.6828C51.2045 97.8198 50.2269 99.3021 50.2269 100.908C50.1905 101.158 50.2185 101.414 50.3084 101.65C50.398 101.887 50.5467 102.097 50.7398 102.261L50.8264 102.309L51.7878 102.848C51.8158 102.702 51.797 102.551 51.7336 102.417C52.0392 102.376 52.341 102.309 52.6356 102.218C53.8073 101.867 54.8495 101.312 54.8495 102.174C54.8488 102.305 54.8249 102.434 54.7794 102.557C55.4922 102.325 55.9999 102.234 55.9999 102.843C55.9658 103.139 55.8553 103.421 55.6791 103.662C55.5032 103.903 55.2677 104.094 54.9954 104.217C54.0341 104.756 53.6563 104.319 53.5751 103.872L53.3753 103.759L52.425 103.22L50.0811 105.203L51.2314 105.866C51.2684 106.037 51.3283 106.202 51.4097 106.357C51.9244 106.337 52.4299 106.212 52.8947 105.99V106.529L54.7201 106.276V104.918C56.2968 103.759 57.3879 102.191 57.3879 100.558C57.3951 100.279 57.3512 100.001 57.2582 99.7386C56.6978 99.7855 56.1458 99.9049 55.6165 100.094Z"
      fill="#1D1D1D"
    />
    <g opacity="0.2">
      <path
        d="M52.7859 96.656C51.9088 97.3219 51.1958 98.179 50.7014 99.162C50.4715 99.6569 50.3377 100.191 50.3071 100.735C50.2827 101.008 50.2992 101.282 50.3559 101.549C50.4029 101.819 50.547 102.062 50.7607 102.234C50.5244 102.079 50.3564 101.84 50.2909 101.565C50.2147 101.294 50.1836 101.012 50.1993 100.73C50.2124 100.172 50.3392 99.6215 50.5719 99.1133C50.8164 98.6088 51.1329 98.1424 51.5115 97.7284C51.8926 97.3222 52.3202 96.962 52.7859 96.656Z"
        fill="#1D1D1D"
      />
    </g>
    <path
      d="M57.2953 99.7225C56.7215 99.7714 56.1567 99.8965 55.6158 100.094C54.3791 100.504 53.8069 100.682 53.8069 100.013C53.8084 99.876 53.838 99.7404 53.8932 99.6146C54.1005 99.2009 54.4357 98.8646 54.8492 98.6554C55.6646 98.1867 56.1182 98.2943 56.2315 98.8172L58.4673 96.92C58.3943 96.6334 58.2269 96.3796 57.9919 96.1997C57.7566 96.0198 57.4674 95.9242 57.1713 95.9285C56.6892 95.938 56.2148 96.0482 55.7779 96.2517V94.8938L54.6278 95.5674L53.9417 95.9608V97.3188C52.3758 98.456 51.3983 99.938 51.3983 101.544C51.3877 101.841 51.4355 102.138 51.5387 102.417C51.8842 103.279 52.8294 103.161 53.8231 102.87C54.1739 102.762 54.5142 102.638 54.8165 102.541C55.5295 102.309 56.037 102.218 56.037 102.827C56.0029 103.123 55.8924 103.405 55.7165 103.646C55.5406 103.887 55.3048 104.078 55.0325 104.201C54.0715 104.74 53.6934 104.303 53.6125 103.856L51.2686 105.839C51.3055 106.01 51.3656 106.175 51.4468 106.33C51.5883 106.586 51.811 106.787 52.0797 106.903C52.3483 107.019 52.6483 107.043 52.9319 106.971C53.3315 106.911 53.7204 106.794 54.0876 106.626V107.984L55.9076 106.933V105.575C57.4897 104.411 58.5808 102.848 58.5808 101.21C58.6078 100.03 58.0191 99.7063 57.2953 99.7225Z"
      fill="currentColor"
    />
    <g opacity="0.1">
      <path
        d="M57.2953 99.7225C56.7215 99.7714 56.1567 99.8965 55.6158 100.094C54.3791 100.504 53.8069 100.682 53.8069 100.013C53.8084 99.876 53.838 99.7404 53.8932 99.6146C54.1005 99.2009 54.4357 98.8646 54.8492 98.6554C55.6646 98.1867 56.1182 98.2943 56.2315 98.8172L58.4673 96.92C58.3943 96.6334 58.2269 96.3796 57.9919 96.1997C57.7566 96.0198 57.4674 95.9242 57.1713 95.9285C56.6892 95.938 56.2148 96.0482 55.7779 96.2517V94.8938L54.6278 95.5674L53.9417 95.9608V97.3188C52.3758 98.456 51.3983 99.938 51.3983 101.544C51.3877 101.841 51.4355 102.138 51.5387 102.417C51.8842 103.279 52.8294 103.161 53.8231 102.87C54.1739 102.762 54.5142 102.638 54.8165 102.541C55.5295 102.309 56.037 102.218 56.037 102.827C56.0029 103.123 55.8924 103.405 55.7165 103.646C55.5406 103.887 55.3048 104.078 55.0325 104.201C54.0715 104.74 53.6934 104.303 53.6125 103.856L51.2686 105.839C51.3055 106.01 51.3656 106.175 51.4468 106.33C51.5883 106.586 51.811 106.787 52.0797 106.903C52.3483 107.019 52.6483 107.043 52.9319 106.971C53.3315 106.911 53.7204 106.794 54.0876 106.626V107.984L55.9076 106.933V105.575C57.4897 104.411 58.5808 102.848 58.5808 101.21C58.6078 100.03 58.0191 99.7063 57.2953 99.7225Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.1"
      d="M53.4512 103.996L52.0146 105.209L53.6345 106.826C53.7912 106.772 53.9479 106.712 54.1099 106.642V107.294L54.5527 107.742L55.9299 106.95V106.464L53.4512 103.996Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M57.2959 99.7222C56.7221 99.7711 56.1573 99.8962 55.6164 100.094L55.2168 100.224L58.1006 103.101C58.406 102.515 58.5704 101.865 58.5814 101.204C58.5806 101.023 58.5627 100.843 58.5272 100.665L57.6147 99.7547C57.5099 99.7329 57.403 99.7222 57.2959 99.7222Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M54.1739 99.1887C54.3694 98.975 54.5972 98.793 54.8489 98.6497C55.6644 98.1809 56.1182 98.2885 56.2315 98.8114L56.4799 98.6066L53.9418 96.0684V97.3133C53.6274 97.5414 53.3295 97.7918 53.0508 98.0622L54.1739 99.1887Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M56.3887 96.0416C56.1812 96.0988 55.9772 96.169 55.7784 96.2518V95.4326L55.4384 95.093L54.6283 95.5672L54.4824 95.6482L57.0043 98.1649L57.8198 97.4696L56.3887 96.0416Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M52.5322 103.08L51.4521 102.002C51.4701 102.152 51.5066 102.298 51.5603 102.439C51.6373 102.632 51.7717 102.797 51.9455 102.911C52.1192 103.026 52.3241 103.085 52.5322 103.08Z"
      fill="white"
    />
    <path
      d="M53.9365 97.3189L52.7861 96.6558V95.2979L53.9365 95.9607V97.3189Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M53.9365 97.3189L52.7861 96.6558V95.2979L53.9365 95.9607V97.3189Z"
      fill="#1D1D1D"
    />
    <path
      d="M55.9082 105.575L56.0865 105.43L56.2917 105.268L56.5454 105.042C56.6371 104.955 56.7345 104.869 56.8315 104.767L56.9882 104.611L57.1395 104.444C57.1934 104.384 57.2474 104.325 57.2962 104.26C57.3447 104.196 57.3986 104.142 57.4472 104.077C57.4957 104.012 57.647 103.813 57.7336 103.678C57.8268 103.543 57.9134 103.402 57.9927 103.258C58.0682 103.112 58.144 102.972 58.2033 102.827C58.2657 102.694 58.3197 102.557 58.3654 102.417L58.4247 102.218L58.4517 102.121V102.029C58.4779 101.919 58.4979 101.807 58.511 101.695C58.5285 101.61 58.5395 101.523 58.5436 101.436V101.21V101.442C58.5436 101.512 58.5436 101.598 58.5436 101.701C58.5436 101.803 58.511 101.911 58.4894 102.035V102.132L58.4625 102.223L58.4085 102.428C58.36 102.557 58.3166 102.703 58.2465 102.848C58.1869 102.996 58.1183 103.14 58.0413 103.279C57.9604 103.42 57.8792 103.565 57.7821 103.705C57.6848 103.845 57.5877 103.975 57.4903 104.104C57.3933 104.233 57.3879 104.228 57.3339 104.293L57.1773 104.471L57.0206 104.638L56.8639 104.788C56.7614 104.891 56.6587 104.977 56.567 105.063C56.4753 105.149 56.3834 105.214 56.3078 105.279L56.0972 105.441L55.9082 105.575Z"
      fill="white"
    />
    <path
      d="M53.9365 95.9606L55.7781 94.8989V96.2517L55.6538 95.1145L53.9365 95.9606Z"
      fill="#FAFAFA"
    />
    <path
      d="M54.0879 106.626L54.185 107.806L55.9079 106.933L54.0879 107.984V106.626Z"
      fill="#FAFAFA"
    />
    <path
      d="M61.0369 92.3552L61.1879 90.3345L61.3446 92.3552L63.3644 92.5116L61.3446 92.6626L61.1879 94.6836L61.0369 92.6626L59.0117 92.5116L61.0369 92.3552Z"
      fill="white"
    />
    <path
      d="M59.0391 90.8786L59.1148 89.8977L59.1903 90.8786L60.1733 90.954L59.1903 91.0296L59.1148 92.0051L59.0391 91.0296L58.0615 90.954L59.0391 90.8786Z"
      fill="white"
    />
    <path
      d="M107.539 162.845C108.414 161.301 109.896 159.838 111.971 158.577C119.289 154.154 131.289 153.925 138.776 158.039C140.915 159.215 142.47 160.619 143.389 162.127L144.412 162.108L144.49 165.971C144.409 168.662 142.617 171.364 139.101 173.483C131.784 177.907 119.784 178.135 112.296 174.018C108.695 172.039 106.786 169.414 106.597 166.731L106.52 162.864L107.539 162.845Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M107.539 162.845C108.414 161.301 109.896 159.838 111.971 158.577C119.289 154.154 131.289 153.925 138.776 158.039C140.915 159.215 142.47 160.619 143.389 162.127L144.412 162.108L144.49 165.971C144.409 168.662 142.617 171.364 139.101 173.483C131.784 177.907 119.784 178.135 112.296 174.018C108.695 172.039 106.786 169.414 106.597 166.731L106.52 162.864L107.539 162.845Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M125.319 155.109C130.169 155.013 135.041 155.982 138.786 158.039C140.925 159.215 142.48 160.619 143.4 162.127L144.423 162.108L144.5 165.971C144.419 168.662 142.628 171.364 139.111 173.484C135.455 175.695 130.616 176.853 125.774 176.952L125.319 155.109Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M135.705 156.675C136.768 157.044 137.798 157.5 138.786 158.039C140.924 159.215 142.479 160.619 143.399 162.127L144.422 162.108L144.5 165.971C144.418 168.662 142.627 171.364 139.111 173.483C138.144 174.054 137.134 174.546 136.089 174.958L135.705 156.675Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M115.003 157.088C113.958 157.498 112.948 157.991 111.982 158.562C109.895 159.823 108.414 161.286 107.549 162.831L106.526 162.849L106.604 166.716C106.792 169.399 108.702 172.024 112.303 174.003C113.292 174.54 114.322 174.997 115.384 175.371L115.003 157.088Z"
      fill="currentColor"
    />
    <path
      d="M138.715 154.501C146.203 158.614 146.339 165.529 139.026 169.941C132.625 173.804 122.637 174.471 115.224 171.818L114.928 171.711C113.999 171.365 113.095 170.954 112.225 170.479C105.979 167.044 104.838 161.666 108.901 157.416C109.798 156.492 110.815 155.693 111.926 155.039C119.228 150.615 131.229 150.383 138.715 154.501Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M138.715 154.501C146.203 158.614 146.339 165.529 139.026 169.941C132.625 173.804 122.637 174.471 115.224 171.818L114.928 171.711C113.999 171.365 113.095 170.954 112.225 170.479C105.979 167.044 104.838 161.666 108.901 157.416C109.798 156.492 110.815 155.693 111.926 155.039C119.228 150.615 131.229 150.383 138.715 154.501Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M108.883 157.416C108.805 157.508 106.271 160.284 107.036 164.195C107.775 167.932 113.097 170.988 114.926 171.711C113.996 171.365 113.093 170.954 112.222 170.48C105.976 167.044 104.842 161.673 108.883 157.416Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M142.055 167.571C142.133 167.483 144.667 164.703 143.902 160.796C143.163 157.055 137.841 154.003 136.009 153.277C136.94 153.622 137.845 154.035 138.716 154.512C144.958 157.932 146.092 163.31 142.055 167.571Z"
      fill="#F5F5F5"
    />
    <path
      d="M125.688 172.617C135.419 172.422 143.216 167.718 143.103 162.111C142.99 156.503 135.011 152.116 125.28 152.311C115.549 152.506 107.753 157.209 107.865 162.817C107.978 168.424 115.958 172.812 125.688 172.617Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M125.688 172.617C135.419 172.422 143.216 167.718 143.103 162.111C142.99 156.503 135.011 152.116 125.28 152.311C115.549 152.506 107.753 157.209 107.865 162.817C107.978 168.424 115.958 172.812 125.688 172.617Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M142.353 165.168C143.716 162.528 143.196 159.639 140.81 157.261C140.34 156.794 139.832 156.368 139.291 155.986C138.828 155.656 138.346 155.353 137.847 155.079C137.563 154.92 137.271 154.769 136.968 154.626C137.707 155.812 137.485 157.106 133.337 157.431C125.211 158.068 135.675 165.606 135.675 165.606C135.675 165.606 140.858 162.978 142.353 165.168Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M141.474 163.045C142.69 162.883 143.266 161.622 142.767 160.225C142.268 158.828 140.872 157.822 139.653 157.988C138.434 158.153 137.862 159.41 138.36 160.808C138.859 162.204 140.255 163.203 141.474 163.045Z"
      fill="white"
    />
    <path
      d="M128.759 169.753C128.297 166.753 114.949 158.695 114.949 158.695C114.949 158.695 111.994 157.589 112.932 155.525C112.453 155.812 111.989 156.125 111.543 156.461C111.24 156.69 110.959 156.918 110.697 157.158C106.841 160.627 106.926 165.079 110.926 168.397C111.196 168.622 111.488 168.843 111.794 169.057C112.221 169.354 112.662 169.629 113.117 169.882C113.571 170.133 114.044 170.365 114.531 170.583C114.901 170.756 115.321 170.918 115.728 171.069C116.134 171.22 116.548 171.36 116.973 171.49C118.266 171.88 119.587 172.17 120.925 172.356C121.216 172.396 121.516 172.433 121.811 172.466C123.119 172.607 124.434 172.662 125.749 172.632C127.36 172.598 128.966 172.434 130.55 172.142C130.55 172.142 128.423 171.74 128.759 169.753Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M128.759 169.753C128.297 166.753 114.949 158.695 114.949 158.695C114.949 158.695 111.994 157.589 112.932 155.525C112.453 155.812 111.989 156.125 111.543 156.461C111.24 156.69 110.959 156.918 110.697 157.158C106.841 160.627 106.926 165.079 110.926 168.397C111.196 168.622 111.488 168.843 111.794 169.057C112.221 169.354 112.662 169.629 113.117 169.882C113.571 170.133 114.044 170.365 114.531 170.583C114.901 170.756 115.321 170.918 115.728 171.069C116.134 171.22 116.548 171.36 116.973 171.49C118.266 171.88 119.587 172.17 120.925 172.356C121.216 172.396 121.516 172.433 121.811 172.466C123.119 172.607 124.434 172.662 125.749 172.632C127.36 172.598 128.966 172.434 130.55 172.142C130.55 172.142 128.423 171.74 128.759 169.753Z"
      fill="white"
    />
    <path
      d="M116.078 157.309C111.221 160.258 111.11 164.766 115.686 167.63C115.882 167.752 116.085 167.869 116.296 167.999C121.467 170.848 129.788 170.682 134.856 167.63C139.924 164.578 139.831 159.786 134.645 156.941C134.431 156.822 134.213 156.712 133.992 156.605C128.784 154.106 120.938 154.379 116.078 157.309Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M116.078 157.309C111.221 160.258 111.11 164.766 115.686 167.63C115.882 167.752 116.085 167.869 116.296 167.999C121.467 170.848 129.788 170.682 134.856 167.63C139.924 164.578 139.831 159.786 134.645 156.941C134.431 156.822 134.213 156.712 133.992 156.605C128.784 154.106 120.938 154.379 116.078 157.309Z"
      fill="#1D1D1D"
    />
    <path
      d="M125.646 169.734C132.578 169.595 138.132 166.245 138.052 162.251C137.972 158.257 132.288 155.131 125.356 155.27C118.424 155.408 112.87 158.758 112.95 162.752C113.03 166.746 118.714 169.872 125.646 169.734Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M125.646 169.734C132.578 169.595 138.132 166.245 138.052 162.251C137.972 158.257 132.288 155.131 125.356 155.27C118.424 155.408 112.87 158.758 112.95 162.752C113.03 166.746 118.714 169.872 125.646 169.734Z"
      fill="#1D1D1D"
    />
    <path
      d="M116.544 159.554C121.386 156.635 129.331 156.476 134.273 159.185C136.301 160.291 137.512 161.725 137.908 163.218C137.575 164.725 136.43 166.196 134.439 167.39C129.597 170.313 121.652 170.472 116.71 167.759C114.682 166.653 113.47 165.219 113.075 163.726C113.408 162.219 114.56 160.748 116.544 159.554Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M116.544 159.554C121.386 156.635 129.331 156.476 134.273 159.185C136.301 160.291 137.512 161.725 137.908 163.218C137.575 164.725 136.43 166.196 134.439 167.39C129.597 170.313 121.652 170.472 116.71 167.759C114.682 166.653 113.47 165.219 113.075 163.726C113.408 162.219 114.56 160.748 116.544 159.554Z"
      fill="#1D1D1D"
    />
    <path
      d="M133.42 164.493C133.42 164.25 133.398 163.255 133.39 162.997C133.39 162.385 132.94 161.799 132.016 161.283L131.987 161.264C130.28 160.328 128.541 160.49 127 160.896C126.347 161.076 125.722 161.301 125.154 161.507C124.506 161.765 123.844 161.984 123.17 162.164C122.861 162.243 122.543 162.28 122.225 162.27C121.952 162.261 121.684 162.195 121.438 162.079L121.5 162.035L121.693 161.909C121.86 161.808 122.034 161.718 122.213 161.64C122.647 161.438 123.117 161.323 123.596 161.302C124.074 161.281 124.552 161.355 125.002 161.518L126.579 159.867L126.55 158.393C125.121 157.94 123.59 157.916 122.147 158.323C121.671 158.454 121.206 158.624 120.758 158.831L119.133 157.939L117.234 159.082L117.264 160.556L117.929 160.925C117.757 161.183 117.665 161.485 117.663 161.795C117.663 162.042 117.692 163.03 117.696 163.269C117.696 163.881 118.162 164.464 119.096 164.98C119.912 165.433 120.829 165.676 121.763 165.687C121.94 165.687 122.117 165.687 122.295 165.687C123.267 165.594 124.221 165.368 125.131 165.017L125.682 164.814L124.094 166.465L124.123 167.94C125.125 168.298 126.19 168.449 127.252 168.386C128.315 168.323 129.354 168.045 130.306 167.571L131.913 168.455L133.811 167.309L133.782 165.835L133.113 165.466C133.311 165.18 133.418 164.841 133.42 164.493ZM129.357 164.445C129.215 164.532 129.069 164.61 128.917 164.677C127.926 165.123 126.798 165.162 125.778 164.784C127.255 164.228 128.518 163.738 129.597 164.287C129.534 164.338 129.457 164.393 129.368 164.445H129.357Z"
      fill="currentColor"
    />
    <path
      d="M127 160.91C126.347 161.091 125.722 161.316 125.154 161.522C124.506 161.779 123.844 161.998 123.17 162.178C122.861 162.258 122.543 162.294 122.225 162.285C121.903 162.276 121.589 162.189 121.308 162.031C120.684 161.688 120.57 161.168 121.467 160.556L121.663 160.431C121.831 160.33 122.005 160.239 122.184 160.158C122.618 159.956 123.088 159.842 123.567 159.822C124.045 159.802 124.523 159.876 124.973 160.04L126.55 158.389C125.121 157.936 123.59 157.912 122.147 158.319C121.671 158.45 121.206 158.62 120.758 158.828L119.133 157.936L117.234 159.078L118.841 159.963C117.253 161.216 117.245 162.488 119.081 163.498C119.897 163.951 120.814 164.194 121.748 164.205C121.925 164.205 122.117 164.205 122.28 164.205C123.252 164.116 124.207 163.89 125.117 163.534C126.945 162.886 128.422 162.13 129.678 162.82C130.52 163.284 130.302 163.888 129.398 164.434C129.256 164.521 129.109 164.598 128.958 164.666C128.457 164.892 127.917 165.016 127.367 165.031C126.818 165.045 126.271 164.95 125.759 164.751L124.108 166.472C125.109 166.827 126.171 166.977 127.231 166.912C128.291 166.848 129.327 166.57 130.277 166.096L131.883 166.981L133.782 165.835L132.175 164.95C133.749 163.686 133.904 162.34 132.016 161.286L131.987 161.268C130.28 160.328 128.541 160.49 127 160.91Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M127 160.91C126.347 161.091 125.722 161.316 125.154 161.522C124.506 161.779 123.844 161.998 123.17 162.178C122.861 162.258 122.543 162.294 122.225 162.285C121.903 162.276 121.589 162.189 121.308 162.031C120.684 161.688 120.57 161.168 121.467 160.556L121.663 160.431C121.831 160.33 122.005 160.239 122.184 160.158C122.618 159.956 123.088 159.842 123.567 159.822C124.045 159.802 124.523 159.876 124.973 160.04L126.55 158.389C125.121 157.936 123.59 157.912 122.147 158.319C121.671 158.45 121.206 158.62 120.758 158.828L119.133 157.936L117.234 159.078L118.841 159.963C117.253 161.216 117.245 162.488 119.081 163.498C119.897 163.951 120.814 164.194 121.748 164.205C121.925 164.205 122.117 164.205 122.28 164.205C123.252 164.116 124.207 163.89 125.117 163.534C126.945 162.886 128.422 162.13 129.678 162.82C130.52 163.284 130.302 163.888 129.398 164.434C129.256 164.521 129.109 164.598 128.958 164.666C128.457 164.892 127.917 165.016 127.367 165.031C126.818 165.045 126.271 164.95 125.759 164.751L124.108 166.472C125.109 166.827 126.171 166.977 127.231 166.912C128.291 166.848 129.327 166.57 130.277 166.096L131.883 166.981L133.782 165.835L132.175 164.95C133.749 163.686 133.904 162.34 132.016 161.286L131.987 161.268C130.28 160.328 128.541 160.49 127 160.91Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M117.22 159.089L119.14 158.175L120.743 158.839L119.118 157.947L117.22 159.089Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M121.925 158.393C123.287 158.093 124.701 158.113 126.054 158.452L124.972 160.051L126.549 158.4C125.045 157.925 123.431 157.922 121.925 158.393Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.986 161.264L132.016 161.282C133.903 162.337 133.748 163.682 132.175 164.946C133.56 163.38 133.283 162.315 131.728 161.46C130.173 160.604 128.212 160.652 127 160.91C128.54 160.49 130.28 160.328 131.986 161.264Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M132.176 164.947L133.413 165.857L131.884 166.978L133.782 165.832L132.176 164.947Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M128.943 164.677C128.004 165.173 126.911 165.29 125.888 165.005L124.093 166.48L125.744 164.759C126.256 164.958 126.802 165.054 127.352 165.04C127.901 165.026 128.442 164.903 128.943 164.677Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M123.155 162.182C122.439 162.481 121.39 162.451 120.854 162.009C120.319 161.566 120.636 161.028 121.453 160.568C120.57 161.172 120.67 161.692 121.294 162.042C121.574 162.2 121.888 162.287 122.21 162.296C122.529 162.303 122.847 162.265 123.155 162.182Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M118.811 159.989C118.678 160.11 117.544 161.209 118.073 162.274C118.638 163.409 120.348 164.117 121.714 164.231C120.781 164.22 119.864 163.977 119.048 163.524C117.216 162.513 117.223 161.242 118.811 159.989Z"
      fill="#F5F5F5"
    />
    <path
      d="M110.277 155.957C111.186 154.431 112.693 153.008 114.806 151.781C122.193 147.512 134.212 147.512 141.618 151.781C143.73 152.997 145.238 154.431 146.146 155.957H147.169V159.831C147.033 162.518 145.193 165.183 141.629 167.232C134.223 171.497 122.222 171.497 114.817 167.232C111.26 165.183 109.402 162.518 109.276 159.831V155.957H110.277Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M110.277 155.957C111.186 154.431 112.693 153.008 114.806 151.781C122.193 147.512 134.212 147.512 141.618 151.781C143.73 152.997 145.238 154.431 146.146 155.957H147.169V159.831C147.033 162.518 145.193 165.183 141.629 167.232C134.223 171.497 122.222 171.497 114.817 167.232C111.26 165.183 109.402 162.518 109.276 159.831V155.957H110.277Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M128.202 148.581C133.056 148.581 137.909 149.646 141.61 151.78C143.723 152.996 145.23 154.43 146.139 155.956H147.162V159.83C147.025 162.517 145.185 165.182 141.621 167.232C137.928 169.366 133.067 170.431 128.213 170.431L128.202 148.581Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M138.567 150.354C139.621 150.746 140.642 151.223 141.618 151.78C143.731 152.997 145.238 154.43 146.147 155.957H147.17V159.83C147.033 162.518 145.194 165.183 141.629 167.232C140.653 167.789 139.632 168.266 138.578 168.659L138.567 150.354Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M117.861 150.354C116.807 150.746 115.787 151.223 114.81 151.78C112.697 152.997 111.19 154.43 110.282 155.957H109.255V159.83C109.392 162.518 111.25 165.183 114.795 167.232C115.772 167.789 116.792 168.266 117.846 168.659L117.861 150.354Z"
      fill="currentColor"
    />
    <path
      d="M141.618 148.238C149.006 152.502 149.006 159.418 141.618 163.682C135.14 167.416 125.141 167.881 117.783 165.083L117.492 164.969C116.568 164.605 115.672 164.175 114.81 163.682C108.638 160.125 107.611 154.725 111.737 150.556C112.652 149.649 113.686 148.87 114.81 148.238C122.212 143.98 134.213 143.98 141.618 148.238Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M141.618 148.238C149.006 152.502 149.006 159.418 141.618 163.682C135.14 167.416 125.141 167.881 117.783 165.083L117.492 164.969C116.568 164.605 115.672 164.175 114.81 163.682C108.638 160.125 107.611 154.725 111.737 150.556C112.652 149.649 113.686 148.87 114.81 148.238C122.212 143.98 134.213 143.98 141.618 148.238Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M111.732 150.556C111.651 150.645 109.062 153.372 109.749 157.294C110.403 161.05 115.659 164.209 117.487 164.969C116.563 164.605 115.667 164.175 114.805 163.682C108.626 160.125 107.606 154.725 111.732 150.556Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M144.691 161.363C144.772 161.271 147.362 158.547 146.675 154.622C146.021 150.869 140.765 147.71 138.937 146.951C139.861 147.313 140.757 147.743 141.618 148.237C147.779 151.794 148.817 157.194 144.691 161.363Z"
      fill="#F5F5F5"
    />
    <path
      d="M140.68 163.126C147.561 159.159 147.561 152.731 140.68 148.75C133.799 144.769 122.64 144.784 115.759 148.75C108.878 152.716 108.874 159.145 115.759 163.126C122.644 167.106 133.799 167.088 140.68 163.126Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M140.68 163.126C147.561 159.159 147.561 152.731 140.68 148.75C133.799 144.769 122.64 144.784 115.759 148.75C108.878 152.716 108.874 159.145 115.759 163.126C122.644 167.106 133.799 167.088 140.68 163.126Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M145.041 158.979C146.456 156.365 145.994 153.483 143.656 151.043C143.201 150.566 142.707 150.129 142.179 149.734C141.722 149.396 141.245 149.083 140.753 148.798C140.469 148.632 140.181 148.477 139.885 148.326C140.624 149.531 140.347 150.818 136.191 151.061C128.065 151.533 138.36 159.281 138.36 159.281C138.36 159.281 143.59 156.76 145.041 158.979Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M144.205 156.834C145.423 156.697 146.025 155.448 145.553 154.04C145.08 152.632 143.706 151.603 142.487 151.739C141.268 151.876 140.666 153.126 141.139 154.533C141.612 155.942 142.978 156.97 144.205 156.834Z"
      fill="white"
    />
    <path
      d="M131.357 163.291C130.955 160.28 117.772 151.946 117.772 151.946C117.772 151.946 114.847 150.777 115.818 148.735C115.331 149.012 114.86 149.316 114.407 149.646C114.104 149.867 113.82 150.092 113.55 150.324C109.628 153.715 109.62 158.172 113.55 161.566C113.816 161.798 114.101 162.023 114.404 162.245C114.825 162.549 115.261 162.832 115.711 163.092C116.158 163.354 116.627 163.597 117.107 163.829C117.477 164.01 117.894 164.198 118.297 164.342C118.699 164.486 119.113 164.648 119.534 164.784C120.819 165.203 122.136 165.518 123.471 165.728C123.763 165.776 124.059 165.82 124.354 165.857C127.261 166.23 130.208 166.18 133.101 165.709C133.101 165.709 130.981 165.271 131.357 163.291Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M131.357 163.291C130.955 160.28 117.772 151.946 117.772 151.946C117.772 151.946 114.847 150.777 115.818 148.735C115.331 149.012 114.86 149.316 114.407 149.646C114.104 149.867 113.82 150.092 113.55 150.324C109.628 153.715 109.62 158.172 113.55 161.566C113.816 161.798 114.101 162.023 114.404 162.245C114.825 162.549 115.261 162.832 115.711 163.092C116.158 163.354 116.627 163.597 117.107 163.829C117.477 164.01 117.894 164.198 118.297 164.342C118.699 164.486 119.113 164.648 119.534 164.784C120.819 165.203 122.136 165.518 123.471 165.728C123.763 165.776 124.059 165.82 124.354 165.857C127.261 166.23 130.208 166.18 133.101 165.709C133.101 165.709 130.981 165.271 131.357 163.291Z"
      fill="white"
    />
    <path
      d="M118.927 150.615C114.011 153.446 113.811 157.969 118.329 160.936C118.521 161.062 118.72 161.187 118.931 161.305C124.057 164.254 132.368 164.254 137.495 161.305C142.622 158.356 142.625 153.564 137.495 150.615C137.288 150.494 137.07 150.379 136.852 150.269C131.696 147.644 123.847 147.762 118.927 150.615Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M118.927 150.615C114.011 153.446 113.811 157.969 118.329 160.936C118.521 161.062 118.72 161.187 118.931 161.305C124.057 164.254 132.368 164.254 137.495 161.305C142.622 158.356 142.625 153.564 137.495 150.615C137.288 150.494 137.07 150.379 136.852 150.269C131.696 147.644 123.847 147.762 118.927 150.615Z"
      fill="#1D1D1D"
    />
    <path
      d="M128.221 163.177C135.153 163.177 140.772 159.939 140.772 155.945C140.772 151.951 135.153 148.713 128.221 148.713C121.289 148.713 115.67 151.951 115.67 155.945C115.67 159.939 121.289 163.177 128.221 163.177Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M128.221 163.177C135.153 163.177 140.772 159.939 140.772 155.945C140.772 151.951 135.153 148.713 128.221 148.713C121.289 148.713 115.67 151.951 115.67 155.945C115.67 159.939 121.289 163.177 128.221 163.177Z"
      fill="#1D1D1D"
    />
    <path
      d="M119.338 152.849C124.24 150.026 132.185 150.026 137.086 152.849C139.092 154.003 140.274 155.451 140.64 156.952C140.27 158.456 139.092 159.901 137.086 161.058C132.185 163.882 124.24 163.882 119.338 161.058C117.336 159.901 116.151 158.456 115.785 156.952C116.158 155.451 117.344 154.003 119.338 152.849Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M119.338 152.849C124.24 150.026 132.185 150.026 137.086 152.849C139.092 154.003 140.274 155.451 140.64 156.952C140.27 158.456 139.092 159.901 137.086 161.058C132.185 163.882 124.24 163.882 119.338 161.058C117.336 159.901 116.151 158.456 115.785 156.952C116.158 155.451 117.344 154.003 119.338 152.849Z"
      fill="#1D1D1D"
    />
    <path
      d="M136.121 158.124C136.121 157.877 136.121 156.885 136.121 156.627C136.121 156.015 135.697 155.418 134.784 154.887L134.751 154.865C133.067 153.896 131.323 154.021 129.772 154.412C129.111 154.578 128.487 154.78 127.925 154.99C127.272 155.232 126.606 155.438 125.931 155.606C125.621 155.679 125.303 155.708 124.985 155.695C124.712 155.68 124.444 155.609 124.198 155.488L124.261 155.444L124.461 155.322C124.629 155.224 124.804 155.138 124.985 155.064C125.423 154.87 125.894 154.765 126.373 154.754C126.852 154.744 127.328 154.828 127.774 155.001L129.384 153.38V151.905C127.965 151.424 126.435 151.369 124.985 151.747C124.506 151.869 124.038 152.031 123.585 152.23L121.979 151.301L120.058 152.407V153.881L120.715 154.25C120.538 154.506 120.439 154.808 120.431 155.12C120.431 155.366 120.431 156.351 120.431 156.594C120.431 157.21 120.87 157.799 121.797 158.334C122.604 158.804 123.516 159.066 124.449 159.097C124.627 159.097 124.804 159.097 124.981 159.097C125.955 159.025 126.913 158.818 127.829 158.481L128.383 158.293L126.765 159.911V161.386C127.764 161.762 128.828 161.932 129.894 161.886C130.96 161.84 132.005 161.578 132.967 161.117L134.559 162.031L136.479 160.925V159.451L135.818 159.082C136.011 158.799 136.116 158.466 136.121 158.124ZM132.058 157.995C131.914 158.077 131.765 158.151 131.611 158.216C130.612 158.644 129.484 158.661 128.472 158.264C129.975 157.733 131.231 157.272 132.302 157.84C132.239 157.891 132.162 157.943 132.073 157.987L132.058 157.995Z"
      fill="currentColor"
    />
    <path
      d="M129.772 154.412C129.111 154.578 128.487 154.781 127.925 154.991C127.272 155.232 126.606 155.438 125.931 155.606C125.621 155.679 125.303 155.709 124.985 155.695C124.664 155.679 124.351 155.587 124.073 155.426C123.452 155.057 123.363 154.548 124.261 153.951L124.461 153.83C124.63 153.732 124.805 153.644 124.985 153.568C125.87 153.18 126.872 153.158 127.774 153.505L129.384 151.887C127.965 151.406 126.435 151.351 124.985 151.729C124.506 151.851 124.038 152.012 123.585 152.212L121.979 151.283L120.058 152.388L121.646 153.306C120.032 154.53 119.999 155.802 121.816 156.849C122.622 157.319 123.534 157.581 124.468 157.608C124.645 157.608 124.823 157.608 125 157.608C125.973 157.536 126.932 157.329 127.848 156.992C129.695 156.377 131.172 155.654 132.424 156.369C133.255 156.849 133.026 157.449 132.114 157.977C131.969 158.059 131.82 158.133 131.667 158.198C131.162 158.414 130.619 158.528 130.07 158.532C129.52 158.536 128.976 158.429 128.468 158.22L126.784 159.908C127.782 160.285 128.846 160.455 129.912 160.41C130.978 160.364 132.024 160.103 132.985 159.643L134.577 160.557L136.498 159.451L134.851 158.552C136.45 157.32 136.635 155.971 134.769 154.887L134.736 154.865C133.067 153.896 131.323 154.021 129.772 154.412Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M129.773 154.412C129.112 154.578 128.488 154.781 127.926 154.991C127.273 155.232 126.607 155.438 125.932 155.606C125.622 155.679 125.304 155.709 124.986 155.695C124.665 155.679 124.352 155.587 124.074 155.426C123.453 155.057 123.364 154.548 124.262 153.951L124.461 153.83C124.631 153.732 124.806 153.644 124.986 153.568C125.871 153.18 126.873 153.158 127.775 153.505L129.385 151.887C127.966 151.406 126.436 151.351 124.986 151.729C124.507 151.851 124.039 152.012 123.586 152.212L121.979 151.283L120.059 152.388L121.647 153.306C120.033 154.53 120 155.802 121.817 156.849C122.623 157.319 123.535 157.581 124.469 157.608C124.646 157.608 124.823 157.608 125.001 157.608C125.974 157.536 126.933 157.329 127.849 156.992C129.695 156.377 131.173 155.654 132.425 156.369C133.256 156.849 133.027 157.449 132.115 157.977C131.97 158.059 131.821 158.133 131.668 158.198C131.163 158.414 130.62 158.528 130.071 158.532C129.521 158.536 128.977 158.429 128.469 158.22L126.785 159.908C127.783 160.285 128.847 160.455 129.913 160.41C130.979 160.364 132.025 160.103 132.986 159.643L134.578 160.557L136.499 159.451L134.852 158.552C136.451 157.32 136.636 155.971 134.77 154.887L134.737 154.865C133.068 153.896 131.324 154.021 129.773 154.412Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M120.031 152.396L121.97 151.522L123.559 152.219L121.952 151.29L120.031 152.396Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M124.738 151.795C126.106 151.52 127.519 151.568 128.864 151.935L127.756 153.512L129.366 151.894C127.871 151.385 126.254 151.351 124.738 151.795Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M134.752 154.865L134.785 154.887C136.65 155.993 136.466 157.32 134.866 158.551C136.281 157.014 136.026 155.941 134.497 155.053C132.968 154.165 130.999 154.18 129.78 154.412C131.324 154.021 133.068 153.896 134.752 154.865Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M134.866 158.551L136.085 159.488L134.537 160.575L136.458 159.469L134.866 158.551Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.628 158.216C130.68 158.695 129.583 158.79 128.566 158.481L126.745 159.926L128.429 158.238C128.937 158.448 129.481 158.554 130.031 158.55C130.58 158.546 131.123 158.433 131.628 158.216Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M125.906 155.606C125.167 155.89 124.137 155.842 123.608 155.389C123.08 154.935 123.409 154.401 124.236 153.958C123.339 154.548 123.427 155.064 124.048 155.433C124.326 155.594 124.639 155.686 124.96 155.702C125.278 155.713 125.596 155.681 125.906 155.606Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M121.607 153.324C121.474 153.446 120.317 154.522 120.827 155.595C121.37 156.742 123.062 157.479 124.428 157.626C123.496 157.603 122.584 157.347 121.777 156.882C119.959 155.82 119.993 154.548 121.607 153.324Z"
      fill="#F5F5F5"
    />
    <path
      d="M106.599 151.636C107.503 150.11 109.011 148.687 111.127 147.456C118.514 143.192 130.533 143.192 137.935 147.456C140.048 148.676 141.559 150.11 142.464 151.636H143.487V155.503C143.35 158.19 141.507 160.851 137.946 162.904C130.559 167.169 118.54 167.169 111.138 162.904C107.577 160.851 105.719 158.19 105.598 155.499V151.636H106.599Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M106.599 151.636C107.503 150.11 109.011 148.687 111.127 147.456C118.514 143.192 130.533 143.192 137.935 147.456C140.048 148.676 141.559 150.11 142.464 151.636H143.487V155.503C143.35 158.19 141.507 160.851 137.946 162.904C130.559 167.169 118.54 167.169 111.138 162.904C107.577 160.851 105.719 158.19 105.598 155.499V151.636H106.599Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M124.531 144.257C129.381 144.257 134.234 145.322 137.935 147.456C140.048 148.676 141.559 150.11 142.464 151.636H143.487V155.503C143.35 158.19 141.507 160.852 137.947 162.905C134.253 165.035 129.392 166.104 124.543 166.104L124.531 144.257Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M134.886 146.03C135.94 146.422 136.96 146.899 137.937 147.456C140.05 148.676 141.56 150.11 142.465 151.636H143.488V155.503C143.351 158.19 141.508 160.852 137.948 162.905C136.97 163.46 135.95 163.937 134.897 164.331L134.886 146.03Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M114.168 146.03C113.113 146.422 112.093 146.899 111.117 147.456C109 148.676 107.493 150.11 106.588 151.636H105.562V155.503C105.698 158.194 107.556 160.855 111.102 162.908C112.079 163.464 113.099 163.941 114.153 164.335L114.168 146.03Z"
      fill="currentColor"
    />
    <path
      d="M137.935 143.914C145.323 148.179 145.323 155.097 137.935 159.362C131.457 163.092 121.458 163.56 114.1 160.759L113.805 160.645C112.882 160.283 111.987 159.854 111.127 159.362C104.951 155.805 103.924 150.405 108.05 146.236C108.966 145.329 109.999 144.55 111.123 143.918C118.529 139.649 130.533 139.649 137.935 143.914Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M137.935 143.914C145.323 148.179 145.323 155.097 137.935 159.362C131.457 163.092 121.458 163.56 114.1 160.759L113.805 160.645C112.882 160.283 111.987 159.854 111.127 159.362C104.951 155.805 103.924 150.405 108.05 146.236C108.966 145.329 109.999 144.55 111.123 143.918C118.529 139.649 130.533 139.649 137.935 143.914Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M108.05 146.236C107.969 146.325 105.38 149.052 106.067 152.975C106.724 156.727 111.977 159.886 113.805 160.645C112.882 160.283 111.987 159.854 111.127 159.362C104.951 155.805 103.924 150.405 108.05 146.236Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M141.013 157.04C141.091 156.951 143.68 154.224 142.993 150.302C142.339 146.549 137.083 143.387 135.236 142.627C136.16 142.992 137.056 143.422 137.918 143.914C144.108 147.471 145.135 152.871 141.013 157.04Z"
      fill="#F5F5F5"
    />
    <path
      d="M124.539 161.777C134.272 161.777 142.162 157.23 142.162 151.621C142.162 146.013 134.272 141.467 124.539 141.467C114.807 141.467 106.917 146.013 106.917 151.621C106.917 157.23 114.807 161.777 124.539 161.777Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M124.539 161.777C134.272 161.777 142.162 157.23 142.162 151.621C142.162 146.013 134.272 141.467 124.539 141.467C114.807 141.467 106.917 146.013 106.917 151.621C106.917 157.23 114.807 161.777 124.539 161.777Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M141.361 154.67C142.775 152.056 142.314 149.178 139.976 146.734C139.521 146.258 139.027 145.821 138.498 145.429C138.04 145.09 137.564 144.776 137.072 144.489C136.792 144.327 136.504 144.168 136.204 144.021C136.943 145.222 136.67 146.512 132.511 146.752C124.385 147.227 134.679 154.972 134.679 154.972C134.679 154.972 139.909 152.436 141.361 154.67Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M140.521 152.514C141.74 152.377 142.345 151.124 141.869 149.72C141.393 148.315 140.022 147.28 138.807 147.416C137.592 147.552 136.982 148.805 137.455 150.21C137.928 151.614 139.302 152.65 140.521 152.514Z"
      fill="white"
    />
    <path
      d="M127.674 158.968C127.275 155.956 114.089 147.626 114.089 147.626C114.089 147.626 111.164 146.453 112.135 144.415C111.65 144.693 111.18 144.996 110.728 145.326C110.431 145.536 110.145 145.761 109.871 146C105.945 149.391 105.941 153.848 109.871 157.243C110.137 157.475 110.421 157.7 110.728 157.921C111.148 158.227 111.584 158.51 112.036 158.769C112.482 159.03 112.948 159.274 113.432 159.506C113.801 159.686 114.215 159.856 114.617 160.015C115.02 160.173 115.434 160.324 115.855 160.46C117.139 160.876 118.455 161.191 119.788 161.404C120.08 161.448 120.379 161.493 120.671 161.53C123.578 161.903 126.525 161.853 129.418 161.382C129.418 161.382 127.298 160.936 127.674 158.968Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M127.674 158.968C127.275 155.956 114.089 147.626 114.089 147.626C114.089 147.626 111.164 146.453 112.135 144.415C111.65 144.693 111.18 144.996 110.728 145.326C110.431 145.536 110.145 145.761 109.871 146C105.945 149.391 105.941 153.848 109.871 157.243C110.137 157.475 110.421 157.7 110.728 157.921C111.148 158.227 111.584 158.51 112.036 158.769C112.482 159.03 112.948 159.274 113.432 159.506C113.801 159.686 114.215 159.856 114.617 160.015C115.02 160.173 115.434 160.324 115.855 160.46C117.139 160.876 118.455 161.191 119.788 161.404C120.08 161.448 120.379 161.493 120.671 161.53C123.578 161.903 126.525 161.853 129.418 161.382C129.418 161.382 127.298 160.936 127.674 158.968Z"
      fill="white"
    />
    <path
      d="M115.25 146.273C110.33 149.108 110.13 153.627 114.648 156.594C114.84 156.72 115.043 156.845 115.25 156.963C120.377 159.912 128.687 159.912 133.814 156.963C138.941 154.014 138.944 149.222 133.814 146.273C133.603 146.152 133.385 146.037 133.167 145.927C128.015 143.325 120.166 143.439 115.25 146.273Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M115.25 146.273C110.33 149.108 110.13 153.627 114.648 156.594C114.84 156.72 115.043 156.845 115.25 156.963C120.377 159.912 128.687 159.912 133.814 156.963C138.941 154.014 138.944 149.222 133.814 146.273C133.603 146.152 133.385 146.037 133.167 145.927C128.015 143.325 120.166 143.439 115.25 146.273Z"
      fill="#1D1D1D"
    />
    <path
      d="M124.54 158.853C131.472 158.853 137.091 155.616 137.091 151.622C137.091 147.627 131.472 144.389 124.54 144.389C117.609 144.389 111.989 147.627 111.989 151.622C111.989 155.616 117.609 158.853 124.54 158.853Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M124.54 158.853C131.472 158.853 137.091 155.616 137.091 151.622C137.091 147.627 131.472 144.389 124.54 144.389C117.609 144.389 111.989 147.627 111.989 151.622C111.989 155.616 117.609 158.853 124.54 158.853Z"
      fill="#1D1D1D"
    />
    <path
      d="M115.664 148.525C120.565 145.702 128.51 145.702 133.412 148.525C135.417 149.683 136.599 151.128 136.965 152.631C136.596 154.132 135.417 155.58 133.412 156.734C128.51 159.558 120.565 159.558 115.664 156.734C113.662 155.58 112.476 154.132 112.114 152.631C112.476 151.128 113.662 149.679 115.664 148.525Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M115.664 148.525C120.565 145.702 128.51 145.702 133.412 148.525C135.417 149.683 136.599 151.128 136.965 152.631C136.596 154.132 135.417 155.58 133.412 156.734C128.51 159.558 120.565 159.558 115.664 156.734C113.662 155.58 112.476 154.132 112.114 152.631C112.476 151.128 113.662 149.679 115.664 148.525Z"
      fill="#1D1D1D"
    />
    <path
      d="M132.44 153.8C132.44 153.557 132.44 152.562 132.44 152.307C132.44 151.692 132.015 151.098 131.103 150.564L131.069 150.545C129.385 149.572 127.645 149.701 126.094 150.088C125.437 150.254 124.809 150.457 124.247 150.663C123.596 150.906 122.931 151.111 122.256 151.279C121.946 151.352 121.626 151.381 121.307 151.367C121.035 151.353 120.768 151.283 120.524 151.161L120.587 151.117C120.646 151.076 120.713 151.035 120.783 150.995C120.951 150.896 121.127 150.809 121.307 150.733C121.746 150.54 122.218 150.435 122.697 150.425C123.176 150.414 123.653 150.497 124.099 150.671L125.706 149.052V147.578C124.287 147.096 122.757 147.041 121.307 147.419C120.829 147.542 120.362 147.703 119.911 147.902L118.301 146.977L116.38 148.083V149.557L117.037 149.926C116.86 150.181 116.762 150.482 116.757 150.792V152.267C116.757 152.879 117.2 153.472 118.123 154.003C118.93 154.474 119.841 154.736 120.775 154.766C120.949 154.766 121.145 154.766 121.304 154.766C122.277 154.696 123.235 154.49 124.151 154.154L124.705 153.962L123.088 155.584V157.059C124.086 157.435 125.151 157.605 126.218 157.558C127.284 157.512 128.33 157.251 129.293 156.789L130.881 157.707L132.802 156.602V155.127L132.141 154.758C132.334 154.476 132.438 154.142 132.44 153.8ZM128.377 153.671C128.233 153.755 128.084 153.831 127.93 153.896C126.93 154.323 125.802 154.339 124.79 153.94C126.294 153.413 127.549 152.949 128.621 153.516C128.573 153.564 128.48 153.619 128.392 153.671H128.377Z"
      fill="currentColor"
    />
    <path
      d="M126.094 150.088C125.437 150.254 124.809 150.457 124.247 150.663C123.596 150.906 122.931 151.111 122.256 151.279C121.946 151.352 121.626 151.381 121.307 151.367C120.986 151.351 120.673 151.257 120.395 151.094C119.778 150.726 119.686 150.217 120.587 149.62C120.65 149.576 120.715 149.535 120.783 149.498C120.951 149.399 121.127 149.312 121.307 149.237C121.746 149.043 122.218 148.938 122.697 148.927C123.176 148.916 123.653 149 124.099 149.174L125.706 147.552C124.287 147.071 122.757 147.015 121.307 147.394C120.829 147.516 120.362 147.678 119.911 147.877L118.301 146.951L116.38 148.057L117.972 148.975C116.358 150.199 116.324 151.467 118.138 152.514C118.945 152.983 119.856 153.245 120.79 153.277C120.964 153.277 121.159 153.277 121.318 153.277C122.291 153.205 123.25 152.999 124.166 152.665C126.013 152.049 127.49 151.323 128.742 152.038C129.574 152.517 129.345 153.122 128.432 153.645C128.288 153.73 128.139 153.805 127.985 153.87C127.481 154.086 126.938 154.199 126.388 154.203C125.839 154.207 125.294 154.101 124.787 153.892L123.102 155.58C124.101 155.956 125.166 156.127 126.233 156.08C127.299 156.034 128.345 155.772 129.308 155.311L130.896 156.226L132.817 155.12L131.228 154.206C132.824 152.971 133.009 151.625 131.143 150.538L131.11 150.52C129.385 149.572 127.645 149.701 126.094 150.088Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M126.094 150.088C125.437 150.254 124.809 150.457 124.247 150.663C123.596 150.906 122.931 151.111 122.256 151.279C121.946 151.352 121.626 151.381 121.307 151.367C120.986 151.351 120.673 151.257 120.395 151.094C119.778 150.726 119.686 150.217 120.587 149.62C120.65 149.576 120.715 149.535 120.783 149.498C120.951 149.399 121.127 149.312 121.307 149.237C121.746 149.043 122.218 148.938 122.697 148.927C123.176 148.916 123.653 149 124.099 149.174L125.706 147.552C124.287 147.071 122.757 147.015 121.307 147.394C120.829 147.516 120.362 147.678 119.911 147.877L118.301 146.951L116.38 148.057L117.972 148.975C116.358 150.199 116.324 151.467 118.138 152.514C118.945 152.983 119.856 153.245 120.79 153.277C120.964 153.277 121.159 153.277 121.318 153.277C122.291 153.205 123.25 152.999 124.166 152.665C126.013 152.049 127.49 151.323 128.742 152.038C129.574 152.517 129.345 153.122 128.432 153.645C128.288 153.73 128.139 153.805 127.985 153.87C127.481 154.086 126.938 154.199 126.388 154.203C125.839 154.207 125.294 154.101 124.787 153.892L123.102 155.58C124.101 155.956 125.166 156.127 126.233 156.08C127.299 156.034 128.345 155.772 129.308 155.311L130.896 156.226L132.817 155.12L131.228 154.206C132.824 152.971 133.009 151.625 131.143 150.538L131.11 150.52C129.385 149.572 127.645 149.701 126.094 150.088Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M116.35 148.076L118.289 147.198L119.881 147.895L118.27 146.97L116.35 148.076Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M121.056 147.475C122.423 147.2 123.835 147.247 125.182 147.611L124.073 149.193L125.68 147.574C124.186 147.066 122.571 147.031 121.056 147.475Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.071 150.546L131.104 150.564C132.951 151.67 132.785 152.997 131.189 154.232C132.603 152.691 132.349 151.618 130.819 150.733C129.29 149.849 127.322 149.86 126.106 150.089C127.647 149.701 129.387 149.572 131.071 150.546Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.187 154.231L132.406 155.164L130.854 156.251L132.775 155.145L131.187 154.231Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M127.945 153.896C126.998 154.373 125.903 154.468 124.887 154.161L123.062 155.606L124.747 153.918C125.255 154.127 125.799 154.233 126.348 154.229C126.898 154.225 127.441 154.112 127.945 153.896Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M122.228 151.286C121.489 151.57 120.455 151.522 119.927 151.069C119.398 150.615 119.727 150.081 120.558 149.639C119.657 150.225 119.749 150.744 120.366 151.113C120.644 151.275 120.957 151.369 121.279 151.386C121.598 151.396 121.917 151.363 122.228 151.286Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M117.928 149.004C117.791 149.122 116.635 150.199 117.145 151.275C117.688 152.421 119.38 153.155 120.75 153.306C119.816 153.275 118.905 153.012 118.098 152.543C116.281 151.496 116.314 150.246 117.928 149.004Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.3"
      d="M136.321 181.684C130.092 178.097 120.817 177.342 113.303 179.411C105.785 177.342 96.5254 178.097 90.2855 181.684C82.3892 186.233 82.3892 193.608 90.2855 198.157C96.5111 201.744 105.785 202.499 113.303 200.43C120.817 202.499 130.081 201.744 136.321 198.157C144.213 193.608 144.213 186.233 136.321 181.684Z"
      fill="currentColor"
    />
    <path
      d="M86.7683 185.671C87.6464 184.196 89.103 182.821 91.1449 181.631C98.3022 177.509 109.9 177.509 117.057 181.631C119.099 182.81 120.556 184.196 121.434 185.671H122.423V189.408C122.291 192.005 120.509 194.58 117.068 196.561C109.929 200.683 98.3092 200.683 91.1556 196.561C87.7142 194.58 85.9223 192.005 85.8008 189.408V185.671H86.7683Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M86.7683 185.671C87.6464 184.196 89.103 182.821 91.1449 181.631C98.3022 177.509 109.9 177.509 117.057 181.631C119.099 182.81 120.556 184.196 121.434 185.671H122.423V189.408C122.291 192.005 120.509 194.58 117.068 196.561C109.929 200.683 98.3092 200.683 91.1556 196.561C87.7142 194.58 85.9223 192.005 85.8008 189.408V185.671H86.7683Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M104.101 178.542C108.791 178.542 113.478 179.572 117.059 181.631C119.101 182.81 120.557 184.196 121.432 185.671H122.424V189.408C122.292 192.005 120.511 194.58 117.069 196.561C113.5 198.624 108.802 199.653 104.111 199.653L104.101 178.542Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M114.106 180.26C115.126 180.637 116.114 181.097 117.059 181.635C119.1 182.814 120.557 184.2 121.432 185.675H122.424V189.412C122.292 192.009 120.511 194.584 117.069 196.565C116.124 197.104 115.137 197.565 114.117 197.944L114.106 180.26Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M94.0942 180.26C93.0758 180.638 92.0898 181.098 91.1457 181.635C89.1038 182.814 87.6472 184.2 86.7691 185.675H85.7803V189.412C85.9125 192.009 87.7045 194.584 91.1349 196.565C92.079 197.103 93.065 197.564 94.0837 197.944L94.0942 180.26Z"
      fill="currentColor"
    />
    <path
      d="M117.058 178.208C124.198 182.333 124.198 189.016 117.058 193.138C110.797 196.743 101.134 197.195 94.0227 194.492L93.7407 194.381C92.8484 194.029 91.9823 193.613 91.1491 193.138C85.184 189.7 84.1916 184.481 88.1754 180.452C89.061 179.577 90.0599 178.823 91.1455 178.211C98.2993 174.086 109.901 174.086 117.058 178.208Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M117.058 178.208C124.198 182.333 124.198 189.016 117.058 193.138C110.797 196.743 101.134 197.195 94.0227 194.492L93.7407 194.381C92.8484 194.029 91.9823 193.613 91.1491 193.138C85.184 189.7 84.1916 184.481 88.1754 180.452C89.061 179.577 90.0599 178.823 91.1455 178.211C98.2993 174.086 109.901 174.086 117.058 178.208Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M88.1724 180.452C88.0974 180.538 85.595 183.174 86.2589 186.964C86.8907 190.594 91.9705 193.647 93.7553 194.381C92.863 194.029 91.997 193.613 91.1638 193.138C85.1809 189.7 84.1885 184.481 88.1724 180.452Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M120.029 190.893C120.108 190.808 122.61 188.175 121.946 184.381C121.311 180.755 116.235 177.702 114.468 176.968C115.36 177.318 116.225 177.732 117.056 178.208C123.024 181.645 124.017 186.864 120.029 190.893Z"
      fill="#F5F5F5"
    />
    <path
      d="M104.108 195.475C113.514 195.475 121.139 191.081 121.139 185.66C121.139 180.24 113.514 175.846 104.108 175.846C94.7015 175.846 87.0762 180.24 87.0762 185.66C87.0762 191.081 94.7015 195.475 104.108 195.475Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M104.108 195.475C113.514 195.475 121.139 191.081 121.139 185.66C121.139 180.24 113.514 175.846 104.108 175.846C94.7015 175.846 87.0762 180.24 87.0762 185.66C87.0762 191.081 94.7015 195.475 104.108 195.475Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M120.365 188.588C121.735 186.063 121.289 183.28 119.03 180.919C118.589 180.46 118.112 180.038 117.601 179.657C117.159 179.329 116.698 179.027 116.22 178.753C115.949 178.592 115.67 178.443 115.381 178.297C116.095 179.462 115.831 180.705 111.812 180.94C103.958 181.396 113.91 188.884 113.91 188.884C113.91 188.884 118.965 186.444 120.365 188.588Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M119.557 186.519C120.735 186.387 121.317 185.179 120.86 183.818C120.403 182.458 119.075 181.464 117.897 181.595C116.719 181.727 116.137 182.935 116.594 184.296C117.051 185.657 118.379 186.65 119.557 186.519Z"
      fill="white"
    />
    <path
      d="M107.138 192.757C106.753 189.85 94.0126 181.795 94.0126 181.795C94.0126 181.795 91.1854 180.662 92.1241 178.692C91.6538 178.96 91.1984 179.254 90.7605 179.572C90.4677 179.782 90.1893 180.003 89.9322 180.228C86.1377 183.505 86.1341 187.809 89.9322 191.093C90.1857 191.314 90.4641 191.535 90.7569 191.749C91.1497 192.03 91.5673 192.304 92.0206 192.568C92.4739 192.832 92.9059 193.056 93.37 193.28C93.727 193.455 94.1267 193.637 94.5194 193.772C94.9121 193.907 95.3084 194.071 95.7116 194.203C96.9524 194.603 98.2223 194.905 99.5101 195.108C99.7954 195.154 100.081 195.194 100.367 195.233C101.627 195.393 102.898 195.472 104.168 195.471C105.723 195.468 107.275 195.341 108.809 195.09C108.809 195.09 106.774 194.67 107.138 192.757Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M107.138 192.757C106.753 189.85 94.0126 181.795 94.0126 181.795C94.0126 181.795 91.1854 180.662 92.1241 178.692C91.6538 178.96 91.1984 179.254 90.7605 179.572C90.4677 179.782 90.1893 180.003 89.9322 180.228C86.1377 183.505 86.1341 187.809 89.9322 191.093C90.1857 191.314 90.4641 191.535 90.7569 191.749C91.1497 192.03 91.5673 192.304 92.0206 192.568C92.4739 192.832 92.9059 193.056 93.37 193.28C93.727 193.455 94.1267 193.637 94.5194 193.772C94.9121 193.907 95.3084 194.071 95.7116 194.203C96.9524 194.603 98.2223 194.905 99.5101 195.108C99.7954 195.154 100.081 195.194 100.367 195.233C101.627 195.393 102.898 195.472 104.168 195.471C105.723 195.468 107.275 195.341 108.809 195.09C108.809 195.09 106.774 194.67 107.138 192.757Z"
      fill="white"
    />
    <path
      d="M95.1302 180.488C90.3789 183.227 90.1861 187.613 94.5518 190.463C94.7375 190.584 94.9304 190.705 95.1338 190.819C100.089 193.669 108.12 193.669 113.075 190.819C118.03 187.969 118.03 183.338 113.075 180.488C112.875 180.374 112.665 180.26 112.454 180.153C107.467 177.638 99.8851 177.766 95.1302 180.488Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M95.1302 180.488C90.3789 183.227 90.1861 187.613 94.5518 190.463C94.7375 190.584 94.9304 190.705 95.1338 190.819C100.089 193.669 108.12 193.669 113.075 190.819C118.03 187.969 118.03 183.338 113.075 180.488C112.875 180.374 112.665 180.26 112.454 180.153C107.467 177.638 99.8851 177.766 95.1302 180.488Z"
      fill="#1D1D1D"
    />
    <path
      d="M104.108 192.646C110.807 192.646 116.238 189.517 116.238 185.657C116.238 181.797 110.807 178.667 104.108 178.667C97.4084 178.667 91.9775 181.797 91.9775 185.657C91.9775 189.517 97.4084 192.646 104.108 192.646Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M104.108 192.646C110.807 192.646 116.238 189.517 116.238 185.657C116.238 181.797 110.807 178.667 104.108 178.667C97.4084 178.667 91.9775 181.797 91.9775 185.657C91.9775 189.517 97.4084 192.646 104.108 192.646Z"
      fill="#1D1D1D"
    />
    <path
      d="M95.5324 182.668C100.27 179.939 107.948 179.939 112.685 182.668C114.62 183.783 115.766 185.183 116.119 186.633C115.762 188.083 114.62 189.483 112.685 190.598C107.948 193.33 100.27 193.33 95.5504 190.598C93.612 189.483 92.4696 188.083 92.1162 186.633C92.4519 185.183 93.5941 183.783 95.5324 182.668Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M95.5324 182.668C100.27 179.939 107.948 179.939 112.685 182.668C114.62 183.783 115.766 185.183 116.119 186.633C115.762 188.083 114.62 189.483 112.685 190.598C107.948 193.33 100.27 193.33 95.5504 190.598C93.612 189.483 92.4696 188.083 92.1162 186.633C92.4519 185.183 93.5941 183.783 95.5324 182.668Z"
      fill="#1D1D1D"
    />
    <path
      d="M111.747 187.762C111.747 187.527 111.747 186.565 111.747 186.319C111.747 185.728 111.337 185.151 110.451 184.634H110.423C108.795 183.698 107.11 183.819 105.611 184.196C104.972 184.357 104.368 184.553 103.826 184.752C103.196 184.988 102.552 185.187 101.898 185.347C101.599 185.417 101.291 185.446 100.984 185.433C100.72 185.418 100.461 185.35 100.224 185.233L100.285 185.19L100.474 185.073C100.638 184.978 100.809 184.895 100.984 184.823C101.407 184.636 101.863 184.534 102.325 184.523C102.788 184.513 103.248 184.593 103.679 184.759L105.222 183.181V181.756C103.85 181.29 102.372 181.235 100.97 181.599C100.508 181.721 100.056 181.877 99.6171 182.066L98.0606 181.172L96.208 182.24V183.666L96.8434 184.022C96.6724 184.268 96.5768 184.559 96.5686 184.859C96.5686 185.098 96.5686 186.052 96.5686 186.284C96.5686 186.875 96.9934 187.449 97.8893 187.965C98.6685 188.42 99.55 188.673 100.453 188.699C100.624 188.699 100.795 188.699 100.966 188.699C101.907 188.629 102.833 188.429 103.719 188.104C103.901 188.044 104.076 187.983 104.254 187.919L102.687 189.486V190.912C103.652 191.275 104.681 191.44 105.712 191.396C106.743 191.352 107.754 191.1 108.684 190.655L110.219 191.538L112.076 190.47V189.045L111.44 188.689C111.632 188.417 111.739 188.095 111.747 187.762ZM107.82 187.641C107.3 187.941 106.718 188.12 106.119 188.165C105.519 188.21 104.917 188.12 104.358 187.901C105.81 187.388 107.024 186.939 108.056 187.492C107.992 187.542 107.917 187.591 107.831 187.641H107.82Z"
      fill="currentColor"
    />
    <path
      d="M105.609 184.178C104.97 184.339 104.367 184.535 103.824 184.734C103.194 184.97 102.55 185.169 101.897 185.329C101.597 185.399 101.29 185.428 100.983 185.415C100.67 185.399 100.367 185.309 100.097 185.151C99.5013 184.795 99.4155 184.303 100.283 183.726L100.472 183.608C100.637 183.514 100.808 183.43 100.983 183.355C101.406 183.169 101.862 183.068 102.324 183.058C102.787 183.047 103.247 183.128 103.678 183.295L105.234 181.731C103.863 181.265 102.385 181.21 100.983 181.574C100.52 181.696 100.068 181.852 99.6297 182.041L98.0733 181.147L96.2207 182.216L97.7235 183.11C96.1634 184.292 96.1313 185.521 97.8876 186.533C98.6676 186.987 99.5486 187.239 100.451 187.267C100.622 187.267 100.808 187.267 100.965 187.267C101.905 187.198 102.832 186.999 103.717 186.676C105.502 186.081 106.93 185.383 108.14 186.07C108.943 186.537 108.722 187.117 107.837 187.627C107.307 187.932 106.714 188.112 106.104 188.153C105.494 188.194 104.882 188.094 104.317 187.862L102.678 189.522C103.644 189.885 104.673 190.05 105.703 190.005C106.734 189.96 107.745 189.708 108.675 189.262L110.211 190.149L112.067 189.08L110.532 188.193C112.078 187.003 112.252 185.7 110.45 184.631H110.421C108.79 183.68 107.108 183.801 105.609 184.178Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M105.612 184.179C104.973 184.339 104.37 184.535 103.827 184.734C103.197 184.971 102.553 185.169 101.899 185.329C101.6 185.399 101.293 185.428 100.985 185.415C100.673 185.4 100.37 185.309 100.1 185.151C99.504 184.795 99.4185 184.303 100.286 183.726L100.475 183.609C100.64 183.515 100.811 183.43 100.985 183.356C101.409 183.169 101.865 183.068 102.327 183.058C102.789 183.047 103.249 183.128 103.681 183.295L105.237 181.731C103.866 181.265 102.388 181.21 100.985 181.574C100.523 181.696 100.071 181.852 99.6327 182.041L98.0762 181.147L96.2236 182.216L97.7264 183.11C96.1664 184.293 96.1343 185.522 97.8905 186.533C98.6706 186.987 99.5515 187.239 100.454 187.267C100.625 187.267 100.811 187.267 100.968 187.267C101.908 187.199 102.834 187 103.72 186.676C105.505 186.081 106.933 185.383 108.143 186.07C108.946 186.537 108.725 187.118 107.839 187.627C107.31 187.932 106.717 188.112 106.107 188.153C105.497 188.194 104.885 188.094 104.32 187.862L102.681 189.522C103.647 189.886 104.676 190.05 105.706 190.005C106.737 189.961 107.748 189.708 108.678 189.262L110.213 190.149L112.07 189.081L110.535 188.194C112.08 187.004 112.255 185.7 110.453 184.631H110.424C108.793 183.68 107.111 183.801 105.612 184.179Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M96.1973 182.23L98.0714 181.382L99.6063 182.059L98.0534 181.161L96.1973 182.23Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M100.741 181.649C102.064 181.383 103.431 181.43 104.732 181.785L103.661 183.309L105.218 181.745C103.771 181.253 102.208 181.22 100.741 181.649Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M110.422 184.616H110.451C112.235 185.685 112.078 186.971 110.533 188.179C111.9 186.693 111.653 185.657 110.176 184.798C108.698 183.94 106.795 183.954 105.617 184.178C107.109 183.801 108.791 183.679 110.422 184.616Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M110.534 188.179L111.712 189.084L110.216 190.135L112.068 189.063L110.534 188.179Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M107.402 187.855C106.486 188.318 105.426 188.41 104.443 188.111L102.68 189.522L104.311 187.89C104.803 188.09 105.33 188.19 105.86 188.184C106.391 188.178 106.915 188.066 107.402 187.855Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M101.874 185.333C101.16 185.607 100.164 185.561 99.6536 185.123C99.143 184.684 99.4607 184.168 100.26 183.74C99.3929 184.307 99.4787 184.809 100.075 185.165C100.344 185.323 100.648 185.414 100.96 185.429C101.268 185.439 101.575 185.406 101.874 185.333Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M97.7248 183.128C97.5928 183.245 96.4756 184.285 96.9718 185.322C97.4929 186.43 99.1136 187.143 100.452 187.285C99.5499 187.257 98.669 187.004 97.8892 186.551C96.1258 185.539 96.1579 184.31 97.7248 183.128Z"
      fill="#F5F5F5"
    />
    <path
      d="M87.2517 179.362C88.1262 177.887 89.5828 176.512 91.6283 175.322C98.7676 171.201 110.383 171.201 117.537 175.322C119.579 176.501 121.039 177.887 121.914 179.362H122.903V183.11C122.771 185.707 120.989 188.279 117.548 190.263C110.409 194.385 98.7926 194.385 91.6388 190.263C88.1976 188.279 86.4021 185.707 86.2842 183.11V179.362H87.2517Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M87.2517 179.362C88.1262 177.887 89.5828 176.512 91.6283 175.322C98.7676 171.201 110.383 171.201 117.537 175.322C119.579 176.501 121.039 177.887 121.914 179.362H122.903V183.11C122.771 185.707 120.989 188.279 117.548 190.263C110.409 194.385 98.7926 194.385 91.6388 190.263C88.1976 188.279 86.4021 185.707 86.2842 183.11V179.362H87.2517Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M104.581 172.23C109.268 172.23 113.959 173.263 117.536 175.322C119.581 176.502 121.037 177.887 121.912 179.362H122.901V183.11C122.769 185.707 120.987 188.279 117.546 190.263C113.977 192.326 109.279 193.355 104.592 193.355L104.581 172.23Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M114.588 173.944C115.606 174.324 116.592 174.785 117.536 175.323C119.582 176.502 121.038 177.887 121.913 179.362H122.902V183.11C122.77 185.707 120.988 188.279 117.547 190.263C116.604 190.802 115.617 191.263 114.598 191.642L114.588 173.944Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M94.5756 173.944C93.5575 174.324 92.5715 174.785 91.6271 175.323C89.5816 176.502 88.1251 177.887 87.2506 179.362H86.2617V183.11C86.394 185.707 88.186 188.279 91.6163 190.263C92.56 190.802 93.5462 191.263 94.5651 191.642L94.5756 173.944Z"
      fill="currentColor"
    />
    <path
      d="M117.537 171.899C124.694 176.02 124.676 182.707 117.537 186.829C111.275 190.434 101.612 190.887 94.5013 188.183L94.2156 188.072C93.3244 187.72 92.4594 187.304 91.6275 186.829C85.659 183.391 84.6702 178.172 88.654 174.143C89.5388 173.267 90.538 172.513 91.6239 171.903C98.7956 167.792 110.383 167.792 117.537 171.899Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M117.537 171.899C124.694 176.02 124.676 182.707 117.537 186.829C111.275 190.434 101.612 190.887 94.5013 188.183L94.2156 188.072C93.3244 187.72 92.4594 187.304 91.6275 186.829C85.659 183.391 84.6702 178.172 88.654 174.143C89.5388 173.267 90.538 172.513 91.6239 171.903C98.7956 167.792 110.383 167.792 117.537 171.899Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M88.6539 174.143C88.5789 174.229 86.0765 176.865 86.7368 180.655C87.3722 184.282 92.4484 187.338 94.2335 188.072C93.3422 187.72 92.4772 187.304 91.6453 186.829C85.6588 183.391 84.67 178.172 88.6539 174.143Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M120.51 184.585C120.585 184.499 123.087 181.863 122.427 178.073C121.791 174.446 116.715 171.393 114.948 170.659C115.84 171.009 116.705 171.423 117.536 171.899C123.505 175.337 124.497 180.556 120.51 184.585Z"
      fill="#F5F5F5"
    />
    <path
      d="M116.643 186.288C123.297 182.458 123.297 176.241 116.643 172.412C109.989 168.582 99.2084 168.579 92.5581 172.412C85.9077 176.245 85.9077 182.458 92.5581 186.288C99.2084 190.117 109.979 190.121 116.643 186.288Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M116.643 186.288C123.297 182.458 123.297 176.241 116.643 172.412C109.989 168.582 99.2084 168.579 92.5581 172.412C85.9077 176.245 85.9077 182.458 92.5581 186.288C99.2084 190.117 109.979 190.121 116.643 186.288Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M120.845 182.28C122.216 179.754 121.766 176.972 119.51 174.61C119.066 174.151 118.585 173.729 118.072 173.349C117.63 173.021 117.17 172.719 116.694 172.444C116.423 172.283 116.144 172.134 115.855 171.988C116.569 173.149 116.305 174.396 112.285 174.631C104.432 175.087 114.384 182.575 114.384 182.575C114.384 182.575 119.442 180.135 120.845 182.28Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M120.035 180.21C121.213 180.078 121.799 178.871 121.342 177.51C120.885 176.149 119.557 175.155 118.379 175.287C117.201 175.419 116.615 176.626 117.072 177.987C117.529 179.348 118.857 180.342 120.035 180.21Z"
      fill="white"
    />
    <path
      d="M107.619 186.448C107.233 183.541 94.4895 175.486 94.4895 175.486C94.4895 175.486 91.6658 174.353 92.6009 172.383C92.1317 172.651 91.6776 172.945 91.2409 173.263C90.9445 173.473 90.6697 173.694 90.4129 173.915C86.6181 177.196 86.6145 181.5 90.4129 184.784C90.6697 185.005 90.9481 185.226 91.2409 185.436C91.6473 185.732 92.0693 186.007 92.5046 186.259C92.9366 186.512 93.3863 186.743 93.854 186.971C94.211 187.146 94.6107 187.31 94.9998 187.463C95.3889 187.616 95.7888 187.762 96.1957 187.894C97.4375 188.297 98.7086 188.603 99.9974 188.81C100.279 188.856 100.569 188.895 100.854 188.934C102.114 189.095 103.383 189.173 104.652 189.169C106.207 189.169 107.759 189.043 109.293 188.792C109.293 188.792 107.255 188.361 107.619 186.448Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M107.619 186.448C107.233 183.541 94.4895 175.486 94.4895 175.486C94.4895 175.486 91.6658 174.353 92.6009 172.383C92.1317 172.651 91.6776 172.945 91.2409 173.263C90.9445 173.473 90.6697 173.694 90.4129 173.915C86.6181 177.196 86.6145 181.5 90.4129 184.784C90.6697 185.005 90.9481 185.226 91.2409 185.436C91.6473 185.732 92.0693 186.007 92.5046 186.259C92.9366 186.512 93.3863 186.743 93.854 186.971C94.211 187.146 94.6107 187.31 94.9998 187.463C95.3889 187.616 95.7888 187.762 96.1957 187.894C97.4375 188.297 98.7086 188.603 99.9974 188.81C100.279 188.856 100.569 188.895 100.854 188.934C102.114 189.095 103.383 189.173 104.652 189.169C106.207 189.169 107.759 189.043 109.293 188.792C109.293 188.792 107.255 188.361 107.619 186.448Z"
      fill="white"
    />
    <path
      d="M95.611 174.179C90.8561 176.918 90.6632 181.304 95.029 184.154C95.2147 184.275 95.4109 184.396 95.611 184.51C100.566 187.36 108.598 187.36 113.552 184.51C118.507 181.66 118.511 177.029 113.552 174.179C113.349 174.065 113.142 173.951 112.928 173.844C107.948 171.329 100.362 171.443 95.611 174.179Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M95.611 174.179C90.8561 176.918 90.6632 181.304 95.029 184.154C95.2147 184.275 95.4109 184.396 95.611 184.51C100.566 187.36 108.598 187.36 113.552 184.51C118.507 181.66 118.511 177.029 113.552 174.179C113.349 174.065 113.142 173.951 112.928 173.844C107.948 171.329 100.362 171.443 95.611 174.179Z"
      fill="#1D1D1D"
    />
    <path
      d="M96.0148 174.407C91.2776 177.136 91.2776 181.56 96.0148 184.289C100.752 187.018 108.43 187.018 113.149 184.289C117.869 181.56 117.887 177.136 113.149 174.407C108.412 171.678 100.748 171.678 96.0148 174.407Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M96.0148 174.407C91.2776 177.136 91.2776 181.56 96.0148 184.289C100.752 187.018 108.43 187.018 113.149 184.289C117.869 181.56 117.887 177.136 113.149 174.407C108.412 171.678 100.748 171.678 96.0148 174.407Z"
      fill="#1D1D1D"
    />
    <path
      d="M96.0143 176.341C100.748 173.612 108.43 173.612 113.149 176.341C115.087 177.46 116.23 178.86 116.583 180.31C116.226 181.76 115.087 183.16 113.149 184.275C108.416 187.004 100.733 187.004 96.0143 184.275C94.0759 183.16 92.9335 181.76 92.5801 180.31C92.9335 178.874 94.0759 177.474 96.0143 176.341Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M96.0143 176.341C100.748 173.612 108.43 173.612 113.149 176.341C115.087 177.46 116.23 178.86 116.583 180.31C116.226 181.76 115.087 183.16 113.149 184.275C108.416 187.004 100.733 187.004 96.0143 184.275C94.0759 183.16 92.9335 181.76 92.5801 180.31C92.9335 178.874 94.0759 177.474 96.0143 176.341Z"
      fill="#1D1D1D"
    />
    <path
      d="M112.223 181.453C112.223 181.218 112.223 180.256 112.223 180.01C112.223 179.419 111.809 178.842 110.928 178.325L110.896 178.308C109.268 177.367 107.586 177.492 106.087 177.869C105.452 178.03 104.845 178.225 104.302 178.425C103.673 178.661 103.031 178.86 102.378 179.02C102.078 179.09 101.769 179.119 101.461 179.105C101.198 179.091 100.94 179.023 100.704 178.906L100.765 178.863C100.822 178.824 100.886 178.785 100.954 178.746C101.118 178.652 101.287 178.568 101.461 178.493C102.317 178.118 103.287 178.096 104.159 178.432L105.712 176.868V175.443C104.335 174.967 102.847 174.908 101.436 175.272C100.974 175.39 100.523 175.547 100.086 175.739L98.53 174.845L96.6738 175.914V177.338L97.309 177.695C97.139 177.941 97.0447 178.232 97.0378 178.532C97.0378 178.771 97.0378 179.725 97.0378 179.957C97.0378 180.548 97.4662 181.122 98.3623 181.638C99.1423 182.104 100.028 182.365 100.936 182.397C101.104 182.397 101.293 182.397 101.446 182.397C102.387 182.328 103.314 182.128 104.199 181.802C104.381 181.742 104.556 181.681 104.734 181.617L103.171 183.184V184.609C104.136 184.974 105.165 185.139 106.196 185.094C107.227 185.049 108.238 184.796 109.168 184.349L110.703 185.236L112.559 184.168V182.743L111.92 182.386C112.115 182.114 112.221 181.788 112.223 181.453ZM108.297 181.332C108.158 181.412 108.013 181.484 107.865 181.546C106.899 181.958 105.808 181.974 104.831 181.589C106.284 181.079 107.497 180.63 108.532 181.179C108.462 181.233 108.388 181.283 108.311 181.328L108.297 181.332Z"
      fill="currentColor"
    />
    <path
      d="M106.091 177.869C105.455 178.03 104.849 178.225 104.306 178.425C103.677 178.661 103.034 178.86 102.382 179.02C102.081 179.09 101.773 179.119 101.464 179.105C101.153 179.09 100.851 179 100.583 178.842C99.9863 178.486 99.9008 177.994 100.768 177.417C100.825 177.378 100.89 177.338 100.957 177.299C101.121 177.204 101.29 177.12 101.464 177.046C101.888 176.859 102.344 176.758 102.808 176.747C103.271 176.737 103.731 176.818 104.163 176.986L105.716 175.422C104.335 174.952 102.846 174.9 101.436 175.272C100.974 175.39 100.523 175.547 100.086 175.739L98.53 174.845L96.6738 175.914L98.2123 176.801C96.6523 177.983 96.6201 179.212 98.3764 180.22C99.1531 180.681 100.033 180.94 100.936 180.972C101.104 180.972 101.293 180.972 101.446 180.972C102.387 180.904 103.313 180.705 104.199 180.381C105.984 179.786 107.412 179.084 108.622 179.775C109.428 180.238 109.204 180.822 108.322 181.332C108.183 181.412 108.038 181.484 107.89 181.546C107.403 181.754 106.878 181.864 106.348 181.867C105.818 181.871 105.292 181.769 104.802 181.567L103.171 183.199C104.136 183.562 105.165 183.726 106.196 183.682C107.227 183.637 108.238 183.384 109.168 182.939L110.703 183.826L112.559 182.757L111.024 181.873C112.566 180.68 112.745 179.38 110.942 178.329L110.91 178.311C109.271 177.367 107.59 177.492 106.091 177.869Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M106.091 177.869C105.455 178.03 104.849 178.225 104.306 178.425C103.677 178.661 103.034 178.86 102.382 179.02C102.081 179.09 101.773 179.119 101.464 179.105C101.153 179.09 100.851 179 100.583 178.842C99.9863 178.486 99.9008 177.994 100.768 177.417C100.825 177.378 100.89 177.338 100.957 177.299C101.121 177.204 101.29 177.12 101.464 177.046C101.888 176.859 102.344 176.758 102.808 176.747C103.271 176.737 103.731 176.818 104.163 176.986L105.716 175.422C104.335 174.952 102.846 174.9 101.436 175.272C100.974 175.39 100.523 175.547 100.086 175.739L98.53 174.845L96.6738 175.914L98.2123 176.801C96.6523 177.983 96.6201 179.212 98.3764 180.22C99.1531 180.681 100.033 180.94 100.936 180.972C101.104 180.972 101.293 180.972 101.446 180.972C102.387 180.904 103.313 180.705 104.199 180.381C105.984 179.786 107.412 179.084 108.622 179.775C109.428 180.238 109.204 180.822 108.322 181.332C108.183 181.412 108.038 181.484 107.89 181.546C107.403 181.754 106.878 181.864 106.348 181.867C105.818 181.871 105.292 181.769 104.802 181.567L103.171 183.199C104.136 183.562 105.165 183.726 106.196 183.682C107.227 183.637 108.238 183.384 109.168 182.939L110.703 183.826L112.559 182.757L111.024 181.873C112.566 180.68 112.745 179.38 110.942 178.329L110.91 178.311C109.271 177.367 107.59 177.492 106.091 177.869Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M96.6738 175.921L98.5477 175.073L100.086 175.747L98.53 174.853L96.6738 175.921Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M101.222 175.34C102.544 175.075 103.911 175.121 105.213 175.475L104.142 177L105.695 175.436C104.249 174.944 102.687 174.911 101.222 175.34Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M110.901 178.308L110.933 178.325C112.736 179.394 112.557 180.676 111.015 181.87C112.382 180.384 112.136 179.344 110.658 178.489C109.18 177.634 107.274 177.645 106.1 177.869C107.592 177.492 109.273 177.367 110.901 178.308Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M111.015 181.87L112.193 182.775L110.693 183.826L112.55 182.754L111.015 181.87Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M107.88 181.546C106.965 182.007 105.906 182.099 104.925 181.802L103.161 183.199L104.793 181.567C105.283 181.769 105.808 181.871 106.339 181.868C106.869 181.864 107.393 181.755 107.88 181.546Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M102.364 179.024C101.65 179.298 100.65 179.252 100.14 178.814C99.6292 178.375 99.9469 177.859 100.75 177.431C99.8827 177.998 99.9685 178.5 100.565 178.856C100.833 179.014 101.135 179.105 101.446 179.12C101.755 179.13 102.064 179.098 102.364 179.024Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M98.199 176.819C98.067 176.936 96.9497 177.973 97.4423 179.013C97.967 180.121 99.6054 180.833 100.926 180.976C100.025 180.948 99.1447 180.694 98.367 180.238C96.6071 179.23 96.6533 178.001 98.199 176.819Z"
      fill="#F5F5F5"
    />
    <path
      d="M83.6922 175.187C84.5703 173.709 86.0269 172.337 88.0687 171.147C95.2081 167.025 106.824 167.025 113.978 171.147C116.023 172.323 117.48 173.709 118.354 175.187H119.347V178.92C119.215 181.517 117.433 184.093 113.992 186.074C106.853 190.199 95.2369 190.199 88.0831 186.074C84.6417 184.093 82.8497 181.517 82.7285 178.92V175.187H83.6922Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M83.6922 175.187C84.5703 173.709 86.0269 172.337 88.0687 171.147C95.2081 167.025 106.824 167.025 113.978 171.147C116.023 172.323 117.48 173.709 118.354 175.187H119.347V178.92C119.215 181.517 117.433 184.093 113.992 186.074C106.853 190.199 95.2369 190.199 88.0831 186.074C84.6417 184.093 82.8497 181.517 82.7285 178.92V175.187H83.6922Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M101.023 168.055C105.711 168.055 110.401 169.084 113.978 171.147C116.023 172.323 117.48 173.708 118.355 175.187H119.347V178.92C119.215 181.517 117.433 184.093 113.992 186.073C110.423 188.136 105.725 189.166 101.038 189.166L101.023 168.055Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M111.028 169.769C112.047 170.147 113.033 170.608 113.977 171.147C116.022 172.323 117.479 173.709 118.353 175.187H119.346V178.92C119.214 181.517 117.432 184.093 113.991 186.074C113.048 186.614 112.062 187.075 111.043 187.452L111.028 169.769Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M91.017 169.769C89.9984 170.148 89.0124 170.609 88.0685 171.147C86.0266 172.323 84.5701 173.709 83.692 175.187H82.7031V178.92C82.8351 181.517 84.6271 184.093 88.0577 186.074C89.0008 186.613 89.9871 187.075 91.0063 187.452L91.017 169.769Z"
      fill="currentColor"
    />
    <path
      d="M113.977 167.723C121.134 171.845 121.134 178.528 113.977 182.65C107.719 186.259 98.0561 186.711 90.9416 184.004L90.6596 183.893C89.7655 183.545 88.8992 183.129 88.0681 182.65C82.103 179.212 81.1105 173.993 85.0944 169.964C85.9795 169.088 86.9783 168.334 88.0645 167.723C95.2254 163.602 106.823 163.598 113.977 167.723Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M113.977 167.723C121.134 171.845 121.134 178.528 113.977 182.65C107.719 186.259 98.0561 186.711 90.9416 184.004L90.6596 183.893C89.7655 183.545 88.8992 183.129 88.0681 182.65C82.103 179.212 81.1105 173.993 85.0944 169.964C85.9795 169.088 86.9783 168.334 88.0645 167.723C95.2254 163.602 106.823 163.598 113.977 167.723Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M85.0944 169.964C85.0194 170.054 82.517 172.686 83.1809 176.48C83.8128 180.107 88.8925 183.16 90.6596 183.893C89.7655 183.545 88.8992 183.129 88.0681 182.65C82.103 179.212 81.1105 173.994 85.0944 169.964Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M116.952 180.409C117.03 180.324 119.532 177.688 118.868 173.897C118.233 170.267 113.157 167.214 111.39 166.48C112.282 166.831 113.147 167.247 113.978 167.724C119.947 171.161 120.928 176.38 116.952 180.409Z"
      fill="#F5F5F5"
    />
    <path
      d="M101.031 184.987C110.437 184.987 118.062 180.593 118.062 175.172C118.062 169.752 110.437 165.358 101.031 165.358C91.6251 165.358 84 169.752 84 175.172C84 180.593 91.6251 184.987 101.031 184.987Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M101.031 184.987C110.437 184.987 118.062 180.593 118.062 175.172C118.062 169.752 110.437 165.358 101.031 165.358C91.6251 165.358 84 169.752 84 175.172C84 180.593 91.6251 184.987 101.031 184.987Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M117.287 178.122C118.657 175.597 118.211 172.811 115.951 170.453C115.512 169.993 115.034 169.571 114.524 169.191C114.081 168.862 113.62 168.558 113.142 168.283C112.871 168.126 112.592 167.973 112.303 167.831C113.017 168.992 112.753 170.239 108.733 170.47C100.88 170.93 110.833 178.415 110.833 178.415C110.833 178.415 115.884 175.96 117.287 178.122Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M116.477 176.031C117.655 175.899 118.24 174.691 117.784 173.331C117.327 171.97 115.998 170.976 114.821 171.108C113.642 171.239 113.057 172.447 113.518 173.808C113.978 175.169 115.299 176.163 116.477 176.031Z"
      fill="white"
    />
    <path
      d="M104.06 182.272C103.675 179.362 90.9307 171.307 90.9307 171.307C90.9307 171.307 88.107 170.178 89.046 168.204C88.5757 168.473 88.1206 168.767 87.6824 169.084C87.386 169.298 87.1112 169.515 86.8541 169.74C83.0595 173.017 83.0559 177.324 86.8541 180.605C87.1112 180.83 87.3896 181.047 87.6824 181.261C88.0887 181.556 88.5107 181.829 88.9461 182.08C89.3778 182.333 89.8314 182.568 90.2953 182.792C90.6523 182.967 91.0522 183.149 91.4449 183.288C91.8376 183.427 92.23 183.583 92.6371 183.719C93.8787 184.12 95.1498 184.424 96.4389 184.627C96.7245 184.673 97.0101 184.716 97.2957 184.752C98.5563 184.914 99.8263 184.993 101.097 184.991C102.652 184.989 104.204 184.862 105.738 184.609C105.738 184.609 103.696 184.178 104.06 182.272Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M104.06 182.272C103.675 179.362 90.9307 171.307 90.9307 171.307C90.9307 171.307 88.107 170.178 89.046 168.204C88.5757 168.473 88.1206 168.767 87.6824 169.084C87.386 169.298 87.1112 169.515 86.8541 169.74C83.0595 173.017 83.0559 177.324 86.8541 180.605C87.1112 180.83 87.3896 181.047 87.6824 181.261C88.0887 181.556 88.5107 181.829 88.9461 182.08C89.3778 182.333 89.8314 182.568 90.2953 182.792C90.6523 182.967 91.0522 183.149 91.4449 183.288C91.8376 183.427 92.23 183.583 92.6371 183.719C93.8787 184.12 95.1498 184.424 96.4389 184.627C96.7245 184.673 97.0101 184.716 97.2957 184.752C98.5563 184.914 99.8263 184.993 101.097 184.991C102.652 184.989 104.204 184.862 105.738 184.609C105.738 184.609 103.696 184.178 104.06 182.272Z"
      fill="white"
    />
    <path
      d="M92.0528 170.003C87.298 172.743 87.1051 177.128 91.4744 179.978C91.6565 180.099 91.853 180.22 92.0528 180.334C97.0111 183.184 105.039 183.184 109.998 180.334C114.956 177.484 114.953 172.853 109.998 170.003C109.798 169.886 109.587 169.775 109.377 169.668C104.39 167.153 96.8077 167.264 92.0528 170.003Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M92.0528 170.003C87.298 172.743 87.1051 177.128 91.4744 179.978C91.6565 180.099 91.853 180.22 92.0528 180.334C97.0111 183.184 105.039 183.184 109.998 180.334C114.956 177.484 114.953 172.853 109.998 170.003C109.798 169.886 109.587 169.775 109.377 169.668C104.39 167.153 96.8077 167.264 92.0528 170.003Z"
      fill="#1D1D1D"
    />
    <path
      d="M101.029 182.162C107.729 182.162 113.159 179.033 113.159 175.172C113.159 171.312 107.729 168.183 101.029 168.183C94.33 168.183 88.8994 171.312 88.8994 175.172C88.8994 179.033 94.33 182.162 101.029 182.162Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M101.029 182.162C107.729 182.162 113.159 179.033 113.159 175.172C113.159 171.312 107.729 168.183 101.029 168.183C94.33 168.183 88.8994 171.312 88.8994 175.172C88.8994 179.033 94.33 182.162 101.029 182.162Z"
      fill="#1D1D1D"
    />
    <path
      d="M92.4553 172.18C97.1922 169.451 104.871 169.451 109.608 172.18C111.543 173.295 112.689 174.695 113.038 176.145C112.681 177.598 111.543 178.995 109.608 180.113C104.871 182.842 97.1922 182.842 92.473 180.113C90.5346 178.998 89.3925 177.598 89.0391 176.145C89.3745 174.695 90.5169 173.295 92.4553 172.18Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M92.4553 172.18C97.1922 169.451 104.871 169.451 109.608 172.18C111.543 173.295 112.689 174.695 113.038 176.145C112.681 177.598 111.543 178.995 109.608 180.113C104.871 182.842 97.1922 182.842 92.473 180.113C90.5346 178.998 89.3925 177.598 89.0391 176.145C89.3745 174.695 90.5169 173.295 92.4553 172.18Z"
      fill="#1D1D1D"
    />
    <path
      d="M108.669 177.278C108.669 177.043 108.669 176.081 108.669 175.831C108.669 175.24 108.259 174.667 107.373 174.15L107.345 174.129C105.714 173.192 104.032 173.313 102.533 173.69C101.898 173.851 101.291 174.047 100.748 174.246C100.117 174.479 99.4732 174.678 98.8203 174.841C98.5214 174.912 98.2137 174.942 97.9065 174.93C97.6419 174.915 97.383 174.846 97.1462 174.727L97.2068 174.688C97.2577 174.642 97.3114 174.599 97.3674 174.56C97.5318 174.464 97.7023 174.38 97.878 174.307C98.301 174.119 98.7569 174.018 99.2194 174.007C99.682 173.997 100.142 174.078 100.573 174.246L102.129 172.679V171.254C100.758 170.788 99.2796 170.735 97.878 171.101C97.4149 171.219 96.9623 171.375 96.5249 171.567L94.9687 170.669L93.1123 171.738V173.163L93.7513 173.519C93.5798 173.766 93.4842 174.057 93.4765 174.357C93.4765 174.595 93.4765 175.55 93.4765 175.782C93.4765 176.377 93.9013 176.947 94.7972 177.463C95.5769 177.917 96.4579 178.171 97.3604 178.2C97.5318 178.2 97.7174 178.2 97.8744 178.2C98.8147 178.13 99.7408 177.931 100.627 177.609L101.162 177.424L99.595 178.988V180.413C100.56 180.776 101.59 180.94 102.62 180.896C103.651 180.851 104.662 180.598 105.592 180.153L107.127 181.036L108.983 179.967V178.542L108.348 178.186C108.541 177.921 108.652 177.605 108.669 177.278ZM104.743 177.153C104.222 177.453 103.64 177.632 103.041 177.677C102.442 177.722 101.84 177.632 101.28 177.413C102.733 176.9 103.947 176.455 104.978 177.003C104.914 177.053 104.839 177.103 104.753 177.153H104.743Z"
      fill="currentColor"
    />
    <path
      d="M102.533 173.69C101.894 173.851 101.287 174.047 100.748 174.246C100.117 174.479 99.4732 174.678 98.8203 174.841C98.5214 174.912 98.2137 174.942 97.9065 174.93C97.5949 174.912 97.2919 174.822 97.0212 174.666C96.425 174.31 96.3395 173.819 97.2068 173.242L97.3674 173.135C97.5318 173.04 97.7023 172.957 97.878 172.885C98.301 172.698 98.7569 172.596 99.2194 172.586C99.682 172.575 100.142 172.657 100.573 172.825L102.129 171.257C100.758 170.792 99.2796 170.739 97.878 171.104C97.4149 171.222 96.9623 171.378 96.5249 171.571L94.9687 170.673L93.1123 171.742L94.651 172.625C93.091 173.808 93.0589 175.033 94.8152 176.045C95.5949 176.5 96.4756 176.753 97.3782 176.783C97.5495 176.783 97.7208 176.783 97.8924 176.783C98.8329 176.713 99.7593 176.513 100.644 176.188C102.429 175.593 103.857 174.895 105.067 175.586C105.871 176.049 105.646 176.63 104.764 177.139C104.235 177.444 103.642 177.624 103.032 177.665C102.422 177.707 101.81 177.608 101.244 177.377L99.6129 179.006C100.578 179.37 101.607 179.536 102.638 179.492C103.669 179.447 104.68 179.195 105.61 178.749L107.145 179.633L109.001 178.564L107.466 177.68C109.008 176.487 109.187 175.187 107.384 174.136L107.356 174.114C105.714 173.192 104.032 173.313 102.533 173.69Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M102.533 173.691C101.894 173.851 101.287 174.047 100.748 174.246C100.117 174.479 99.4732 174.678 98.8203 174.841C98.5214 174.912 98.2137 174.942 97.9065 174.93C97.5949 174.912 97.2919 174.822 97.0212 174.667C96.425 174.31 96.3395 173.819 97.2068 173.242L97.3674 173.135C97.5318 173.04 97.7023 172.957 97.878 172.886C98.301 172.698 98.7569 172.596 99.2194 172.586C99.682 172.576 100.142 172.657 100.573 172.825L102.129 171.257C100.758 170.792 99.2796 170.739 97.878 171.104C97.4149 171.223 96.9623 171.379 96.5249 171.571L94.9687 170.673L93.1123 171.742L94.651 172.625C93.091 173.808 93.0589 175.034 94.8152 176.045C95.5949 176.5 96.4756 176.753 97.3782 176.783C97.5495 176.783 97.7208 176.783 97.8924 176.783C98.8329 176.713 99.7593 176.513 100.644 176.188C102.429 175.593 103.857 174.895 105.067 175.586C105.871 176.049 105.646 176.63 104.764 177.139C104.235 177.444 103.642 177.624 103.032 177.666C102.422 177.707 101.81 177.608 101.244 177.378L99.6129 179.006C100.578 179.371 101.607 179.536 102.638 179.492C103.669 179.448 104.68 179.195 105.61 178.749L107.145 179.633L109.001 178.564L107.466 177.681C109.008 176.487 109.187 175.187 107.384 174.136L107.356 174.115C105.714 173.192 104.032 173.313 102.533 173.691Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M93.1201 171.745L94.9904 170.897L96.5292 171.571L94.9727 170.677L93.1201 171.745Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M97.6631 171.161C98.9861 170.897 100.352 170.943 101.654 171.296L100.583 172.825L102.14 171.257C100.693 170.765 99.1294 170.732 97.6631 171.161Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M107.362 174.129L107.391 174.15C109.194 175.219 109.015 176.502 107.473 177.695C108.84 176.206 108.594 175.169 107.116 174.314C105.638 173.459 103.732 173.466 102.558 173.691C104.032 173.313 105.713 173.192 107.362 174.129Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M107.457 177.694L108.635 178.596L107.136 179.647L108.992 178.578L107.457 177.694Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M104.324 177.367C103.408 177.83 102.348 177.923 101.365 177.627L99.6016 179.02L101.233 177.392C101.724 177.593 102.25 177.695 102.781 177.69C103.312 177.686 103.837 177.576 104.324 177.367Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M98.795 174.845C98.0812 175.119 97.0852 175.073 96.5713 174.634C96.0571 174.196 96.382 173.68 97.1815 173.252C96.3142 173.822 96.3997 174.321 96.9958 174.677C97.2668 174.832 97.5696 174.922 97.8811 174.941C98.1888 174.951 98.4963 174.918 98.795 174.845Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M94.6402 172.643C94.5082 172.757 93.3906 173.798 93.8833 174.838C94.408 175.946 96.0466 176.654 97.3673 176.797C96.465 176.767 95.5841 176.514 94.8043 176.059C93.048 175.051 93.0837 173.826 94.6402 172.643Z"
      fill="#F5F5F5"
    />
    <path
      d="M85.1651 168.884C86.0432 167.409 87.4995 166.034 89.5414 164.848C96.681 160.723 108.297 160.723 115.451 164.848C117.496 166.024 118.953 167.409 119.831 168.884H120.82V172.621C120.687 175.218 118.906 177.794 115.465 179.775C108.325 183.896 96.7095 183.896 89.5558 179.775C86.1146 177.794 84.3224 175.218 84.2012 172.621V168.884H85.1651Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M85.1651 168.884C86.0432 167.409 87.4995 166.034 89.5414 164.848C96.681 160.723 108.297 160.723 115.451 164.848C117.496 166.024 118.953 167.409 119.831 168.884H120.82V172.621C120.687 175.218 118.906 177.794 115.465 179.775C108.325 183.896 96.7095 183.896 89.5558 179.775C86.1146 177.794 84.3224 175.218 84.2012 172.621V168.884H85.1651Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M102.496 161.756C107.187 161.756 111.874 162.785 115.451 164.848C117.496 166.023 118.953 167.409 119.827 168.884H120.82V172.621C120.688 175.218 118.906 177.794 115.465 179.774C111.895 181.837 107.201 182.867 102.51 182.867L102.496 161.756Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M112.502 163.469C113.522 163.846 114.508 164.307 115.451 164.848C117.496 166.024 118.952 167.409 119.827 168.884H120.819V172.621C120.687 175.218 118.906 177.794 115.465 179.775C114.522 180.314 113.535 180.775 112.516 181.153L112.502 163.469Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M92.4907 163.469C91.4715 163.847 90.4853 164.308 89.5421 164.848C87.5003 166.024 86.0437 167.409 85.1656 168.884H84.1768V172.621C84.309 175.218 86.101 177.794 89.5314 179.775C90.4755 180.313 91.4615 180.774 92.4801 181.153L92.4907 163.469Z"
      fill="currentColor"
    />
    <path
      d="M115.455 161.421C122.594 165.546 122.594 172.229 115.455 176.351C109.193 179.96 99.53 180.409 92.4191 177.705L92.1371 177.594C91.2449 177.242 90.3788 176.826 89.5456 176.351C83.5805 172.914 82.5881 167.694 86.5719 163.666C87.4585 162.791 88.4571 162.037 89.542 161.425C96.6958 157.299 108.297 157.299 115.455 161.421Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M115.455 161.421C122.594 165.546 122.594 172.229 115.455 176.351C109.193 179.96 99.53 180.409 92.4191 177.705L92.1371 177.594C91.2449 177.242 90.3788 176.826 89.5456 176.351C83.5805 172.914 82.5881 167.694 86.5719 163.666C87.4585 162.791 88.4571 162.037 89.542 161.425C96.6958 157.299 108.297 157.299 115.455 161.421Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M86.5688 163.665C86.4938 163.751 83.9914 166.387 84.6554 170.177C85.2872 173.807 90.367 176.86 92.1518 177.594C91.2595 177.242 90.3934 176.826 89.5603 176.351C83.5774 172.913 82.585 167.694 86.5688 163.665Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M118.429 174.11C118.508 174.021 121.01 171.389 120.346 167.595C119.711 163.968 114.634 160.915 112.85 160.181C113.742 160.531 114.607 160.946 115.438 161.425C121.421 164.862 122.413 170.078 118.429 174.11Z"
      fill="#F5F5F5"
    />
    <path
      d="M102.503 178.688C111.909 178.688 119.534 174.294 119.534 168.874C119.534 163.453 111.909 159.059 102.503 159.059C93.097 159.059 85.4717 163.453 85.4717 168.874C85.4717 174.294 93.097 178.688 102.503 178.688Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M102.503 178.688C111.909 178.688 119.534 174.294 119.534 168.874C119.534 163.453 111.909 159.059 102.503 159.059C93.097 159.059 85.4717 163.453 85.4717 168.874C85.4717 174.294 93.097 178.688 102.503 178.688Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M118.76 171.802C120.131 169.279 119.685 166.494 117.425 164.132C116.984 163.674 116.506 163.252 115.997 162.871C115.555 162.542 115.093 162.241 114.616 161.966C114.344 161.806 114.066 161.656 113.777 161.51C114.491 162.675 114.226 163.918 110.207 164.153C102.353 164.609 112.306 172.097 112.306 172.097C112.306 172.097 117.357 169.661 118.76 171.802Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M117.954 169.732C119.132 169.6 119.714 168.393 119.257 167.032C118.8 165.671 117.472 164.677 116.294 164.809C115.116 164.941 114.534 166.148 114.991 167.509C115.448 168.87 116.776 169.864 117.954 169.732Z"
      fill="white"
    />
    <path
      d="M105.534 175.984C105.148 173.077 92.4082 165.022 92.4082 165.022C92.4082 165.022 89.5809 163.893 90.5196 161.92C90.0491 162.189 89.594 162.484 89.156 162.803C88.8632 163.017 88.5848 163.234 88.3277 163.459C84.5332 166.736 84.5296 171.043 88.3277 174.324C88.5812 174.545 88.8597 174.766 89.1524 174.979C89.5452 175.261 89.9628 175.535 90.4161 175.799C90.8694 176.062 91.3014 176.287 91.7655 176.511C92.1225 176.686 92.5222 176.867 92.9149 177.006C93.3076 177.145 93.7003 177.302 94.1072 177.434C95.3485 177.837 96.6196 178.143 97.9089 178.346C98.1945 178.392 98.4801 178.435 98.7657 178.471C100.027 178.631 101.296 178.71 102.567 178.709C104.122 178.706 105.674 178.579 107.208 178.328C107.208 178.328 105.17 177.883 105.534 175.984Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M105.534 175.984C105.148 173.077 92.4082 165.022 92.4082 165.022C92.4082 165.022 89.5809 163.893 90.5196 161.92C90.0491 162.189 89.594 162.484 89.156 162.803C88.8632 163.017 88.5848 163.234 88.3277 163.459C84.5332 166.736 84.5296 171.043 88.3277 174.324C88.5812 174.545 88.8597 174.766 89.1524 174.979C89.5452 175.261 89.9628 175.535 90.4161 175.799C90.8694 176.062 91.3014 176.287 91.7655 176.511C92.1225 176.686 92.5222 176.867 92.9149 177.006C93.3076 177.145 93.7003 177.302 94.1072 177.434C95.3485 177.837 96.6196 178.143 97.9089 178.346C98.1945 178.392 98.4801 178.435 98.7657 178.471C100.027 178.631 101.296 178.71 102.567 178.709C104.122 178.706 105.674 178.579 107.208 178.328C107.208 178.328 105.17 177.883 105.534 175.984Z"
      fill="white"
    />
    <path
      d="M93.5248 163.704C88.7735 166.44 88.5806 170.829 92.9464 173.679C93.1321 173.8 93.3249 173.922 93.5284 174.035C98.4831 176.885 106.515 176.885 111.47 174.035C116.425 171.186 116.425 166.554 111.47 163.704C111.27 163.587 111.059 163.473 110.849 163.366C105.862 160.851 98.2796 160.965 93.5248 163.704Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M93.5248 163.704C88.7735 166.44 88.5806 170.829 92.9464 173.679C93.1321 173.8 93.3249 173.922 93.5284 174.035C98.4831 176.885 106.515 176.885 111.47 174.035C116.425 171.186 116.425 166.554 111.47 163.704C111.27 163.587 111.059 163.473 110.849 163.366C105.862 160.851 98.2796 160.965 93.5248 163.704Z"
      fill="#1D1D1D"
    />
    <path
      d="M93.9288 163.932C89.1917 166.661 89.1917 171.086 93.9288 173.814C98.6658 176.543 106.344 176.543 111.063 173.814C115.783 171.086 115.801 166.661 111.063 163.932C106.327 161.204 98.6658 161.204 93.9288 163.932Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M93.9288 163.932C89.1917 166.661 89.1917 171.086 93.9288 173.814C98.6658 176.543 106.344 176.543 111.063 173.814C115.783 171.086 115.801 166.661 111.063 163.932C106.327 161.204 98.6658 161.204 93.9288 163.932Z"
      fill="#1D1D1D"
    />
    <path
      d="M93.9291 165.881C98.6662 163.153 106.345 163.153 111.064 165.881C112.999 166.996 114.145 168.396 114.498 169.846C114.141 171.3 112.999 172.696 111.064 173.815C106.327 176.544 98.6483 176.544 93.9291 173.815C91.9907 172.696 90.8485 171.296 90.4951 169.846C90.8485 168.396 91.9907 166.996 93.9291 165.881Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M93.9291 165.881C98.6662 163.153 106.345 163.153 111.064 165.881C112.999 166.996 114.145 168.396 114.498 169.846C114.141 171.3 112.999 172.696 111.064 173.815C106.327 176.544 98.6483 176.544 93.9291 173.815C91.9907 172.696 90.8485 171.296 90.4951 169.846C90.8485 168.396 91.9907 166.996 93.9291 165.881Z"
      fill="#1D1D1D"
    />
    <path
      d="M110.141 170.976C110.141 170.741 110.141 169.779 110.141 169.533C110.141 168.941 109.73 168.365 108.845 167.851C108.845 167.851 108.824 167.851 108.817 167.83C107.189 166.893 105.504 167.014 104.004 167.392C103.365 167.552 102.762 167.748 102.22 167.947C101.589 168.183 100.945 168.383 100.292 168.546C99.9926 168.616 99.6852 168.645 99.3783 168.632C99.1137 168.617 98.8551 168.549 98.6178 168.432L98.6784 168.389L98.8677 168.272C99.0321 168.177 99.2026 168.094 99.3783 168.022C99.801 167.835 100.257 167.733 100.72 167.723C101.182 167.712 101.642 167.794 102.073 167.962L103.63 166.395V164.969C102.258 164.504 100.78 164.451 99.3783 164.816C98.9152 164.934 98.4627 165.09 98.0253 165.283L96.4688 164.385L94.6162 165.454V166.879L95.2516 167.235C95.0798 167.483 94.9843 167.775 94.9766 168.076C94.9766 168.315 94.9766 169.266 94.9766 169.501C94.9766 170.096 95.4014 170.666 96.2975 171.182C97.0773 171.637 97.958 171.89 98.8605 171.92C99.0318 171.92 99.2034 171.92 99.3747 171.92C100.315 171.85 101.242 171.65 102.127 171.325L102.662 171.143L101.095 172.707V174.132C102.06 174.497 103.089 174.662 104.12 174.618C105.151 174.574 106.162 174.321 107.092 173.876L108.627 174.759L110.484 173.69V172.265L109.848 171.909C110.035 171.633 110.137 171.309 110.141 170.976ZM106.214 170.855C105.693 171.154 105.112 171.333 104.513 171.379C103.913 171.424 103.311 171.333 102.752 171.115C104.205 170.602 105.418 170.156 106.45 170.705C106.386 170.755 106.311 170.805 106.225 170.855H106.214Z"
      fill="currentColor"
    />
    <path
      d="M104.004 167.392C103.365 167.552 102.762 167.748 102.22 167.948C101.589 168.183 100.945 168.383 100.292 168.546C99.9926 168.616 99.6852 168.645 99.3783 168.631C99.0665 168.616 98.7632 168.527 98.493 168.371C97.8968 168.015 97.811 167.524 98.6784 166.946L98.8677 166.829C99.0328 166.735 99.2031 166.65 99.3783 166.576C100.233 166.201 101.202 166.179 102.073 166.515L103.63 164.952C102.258 164.486 100.78 164.433 99.3783 164.798C98.9152 164.916 98.4627 165.073 98.0253 165.265L96.4688 164.367L94.6162 165.436L96.1511 166.323C94.591 167.506 94.5589 168.735 96.3152 169.746C97.0945 170.202 97.9759 170.454 98.8785 170.48C99.0498 170.48 99.2211 170.48 99.3924 170.48C100.333 170.411 101.259 170.21 102.145 169.885C103.929 169.29 105.358 168.592 106.568 169.284C107.371 169.746 107.15 170.327 106.264 170.837C105.735 171.142 105.142 171.322 104.532 171.362C103.922 171.403 103.31 171.304 102.744 171.072L101.113 172.703C102.078 173.068 103.107 173.233 104.138 173.188C105.169 173.143 106.18 172.89 107.11 172.443L108.645 173.33L110.502 172.262L108.966 171.375C110.512 170.185 110.687 168.881 108.884 167.834C108.884 167.834 108.863 167.834 108.856 167.812C107.189 166.893 105.504 167.014 104.004 167.392Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M104.005 167.392C103.366 167.552 102.763 167.748 102.221 167.948C101.59 168.183 100.946 168.383 100.293 168.546C99.9936 168.616 99.6862 168.645 99.379 168.631C99.0675 168.616 98.7641 168.527 98.4939 168.371C97.8978 168.015 97.812 167.524 98.6794 166.946L98.8687 166.829C99.0336 166.735 99.2041 166.65 99.379 166.576C100.234 166.201 101.203 166.179 102.074 166.515L103.631 164.952C102.259 164.486 100.781 164.433 99.379 164.798C98.9159 164.916 98.4636 165.073 98.0262 165.265L96.4698 164.367L94.6172 165.436L96.1521 166.323C94.592 167.506 94.5599 168.735 96.3162 169.746C97.0954 170.202 97.9769 170.454 98.8795 170.48C99.0508 170.48 99.2221 170.48 99.3934 170.48C100.334 170.411 101.26 170.21 102.146 169.885C103.93 169.29 105.359 168.592 106.568 169.284C107.372 169.746 107.15 170.327 106.265 170.837C105.736 171.142 105.143 171.322 104.533 171.362C103.923 171.403 103.311 171.304 102.745 171.072L101.114 172.703C102.079 173.068 103.108 173.233 104.139 173.188C105.17 173.143 106.181 172.89 107.111 172.443L108.646 173.33L110.503 172.262L108.967 171.375C110.513 170.185 110.688 168.881 108.885 167.834C108.885 167.834 108.864 167.834 108.857 167.812C107.19 166.893 105.505 167.014 104.005 167.392Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M94.5938 165.443L96.4679 164.599L98.0028 165.272L96.4499 164.374L94.5938 165.443Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M99.1514 164.862C100.474 164.597 101.841 164.643 103.142 164.998L102.071 166.522L103.628 164.958C102.181 164.467 100.618 164.433 99.1514 164.862Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M108.818 167.829C108.818 167.829 108.84 167.829 108.847 167.851C110.65 168.902 110.475 170.202 108.929 171.392C110.296 169.906 110.05 168.87 108.572 168.011C107.094 167.153 105.188 167.167 104.014 167.391C105.506 167.014 107.191 166.893 108.818 167.829Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M108.93 171.393L110.108 172.297L108.608 173.348L110.465 172.279L108.93 171.393Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M105.798 171.068C104.882 171.531 103.821 171.623 102.839 171.324L101.075 172.721L102.707 171.089C103.197 171.292 103.723 171.395 104.254 171.391C104.786 171.387 105.31 171.277 105.798 171.068Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M100.269 168.546C99.5555 168.82 98.5594 168.774 98.0491 168.336C97.5385 167.897 97.8562 167.381 98.6558 166.953C97.7884 167.523 97.8742 168.022 98.4703 168.378C98.7405 168.534 99.0441 168.623 99.3556 168.638C99.6631 168.649 99.9705 168.618 100.269 168.546Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M96.114 166.341C95.982 166.458 94.8648 167.499 95.361 168.535C95.8821 169.643 97.5205 170.356 98.8414 170.498C97.9389 170.472 97.0574 170.22 96.2784 169.764C94.5114 168.753 94.5542 167.524 96.114 166.341Z"
      fill="#F5F5F5"
    />
    <path
      d="M100.728 163.573C101.603 162.098 103.059 160.723 105.105 159.533C112.244 155.411 123.86 155.411 131.014 159.533C133.059 160.713 134.516 162.098 135.39 163.573H136.379V167.31C136.247 169.907 134.466 172.483 131.025 174.463C123.885 178.585 112.269 178.585 105.115 174.463C101.674 172.483 99.8786 169.907 99.7607 167.31V163.573H100.728Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M100.728 163.573C101.603 162.098 103.059 160.723 105.105 159.533C112.244 155.411 123.86 155.411 131.014 159.533C133.059 160.713 134.516 162.098 135.39 163.573H136.379V167.31C136.247 169.907 134.466 172.483 131.025 174.463C123.885 178.585 112.269 178.585 105.115 174.463C101.674 172.483 99.8786 169.907 99.7607 167.31V163.573H100.728Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M118.072 156.441C122.759 156.441 127.45 157.474 131.027 159.533C133.072 160.712 134.529 162.098 135.403 163.573H136.392V167.31C136.26 169.907 134.479 172.483 131.038 174.463C127.468 176.526 122.77 177.555 118.083 177.555L118.072 156.441Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M128.067 158.172C129.086 158.55 130.072 159.01 131.016 159.547C133.061 160.727 134.518 162.112 135.392 163.587H136.381V167.324C136.249 169.921 134.468 172.497 131.027 174.478C130.079 175.012 129.089 175.469 128.067 175.842V158.172Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M108.052 158.172C107.034 158.55 106.047 159.01 105.104 159.547C103.062 160.727 101.602 162.112 100.727 163.587H99.7383V167.324C99.8703 169.921 101.663 172.497 105.093 174.478C106.037 175.016 107.023 175.478 108.041 175.856L108.052 158.172Z"
      fill="currentColor"
    />
    <path
      d="M131.012 156.11C138.17 160.235 138.152 166.918 131.012 171.04C124.755 174.645 115.088 175.098 107.977 172.394L107.691 172.283C106.8 171.931 105.935 171.515 105.103 171.04C99.1345 167.602 98.1459 162.383 102.129 158.354C103.015 157.478 104.014 156.724 105.1 156.114C112.257 151.988 123.859 151.988 131.012 156.11Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M131.012 156.11C138.17 160.235 138.152 166.918 131.012 171.04C124.755 174.645 115.088 175.098 107.977 172.394L107.691 172.283C106.8 171.931 105.935 171.515 105.103 171.04C99.1345 167.602 98.1459 162.383 102.129 158.354C103.015 157.478 104.014 156.724 105.1 156.114C112.257 151.988 123.859 151.988 131.012 156.11Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M102.129 158.354C102.054 158.439 99.552 161.076 100.212 164.866C100.848 168.496 105.924 171.549 107.691 172.283C106.8 171.93 105.935 171.515 105.103 171.04C99.1521 167.602 98.1455 162.383 102.129 158.354Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M133.985 168.796C134.06 168.71 136.563 166.077 135.902 162.283C135.267 158.657 130.191 155.604 128.424 154.87C129.316 155.22 130.181 155.634 131.012 156.11C136.991 159.548 137.969 164.766 133.985 168.796Z"
      fill="#F5F5F5"
    />
    <path
      d="M130.106 170.498C136.76 166.669 136.76 160.456 130.106 156.605C123.452 152.754 112.671 152.771 106.021 156.605C99.3706 160.438 99.3706 166.651 106.021 170.498C112.671 174.346 123.456 174.331 130.106 170.498Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M130.106 170.498C136.76 166.669 136.76 160.456 130.106 156.605C123.452 152.754 112.671 152.771 106.021 156.605C99.3706 160.438 99.3706 166.651 106.021 170.498C112.671 174.346 123.456 174.331 130.106 170.498Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M134.321 166.49C135.692 163.965 135.246 161.182 132.986 158.821C132.54 158.361 132.057 157.939 131.541 157.56C131.099 157.232 130.638 156.93 130.163 156.654C129.891 156.494 129.613 156.345 129.323 156.198C130.037 157.363 129.773 158.607 125.754 158.842C117.901 159.298 127.853 166.786 127.853 166.786C127.853 166.786 132.918 164.346 134.321 166.49Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M133.511 164.421C134.689 164.289 135.275 163.081 134.818 161.721C134.361 160.36 133.033 159.366 131.855 159.498C130.677 159.629 130.091 160.837 130.552 162.198C131.012 163.559 132.351 164.553 133.511 164.421Z"
      fill="white"
    />
    <path
      d="M121.096 170.659C120.71 167.752 107.966 159.697 107.966 159.697C107.966 159.697 105.142 158.564 106.078 156.594C105.608 156.862 105.154 157.156 104.718 157.474C104.431 157.679 104.154 157.897 103.889 158.13C100.095 161.407 100.091 165.711 103.889 168.995C104.146 169.216 104.425 169.437 104.718 169.651C105.107 169.932 105.528 170.206 105.981 170.47C106.435 170.734 106.863 170.958 107.331 171.182C107.687 171.357 108.087 171.521 108.476 171.674C108.866 171.827 109.265 171.973 109.672 172.105C110.914 172.508 112.185 172.813 113.474 173.017C113.756 173.063 114.045 173.103 114.331 173.142C115.592 173.302 116.861 173.382 118.133 173.38C119.687 173.377 121.239 173.25 122.773 172.999C122.773 172.999 120.731 172.572 121.096 170.659Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M121.096 170.659C120.71 167.752 107.966 159.697 107.966 159.697C107.966 159.697 105.142 158.564 106.078 156.594C105.608 156.862 105.154 157.156 104.718 157.474C104.431 157.679 104.154 157.897 103.889 158.13C100.095 161.407 100.091 165.711 103.889 168.995C104.146 169.216 104.425 169.437 104.718 169.651C105.107 169.932 105.528 170.206 105.981 170.47C106.435 170.734 106.863 170.958 107.331 171.182C107.687 171.357 108.087 171.521 108.476 171.674C108.866 171.827 109.265 171.973 109.672 172.105C110.914 172.508 112.185 172.813 113.474 173.017C113.756 173.063 114.045 173.103 114.331 173.142C115.592 173.302 116.861 173.382 118.133 173.38C119.687 173.377 121.239 173.25 122.773 172.999C122.773 172.999 120.731 172.572 121.096 170.659Z"
      fill="white"
    />
    <path
      d="M109.087 158.389C104.333 161.129 104.14 165.514 108.506 168.364C108.691 168.485 108.888 168.607 109.087 168.72C114.042 171.57 122.074 171.57 127.029 168.72C131.984 165.87 131.987 161.239 127.029 158.389C126.826 158.276 126.619 158.162 126.404 158.055C121.424 155.539 113.839 155.654 109.087 158.389Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M109.087 158.389C104.333 161.129 104.14 165.514 108.506 168.364C108.691 168.485 108.888 168.607 109.087 168.72C114.042 171.57 122.074 171.57 127.029 168.72C131.984 165.87 131.987 161.239 127.029 158.389C126.826 158.276 126.619 158.162 126.404 158.055C121.424 155.539 113.839 155.654 109.087 158.389Z"
      fill="#1D1D1D"
    />
    <path
      d="M118.065 170.548C124.764 170.548 130.195 167.419 130.195 163.559C130.195 159.698 124.764 156.569 118.065 156.569C111.365 156.569 105.935 159.698 105.935 163.559C105.935 167.419 111.365 170.548 118.065 170.548Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M118.065 170.548C124.764 170.548 130.195 167.419 130.195 163.559C130.195 159.698 124.764 156.569 118.065 156.569C111.365 156.569 105.935 159.698 105.935 163.559C105.935 167.419 111.365 170.548 118.065 170.548Z"
      fill="#1D1D1D"
    />
    <path
      d="M109.505 160.57C114.238 157.837 121.92 157.837 126.639 160.57C128.578 161.685 129.72 163.085 130.073 164.535C129.716 165.985 128.578 167.385 126.639 168.5C121.906 171.229 114.224 171.229 109.505 168.5C107.566 167.385 106.424 165.985 106.07 164.535C106.41 163.085 107.552 161.685 109.505 160.57Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M109.505 160.57C114.238 157.837 121.92 157.837 126.639 160.57C128.578 161.685 129.72 163.085 130.073 164.535C129.716 165.985 128.578 167.385 126.639 168.5C121.906 171.229 114.224 171.229 109.505 168.5C107.566 167.385 106.424 165.985 106.07 164.535C106.41 163.085 107.552 161.685 109.505 160.57Z"
      fill="#1D1D1D"
    />
    <path
      d="M125.7 165.654C125.7 165.418 125.7 164.457 125.7 164.228C125.7 163.637 125.286 163.06 124.404 162.543L124.372 162.526C122.744 161.589 121.063 161.71 119.563 162.087C118.928 162.248 118.321 162.444 117.778 162.643C117.149 162.879 116.507 163.078 115.855 163.238C115.554 163.308 115.245 163.337 114.937 163.324C114.674 163.309 114.416 163.241 114.18 163.124L114.241 163.081C114.298 163.042 114.362 163.003 114.43 162.964C114.593 162.87 114.763 162.787 114.937 162.714C115.361 162.528 115.817 162.426 116.28 162.415C116.743 162.404 117.204 162.484 117.636 162.65L119.189 161.086V159.661C117.817 159.195 116.339 159.14 114.937 159.505C114.476 159.626 114.025 159.782 113.588 159.971L112.003 159.063L110.146 160.132V161.557L110.782 161.913C110.612 162.16 110.517 162.451 110.51 162.75C110.51 162.989 110.51 163.943 110.51 164.175C110.51 164.766 110.939 165.34 111.835 165.857C112.613 166.311 113.493 166.563 114.394 166.59C114.562 166.59 114.751 166.59 114.905 166.59C115.846 166.521 116.772 166.321 117.657 165.995C117.843 165.935 118.014 165.874 118.193 165.81L116.629 167.378V168.803C117.594 169.167 118.623 169.332 119.654 169.288C120.685 169.243 121.696 168.991 122.626 168.546L124.161 169.43L126.017 168.361V166.936L125.378 166.58C125.578 166.311 125.69 165.988 125.7 165.654ZM121.773 165.532C121.634 165.613 121.49 165.684 121.341 165.746C120.375 166.158 119.285 166.175 118.307 165.792C119.76 165.279 120.973 164.831 122.009 165.383C121.947 165.44 121.873 165.493 121.787 165.543L121.773 165.532Z"
      fill="currentColor"
    />
    <path
      d="M119.567 162.091C118.931 162.251 118.325 162.447 117.782 162.647C117.153 162.883 116.51 163.082 115.858 163.242C115.558 163.312 115.249 163.341 114.94 163.327C114.63 163.312 114.327 163.222 114.059 163.064C113.463 162.707 113.377 162.216 114.244 161.639C114.302 161.599 114.366 161.56 114.434 161.521C114.598 161.427 114.767 161.343 114.94 161.268C115.364 161.082 115.821 160.98 116.284 160.97C116.747 160.96 117.208 161.04 117.639 161.208L119.192 159.644C117.821 159.177 116.343 159.122 114.94 159.487C114.48 159.608 114.029 159.764 113.591 159.954L112.003 159.063L110.146 160.132L111.685 161.019C110.125 162.202 110.093 163.431 111.849 164.439C112.627 164.894 113.507 165.148 114.409 165.176C114.576 165.176 114.765 165.176 114.919 165.176C115.86 165.108 116.786 164.909 117.671 164.585C119.456 163.99 120.884 163.288 122.094 163.979C122.901 164.442 122.676 165.026 121.794 165.536C121.655 165.616 121.511 165.688 121.362 165.75C120.875 165.958 120.351 166.068 119.821 166.071C119.291 166.075 118.765 165.973 118.275 165.771L116.643 167.41C117.609 167.773 118.638 167.938 119.669 167.893C120.699 167.848 121.71 167.595 122.64 167.15L124.175 168.037L126.032 166.968L124.497 166.081C126.039 164.891 126.217 163.587 124.415 162.536L124.382 162.519C122.748 161.582 121.066 161.703 119.567 162.091Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M119.569 162.091C118.934 162.251 118.327 162.447 117.784 162.647C117.155 162.883 116.513 163.082 115.86 163.242C115.56 163.312 115.251 163.341 114.943 163.327C114.632 163.312 114.329 163.222 114.061 163.064C113.465 162.707 113.379 162.216 114.247 161.639C114.304 161.599 114.368 161.56 114.436 161.521C114.6 161.427 114.769 161.343 114.943 161.268C115.366 161.082 115.823 160.98 116.286 160.97C116.749 160.96 117.21 161.04 117.641 161.208L119.194 159.644C117.823 159.177 116.345 159.122 114.943 159.487C114.482 159.608 114.031 159.764 113.593 159.954L112.005 159.063L110.148 160.132L111.687 161.019C110.127 162.202 110.095 163.431 111.851 164.439C112.629 164.894 113.509 165.148 114.411 165.176C114.579 165.176 114.768 165.176 114.921 165.176C115.862 165.108 116.788 164.909 117.674 164.585C119.458 163.99 120.886 163.288 122.096 163.979C122.903 164.442 122.678 165.026 121.797 165.536C121.657 165.616 121.513 165.688 121.365 165.75C120.877 165.958 120.353 166.068 119.823 166.071C119.293 166.075 118.767 165.973 118.277 165.771L116.645 167.41C117.611 167.773 118.64 167.938 119.671 167.893C120.701 167.848 121.712 167.595 122.643 167.15L124.178 168.037L126.034 166.968L124.499 166.081C126.041 164.891 126.219 163.587 124.417 162.536L124.385 162.519C122.75 161.582 121.068 161.703 119.569 162.091Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M110.149 160.132L112.024 159.284L113.562 159.961L112.006 159.063L110.149 160.132Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M114.698 159.551C116.021 159.285 117.388 159.332 118.689 159.686L117.618 161.211L119.171 159.647C117.726 159.155 116.164 159.121 114.698 159.551Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M124.377 162.518L124.409 162.536C126.211 163.605 126.033 164.891 124.491 166.081C125.858 164.595 125.612 163.558 124.134 162.7C122.656 161.841 120.75 161.855 119.575 162.08C121.067 161.702 122.749 161.581 124.377 162.518Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M124.49 166.081L125.668 166.986L124.169 168.037L126.025 166.968L124.49 166.081Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M121.356 165.757C120.44 166.218 119.382 166.309 118.4 166.013L116.644 167.41L118.275 165.778C118.764 165.979 119.288 166.081 119.818 166.077C120.346 166.073 120.869 165.964 121.356 165.757Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M115.83 163.235C115.117 163.509 114.117 163.463 113.607 163.024C113.096 162.586 113.414 162.07 114.217 161.642C113.349 162.209 113.435 162.711 114.031 163.067C114.299 163.225 114.602 163.316 114.913 163.331C115.222 163.341 115.53 163.308 115.83 163.235Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M111.675 161.022C111.543 161.14 110.426 162.176 110.918 163.217C111.443 164.325 113.082 165.037 114.402 165.179C113.501 165.151 112.621 164.898 111.843 164.442C110.083 163.441 110.115 162.212 111.675 161.022Z"
      fill="#F5F5F5"
    />
    <path
      d="M140.842 168.428C141.688 169.914 142.163 171.852 142.163 174.189C142.163 182.382 136.355 192.328 129.194 196.421C127.148 197.59 125.214 198.153 123.482 198.171L122.99 199.022L119.745 197.166C117.557 195.762 116.211 192.959 116.211 189.022C116.211 180.829 122.015 170.883 129.18 166.789C132.625 164.819 135.759 164.559 138.079 165.721L141.324 167.577L140.842 168.428Z"
      fill="currentColor"
    />
    <path
      opacity="0.15"
      d="M140.842 168.428C141.688 169.914 142.163 171.852 142.163 174.189C142.163 182.382 136.355 192.328 129.194 196.421C127.148 197.59 125.214 198.153 123.482 198.171L122.99 199.022L119.745 197.166C117.557 195.762 116.211 192.959 116.211 189.022C116.211 180.829 122.015 170.883 129.18 166.789C132.625 164.819 135.759 164.559 138.079 165.721L141.324 167.577L140.842 168.428Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M138.365 186.839C136.016 190.865 132.775 194.377 129.194 196.422C127.148 197.591 125.214 198.153 123.482 198.171L122.99 199.023L119.745 197.167C117.557 195.763 116.211 192.96 116.211 189.023C116.211 184.93 117.664 180.395 120.009 176.369L138.365 186.839Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.1"
      d="M131.868 194.576C131.028 195.262 130.133 195.879 129.194 196.422C127.148 197.59 125.214 198.153 123.482 198.171L122.99 199.022L119.745 197.166C117.557 195.763 116.211 192.959 116.211 189.023C116.216 187.948 116.309 186.876 116.489 185.816L131.868 194.576Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M141.885 177.409C142.064 176.35 142.157 175.278 142.163 174.203C142.163 171.866 141.688 169.928 140.842 168.443L141.339 167.595L138.094 165.739C135.773 164.563 132.639 164.823 129.194 166.808C128.255 167.35 127.361 167.967 126.521 168.653L141.885 177.409Z"
      fill="currentColor"
    />
    <path
      d="M132.168 198.121C125.007 202.214 119.199 198.897 119.199 190.711C119.199 183.547 123.644 175.036 129.551 170.273C129.63 170.209 129.708 170.145 129.791 170.084C130.542 169.492 131.335 168.955 132.165 168.478C138.137 165.065 143.166 166.804 144.659 172.226C144.976 173.42 145.131 174.652 145.119 175.888C145.137 184.074 139.329 194.027 132.168 198.121Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M132.168 198.121C125.007 202.214 119.199 198.897 119.199 190.711C119.199 183.547 123.644 175.036 129.551 170.273C129.63 170.209 129.708 170.145 129.791 170.084C130.542 169.492 131.335 168.955 132.165 168.478C138.137 165.065 143.166 166.804 144.659 172.226C144.976 173.42 145.131 174.652 145.119 175.888C145.137 184.074 139.329 194.027 132.168 198.121Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.5"
      d="M144.677 172.226C144.641 172.115 143.606 168.663 139.979 167.349C136.51 166.091 131.308 168.941 129.795 170.084C130.546 169.492 131.339 168.955 132.169 168.478C138.141 165.065 143.171 166.804 144.677 172.226Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.5"
      d="M119.66 194.373C119.696 194.484 120.731 197.936 124.358 199.25C127.828 200.508 133.029 197.658 134.542 196.514C133.791 197.107 132.998 197.644 132.168 198.121C126.196 201.534 121.167 199.795 119.66 194.373Z"
      fill="#F5F5F5"
    />
    <path
      d="M140.647 188.279C145.384 180.224 145.431 171.473 140.752 168.732C136.073 165.991 128.439 170.299 123.702 178.354C118.965 186.408 118.918 195.16 123.597 197.901C128.276 200.641 135.91 196.333 140.647 188.279Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M140.647 188.279C145.384 180.224 145.431 171.473 140.752 168.732C136.073 165.991 128.439 170.299 123.702 178.354C118.965 186.408 118.918 195.16 123.597 197.901C128.276 200.641 135.91 196.333 140.647 188.279Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M137.77 167.908C140.647 167.987 142.843 169.75 143.764 172.863C143.934 173.474 144.054 174.097 144.121 174.727C144.186 175.268 144.219 175.813 144.221 176.358C144.221 176.672 144.221 176.985 144.196 177.306C143.539 176.119 142.325 175.724 140.115 179.051C135.789 185.56 134.265 173.309 134.265 173.309C134.265 173.309 138.93 170.174 137.77 167.908Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M139.161 169.629C139.875 168.685 141.207 168.785 142.16 169.853C143.113 170.922 143.313 172.554 142.61 173.498C141.906 174.442 140.564 174.346 139.611 173.277C138.658 172.208 138.458 170.576 139.161 169.629Z"
      fill="white"
    />
    <path
      d="M127.529 177.189C129.863 178.97 130.481 193.896 130.481 193.896C130.481 193.896 130.049 196.882 132.23 197.056C131.768 197.326 131.291 197.569 130.802 197.786C130.47 197.936 130.145 198.064 129.821 198.175C125.076 199.803 121.332 197.669 120.382 192.778C120.318 192.446 120.264 192.101 120.229 191.745C120.175 191.249 120.148 190.75 120.146 190.252C120.146 189.757 120.168 189.251 120.211 188.745C120.243 188.339 120.293 187.926 120.357 187.512C120.421 187.099 120.493 186.689 120.578 186.276C120.852 185.011 121.224 183.768 121.692 182.561C121.792 182.293 121.899 182.03 122.01 181.766C122.502 180.604 123.069 179.475 123.706 178.386C124.488 177.049 125.378 175.778 126.365 174.584C126.365 174.584 125.683 176.551 127.529 177.189Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M127.529 177.189C129.863 178.97 130.481 193.896 130.481 193.896C130.481 193.896 130.049 196.882 132.23 197.056C131.768 197.326 131.291 197.569 130.802 197.786C130.47 197.936 130.145 198.064 129.821 198.175C125.076 199.803 121.332 197.669 120.382 192.778C120.318 192.446 120.264 192.101 120.229 191.745C120.175 191.249 120.148 190.75 120.146 190.252C120.146 189.757 120.168 189.251 120.211 188.745C120.243 188.339 120.293 187.926 120.357 187.512C120.421 187.099 120.493 186.689 120.578 186.276C120.852 185.011 121.224 183.768 121.692 182.561C121.792 182.293 121.899 182.03 122.01 181.766C122.502 180.604 123.069 179.475 123.706 178.386C124.488 177.049 125.378 175.778 126.365 174.584C126.365 174.584 125.683 176.551 127.529 177.189Z"
      fill="white"
    />
    <path
      d="M132.175 193.583C127.417 196.301 123.525 194.295 123.215 189.126C123.215 188.908 123.193 188.681 123.193 188.449C123.193 182.781 127.213 175.888 132.175 173.052C137.137 170.217 141.156 172.514 141.156 178.186C141.156 178.414 141.157 178.652 141.135 178.898C140.824 184.413 136.933 190.864 132.175 193.583Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M132.175 193.583C127.417 196.301 123.525 194.295 123.215 189.126C123.215 188.908 123.193 188.681 123.193 188.449C123.193 182.781 127.213 175.888 132.175 173.052C137.137 170.217 141.156 172.514 141.156 178.186C141.156 178.414 141.157 178.652 141.135 178.898C140.824 184.413 136.933 190.864 132.175 193.583Z"
      fill="#1D1D1D"
    />
    <path
      d="M132.18 193.123C127.439 195.834 123.595 193.636 123.595 188.217C123.595 182.799 127.439 176.209 132.18 173.501C136.921 170.794 140.747 172.988 140.747 178.407C140.747 183.825 136.921 190.416 132.18 193.123Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M132.18 193.123C127.439 195.834 123.595 193.636 123.595 188.217C123.595 182.799 127.439 176.209 132.18 173.501C136.921 170.794 140.747 172.988 140.747 178.407C140.747 183.825 136.921 190.416 132.18 193.123Z"
      fill="#1D1D1D"
    />
    <path
      d="M130.484 192.158C135.228 189.447 139.051 182.856 139.051 177.438C139.051 175.222 138.408 173.548 137.323 172.525C135.895 172.105 134.11 172.393 132.161 173.501C127.421 176.209 123.576 182.799 123.576 188.218C123.576 190.434 124.219 192.111 125.304 193.134C126.757 193.551 128.545 193.266 130.484 192.158Z"
      fill="currentColor"
    />
    <path
      opacity="0.35"
      d="M130.484 192.158C135.228 189.447 139.051 182.856 139.051 177.438C139.051 175.222 138.408 173.548 137.323 172.525C135.895 172.105 134.11 172.393 132.161 173.501C127.421 176.209 123.576 182.799 123.576 188.218C123.576 190.434 124.219 192.111 125.304 193.134C126.757 193.551 128.545 193.266 130.484 192.158Z"
      fill="#1D1D1D"
    />
    <path
      d="M134.467 181.146C134.079 180.948 133.636 180.884 133.207 180.965C132.743 181.035 132.285 181.14 131.836 181.278C131.479 181.378 131.172 181.463 130.897 181.524C130.897 180.84 131.301 180.07 132.211 179.479L132.407 179.358C132.558 179.271 132.716 179.194 132.878 179.13C132.918 179.112 132.957 179.105 132.996 179.091C133.71 178.852 134.124 179.144 134.256 179.828L136.38 177.937C136.334 177.675 136.235 177.426 136.089 177.204C135.943 176.982 135.753 176.793 135.531 176.647C135.32 176.526 134.581 176.102 134.367 175.984C134.057 175.803 133.702 175.713 133.343 175.724V174.973L132.075 174.242L130.233 175.311V177.092H130.216C128.431 178.41 127.36 180.241 127.338 182.272C127.338 183.27 127.578 183.832 127.967 184.125C128.045 184.185 129.084 184.744 129.234 184.858C129.312 184.918 129.397 184.969 129.487 185.008C130.009 185.222 130.694 185.104 131.361 184.919C131.872 184.784 132.375 184.616 132.789 184.52C132.789 184.541 132.789 184.555 132.789 184.577C132.789 185.507 132.172 186.312 131.293 186.818C131.167 186.894 131.035 186.959 130.897 187.014C130.819 187.043 130.739 187.069 130.658 187.088C130.638 187.018 130.624 186.947 130.615 186.875L129.348 186.162L127.146 188.097C127.274 188.859 127.563 189.326 127.956 189.575C128.188 189.728 129.052 190.198 129.241 190.316C129.569 190.508 129.952 190.586 130.33 190.537V191.249L131.593 191.98L133.439 190.911V189.13C135.224 187.79 136.445 185.899 136.466 183.786V183.75C136.466 182.682 136.17 182.123 135.717 181.859L134.467 181.146Z"
      fill="currentColor"
    />
    <path
      d="M134.492 181.684C134.03 181.755 133.573 181.858 133.125 181.994C132.649 182.155 132.157 182.266 131.658 182.325C131.47 182.347 131.282 182.298 131.13 182.186C131.049 182.099 130.989 181.996 130.953 181.883C130.917 181.771 130.907 181.651 130.923 181.534C130.923 180.843 131.315 180.063 132.236 179.464L132.433 179.343C132.584 179.256 132.741 179.179 132.904 179.115C133.714 178.795 134.146 179.087 134.289 179.81L136.413 177.918C136.134 176.814 135.463 176.319 134.442 176.444C134.074 176.5 133.714 176.602 133.371 176.75V174.969L131.526 176.037V177.819H131.508C129.723 179.137 128.634 180.968 128.627 182.998C128.627 183.996 128.87 184.559 129.259 184.851C129.337 184.911 129.422 184.961 129.513 185C130.034 185.214 130.719 185.097 131.387 184.911C132.782 184.555 134.103 183.914 134.096 185.3C134.096 186.229 133.478 187.035 132.604 187.54C132.476 187.617 132.342 187.682 132.204 187.736C131.23 188.132 130.755 187.615 130.658 186.867L128.424 188.83C128.752 190.804 130.18 190.804 131.611 190.195V191.976L133.457 190.907V189.126C135.242 187.786 136.463 185.895 136.484 183.782V183.746C136.477 181.873 135.538 181.566 134.492 181.684Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M134.492 181.684C134.03 181.755 133.573 181.858 133.125 181.994C132.649 182.155 132.157 182.266 131.658 182.325C131.47 182.347 131.282 182.298 131.13 182.186C131.049 182.099 130.989 181.996 130.953 181.883C130.917 181.771 130.907 181.651 130.923 181.534C130.923 180.843 131.315 180.063 132.236 179.464L132.433 179.343C132.584 179.256 132.741 179.179 132.904 179.115C133.714 178.795 134.146 179.087 134.289 179.81L136.413 177.918C136.134 176.814 135.463 176.319 134.442 176.444C134.074 176.5 133.714 176.602 133.371 176.75V174.969L131.526 176.037V177.819H131.508C129.723 179.137 128.634 180.968 128.627 182.998C128.627 183.996 128.87 184.559 129.259 184.851C129.337 184.911 129.422 184.961 129.513 185C130.034 185.214 130.719 185.097 131.387 184.911C132.782 184.555 134.103 183.914 134.096 185.3C134.096 186.229 133.478 187.035 132.604 187.54C132.476 187.617 132.342 187.682 132.204 187.736C131.23 188.132 130.755 187.615 130.658 186.867L128.424 188.83C128.752 190.804 130.18 190.804 131.611 190.195V191.976L133.457 190.907V189.126C135.242 187.786 136.463 185.895 136.484 183.782V183.746C136.477 181.873 135.538 181.566 134.492 181.684Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.45"
      d="M131.5 176.027L133.16 175.304L133.338 176.761L133.342 174.966L131.5 176.027Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M134.253 176.483C134.671 176.468 135.083 176.594 135.419 176.842C135.757 177.09 136 177.444 136.109 177.848L134.257 179.821L136.377 177.929C136.016 176.654 135.259 176.28 134.253 176.483Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M136.47 183.757V183.793C136.449 185.902 135.239 187.793 133.443 189.137C135.407 187.305 136.181 185.677 136.188 183.96C136.195 182.243 135.246 181.705 134.475 181.698C135.539 181.566 136.477 181.873 136.47 183.757Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M133.443 189.133L133.243 190.829L131.594 191.969L133.439 190.907L133.443 189.133Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M132.175 187.74C130.705 188.421 130.491 187.263 130.491 187.263L128.424 188.83L130.641 186.86C130.726 187.608 131.201 188.125 132.175 187.74Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.636 182.336C131.051 182.543 130.594 182.176 130.719 181.371C130.802 180.96 130.981 180.574 131.241 180.245C131.5 179.916 131.834 179.652 132.215 179.476C131.294 180.074 130.901 180.854 130.901 181.545C130.885 181.662 130.895 181.782 130.931 181.894C130.967 182.007 131.027 182.11 131.108 182.197C131.26 182.309 131.449 182.358 131.636 182.336Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.45"
      d="M131.481 177.815C130.495 178.649 129.716 179.701 129.207 180.886C128.493 182.699 128.703 184.245 129.231 184.844C128.842 184.552 128.596 183.989 128.6 182.991C128.607 180.971 129.685 179.133 131.481 177.815Z"
      fill="#F5F5F5"
    />
    <path
      d="M140.982 170.413L143.784 172.539L140.521 171.203L137.259 176.269L140.061 170.936L137.259 168.81L140.521 170.146L143.784 165.08L140.982 170.413Z"
      fill="white"
    />
  </svg>
);
