import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import Container from "../../components/layout/Container/Container";
import Button from "../../components/ui/Button/Button";

const ErrorBoundary = () => {
  return (
    <div className="App">
      <Header />
      <main className="main">
        <Container>
          <section className="home">
            <h1 className="title">Ой! Что-то пошло не так!</h1>
            <p style={{marginBottom: '25px'}}>
              Страница не найдена или ошибка сервера. Проверьте правильность
              адреса страницы
            </p>
            
            <Button isLink={"/"} buttonType="secondary">Вернуться на главную</Button>
          </section>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default ErrorBoundary;
