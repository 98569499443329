import classNames from "classnames";
import { useState } from "react";
import Popup from "../Popup/Popup";
import "./index.scss";

const Input = ({
  size = "default",
  placeholder = 0,
  withLabel = false,
  withNotification = false,
  value = "",
  outerValue = null,
  addClass,
  labelSize = "default",
  ref,
  withError = false,
  disabled = false,
  onChange,
  ...arg
}) => {
  const [inputValue, setValue] = useState(value);
  const inputClass = classNames({
    input: true,
    [`input_${size}`]: true,
    [`${addClass}`]: addClass,
  });

  const labelClass = classNames({
    "input-wrapper__label": true,
    "body-smaller": labelSize === "small",
  });

  return (
    <label className="input-wrapper">
      {withLabel && <span className={labelClass}>{withLabel}</span>}

      <input
        className={inputClass}
        value={outerValue ? outerValue : inputValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange ? onChange : (evt) => setValue(evt.target.value)}
        {...arg}
        ref={ref}
      />
      {withError && <span className="input-error">{withError}</span>}

      {withNotification && (
        <Popup text={"На новый адрес отправлено письмо для подтверждения."} />
      )}
    </label>
  );
};

export default Input;
