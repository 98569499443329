import CardHorizontalFavorites from "./CardHorizontalFavorites";
import "./index.scss";


const Favorites = () => {

  return (
    <section className="my-tokens">
      <CardHorizontalFavorites />
      <CardHorizontalFavorites />
    </section>
  );
};

export default Favorites;
