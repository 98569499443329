import Switcher from "../../../ui/Switcher/Switcher";
import "./Notifications.scss";

const Notifications = () => {
  return (
    <section className="notifications">
      <div className="notifications__group">
        <div className="notifications__block notifications__block_master">
          <div className="notifications__row">
            <span>Получать все уведомления</span>
            <Switcher />
          </div>
        </div>
        <div className="notifications__block">
          <div className="notifications__row">
            <span>Получать уведомления о выпуске новых токенов от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления об изменении цены токена от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления о статусах ЖК</span>
            <Switcher />
          </div>
        </div>
      </div>

      <div className="notifications__group">
        <div className="notifications__block notifications__block_master">
          <div className="notifications__row">
            <span>Получать все уведомления</span>
            <Switcher />
          </div>
        </div>
        <div className="notifications__block">
          <div className="notifications__row">
            <span>Получать уведомления о выпуске новых токенов от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления об изменении цены токена от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления о статусах ЖК</span>
            <Switcher />
          </div>
        </div>
      </div>
      <div className="notifications__group">
        <div className="notifications__block notifications__block_master">
          <div className="notifications__row">
            <span>Получать все уведомления</span>
            <Switcher />
          </div>
        </div>
        <div className="notifications__block">
          <div className="notifications__row">
            <span>Получать уведомления о выпуске новых токенов от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления об изменении цены токена от ЖК</span>
            <Switcher />
          </div>
          <div className="notifications__row">
            <span>Получать уведомления о статусах ЖК</span>
            <Switcher />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notifications;
