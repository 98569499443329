import Button from "../Button/Button";

const Label = ({ labelType = "primary", size = "xs", children, ...arg }) => {
  return (
    <Button size={size} isLabel buttonType={labelType} {...arg}>
      {children}
    </Button>
  );
};

export default Label;
