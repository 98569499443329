export const accountLinks = [
  {
    link: "exchange",
    label: "Мой метр",
  },
  {
    link: "tokens",
    label: "Мои токены",
  },
  {
    link: "sells",
    label: "Мои продажи",
  },
  {
    link: "history",
    label: "История",
  },
  {
    link: "favorites",
    label: "Избранные ЖК",
  },
  {
    link: "notifications",
    label: "Уведомления",
  },
];

export const settingsLinks = [
  {
    link: "personal",
    label: "Персональные данные",
  },
  {
    link: "notifications",
    label: "Уведомления",
  },
  {
    link: "support",
    label: "Поддержка",
  },
  {
    link: "requisites",
    label: "Реквизиты",
  },
];