import classNames from "classnames";
import Countdown from "react-countdown";
import lightningSvg from "../../../assets/img/icons/lightning.svg";
import { TimerSvg } from "./Icons";
import "./index.scss";

const TimerBlock = ({ isSmall = false, isLarge = false }) => {
  const timerClass = classNames({
    "timer-block": true,
    [`timer-block_sm`]: isSmall,
    [`timer-block_lg`]: isLarge,
  });

  const titleClass = classNames({
    "timer-block__title": true,
    [`base-14 medium`]: !isSmall && !isLarge,
    [`body-least`]: isSmall,
    [`base-16 medium`]: isLarge,
  });

  const valueClass = classNames({
    "timer-block__key bold": true,
    [`base-24`]: !isSmall,
    [`base-18`]: isSmall,
  });

  const renderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted; // Добавляет формат 00:00 вместо 0:0

    if (completed) {
      // Render a completed state
      console.log("timer finished");
      return;
    } else {
      // Render a countdown
      return (
        <div className="timer-block__container">
          <div className="timer-block__col">
            <span className={valueClass}>{days}</span>
            <span className="timer-block__value text-gray body-least">
              дней
            </span>
          </div>
          <span className="timer-block__separator h7 text-gray">:</span>
          <div className="timer-block__col">
            <span className={valueClass}>{hours}</span>
            <span className="timer-block__value text-gray body-least">
              часов
            </span>
          </div>
          <span className="timer-block__separator h7 text-gray">:</span>
          <div className="timer-block__col">
            <span className={valueClass}>{minutes}</span>
            <span className="timer-block__value text-gray body-least">мин</span>
          </div>
          <span className="timer-block__separator h7 text-gray">:</span>
          <div className="timer-block__col">
            <span className={valueClass}>{seconds}</span>
            <span className="timer-block__value text-gray body-least">сек</span>
          </div>
        </div>
      );
    }
  };

  return (
    <article className={timerClass}>
      <span className={titleClass}>
        {isSmall && <img src={lightningSvg} alt="" />}
        До повышения цены токена осталось:
      </span>
      <div className="timer-block__box">
        <Countdown date={Date.now() + 10000000000} renderer={renderer} />
      </div>

      {isLarge && <TimerSvg />}
    </article>
  );
};

export default TimerBlock;
