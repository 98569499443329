import PersonalEditForm from "../../../forms/PersonalEditForm/PersonalEditForm";
import RegisterForm from "../../../forms/RegisterForm/RegisterForm";
import "./PersonalInfo.scss";

const PersonalInfo = () => {
  return (
    <section className="personal">
      <PersonalEditForm />
    </section>
  );
};

export default PersonalInfo;
