import { useEffect, useState } from "react";
import Input from "../../ui/Input/Input";
import CodeField from "../../ui/CodeField/CodeField";
import Countdown from "react-countdown";
import Button from "../../ui/Button/Button";

const StepLogin = ({
  codeFilled,
  setCodeFilled,
  changePhoneRequest = false,
}) => {
  const [numberValue, setNumberValue] = useState(null);

  const [codeRequest, setCodeRequest] = useState(false);
  const [sendAgain, setSendAgain] = useState(false);

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      setSendAgain(false);
    } else {
      // Render a countdown
      return (
        <span>
          Отправить код повторно через: <b>{seconds}</b> сек.
        </span>
      );
    }
  };

  return (
    <>
      {!changePhoneRequest && (
        <div className="modal__info text-block">
          <p>
            Зарегистрируйтесь по номеру телефона и получите доступ ко всем
            преимуществам личного кабинета.
          </p>
          <p>
            У нас нет паролей — вход осуществляется по номеру телефона, на
            который мы присылаем СМС c кодом доступа.
          </p>
        </div>
      )}

      {!codeRequest ? (
        <Input
          // value={amount === 0 ? null : amount}
          type="number"
          withLabel="Введите номер телефона"
          labelSize={"small"}
          outerValue={numberValue}
          onChange={(evt) => {
            setNumberValue(Number(evt.target.value).toString());
            console.log(111111);
          }}
          addClass={"input_lg"}
          placeholder="+7 999 999-99-99"
        />
      ) : (
        <CodeField codeFilled={codeFilled} setCodeFilled={setCodeFilled} />
      )}

      {
        // test disabled
      }

      {!codeRequest ? (
        <Button
          addClass="modal__btn"
          isFull
          disabled={!numberValue || numberValue == 0}
          onClick={() => setCodeRequest(true)}
        >
          Получить код
        </Button>
      ) : (
        <Button
          addClass="modal__btn"
          isFull
          disabled={sendAgain}
          onClick={() => setSendAgain(true)}
        >
          {!sendAgain ? (
            <>Отправить код повторно</>
          ) : (
            <Countdown date={Date.now() + 10000} renderer={renderer} />
          )}
        </Button>
      )}

      <p className="agreement text-gray">
        Нажимая кнопку «Получить код», вы подтверждаете своё согласие на{" "}
        <a className="link link_blue" href="#">
          обработку персональных данных
        </a>{" "}
        и принимаю условия{" "}
        <a className="link link_blue" href="#">
          Политики конфиденциальности
        </a>
      </p>
    </>
  );
};

export default StepLogin;
