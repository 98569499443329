const BellSvg = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 0C6.89544 0 6.00001 0.89543 6.00001 2V2.34141C3.66962 3.16508 2.00001 5.38756 2.00001 8L2.00001 9.5L2.00001 11.5996L0.382537 13.8237C-0.0599155 14.432 -0.123575 15.2372 0.217778 15.9076C0.559133 16.5779 1.24776 17 2.00001 17H5.00001C5.00001 18.6569 6.34315 20 8.00001 20C9.65686 20 11 18.6569 11 17H14C14.7523 17 15.4409 16.5779 15.7822 15.9076C16.1236 15.2372 16.0599 14.432 15.6175 13.8237L14 11.5996L14 9.5V8C14 5.38756 12.3304 3.16508 10 2.34141V2C10 0.895431 9.10458 0 8.00001 0ZM9.00119 15H9.00001H7.00001L4.473 15H2.00001L3.45455 13L3.61748 12.776C3.86609 12.4341 4.00001 12.0223 4.00001 11.5996V9.5V8C4.00001 5.79086 5.79087 4 8.00001 4C10.2091 4 12 5.79086 12 8L12 9.5V11.5996C12 12.0223 12.1339 12.4341 12.3825 12.776L12.5455 13L14 15H11.527H9.00119ZM8.00001 18C7.44772 18 7.00001 17.5523 7.00001 17H9.00001C9.00001 17.5523 8.55229 18 8.00001 18Z"
        fill="currentColor"
      />
    </svg>
  );
};

const UserSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 12.1455 2.8446 14.0938 4.21946 15.5304C4.60042 14.2897 5.45113 13.2545 6.56411 12.6325C5.60091 11.7211 5 10.4307 5 9C5 6.23858 7.23858 4 10 4C12.7614 4 15 6.23858 15 9C15 10.4307 14.3991 11.7211 13.4359 12.6325C14.5489 13.2545 15.3996 14.2897 15.7805 15.5304C17.1554 14.0938 18 12.1455 18 10C18 5.58172 14.4183 2 10 2ZM13.9992 16.9302C13.9621 15.3056 12.6335 14 11 14H10H9C7.36648 14 6.03789 15.3056 6.0008 16.9302C7.17731 17.6106 8.54318 18 10 18C11.4568 18 12.8227 17.6106 13.9992 16.9302ZM10 12C11.6569 12 13 10.6569 13 9C13 7.34315 11.6569 6 10 6C8.34315 6 7 7.34315 7 9C7 10.6569 8.34315 12 10 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

const DotsSvg = () => {
  return (
    <svg
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-8.74228e-08 16C-3.91405e-08 17.1046 0.895431 18 2 18C3.10457 18 4 17.1046 4 16C4 14.8954 3.10457 14 2 14C0.89543 14 -1.35705e-07 14.8954 -8.74228e-08 16ZM-3.93402e-07 9C-3.4512e-07 10.1046 0.89543 11 2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7C0.89543 7 -4.41685e-07 7.89543 -3.93402e-07 9ZM2 4C0.89543 4 -6.511e-07 3.10457 -6.99382e-07 2C-7.47664e-07 0.895432 0.89543 -3.91405e-08 2 -8.74228e-08C3.10457 -1.35705e-07 4 0.895431 4 2C4 3.10457 3.10457 4 2 4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export {BellSvg, UserSvg, DotsSvg};
