import classNames from "classnames";
import qrCodeImg from "../../../assets/img/QR.png";
import "./index.scss";

const QrCode = () => {
  const qrCodeClass = classNames({
    "qr-code": true,
  });


  return (
    <div className={qrCodeClass}>
      <img src={qrCodeImg} alt="" />
    </div>
  );
};

export default QrCode;
