const EditIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80354 0.449114L0.776101 9.47656C-0.258697 10.5114 -0.258703 12.1891 0.776099 13.2239C1.8109 14.2587 3.48864 14.2587 4.52344 13.2239L13.5509 4.19646C14.1497 3.59764 14.1497 2.62676 13.5509 2.02794L11.9721 0.449114C11.3732 -0.149705 10.4024 -0.149704 9.80354 0.449114ZM8.2056 4.01725L10.7886 1.43421C10.8434 1.37945 10.9322 1.37945 10.987 1.43421L12.5658 3.01304C12.6206 3.0678 12.6206 3.15659 12.5658 3.21136L9.98275 5.7944L8.2056 4.01725ZM7.22051 5.00235L1.7612 10.4617C1.27045 10.9524 1.27045 11.7481 1.7612 12.2388C2.25194 12.7295 3.0476 12.7295 3.53834 12.2388L8.99765 6.77949L7.22051 5.00235Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;