import { BurgerSvg } from "./Icons";

const Burger = ({setMobileMenuShown}) => {
  return (
    <button type="button" className="burger" onClick={() => setMobileMenuShown(true)}>
      <BurgerSvg />
    </button>
  );
};

export default Burger;