import JkInfo from "../../components/content/JkInfo/JkInfo";
import JkStatus from "../../components/content/JkStatus/JkStatus";
import ShowmoreBtn from "../../components/content/ShowmoreBtn/ShowmoreBtn";
import TokenHistoryTabs from "../../components/content/TokenHistoryTabs/TokenHistoryTabs";
import ApartmentCard from "../../components/ui/ApartmentCard/ApartmentCard";
import Banner from "../../components/ui/Banner/Banner";
import CardMain from "../../components/ui/Card/CardMain";
import ComparisonBlock from "../../components/ui/ComparisonBlock/ComparisonBlock";
import SectionTitle from "../../components/ui/SectionTitle/SectionTitle";
import TimerBlock from "../../components/ui/TimerBlock/TimerBlock";
import "./index.scss";

const JkPage = () => {
  return (
    <section className="home jk">
      <div className="jk__title title">
        <span>ЖК «Аист»</span>
        <span className="jk__sub">СК «Красная стрела»</span>
      </div>

      <div className="home__slider">
        <CardMain buyTokens />
      </div>

      <div className="jk__info-grid">
        <JkInfo />

        <div className="jk__info-tokens">
          <TimerBlock isLarge />
          <ComparisonBlock />
        </div>
      </div>

      <SectionTitle>Список токенизированых квартир</SectionTitle>
      <div className="jk__grid">
        <ApartmentCard />
        <ApartmentCard />
        <ApartmentCard />
      </div>
      <ShowmoreBtn />

      <Banner addClass="jk__banner" />

      <JkStatus />

      <TokenHistoryTabs />
    </section>
  );
};

export default JkPage;
