import classNames from "classnames";
import Button from "../Button/Button";
import Input from "../Input/Input";
import CloseButton from "../CloseButton/CloseButton";
import { useState } from "react";
import "./index.scss";

const PurchaseCard = ({
  isHorizontal,
  isMySells,
  addClass,
  isOpened,
  setIsOpened,
  buttonText = "Купить токены",
}) => {
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const enableToBuy = () => price > 0 && amount > 0;

  const purchaseCardClass = classNames({
    "purchase-card": true,
    "purchase-card_h": isHorizontal,
    "purchase-card_my-sells": isMySells,
    "purchase-card_opened": isOpened,
    [`${addClass}`]: addClass,
  });

  return (
    <article className={purchaseCardClass}>
      <CloseButton
        addClass="purchase-card__close-btn"
        onClick={() => setIsOpened(false)}
      />

      <div className="purchase-card__body">
        {!isMySells && (
          <div className="purchase-card__item purchase-card__item_token">
            <span className="purchase-sell-info__key">Цена токена</span>
            <span className="purchase-sell-info__value">
              1 токен = 185 273 ₽
            </span>
            {isHorizontal ? (
              <span className="purchase-card__descr body-smaller">
                Рыночная цена на данный момент
              </span>
            ) : (
              <span className="purchase-card__descr base-14">
                От застройщика
              </span>
            )}
          </div>
        )}

        <div className="purchase-card__item purchase-card__item_count">
          <span className="purchase-sell-info__key">Количество токенов</span>
          <Input
            outerValue={amount === 0 ? 0 : amount}
            type="number"
            min={0}
            withLabel="Введите количество"
            labelSize={"small"}
            onChange={(evt) => setAmount(Number(evt.target.value).toString())}
            addClass={amount > 0 ? null : "text-gray"}
          />
          <span className="purchase-card__descr base-12">
            Всего доступно 3 тыс. токенов
          </span>
        </div>

        <div className="purchase-card__item purchase-card__item_price">
          <span className="purchase-sell-info__key">Стоимость</span>
          <Input
            outerValue={price === 0 ? 0 : price}
            type="number"
            min={50000}
            withLabel={
              isMySells
                ? "Введите цену для продажи"
                : "Введите сумму для покупки"
            }
            labelSize={"small"}
            onChange={(evt) => setPrice(Number(evt.target.value).toString())}
            addClass={price > 0 ? null : "text-gray"}
          />
          <span className="purchase-card__descr base-12">
            {isMySells ? (
              `Рекомендованная цена  100 тыс. ₽ за 1 токен`
            ) : (
              <>
                Минимальная сумма ввода <b>50</b> тыс. ₽
              </>
            )}
          </span>
        </div>
      </div>

      {
        <Button
          addClass="purchase-card__btn"
          buttonType={enableToBuy() ? "primary" : "secondary"}
          size={isHorizontal ? "xs" : "s"}
          disabled={!enableToBuy()}
        >
          {buttonText}
        </Button>
      }
    </article>
  );
};

export default PurchaseCard;
