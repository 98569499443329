import FeedbackForm from "../../../forms/FeedbackForm/FeedbackForm";
import SectionTitle from "../../../ui/SectionTitle/SectionTitle";
import "./Requisites.scss";

const Requisites = () => {
  return (
    <section className="requisites">
      Реквизиты
    </section>
  );
};

export default Requisites;
