import classNames from "classnames";
import Container from "../../Container/Container";
import "./index.scss";
import Logo from "../../../ui/Logo/Logo";
import UserMenu from "../../../ui/UserMenu/UserMenu";
import { Link } from "react-router-dom";
import useMobile from "../../../hooks/useMobile";

const BottomBar = () => {
  
  const bottombarClass = classNames({
    "bottombar": true,
  });

  return (
    <section className={bottombarClass}>
      <Container>
        <div className="bottombar__block">
          <Logo />
          <ul className="bottombar__nav">
            <li>
              <Link to={'/'} className="link">
                Новостройки
              </Link>
            </li>
            <li>
              <Link to={'/user/exchange'} className="link">
                Мой метр
              </Link>
            </li>
          </ul>

          <UserMenu />
        </div>
      </Container>
    </section>
  );
};

export default BottomBar;
