import { Collapse } from "react-collapse";
import classNames from "classnames";
import "./index.scss";

const AppCollapse = ({ isOpened, addClass, children }) => {
  const collapseClass = classNames({
    "collapse": true,
    [`${addClass}`]: true,
  });

  return (
    <div className={collapseClass}>
      <Collapse isOpened={isOpened}>{children}</Collapse>
    </div>
  );
};

export default AppCollapse;
