import classNames from "classnames";
import Label from "../Label/Label";
import TimerBlock from "../TimerBlock/TimerBlock";
import Button from "../Button/Button";
import Slider from "../Slider/Slider";
import { useState } from "react";
import PurchaseCard from "../PurchaseCard/PurchaseCard";

const CardMain = ({ buyTokens }) => {
  const [tokenCard, setTokenCard] = useState(false);

  const cardClass = classNames({
    "card card_main": true,
  });

  const tokenCardClass = classNames({
    "card__token-card": true,
    "card__token-card_shown": tokenCard,
  });

  return (
    <article className={cardClass}>
      <div className="card__wrapper">
        <div className="card__labels-wrapper">
          <div className="card__labels-list">
            <Label labelType="secondary">Старт продаж</Label>
            <Label labelType="secondary">1 токен = 1 м2</Label>
          </div>

          <div className="card__labels-list">
            <Label labelType="primary">Токенов осталось: 432,23</Label>
            <Label labelType="primary">Построено 25%</Label>
          </div>
        </div>

        <div className={tokenCardClass}>
          <PurchaseCard setIsOpened={setTokenCard} />
        </div>

        {/* <img className="card__img" src={mocImg} alt="" /> */}

        <Slider />

        <div className="card__timer-block">
          {!buyTokens && <TimerBlock isSmall />}
        </div>
      </div>

      <div className="card__body">
        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__key">ЖК «Аист»</span>
            <p className="sell-info__value base-14 text-gray">
              СК «Красная стрела»
            </p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__key">Санкт-Петербург</span>
            <p className="sell-info__value base-14 text-gray">
              Красносельский район
            </p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__key">
              Сдается в I квартале 2025 года
            </span>
            <p className="sell-info__value base-14 text-gray">
              Красносельский район
            </p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__key">1 токен = 185 273 ₽</span>
            <p className="sell-info__value base-14 text-gray">От застройщика</p>
          </div>
        </div>

        {!buyTokens ? (
          <Button>Подробнее</Button>
        ) : (
          <Button onClick={() => setTokenCard(true)} disabled={tokenCard}>
            Купить токены
          </Button>
        )}
      </div>
    </article>
  );
};

export default CardMain;
