import Button from "../ui/Button/Button";
import CloseButton from "../ui/CloseButton/CloseButton";
import { useState } from "react";
import "./index.scss";
const WantToBuyModal = ({ closeModal }) => {
  const [requestStatus, setRequestStatus] = useState(false);

  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />

      {!requestStatus && (
        <>
          <span className="modal__title">ЖК «Аист»</span>

          <div className="sell-info">
            <div className="sell-info__col">
              <span className="sell-info__title">Продам</span>
              <span className="sell-info__key">ЖК «Аист»</span>
              <p className="sell-info__value">
                СК «Красная стрела»
              </p>

              <span className="sell-info__key">
                Санкт-Петербург
              </span>
              <p className="sell-info__value">
                Красносельский район
              </p>

              <span className="sell-info__key">
                1 токен ≈ 185 273 ₽
              </span>
              <p className="sell-info__value">
                Рыночная цена на данный момент
              </p>
            </div>

            <div className="sell-info__col">
              <span className="sell-info__title">Количество</span>
              <span className="sell-info__key">2 токена</span>
              <p className="sell-info__value">
                Цифрового метра
              </p>
            </div>

            <div className="sell-info__col">
              <span className="sell-info__title">Стоимость</span>
              <span className="sell-info__key">300 000 ₽</span>
              <p className="sell-info__value">
                От пользователя
              </p>

              <span className="sell-info__key">150 000 ₽</span>
              <p className="sell-info__value">
                Стоимость за 1 м2
              </p>
            </div>
          </div>
        </>
      )}

      <div
        className="modal__info text-block"
        style={requestStatus ? { marginBottom: 0 } : {}}
      >
        {!requestStatus ? (
          <>
            <p>
              Пользователь хочет купить у вас токены. Подтвердите согласие в
              течение <b>1 часа 53 минуты.</b>
            </p>

            <p>
              Перед подтверждением проверьте правильность оставленных вами
              реквизитов для перевода.
            </p>

            <p>
              <a href="#" className="link link_blue">
                Реквизиты
              </a>
            </p>
          </>
        ) : (
          <p>
            Ожидайте оплату от пользователя. Как только он оплатит, в карточке
            «Мои продажи» обновится статус на «Оплачено». Деньги придут в
            течении суток.
          </p>
        )}
      </div>

      {!requestStatus ? (
        <div className="modal__btns-group">
          <Button
            addClass="modal__btn"
            isFull
            onClick={() => setRequestStatus(true)}
          >
            Подтвердить
          </Button>

          <Button
            addClass="modal__btn"
            buttonType="secondary"
            isFull
            onClick={closeModal}
          >
            Отказаться
          </Button>
        </div>
      ) : (
        <Button addClass="modal__btn" isFull onClick={closeModal}>
          Мои продажи
        </Button>
      )}
    </div>
  );
};

export default WantToBuyModal;
