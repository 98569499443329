import Modal from "react-modal";
import { useState } from "react";

const useModal = () => {
  Modal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  return [modalIsOpen, openModal, closeModal];
};

export default useModal;
