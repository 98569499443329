import classNames from "classnames";
import Button from "../Button/Button";
import Label from "../Label/Label";
import mocImg from "../../../assets/img/card-horizontal.jpg";
import LabelRequest from "../Label/LabelRequest";

const CardHorizontal = ({ openModal }) => {
  const cardClass = classNames({
    "card card_h": true,
  });

  return (
    <article className={cardClass}>
      <img className="card__img" src={mocImg} alt="" />

      <div className="card__body">
        <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
          <Label labelType="tertiary">ID 42424242</Label>
          <LabelRequest />
        </div>

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Продам</span>
            <span className="sell-info__key">ЖК «Аист»</span>
            <p className="sell-info__value">СК «Красная стрела»</p>

            <span className="sell-info__key">Санкт-Петербург</span>
            <p className="sell-info__value">Красносельский район</p>

            <span className="sell-info__key">1 токен ≈ 185 273 ₽</span>
            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">2 токена</span>
            <p className="sell-info__value">Цифрового метра</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Стоимость</span>
            <span className="sell-info__key">300 000 ₽</span>
            <p className="sell-info__value">От пользователя</p>

            <span className="sell-info__key">150 000 ₽</span>
            <p className="sell-info__value">Стоимость за 1 м2</p>
          </div>
        </div>

        <Button size={"xs"} addClass="card__buy-btn" onClick={openModal}>
          Купить токен
        </Button>
      </div>
    </article>
  );
};

export default CardHorizontal;
