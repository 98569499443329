import CardHorizontalToken from "./CardHorizontalToken";
import "./index.scss";

const MyTokens = () => {
  return (
    <section className="my-tokens">
      <CardHorizontalToken />
      <CardHorizontalToken />
      <CardHorizontalToken />
      <CardHorizontalToken />
      <CardHorizontalToken />
      <CardHorizontalToken />
    </section>
  );
};

export default MyTokens;