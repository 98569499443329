import classNames from "classnames";
import FooterTop from "./FooterTop";
import FooterBottom from "./FooterBottom";
import "./index.scss";
import Container from "../Container/Container";

const Footer = () => {
  const headerClass = classNames({
    footer: true,
  });

  return (
    <footer className={headerClass}>
      <Container>
        <FooterTop />
        <FooterBottom />
      </Container>
    </footer>
  );
};

export default Footer;
