import classNames from "classnames";
import "./index.scss";

const PriceTableExtended = () => {
  const priceClass = classNames({
    "table": true,
  });

  return (
    <article className={priceClass}>
      <div className="table__col">
        <span className="table__key">Дата</span>
        <span className="table__value">24 июня 2023 года</span>
        <span className="table__value">12 мая 2023 года</span>
        <span className="table__value">24 марта 2023 года</span>
      </div>

      <div className="table__col">
        <span className="table__key">Количество м2</span>
        <span className="table__value">123,32  м2</span>
        <span className="table__value">500,23 м2</span>
        <span className="table__value">500,23 м2</span>
      </div>

      <div className="table__col">
        <span className="table__key">Цена за 1 токен</span>
        <span className="table__value">185 273 ₽</span>
        <span className="table__value">182 223 ₽</span>
        <span className="table__value">178 234 ₽</span>
      </div>
    </article>
  );
};

export default PriceTableExtended;
