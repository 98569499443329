import SectionTitle from "../../ui/SectionTitle/SectionTitle";
import PriceTable from "../../ui/Table/PriceTable";
import PriceTableExtended from "../../ui/Table/PriceTableExtended";
import Tabs from "../../ui/Tabs/Tabs";

import "./index.scss";

const TokenHistoryTabs = () => {
  return (
    <section className="history">
      <SectionTitle>История изменения цены токена</SectionTitle>
      <Tabs addClass="history__tabs">
        <div label="История изменения цены токена">
          <PriceTable />
        </div>

        <div label="Выпуск новых токенов">
          <PriceTableExtended />
        </div>
      </Tabs>
    </section>
  );
};

export default TokenHistoryTabs;
