import Button from "../Button/Button";
import Countdown from "react-countdown";
import { useState } from "react";

const LabelRequest = ({ labelType = "secondary", size = "xs" }) => {
  const [denied, setDenied] = useState(false);
  const renderer = ({ formatted, completed }) => {
    const { hours, minutes, seconds } = formatted; // Добавляет формат 00:00 вместо 0:0
    if (completed) {
      // Render a completed state;
      return <span>Время истекло</span>;
    } else {
      // Render a countdown
      return (
        <div className="label__request">
          <span>{`Таймер запроса: ${hours}:${minutes}:${seconds}`}</span>
        </div>
      );
    }
  };

  return (
    <Button
      size={size}
      isLabel
      buttonType={denied ? "error" : "secondary"}
      onClick={() => setDenied(!denied)}
    >
      {denied ? (
        <span>Отказано</span>
      ) : (
        <Countdown date={Date.now() + 5000} renderer={renderer} />
      )}
    </Button>
  );
};

export default LabelRequest;
