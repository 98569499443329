import classNames from "classnames";
import CloseButton from "../../../ui/CloseButton/CloseButton";
import Nav from "../TopBar/Nav";
import "./MobileMenu.scss";

const MobileMenu = ({ mobileMenuShown = false, setMobileMenuShown }) => {
  const mobileMenuClasses = classNames({
    "mobile-menu": true,
    shown: mobileMenuShown,
  });
  return (
    <section className={mobileMenuClasses}>
      <Nav withIcons />
      <CloseButton
        addClass="mobile-menu__close-btn"
        onClick={() => setMobileMenuShown(false)}
      />
    </section>
  );
};

export default MobileMenu;
