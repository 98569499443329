import classNames from "classnames";
import "./index.scss";
import mocImg from "../../../assets/img/apartment.png";
import Button from "../Button/Button";

const ApartmentCard = () => {
  const cardClass = classNames({
    "apartment-card": true,
  });

  return (
    <article className={cardClass}>
      <header className="apartment-card__header">
        <span className="apartment-card__name">ЖК «Аист»</span>
        <span className="apartment-card__adress text-gray">
          ул. Тамбасова дом 32
        </span>
      </header>

      <img className="apartment-card__img" src={mocImg} alt="" />

      <div className="apartment-card__body">
        <span>Студия</span>
        <span>20,56 м2</span>
        <span>42 этаж из 42</span>
      </div>

      <Button isAnchor={"/"} buttonType="secondary" size={"xs"}>
        Подробнее
      </Button>
    </article>
  );
};

export default ApartmentCard;
