import Select from "../../../ui/Select/Select";
import { useState } from "react";
import "./index.scss";
import Tabs from "../../../ui/Tabs/Tabs";
import CardHorizontal from "../../../ui/Card/CardHorizontal";
import ExchangeModal from "../../../modals/ExchangeModal";
import ExchangeModalQr from "../../../modals/ExchangeModalQr";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";

const Exchange = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedJk, setSelectedJk] = useState(null);

  const regions = ["Ленинградская область", "Другая область", "Иная область"];

  const jks = ["ЖК «НЕОКЛАССИКА-2»", "Лучший ЖК - 1", "Отличный ЖК -2"];

  const [modalIsOpen, openModal, closeModal] = useModal();

  const [modalQrIsOpen, openQrModal, closeQrModal] = useModal();

  return (
    <section className="exchange">
      <h3 className="exchange__title">
        Предложения о продаже от пользователей
      </h3>

      {/* test */}
      {/* <div style={{ position: "fixed", bottom: "25px", right: "25px" }}>
        <p>{`Выбран регион ${selectedRegion}`}</p>
        <p>{`Выбран ЖК ${selectedJk}`}</p>
      </div> */}

      <div className="exchange__row">
        <Select
          options={regions}
          defaultOption={"Выберите область"}
          onChange={(choice) => {
            setSelectedRegion(choice.value);
          }}
        />
        <Select
          options={jks}
          defaultOption={"Выберите ЖК"}
          onChange={(choice) => {
            setSelectedJk(choice.value);
          }}
        />
      </div>

      <div className="exchange__sort">
        <Tabs size={"xs"}>
          <div className="exchange__result" label="Популярные">
            {/* test */}
            <h3 className="exchange__title">Популярные</h3>

            <CardHorizontal openModal={openModal} />
          </div>

          <div className="exchange__result" label="Сначала дешёвые">
            {/* test */}
            <h3 className="exchange__title">Сначала дешёвые</h3>

            <CardHorizontal openModal={openQrModal} />
          </div>

          <div className="exchange__result" label="Сначала дорогие">
            {/* test */}
            <h3 className="exchange__title">Сначала дорогие</h3>

            <CardHorizontal openModal={openModal} />
          </div>
        </Tabs>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          <ExchangeModal closeModal={closeModal} />
        </Modal>

        <Modal
          isOpen={modalQrIsOpen}
          onRequestClose={closeQrModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          <ExchangeModalQr closeModal={closeQrModal} />
        </Modal>
      </div>
    </section>
  );
};

export default Exchange;
