import RegisterForm from "../../forms/RegisterForm/RegisterForm";

const StepForm = () => {
  return (
    <>
      <div className="modal__info text-block">
        <p>
          Заполните короткую анкету ниже — она необходима для корректной работы
          личного кабинета, бронирования и оформления договора и заказа услуг.
        </p>
      </div>

      <RegisterForm />
    </>
  );
};

export default StepForm;