import "./index.scss";

const CtrrLogo = () => {
  return (
    <div className="logo">
      <svg
        width="155"
        height="49"
        viewBox="0 0 155 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M60.2582 13.3295V15.9412H59.2424V14.2459H52.7324V6.73169H53.7943V13.2837H57.9958V6.73169H59.0578V13.2837H60.2582V13.3295Z"
          fill="currentColor"
        />
        <path
          d="M66.5378 11.7266H61.9208C62.0593 12.7346 62.8442 13.376 63.9985 13.376C64.691 13.376 65.2451 13.1469 65.6606 12.6888L66.2146 13.376C65.7068 13.9717 64.9219 14.2924 63.9523 14.2924C62.1055 14.2924 60.8589 13.1011 60.8589 11.4058C60.8589 9.71056 62.0593 8.51929 63.7214 8.51929C65.3836 8.51929 66.5378 9.71056 66.5378 11.4517C66.584 11.5433 66.584 11.6349 66.5378 11.7266ZM61.9208 10.9935H65.6144C65.5221 10.0313 64.7834 9.34402 63.7676 9.34402C62.7519 9.38983 62.0131 10.0313 61.9208 10.9935Z"
          fill="currentColor"
        />
        <path
          d="M68.2002 8.56421H69.2159V10.9926H72.494V8.56421H73.5098V14.2457H72.494V11.8631H69.2159V14.2457H68.2002V8.56421Z"
          fill="currentColor"
        />
        <path
          d="M79.7883 9.48058H77.7106V14.2457H76.6949V9.48058H74.6172V8.56421H79.8345V9.48058H79.7883Z"
          fill="currentColor"
        />
        <path
          d="M86.8056 11.4058C86.8056 13.1469 85.6052 14.2924 83.8969 14.2924C83.0658 14.2924 82.3732 13.9717 81.9115 13.376V16.3084H80.8496V8.56511H81.8654V9.48147C82.3271 8.84002 83.0658 8.51929 83.8969 8.51929C85.559 8.51929 86.8056 9.66475 86.8056 11.4058ZM85.7437 11.4058C85.7437 10.2146 84.9126 9.38984 83.8045 9.38984C82.6964 9.38984 81.8654 10.1687 81.8654 11.4058C81.8654 12.5971 82.6964 13.4219 83.8045 13.4219C84.9126 13.4219 85.7437 12.5971 85.7437 11.4058Z"
          fill="currentColor"
        />
        <path
          d="M95.4406 9.48058H93.363V14.2457H92.3472V9.48058H90.2695V8.56421H95.4868V9.48058H95.4406Z"
          fill="currentColor"
        />
        <path
          d="M101.444 11.7266H96.7807C96.9192 12.7346 97.7041 13.376 98.8583 13.376C99.5509 13.376 100.105 13.1469 100.52 12.6888L101.075 13.376C100.567 13.9717 99.7818 14.2924 98.8122 14.2924C96.9654 14.2924 95.7188 13.1011 95.7188 11.4058C95.7188 9.71056 96.9192 8.51929 98.5813 8.51929C100.243 8.51929 101.398 9.71056 101.398 11.4517C101.444 11.5433 101.444 11.6349 101.444 11.7266ZM96.7807 10.9935H100.474C100.382 10.0313 99.6432 9.34402 98.6275 9.34402C97.6117 9.38983 96.873 10.0313 96.7807 10.9935Z"
          fill="currentColor"
        />
        <path
          d="M106.474 14.2457L104.812 12.0922L103.15 14.2457H101.996L104.258 11.3591L102.088 8.56421H103.242L104.812 10.626L106.382 8.56421H107.536L105.366 11.3591L107.628 14.2457H106.474Z"
          fill="currentColor"
        />
        <path
          d="M108.737 8.56421H109.753V10.9926H113.031V8.56421H114.046V14.2457H113.031V11.8631H109.753V14.2457H108.737V8.56421Z"
          fill="currentColor"
        />
        <path
          d="M115.571 11.4058C115.571 9.71056 116.817 8.51929 118.572 8.51929C120.326 8.51929 121.527 9.71056 121.527 11.4058C121.527 13.1011 120.28 14.2924 118.572 14.2924C116.864 14.2924 115.571 13.1011 115.571 11.4058ZM120.465 11.4058C120.465 10.2146 119.634 9.38983 118.526 9.38983C117.418 9.38983 116.587 10.1687 116.587 11.4058C116.587 12.5971 117.418 13.4218 118.526 13.4218C119.634 13.4218 120.465 12.5971 120.465 11.4058Z"
          fill="currentColor"
        />
        <path
          d="M127.853 8.56421V14.2457H126.837V9.48057H124.39L124.344 10.7635C124.251 12.7795 123.974 14.3831 122.682 14.3831C122.497 14.3831 122.266 14.3373 122.035 14.2915L122.081 13.4209C122.174 13.4209 122.266 13.4668 122.358 13.4668C123.282 13.4668 123.328 12.0922 123.42 10.7177L123.513 8.61003H127.853V8.56421Z"
          fill="currentColor"
        />
        <path
          d="M129.329 11.4058C129.329 9.71056 130.575 8.51929 132.33 8.51929C134.084 8.51929 135.285 9.71056 135.285 11.4058C135.285 13.1011 134.038 14.2924 132.33 14.2924C130.621 14.2924 129.329 13.1011 129.329 11.4058ZM134.269 11.4058C134.269 10.2146 133.438 9.38983 132.33 9.38983C131.222 9.38983 130.391 10.1687 130.391 11.4058C130.391 12.5971 131.222 13.4218 132.33 13.4218C133.438 13.4218 134.269 12.5971 134.269 11.4058Z"
          fill="currentColor"
        />
        <path
          d="M141.01 9.48058H137.824V14.2457H136.808V8.56421H141.01V9.48058Z"
          fill="currentColor"
        />
        <path
          d="M142.073 8.56421H143.089V12.6879L146.598 8.56421H147.521V14.2457H146.505V10.122L142.996 14.2457H142.027V8.56421H142.073Z"
          fill="currentColor"
        />
        <path
          d="M149.552 8.56452H150.568V12.6882L154.077 8.56452H155V14.246H153.984V10.1223L150.475 14.246H149.506V8.56452H149.552ZM150.568 6.36523H151.26C151.26 6.91505 151.676 7.23578 152.23 7.23578C152.83 7.23578 153.2 6.91505 153.246 6.36523H153.984C153.984 7.32742 153.338 7.83142 152.276 7.83142C151.214 7.83142 150.568 7.32742 150.568 6.36523Z"
          fill="currentColor"
        />
        <path
          d="M58.504 22.6768C58.504 24.4179 57.3036 25.5634 55.5953 25.5634C54.7642 25.5634 54.0717 25.2427 53.61 24.647V27.5794H52.5942V19.8361H53.61V20.7525C54.0717 20.111 54.8104 19.7903 55.6415 19.7903C57.3036 19.7903 58.504 20.9357 58.504 22.6768ZM57.4883 22.6768C57.4883 21.4856 56.6572 20.6608 55.5491 20.6608C54.441 20.6608 53.61 21.4397 53.61 22.6768C53.61 23.8681 54.441 24.6928 55.5491 24.6928C56.611 24.6928 57.4883 23.9139 57.4883 22.6768Z"
          fill="currentColor"
        />
        <path
          d="M64.4596 22.0812V25.5176H63.49V24.7845C63.1668 25.2885 62.5204 25.6092 61.597 25.6092C60.3042 25.6092 59.4731 24.9219 59.4731 23.9597C59.4731 23.0434 60.0734 22.3103 61.7817 22.3103H63.4438V22.127C63.4438 21.2565 62.9359 20.7525 61.9202 20.7525C61.2276 20.7525 60.5351 20.9816 60.1195 21.3481L59.6117 20.5234C60.2119 20.0652 61.0429 19.7903 61.9664 19.7903C63.5823 19.7903 64.4596 20.5234 64.4596 22.0812ZM63.3976 23.7765V22.9976H61.7817C60.7659 22.9976 60.4427 23.4099 60.4427 23.8681C60.4427 24.4179 60.9044 24.7845 61.6893 24.7845C62.5204 24.7845 63.1668 24.4179 63.3976 23.7765Z"
          fill="currentColor"
        />
        <path
          d="M65.7993 22.6768C65.7993 20.9816 67.0459 19.7903 68.8004 19.7903C69.8161 19.7903 70.6934 20.2026 71.1551 20.9816L70.3702 21.4856C70.0008 20.9357 69.4468 20.6608 68.8004 20.6608C67.6923 20.6608 66.8151 21.4397 66.8151 22.6768C66.8151 23.9139 67.6461 24.6928 68.8004 24.6928C69.4468 24.6928 70.0008 24.4179 70.3702 23.8681L71.1551 24.3721C70.6934 25.151 69.8623 25.6092 68.8004 25.6092C67.0459 25.6092 65.7993 24.3721 65.7993 22.6768Z"
          fill="currentColor"
        />
        <path
          d="M77.8035 19.8357V25.5172H76.7877V20.7521H73.5558V25.5172H72.54V19.8357H77.8035Z"
          fill="currentColor"
        />
        <path
          d="M85.7448 22.6768C85.7448 24.4179 84.5443 25.5634 82.836 25.5634C82.005 25.5634 81.3124 25.2427 80.8507 24.647V27.5794H79.835V19.8361H80.8507V20.7525C81.3124 20.111 82.0511 19.7903 82.8822 19.7903C84.4982 19.7903 85.7448 20.9357 85.7448 22.6768ZM84.6829 22.6768C84.6829 21.4856 83.8518 20.6608 82.7437 20.6608C81.6356 20.6608 80.8045 21.4397 80.8045 22.6768C80.8045 23.8681 81.6356 24.6928 82.7437 24.6928C83.8518 24.6928 84.6829 23.9139 84.6829 22.6768Z"
          fill="currentColor"
        />
        <path
          d="M92.3936 23.043H87.7304C87.8689 24.051 88.6538 24.6924 89.808 24.6924C90.5006 24.6924 91.0546 24.4633 91.4702 24.0052L92.0242 24.6924C91.5163 25.2881 90.7314 25.6088 89.7619 25.6088C87.9151 25.6088 86.6685 24.4175 86.6685 22.7222C86.6685 21.027 87.8689 19.8357 89.531 19.8357C91.1931 19.8357 92.3474 21.027 92.3474 22.7681C92.3936 22.8139 92.3936 22.9513 92.3936 23.043ZM87.7304 22.2641H91.424C91.3317 21.3019 90.5929 20.6146 89.5772 20.6146C88.5614 20.6604 87.8227 21.3019 87.7304 22.2641Z"
          fill="currentColor"
        />
        <path
          d="M99.7331 24.6008V26.8001H98.7635V25.4713H93.9618V26.8001H92.9922V24.6008H93.3154C94.1464 24.555 94.285 23.1804 94.3311 21.7601L94.4235 19.8357H98.8558V24.6008H99.7331ZM94.6082 24.6008H97.7939V20.7062H95.3007L95.2545 21.8059C95.2084 22.9972 95.116 24.1884 94.6082 24.6008Z"
          fill="currentColor"
        />
        <path
          d="M106.244 23.043H101.581C101.719 24.051 102.504 24.6924 103.659 24.6924C104.351 24.6924 104.905 24.4633 105.321 24.0052L105.875 24.6924C105.367 25.2881 104.582 25.6088 103.612 25.6088C101.766 25.6088 100.519 24.4175 100.519 22.7222C100.519 21.027 101.719 19.8357 103.382 19.8357C105.044 19.8357 106.198 21.027 106.198 22.7681C106.244 22.8139 106.244 22.9513 106.244 23.043ZM101.581 22.2641H105.275C105.182 21.3019 104.444 20.6146 103.428 20.6146C102.412 20.6604 101.673 21.3019 101.581 22.2641Z"
          fill="currentColor"
        />
        <path
          d="M112.662 19.8357V25.5172H111.646V20.7521H109.199L109.153 22.035C109.06 24.051 108.783 25.6546 107.491 25.6546C107.306 25.6546 107.075 25.6088 106.844 25.563L106.89 24.6924C106.983 24.6924 107.075 24.7382 107.167 24.7382C108.091 24.7382 108.137 23.3637 108.229 21.9891L108.322 19.8815H112.662V19.8357Z"
          fill="currentColor"
        />
        <path
          d="M119.818 23.043H115.155C115.294 24.051 116.079 24.6924 117.233 24.6924C117.925 24.6924 118.479 24.4633 118.895 24.0052L119.449 24.6924C118.941 25.2881 118.156 25.6088 117.187 25.6088C115.34 25.6088 114.093 24.4175 114.093 22.7222C114.093 21.027 115.294 19.8357 116.956 19.8357C118.618 19.8357 119.772 21.027 119.772 22.7681C119.865 22.8139 119.818 22.9513 119.818 23.043ZM115.201 22.2641H118.895C118.803 21.3019 118.064 20.6146 117.048 20.6146C116.032 20.6604 115.294 21.3019 115.201 22.2641Z"
          fill="currentColor"
        />
        <path
          d="M121.48 19.8357H122.496V22.2641H125.774V19.8357H126.79V25.5172H125.774V23.1346H122.496V25.5172H121.48V19.8357Z"
          fill="currentColor"
        />
        <path
          d="M128.821 19.8357H129.837V22.2641H133.115V19.8357H134.131V25.5172H133.115V23.1346H129.837V25.5172H128.821V19.8357Z"
          fill="currentColor"
        />
        <path
          d="M140.964 23.6386C140.964 24.8757 140.087 25.563 138.563 25.563H136.162V19.8357H137.178V21.7601H138.701C140.179 21.7601 140.964 22.4473 140.964 23.6386ZM139.902 23.5928C139.902 22.8597 139.394 22.539 138.471 22.4932H137.178V24.7383H138.471C139.44 24.7841 139.902 24.3717 139.902 23.5928ZM141.841 19.8357H142.857V25.5172H141.841V19.8357Z"
          fill="currentColor"
        />
        <path
          d="M148.445 25.5172L146.783 23.3637L145.121 25.5172H143.966L146.229 22.6306L144.059 19.8357H145.213L146.783 21.8975L148.353 19.8357H149.507L147.337 22.6306L149.599 25.5172H148.445Z"
          fill="currentColor"
        />
        <path
          d="M58.504 33.9486C58.504 35.6897 57.3036 36.8351 55.5953 36.8351C54.7642 36.8351 54.0717 36.5144 53.61 35.9188V38.8511H52.5942V31.1078H53.61V32.0242C54.0717 31.3827 54.8104 31.062 55.6415 31.062C57.3036 31.062 58.504 32.2075 58.504 33.9486ZM57.4883 33.9486C57.4883 32.7573 56.6572 31.9326 55.5491 31.9326C54.441 31.9326 53.61 32.7115 53.61 33.9486C53.61 35.1398 54.441 35.9646 55.5491 35.9646C56.611 35.9646 57.4883 35.1857 57.4883 33.9486Z"
          fill="currentColor"
        />
        <path
          d="M65.1521 34.3147H60.5351C60.6736 35.3227 61.4585 35.9642 62.6127 35.9642C63.3053 35.9642 63.8593 35.7351 64.2749 35.2769L64.8289 35.9642C64.321 36.5598 63.5361 36.8805 62.5666 36.8805C60.7197 36.8805 59.4731 35.6893 59.4731 33.994C59.4731 32.2987 60.6736 31.1074 62.3357 31.1074C63.9978 31.1074 65.1521 32.2987 65.1521 34.0398C65.1983 34.0856 65.1521 34.2231 65.1521 34.3147ZM60.5351 33.5816H64.2287C64.1363 32.6194 63.3976 31.9322 62.3819 31.9322C61.3661 31.9322 60.6274 32.6194 60.5351 33.5816Z"
          fill="currentColor"
        />
        <path
          d="M71.939 34.3147H67.2758C67.4143 35.3227 68.1992 35.9642 69.3534 35.9642C70.046 35.9642 70.6 35.7351 71.0156 35.2769L71.5696 35.9642C71.0617 36.5598 70.2769 36.8805 69.3073 36.8805C67.4605 36.8805 66.2139 35.6893 66.2139 33.994C66.2139 32.2987 67.4143 31.1074 69.0764 31.1074C70.7386 31.1074 71.8928 32.2987 71.8928 34.0398C71.9852 34.0856 71.939 34.2231 71.939 34.3147ZM67.322 33.5816H71.0156C70.9232 32.6194 70.1845 31.9322 69.1688 31.9322C68.153 31.9322 67.4143 32.6194 67.322 33.5816Z"
          fill="currentColor"
        />
        <path
          d="M73.0474 33.9486C73.0474 32.2533 74.294 31.062 76.0484 31.062C77.0642 31.062 77.9414 31.4744 78.4031 32.2533L77.6182 32.7573C77.2489 32.2075 76.6948 31.9326 76.0484 31.9326C74.9403 31.9326 74.0631 32.7115 74.0631 33.9486C74.0631 35.1857 74.8942 35.9646 76.0484 35.9646C76.6948 35.9646 77.2489 35.6897 77.6182 35.1399L78.4031 35.6439C77.9414 36.4228 77.1104 36.8809 76.0484 36.8809C74.294 36.8809 73.0474 35.6439 73.0474 33.9486Z"
          fill="currentColor"
        />
        <path
          d="M83.9436 32.0238H81.8659V36.7889H80.8501V32.0238H78.7725V31.1074H83.9897V32.0238H83.9436Z"
          fill="currentColor"
        />
        <path
          d="M90.9157 33.9486C90.9157 35.6897 89.7152 36.8351 88.0069 36.8351C87.1759 36.8351 86.4833 36.5144 86.0216 35.9188V38.8511H85.0059V31.1078H86.0216V32.0242C86.4833 31.3827 87.222 31.062 88.0531 31.062C89.7152 31.062 90.9157 32.2075 90.9157 33.9486ZM89.8537 33.9486C89.8537 32.7573 89.0227 31.9326 87.9146 31.9326C86.8065 31.9326 85.9754 32.7115 85.9754 33.9486C85.9754 35.1398 86.8065 35.9646 87.9146 35.9646C89.0227 35.9646 89.8537 35.1857 89.8537 33.9486Z"
          fill="currentColor"
        />
        <path
          d="M91.8848 33.9486C91.8848 32.2533 93.1314 31.062 94.8858 31.062C96.6403 31.062 97.8407 32.2533 97.8407 33.9486C97.8407 35.6439 96.5941 36.8351 94.8858 36.8351C93.1775 36.8351 91.8848 35.6439 91.8848 33.9486ZM96.7788 33.9486C96.7788 32.7573 95.9478 31.9326 94.8397 31.9326C93.7316 31.9326 92.9005 32.7115 92.9005 33.9486C92.9005 35.1398 93.7316 35.9646 94.8397 35.9646C95.9478 35.9646 96.7788 35.1857 96.7788 33.9486Z"
          fill="currentColor"
        />
        <path
          d="M104.351 35.2311C104.351 36.2391 103.613 36.7889 102.089 36.7889H99.3188V31.1074H101.997C103.336 31.1074 104.167 31.6114 104.167 32.5736C104.167 33.1692 103.844 33.6274 103.243 33.8565C103.982 34.0398 104.351 34.498 104.351 35.2311ZM100.335 33.5816H101.904C102.689 33.5816 103.105 33.3067 103.105 32.7569C103.105 32.2071 102.689 31.9322 101.904 31.9322H100.335V33.5816ZM103.29 35.1853C103.29 34.5896 102.92 34.3147 102.043 34.3147H100.335V36.01H101.997C102.874 36.01 103.29 35.7351 103.29 35.1853Z"
          fill="currentColor"
        />
        <path
          d="M108.415 33.0321C108.415 30.8328 110.123 29.1833 112.431 29.1833C113.586 29.1833 114.601 29.5957 115.294 30.3288L114.601 31.0161C114.001 30.4204 113.309 30.1455 112.478 30.1455C110.769 30.1455 109.523 31.3826 109.523 33.0321C109.523 34.6816 110.769 35.9186 112.478 35.9186C113.309 35.9186 114.001 35.6437 114.601 35.0023L115.294 35.6896C114.601 36.4685 113.586 36.8808 112.431 36.8808C110.123 36.8808 108.415 35.2772 108.415 33.0321Z"
          fill="currentColor"
        />
        <path
          d="M123.327 29.3198V36.834H122.265V30.2362H117.879V36.7882H116.817V29.3198H123.327Z"
          fill="currentColor"
        />
        <path
          d="M131.176 34.0854C131.176 35.7348 129.976 36.8803 128.222 36.8803C126.282 36.8803 125.174 35.5057 125.174 33.0774C125.174 30.7406 126.144 29.4577 128.129 29.0453L130.761 28.4497L130.899 29.4119L128.499 29.9159C127.021 30.2366 126.329 30.9697 126.19 32.4359C126.698 31.7028 127.483 31.2904 128.452 31.2904C130.022 31.3363 131.176 32.4359 131.176 34.0854ZM130.115 34.1312C130.115 32.9857 129.376 32.2526 128.222 32.2526C127.067 32.2526 126.329 32.9857 126.329 34.1312C126.329 35.2766 127.113 36.0555 128.222 36.0555C129.33 36.0097 130.115 35.2308 130.115 34.1312Z"
          fill="currentColor"
        />
        <path
          d="M137.732 30.2362H133.853V36.7882H132.792V29.3198H137.732V30.2362Z"
          fill="currentColor"
        />
        <path
          d="M145.211 29.3198L141.841 35.5053C141.287 36.5133 140.548 36.9715 139.717 36.9715C139.394 36.9715 139.024 36.8799 138.701 36.7424L138.978 35.8719C139.209 35.9635 139.486 36.0093 139.671 36.0093C140.132 36.0093 140.502 35.7802 140.825 35.2762L141.01 35.0013L137.962 29.3198H139.117L141.564 34.0849L144.103 29.3198H145.211Z"
          fill="currentColor"
        />
        <path
          d="M43.4932 18.8269H30.4731V19.8807H43.4932V18.8269Z"
          fill="currentColor"
        />
        <path
          d="M43.4935 22.8586H32.4126V23.9125H43.4935V22.8586Z"
          fill="currentColor"
        />
        <path
          d="M41.5536 24.9209H26.7329V25.9747H41.5536V24.9209Z"
          fill="currentColor"
        />
        <path
          d="M43.3543 26.8914H26.7329V27.9452H43.3543V26.8914Z"
          fill="currentColor"
        />
        <path
          d="M41.5536 28.9072H26.7329V29.961H41.5536V28.9072Z"
          fill="currentColor"
        />
        <path
          d="M37.9528 30.8774H30.4731V31.9313H37.9528V30.8774Z"
          fill="currentColor"
        />
        <path
          d="M30.4737 34.8635H22.9941V35.9174H30.4737V34.8635Z"
          fill="currentColor"
        />
        <path
          d="M32.4129 36.8794H22.9941V37.9332H32.4129V36.8794Z"
          fill="currentColor"
        />
        <path
          d="M32.4119 38.8499H26.7329V39.9037H32.4119V38.8499Z"
          fill="currentColor"
        />
        <path
          d="M30.4727 40.8657H26.7329V41.9195H30.4727V40.8657Z"
          fill="currentColor"
        />
        <path
          d="M26.7339 42.9731H17.0381V44.027H26.7339V42.9731Z"
          fill="currentColor"
        />
        <path
          d="M25.0263 40.8657H19.0703V41.9195H25.0263V40.8657Z"
          fill="currentColor"
        />
        <path
          d="M25.0263 45.0356H19.0703V46.0895H25.0263V45.0356Z"
          fill="currentColor"
        />
        <path
          d="M41.462 16.8113H32.4126V17.8651H41.462V16.8113Z"
          fill="currentColor"
        />
        <path
          d="M41.462 20.7974H32.4126V21.8512H41.462V20.7974Z"
          fill="currentColor"
        />
        <path
          d="M26.4107 20.7974H17.3613V21.8512H26.4107V20.7974Z"
          fill="currentColor"
        />
        <path
          d="M25.0245 18.8269H18.7915V19.8807H25.0245V18.8269Z"
          fill="currentColor"
        />
        <path
          d="M25.0245 16.8113H18.7915V17.8651H25.0245V16.8113Z"
          fill="currentColor"
        />
        <path
          d="M26.4123 14.7947H22.9033V15.8485H26.4123V14.7947Z"
          fill="currentColor"
        />
        <path
          d="M32.0887 12.7793H24.6553V13.8331H32.0887V12.7793Z"
          fill="currentColor"
        />
        <path
          d="M32.0887 10.7634H24.6553V11.8172H32.0887V10.7634Z"
          fill="currentColor"
        />
        <path
          d="M30.1964 8.74707H26.6875V9.80089H30.1964V8.74707Z"
          fill="currentColor"
        />
        <path
          d="M24.6569 4.57764H19.0703V5.63146H24.6569V4.57764Z"
          fill="currentColor"
        />
        <path
          d="M24.6569 2.56128H19.0703V3.6151H24.6569V2.56128Z"
          fill="currentColor"
        />
        <path
          d="M30.1964 6.68506H26.6875V7.73888H30.1964V6.68506Z"
          fill="currentColor"
        />
        <path
          d="M35.7834 2.56128H32.2744V3.6151H35.7834V2.56128Z"
          fill="currentColor"
        />
        <path
          d="M43.2618 14.7947H34.2124V15.8485H43.2618V14.7947Z"
          fill="currentColor"
        />
        <path
          d="M41.5074 12.7793H36.1978V13.8331H41.5074V12.7793Z"
          fill="currentColor"
        />
        <path
          d="M43.356 10.7634H38.0464V11.8172H43.356V10.7634Z"
          fill="currentColor"
        />
        <path
          d="M41.8788 8.74707H39.8936V9.80089H41.8788V8.74707Z"
          fill="currentColor"
        />
        <path
          d="M43.771 6.68506H41.7856V7.73888H43.771V6.68506Z"
          fill="currentColor"
        />
        <path
          d="M34.0747 8.74707H32.0894V9.80089H34.0747V8.74707Z"
          fill="currentColor"
        />
        <path
          d="M24.9795 8.74707H22.9941V9.80089H24.9795V8.74707Z"
          fill="currentColor"
        />
        <path
          d="M22.9946 6.68506H21.0093V7.73888H22.9946V6.68506Z"
          fill="currentColor"
        />
        <path
          d="M22.9946 0.5H21.0093V1.55382H22.9946V0.5Z"
          fill="currentColor"
        />
        <path
          d="M34.0747 6.68506H32.0894V7.73888H34.0747V6.68506Z"
          fill="currentColor"
        />
        <path
          d="M32.228 4.57764H30.2427V5.63146H32.228V4.57764Z"
          fill="currentColor"
        />
        <path
          d="M37.6274 4.57764H35.6421V5.63146H37.6274V4.57764Z"
          fill="currentColor"
        />
        <path
          d="M28.7182 22.8586H26.7329V23.9125H28.7182V22.8586Z"
          fill="currentColor"
        />
        <path
          d="M22.8559 38.8499H20.8706V39.9037H22.8559V38.8499Z"
          fill="currentColor"
        />
        <path
          d="M22.8559 47.0515H20.8706V48.1053H22.8559V47.0515Z"
          fill="currentColor"
        />
        <path
          d="M28.7182 30.8774H26.7329V31.9313H28.7182V30.8774Z"
          fill="currentColor"
        />
        <path
          d="M28.7182 32.8477H26.7329V33.9015H28.7182V32.8477Z"
          fill="currentColor"
        />
        <path
          d="M32.4585 32.8477H30.4731V33.9015H32.4585V32.8477Z"
          fill="currentColor"
        />
        <path
          d="M36.0136 32.8477H34.0283V33.9015H36.0136V32.8477Z"
          fill="currentColor"
        />
        <path
          d="M13.2969 18.8276H0.276855V19.8815H13.2969V18.8276Z"
          fill="currentColor"
        />
        <path
          d="M11.3114 22.8596H0.230469V23.9134H11.3114V22.8596Z"
          fill="currentColor"
        />
        <path
          d="M17.0365 24.9216H2.21582V25.9754H17.0365V24.9216Z"
          fill="currentColor"
        />
        <path
          d="M17.0369 26.8918H0.415527V27.9457H17.0369V26.8918Z"
          fill="currentColor"
        />
        <path
          d="M17.0365 28.9072H2.21582V29.961H17.0365V28.9072Z"
          fill="currentColor"
        />
        <path
          d="M13.297 30.8784H5.81738V31.9322H13.297V30.8784Z"
          fill="currentColor"
        />
        <path
          d="M20.7765 34.8647H13.2969V35.9186H20.7765V34.8647Z"
          fill="currentColor"
        />
        <path
          d="M20.7771 36.8801H11.3584V37.9339H20.7771V36.8801Z"
          fill="currentColor"
        />
        <path
          d="M17.0373 38.8503H11.3584V39.9042H17.0373V38.8503Z"
          fill="currentColor"
        />
        <path
          d="M17.0367 40.8665H13.2969V41.9203H17.0367V40.8665Z"
          fill="currentColor"
        />
        <path
          d="M11.358 16.812H2.30859V17.8658H11.358V16.812Z"
          fill="currentColor"
        />
        <path
          d="M11.358 20.7983H2.30859V21.8522H11.358V20.7983Z"
          fill="currentColor"
        />
        <path
          d="M20.8693 14.7961H17.3604V15.85H20.8693V14.7961Z"
          fill="currentColor"
        />
        <path
          d="M19.1151 12.7795H11.6816V13.8334H19.1151V12.7795Z"
          fill="currentColor"
        />
        <path
          d="M19.1151 10.7642H11.6816V11.818H19.1151V10.7642Z"
          fill="currentColor"
        />
        <path
          d="M17.0368 8.7478H13.5278V9.80162H17.0368V8.7478Z"
          fill="currentColor"
        />
        <path
          d="M17.0368 6.68628H13.5278V7.7401H17.0368V6.68628Z"
          fill="currentColor"
        />
        <path
          d="M11.4972 2.5625H7.98828V3.61633H11.4972V2.5625Z"
          fill="currentColor"
        />
        <path
          d="M9.5118 14.7961H0.462402V15.85H9.5118V14.7961Z"
          fill="currentColor"
        />
        <path
          d="M7.6182 12.7795H2.30859V13.8334H7.6182V12.7795Z"
          fill="currentColor"
        />
        <path
          d="M5.72511 10.7642H0.415527V11.818H5.72511V10.7642Z"
          fill="currentColor"
        />
        <path
          d="M3.83251 8.7478H1.84717V9.80162H3.83251V8.7478Z"
          fill="currentColor"
        />
        <path
          d="M1.98532 6.68628H0V7.7401H1.98532V6.68628Z"
          fill="currentColor"
        />
        <path
          d="M11.6821 8.7478H9.69678V9.80162H11.6821V8.7478Z"
          fill="currentColor"
        />
        <path
          d="M20.7309 8.7478H18.7456V9.80162H20.7309V8.7478Z"
          fill="currentColor"
        />
        <path
          d="M11.6821 6.68628H9.69678V7.7401H11.6821V6.68628Z"
          fill="currentColor"
        />
        <path
          d="M13.5278 4.57837H11.5425V5.63219H13.5278V4.57837Z"
          fill="currentColor"
        />
        <path
          d="M8.08102 4.57837H6.0957V5.63219H8.08102V4.57837Z"
          fill="currentColor"
        />
        <path
          d="M17.0376 22.8596H15.0522V23.9134H17.0376V22.8596Z"
          fill="currentColor"
        />
        <path
          d="M16.9907 30.8784H15.0054V31.9322H16.9907V30.8784Z"
          fill="currentColor"
        />
        <path
          d="M16.9907 32.8481H15.0054V33.902H16.9907V32.8481Z"
          fill="currentColor"
        />
        <path
          d="M13.2505 32.8481H11.2651V33.902H13.2505V32.8481Z"
          fill="currentColor"
        />
        <path
          d="M9.69579 32.8481H7.71045V33.902H9.69579V32.8481Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default CtrrLogo;
