import "./assets/styles/index.scss";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Markup from "./pages/Markup/Markup";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage/HomePage";
import JkPage from "./pages/JkPage/JkPage";
import TabsLayout from "./components/layout/TabsLayout/TabsLayout";

import Exchange from "./components/content/Account/Exchange/Exchange";
import MyTokens from "./components/content/Account/MyTokens/MyTokens";
import MySells from "./components/content/Account/MySells/MySells";
import Favorites from "./components/content/Account/Favorites/Favorites";
import Notifications from "./components/content/Account/Notifications/Notifications";
import { accountLinks, settingsLinks } from "./components/layout/TabsLayout/links";
import PersonalInfo from "./components/content/Settings/PersonalInfo/PersonalInfo";
import SettingsNotifications from "./components/content/Settings/Notifications/Notifications";
import Support from "./components/content/Settings/Support/Support";
import Requisites from "./components/content/Settings/Requisites/Requisites";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";
let router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
      
      <Route index element={<HomePage />} />
      <Route path="jk" element={<JkPage />} />

      <Route
        path="user"
        element={<TabsLayout title={"Личный кабинет"} links={accountLinks} />}
      >
        <Route index path="exchange" element={<Exchange />} />
        <Route path="tokens" element={<MyTokens />} />
        <Route path="sells" element={<MySells />} />
        <Route path="history" element={<>История</>} />
        <Route path="favorites" element={<Favorites />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>

      <Route
        path="settings"
        element={<TabsLayout title={"Настройки"} links={settingsLinks} />}
      >
        
        <Route index path="personal" element={<PersonalInfo />} />
        <Route path="notifications" element={<SettingsNotifications />} />
        <Route path="support" element={<Support />} />
        <Route path="requisites" element={<Requisites />} />
      </Route>



      <Route path="markup" element={<Markup />} />
    </Route>
  )
);

const App = () => <RouterProvider router={router} />;

export default App;
