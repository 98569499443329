import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import mocImg from "../../../../assets/img/card-horizontal.jpg";
import Collapse from "../../../ui/Collapse/Collapse";
import { useState } from "react";
import CollapseToggler from "../../../ui/Collapse/CollapseToggler";
import PurchaseCard from "../../../ui/PurchaseCard/PurchaseCard";

const CardHorizontalToken = () => {
  const [collapseOpened, setCollapseOpened] = useState(false);
  const [purchaseCardOpened, setPurchaseCardOpened] = useState(false);
  const [purchaseCardSellOpened, setPurchaseCardSellOpened] = useState(false);

  const cardClass = classNames({
    "card card_h card_token": true,
    "card_collapse-opened": collapseOpened,
  });

  return (
    <article className={cardClass}>
      <img className="card__img" src={mocImg} alt="" />

      <div className="card__body">
        <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
        </div>

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Цифровой токен</span>
            <span className="sell-info__key">ЖК «Аист»</span>
            <p className="sell-info__value">СК «Красная стрела»</p>

            <span className="sell-info__key">Санкт-Петербург</span>
            <p className="sell-info__value">Красносельский район</p>

            <span className="sell-info__key">1 токен ≈ 185 273 ₽</span>
            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Баланс</span>
            <span className="sell-info__key">2 токена</span>
            <p className="sell-info__value">Цифрового метра</p>
          </div>
        </div>

        <div className="card__operations">
          <CollapseToggler
            addClass={"card__collapse-btn"}
            collapseOpened={collapseOpened}
            setCollapseOpened={setCollapseOpened}
          >
            Операции
          </CollapseToggler>
          <div className="card__btns">
            <Button
              size={"xs"}
              addClass="card__buy-btn"
              onClick={() => setPurchaseCardOpened(true)}
            >
              Купить ещё
            </Button>
            <Button
              size={"xs"}
              addClass="card__buy-btn"
              onClick={() => setPurchaseCardSellOpened(true)}
            >
              Продать
            </Button>
          </div>
        </div>

        <Collapse isOpened={collapseOpened} addClass="card__collapse">
          <div className="sell-info sell-info_single">
            <div className="sell-info__col">
              <span className="sell-info__key">
                Вы купили 1 токен ЖК «Аист» за 150 234 ₽
              </span>
              <p className="sell-info__value">12 марта 2023 года 12:22:34</p>

              <span className="sell-info__key">
                Вы купили 1 токен ЖК «Аист» за 150 234 ₽
              </span>
              <p className="sell-info__value">12 марта 2023 года 12:22:34</p>
            </div>
          </div>
        </Collapse>
      </div>

      <PurchaseCard
        isHorizontal
        addClass="card__purchase"
        isOpened={purchaseCardOpened}
        setIsOpened={setPurchaseCardOpened}
        buttonText="Купить токены"
      />

      <PurchaseCard
        isHorizontal
        addClass="card__purchase"
        isOpened={purchaseCardSellOpened}
        setIsOpened={setPurchaseCardSellOpened}
        buttonText="Выставить на продажу"
      />
    </article>
  );
};

export default CardHorizontalToken;
