import Button from "../../ui/Button/Button";
import Input from "../../ui/Input/Input";
import Textarea from "../../ui/Textarea/Textarea";
import "./FeedbackForm.scss";

const FeedbackForm = () => {
  return (
    <form className="form feedback">
      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Имя"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            withError={"Обязательное поле*"}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Номер телефона"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
        <div className="form__item">
          <Input
            withLabel="Email"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Тема обращения"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={"Например: как вывести деньги?"}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Textarea
            withLabel="Сообщение"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__submit form__submit_ml">
          <Button type="submit" buttonType="secondary-gray" size={"m"} disabled>
            Отправить
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FeedbackForm;
