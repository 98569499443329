import classNames from "classnames";
import "./index.scss";
import infoSvg from "../../../assets/img/icons/info.svg";
import { useState } from "react";
import { ComparisonSvg } from "./Icons";

const ComparisonBlock = () => {
  const [popup, setPopup] = useState(false);

  const comparisonClass = classNames({
    "comparison-block": true,
  });

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box">
          <p className="comparison-block__title">
            Цена токена на данный момент
            <button type="button" className="comparison-block__info">
              <img src={infoSvg} alt="" />
            </button>
          </p>

          <p className="comparison-block__price">1 токен = 185 273 ₽</p>
          <span className="comparison-block__text">1 токен = 1 м2</span>
        </div>

        <div className="comparison-block__box">
          <p className="comparison-block__title">
            Цена токена по завершению строительства
            <button type="button" className="comparison-block__info">
              <img src={infoSvg} alt="" />
            </button>
          </p>

          <p className="comparison-block__price">1 токен = 220 345 ₽</p>
          <span className="comparison-block__text">1 токен = 1 м2</span>
        </div>
      </div>

      <ComparisonSvg />
    </article>
  );
};

export default ComparisonBlock;
