import classNames from "classnames";
import "./index.scss";
import infoSvg from "../../../assets/img/icons/info.svg";
import CloseButton from "../CloseButton/CloseButton";
import { useState } from "react";

const Popup = ({
  withIcon = false,
  text = "Lorem ipsum dolor sit amet, consectetur dadipiscing elit.",
}) => {
  const [hidden, setHidden] = useState(false);

  const popupClass = classNames({
    popup: true,
  });

  return hidden ? null : (
    <article className={popupClass}>
      {withIcon && <img src={infoSvg} alt="" className="popup__info-icon" />}
      <p className="popup__text">{text}</p>
      <CloseButton
        addClass="popup__close-btn"
        onClick={() => setHidden(true)}
      />
    </article>
  );
};

export default Popup;
