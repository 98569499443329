import "./index.scss";

const MetrLogo = () => {
  return (
    <div className="logo">
      <svg
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0.105469H30V30.1055H0V0.105469Z" fill={"currentColor"} />
        <path
          d="M19.9125 10.3794V8.9804L22.8774 6.77777C23.0819 6.6236 23.2623 6.48293 23.4186 6.35574C23.575 6.2247 23.6973 6.09174 23.7855 5.95684C23.8777 5.82195 23.9238 5.66971 23.9238 5.50013C23.9238 5.24576 23.8116 5.05305 23.587 4.92201C23.3665 4.78712 23.0999 4.71967 22.7872 4.71967C22.4504 4.71967 22.1798 4.79483 21.9753 4.94514C21.7708 5.09545 21.6686 5.32284 21.6686 5.62732H19.726C19.726 4.85649 20.0147 4.25718 20.592 3.82937C21.1734 3.40156 21.9131 3.18766 22.8112 3.18766C23.4287 3.18766 23.9639 3.28979 24.417 3.49406C24.874 3.69833 25.2269 3.9739 25.4754 4.32077C25.724 4.66764 25.8483 5.05305 25.8483 5.47701C25.8483 5.82388 25.7721 6.1457 25.6198 6.44246C25.4714 6.73923 25.2409 7.02636 24.9282 7.30386C24.6194 7.58135 24.2225 7.8627 23.7374 8.14791L22.8233 8.72603V8.78962H25.8904V10.3794H19.9125Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default MetrLogo;
