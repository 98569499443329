import News from "../../components/content/News/News";
import Card from "../../components/ui/Card/Card";
import CardMain from "../../components/ui/Card/CardMain";
import "./index.scss";

const HomePage = () => {
  return (
    <section className="home">
      <h1 className="title">Новостройки</h1>
      <div className="home__slider">
        <CardMain />
      </div>

      <div className="home__grid">
        <Card />
        <Card />
        <Card />
      </div>

      <News />
    </section>
  );
};

export default HomePage;
