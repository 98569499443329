export const TimerSvg = () => (
  <svg
    width="130"
    height="87"
    viewBox="0 0 130 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="timer-block__pic"
  >
    <path
      d="M28.8277 47.872C35.7795 47.7228 44.341 52.3823 47.9466 58.2781C49.7508 61.2275 49.9719 63.9255 48.8723 65.9045C48.7072 66.1968 47.7196 67.9857 47.5545 68.2839C46.4637 70.2715 44.0462 71.5373 40.5644 71.6132C33.6096 71.7623 25.0511 67.103 21.4455 61.207C19.6412 58.2606 19.4201 55.5654 20.5138 53.5865C20.6818 53.2942 21.6754 51.4849 21.8464 51.1809C22.9431 49.2048 25.3547 47.945 28.8277 47.872Z"
      fill="#37474F"
    />
    <path
      opacity="0.1"
      d="M39.642 50.8066C43.1032 52.692 46.1457 55.3316 47.9471 58.2781C49.7484 61.2246 49.9725 63.9254 48.8729 65.9044C48.7078 66.1967 47.7201 67.9857 47.555 68.2837C46.4641 70.2715 44.0467 71.5371 40.5649 71.6132C37.0831 71.6892 33.1944 70.5579 29.751 68.6755L39.642 50.8066Z"
      fill="#1D1D1D"
    />
    <path
      d="M45.8273 55.5215C46.6249 56.3679 47.3351 57.2914 47.947 58.2781C49.7514 61.2274 49.9725 63.9255 48.8727 65.9044C48.7076 66.1967 47.72 67.9857 47.5549 68.2838C46.4641 70.2715 44.0466 71.5372 40.5648 71.6132C39.3963 71.6313 38.2289 71.5333 37.0801 71.3209L45.8273 55.5215Z"
      fill="#263238"
    />
    <path
      d="M48.8723 65.895C51.2382 61.6209 47.1032 54.8623 39.6366 50.7992C32.17 46.736 24.1991 46.9069 21.8332 51.181C19.4674 55.455 23.6023 62.2135 31.0689 66.2767C38.5356 70.3399 46.5063 70.169 48.8723 65.895Z"
      fill="#455A64"
    />
    <g opacity="0.4">
      <path
        d="M33.2885 41.6924C29.0166 43.5486 26.6198 47.3399 24.0195 52.0343L46.9946 64.5393C49.595 59.8449 51.5348 55.8051 50.8272 51.2363C50.1197 46.6676 48.7135 44.446 48.339 42.7301C47.9646 41.0143 48.224 40.0029 48.6014 39.3189C48.9787 38.635 49.6981 37.8573 51.3638 37.2728C53.0295 36.6882 55.6651 36.6413 59.94 34.7851C64.2149 32.9291 66.603 29.1348 69.2032 24.4404L46.2251 11.9296C43.6278 16.6241 41.682 20.6696 42.3925 25.2355C43.1031 29.8014 44.5004 32.0228 44.8808 33.7417C45.2611 35.4604 44.9929 36.466 44.6125 37.1529C44.2322 37.8398 43.5158 38.5969 41.856 39.1991C40.9509 39.5293 39.7598 39.6843 38.1766 40.0584C36.4999 40.4527 34.8637 40.9998 33.2885 41.6924Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M46.9925 64.5307C49.0027 60.8992 45.4889 55.1564 39.1442 51.7038C32.7996 48.2512 26.0265 48.3962 24.0162 52.0276C22.006 55.6591 25.5198 61.4019 31.8646 64.8546C38.2092 68.3072 44.9823 68.1622 46.9925 64.5307Z"
      fill="currentColor"
    />
    <g opacity="0.8">
      <path
        d="M44.4914 26.8461C44.6801 28.5472 45.3759 29.7691 45.7828 30.8214C46.0915 31.5586 46.3438 32.3178 46.5375 33.0927C46.9502 34.9927 46.7881 36.5712 46.0393 37.9245C45.2905 39.278 45.3141 39.6784 45.6944 39.8918C46.0746 40.1051 46.4314 39.8918 47.1684 38.5442C47.9054 37.1966 49.1761 36.2058 51.0159 35.5364C51.7782 35.2837 52.5587 35.0882 53.3507 34.9518C54.9133 34.6331 54.2175 34.0163 53.1592 33.8C50.0871 33.1687 49.6597 32.3794 48.0381 29.0617C47.6163 28.2095 47.0926 27.4108 46.4785 26.6824C45.7857 25.9399 44.3559 25.6096 44.4914 26.8461Z"
        fill="white"
      />
    </g>
    <g opacity="0.8">
      <path
        d="M45.3108 42.1866C45.3602 42.0455 45.3681 41.8933 45.3331 41.7479C45.2983 41.6026 45.2223 41.4702 45.114 41.3664C45.0057 41.2625 44.8698 41.1914 44.7221 41.1617C44.5744 41.1319 44.4213 41.1446 44.2807 41.1982C44.1401 41.2518 44.018 41.3443 43.9285 41.4645C43.8391 41.5847 43.7863 41.7277 43.7762 41.8767C43.7661 42.0258 43.7991 42.1746 43.8715 42.3056C43.9439 42.4366 44.0525 42.5443 44.1846 42.6162C44.3915 42.7068 44.6261 42.7124 44.8373 42.632C45.0483 42.5514 45.2186 42.3912 45.3108 42.1866Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.8"
      d="M25.5701 52.6072C27.6338 49.0411 29.6593 46.1939 32.917 44.8318C35.4906 43.756 39.1435 43.7649 42.5309 45.6065C45.9184 47.4479 47.7079 49.6579 48.3506 53.2239C48.9697 56.6674 47.6048 59.9032 45.6767 63.5425C44.913 64.9252 43.1678 65.7699 40.6206 65.7553C35.529 65.7261 29.161 62.2476 26.4014 58.0149C25.0218 55.8956 24.8065 53.9781 25.5701 52.5985V52.6072Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M45.6721 63.5416C47.3212 60.5625 44.1568 55.6981 38.6043 52.6765C33.0518 49.6549 27.2138 49.6205 25.5647 52.5997C23.9156 55.5787 27.0799 60.4432 32.6325 63.4646C38.185 66.4862 44.023 66.5206 45.6721 63.5416Z"
      fill="white"
    />
    <path
      d="M52.8815 4.42603C59.8363 4.27695 68.3949 8.93635 72.0004 14.8322C73.8048 17.7787 74.0259 20.4767 72.9291 22.4586L71.6113 24.838C70.5175 26.8228 68.1 28.0914 64.6183 28.1674C57.6664 28.3136 49.1049 23.6541 45.4993 17.7582C43.698 14.8146 43.4739 12.1196 44.5676 10.1407L45.9003 7.73496C46.997 5.75895 49.4085 4.49911 52.8815 4.42603Z"
      fill="#37474F"
    />
    <path
      opacity="0.1"
      d="M63.6959 7.36072C67.157 9.2432 70.1995 11.8828 72.0009 14.8321C73.8021 17.7816 74.0322 20.4854 72.9295 22.4584L71.6117 24.8379C70.518 26.8226 68.1005 28.0913 64.6186 28.1672C61.1368 28.2432 57.263 27.112 53.8047 25.2296L63.6959 7.36072Z"
      fill="#1D1D1D"
    />
    <path
      d="M69.881 12.0757C70.6795 12.9212 71.3897 13.8448 72.0007 14.8322C73.805 17.7786 74.0262 20.4766 72.9295 22.4584L71.6115 24.838C70.5178 26.8226 68.1003 28.0914 64.6185 28.1672C63.4509 28.1852 62.2846 28.0872 61.1367 27.8749L69.881 12.0757Z"
      fill="#263238"
    />
    <path
      d="M72.923 22.4527C75.289 18.1787 71.1541 11.4202 63.6874 7.35701C56.2208 3.29386 48.2499 3.46478 45.884 7.73878C43.5182 12.0128 47.6531 18.7714 55.1197 22.8346C62.5864 26.8977 70.5571 26.7268 72.923 22.4527Z"
      fill="#455A64"
    />
    <path
      d="M118.102 33.2621C118.117 34.6478 118.067 35.9836 116.049 36.2954C118.182 36.266 118.019 37.5987 118.126 39.2698C118.185 37.5987 118.334 36.4013 119.995 36.3277C118.271 36.0394 118.307 35.248 118.102 33.2621Z"
      fill="#455A64"
    />
    <path
      d="M6.47775 31.8118C6.47775 32.8621 6.45105 33.8711 4.91992 34.1066C6.53711 34.0859 6.40357 35.0951 6.49258 36.3601C6.53711 35.0951 6.6469 34.1889 7.90799 34.1301C6.60535 33.9212 6.64096 33.3151 6.47775 31.8118Z"
      fill="#455A64"
    />
    <path
      d="M36.2875 6.15692C36.3052 7.78682 36.246 9.35495 33.8721 9.7227C36.3795 9.69034 36.1895 11.2585 36.3141 13.2208C36.3795 11.2585 36.5546 9.84921 38.51 9.75507C36.4862 9.42261 36.5397 8.49292 36.2875 6.15692Z"
      fill="#455A64"
    />
    <path
      d="M86.8825 65.8808C86.8825 66.6958 86.8617 67.4813 85.6719 67.6636C86.9271 67.646 86.832 68.4315 86.8944 69.4142C86.9271 68.4315 87.016 67.7284 87.9923 67.6813C86.9804 67.5136 87.0071 67.0487 86.8825 65.8808Z"
      fill="#455A64"
    />
    <path
      d="M110.974 43.8036C110.998 45.9189 110.921 47.9578 107.838 48.4315C111.102 48.3902 110.85 50.4262 111.007 52.9769C111.096 50.4262 111.322 48.5962 113.861 48.4755C111.232 48.043 111.304 46.8368 110.974 43.8036Z"
      fill="currentColor"
    />
    <path
      d="M81.5383 29.0756C81.5591 30.8409 81.4937 32.562 78.9033 32.9621C81.6393 32.9267 81.4345 34.6361 81.5739 36.7867C81.6452 34.6449 81.835 33.1091 83.9686 33.0062C81.7579 32.6355 81.8083 31.6235 81.5383 29.0756Z"
      fill="currentColor"
    />
    <path
      d="M104.758 7.51617C104.758 8.3017 104.74 9.05487 103.598 9.22845C104.802 9.22845 104.713 9.96691 104.77 10.9084C104.802 9.96691 104.886 9.29024 105.826 9.24611C104.856 9.08723 104.88 8.64004 104.758 7.51617Z"
      fill="currentColor"
    />
    <path
      d="M15.1869 71.6707C15.1869 72.4739 15.1692 73.2447 14 73.436C15.2344 73.4213 15.1395 74.1921 15.1869 75.1601C15.2195 74.1921 15.3057 73.4977 16.2671 73.4537C15.2879 73.2771 15.3116 72.8211 15.1869 71.6707Z"
      fill="currentColor"
    />
    <path
      d="M14.1007 36.7985C14.1215 38.9344 14.0444 40.9909 10.9316 41.4705C14.2223 41.4293 13.9731 43.4829 14.1334 46.0601C14.2223 43.4829 14.8011 41.5499 17.3647 41.4293C14.712 40.9821 14.4331 39.8611 14.1007 36.7985Z"
      fill="currentColor"
    />
    <path
      d="M123.218 42C123.218 43.0827 122.998 44.1713 121.42 44.4126C123.088 44.392 123.153 45.3893 123.233 46.6956C123.277 45.3893 123.639 44.3831 124.939 44.3213C123.595 44.1007 123.384 43.5534 123.218 42Z"
      fill="#EBEBEB"
    />
    <path
      d="M128.037 76.9371C128.037 78.0962 127.803 79.2584 126.117 79.5172C127.898 79.4937 127.969 80.5616 128.058 81.9562C128.105 80.5616 128.488 79.4848 129.88 79.4202C128.441 79.1848 128.218 78.5964 128.037 76.9371Z"
      fill="#EBEBEB"
    />
    <path
      d="M1.92281 81.8297C1.92281 82.9889 1.68839 84.1509 0 84.4098C1.78039 84.3863 1.8516 85.4543 1.94062 86.8489C1.9881 85.4543 2.37384 84.3775 3.76255 84.3128C2.32637 84.0775 2.10086 83.4889 1.92281 81.8297Z"
      fill="#EBEBEB"
    />
    <path
      d="M109.96 9.69031C109.975 11.1967 109.921 12.6324 107.726 12.9854C110.046 12.9531 109.871 14.4034 109.984 16.2217C110.046 14.4064 110.206 13.1031 112.013 13.0178C110.144 12.7089 110.194 11.8557 109.96 9.69031Z"
      fill="#E0E0E0"
    />
    <path
      d="M17.335 31.5822C17.335 30.0759 17.2964 28.7314 15.1006 28.393C17.421 28.4254 17.2431 26.8691 17.3588 25.0538C17.418 26.8602 17.7149 28.4254 19.4952 28.5107C17.6377 28.8166 17.5694 29.4227 17.335 31.5822Z"
      fill="#E0E0E0"
    />
    <path
      d="M94.9949 31.9524L90.1447 29.1581L90.1193 29.1413L90.0659 29.1134C86.5853 27.1574 81.7971 27.4619 76.51 30.4882C65.8739 36.5769 57.25 51.3838 57.25 63.5641C57.25 69.6137 59.3806 73.8751 62.8273 75.8869L67.7565 78.7175L68.9796 75.5208C70.8507 75.0454 72.6441 74.3087 74.3062 73.3328C84.9451 67.2442 93.5662 52.4345 93.5662 40.2569C93.5851 38.3571 93.3365 36.4638 92.8277 34.632L94.9949 31.9524Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M94.9949 31.9524L90.1447 29.1581L90.1193 29.1413L90.0659 29.1134C86.5853 27.1574 81.7971 27.4619 76.51 30.4882C65.8739 36.5769 57.25 51.3838 57.25 63.5641C57.25 69.6137 59.3806 73.8751 62.8273 75.8869L67.7565 78.7175L68.9796 75.5208C70.8507 75.0454 72.6441 74.3087 74.3062 73.3328C84.9451 67.2442 93.5662 52.4345 93.5662 40.2569C93.5851 38.3571 93.3365 36.4638 92.8277 34.632L94.9949 31.9524Z"
      fill="white"
    />
    <path
      d="M67.4099 79.1916C59.8539 74.8859 59.826 60.876 67.3477 47.8995C74.8695 34.9231 87.0924 27.8941 94.6485 32.1998C102.205 36.5055 102.233 50.5153 94.7107 63.4917C87.1891 76.468 74.9661 83.4971 67.4099 79.1916Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M67.4099 79.1916C59.8539 74.8859 59.826 60.876 67.3477 47.8995C74.8695 34.9231 87.0924 27.8941 94.6485 32.1998C102.205 36.5055 102.233 50.5153 94.7107 63.4917C87.1891 76.468 74.9661 83.4971 67.4099 79.1916Z"
      fill="white"
    />
    <path
      d="M96.0575 37.1553C93.6676 33.0533 88.7723 32.5922 82.9582 35.9203C73.3395 41.4278 65.2031 55.3825 65.2031 66.3947C65.2031 69.2812 65.7669 71.7653 66.8208 73.5816C69.2107 77.6837 74.106 78.1474 79.9201 74.8195C89.5389 69.312 97.6752 55.3573 97.6752 44.3422C97.6752 41.4585 97.1116 38.9716 96.0575 37.1553Z"
      fill="currentColor"
    />
    <path
      d="M96.3218 37.3089C93.6162 33.0253 88.377 32.3659 82.3036 35.5458C72.2593 40.8074 64.1314 54.7649 64.5486 66.0203C64.6585 68.9709 65.3518 71.5333 66.5496 73.4307C69.2579 77.7143 74.497 78.3709 80.5676 75.191C90.6147 69.9294 98.7398 55.9747 98.3227 44.7167C98.2099 41.7659 97.5194 39.2062 96.3189 37.3089H96.3218Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M96.3218 37.3089C93.6162 33.0253 88.377 32.3659 82.3036 35.5458C72.2593 40.8074 64.1314 54.7649 64.5486 66.0203C64.6585 68.9709 65.3518 71.5333 66.5496 73.4307C69.2579 77.7143 74.497 78.3709 80.5676 75.191C90.6147 69.9294 98.7398 55.9747 98.3227 44.7167C98.2099 41.7659 97.5194 39.2062 96.3189 37.3089H96.3218Z"
      fill="white"
    />
    <path
      d="M78.6233 74.1014C74.7566 76.1272 71.2309 76.5883 68.4775 75.5571C67.718 74.9643 67.0685 74.2448 66.5582 73.4307C65.3604 71.5334 64.6672 68.9598 64.5572 66.0203C64.1289 54.7649 72.2568 40.8074 82.3124 35.5458C86.1791 33.5199 89.7046 33.059 92.4581 34.0928C93.2173 34.6841 93.8659 35.4029 94.3745 36.2165C95.5723 38.111 96.2656 40.6733 96.3783 43.6241C96.7954 54.8851 88.6704 68.837 78.6233 74.1014Z"
      fill="#FAFAFA"
    />
    <path
      opacity="0.3"
      d="M81.4893 55.3684L80.3311 55.2903L81.8726 53.3006L86.765 41.1455C86.765 41.1455 84.2286 40.2318 80.4635 43.3474L81.4893 55.3684Z"
      fill="currentColor"
    />
    <path
      d="M80.2603 38.3206C80.14 38.4029 80.0406 38.512 79.9702 38.639C79.8997 38.7661 79.8602 38.9077 79.8545 39.0526V41.1232C79.8545 41.4026 80.0348 41.5172 80.2603 41.4026C80.381 41.3198 80.4803 41.2103 80.5508 41.0826C80.6213 40.955 80.6608 40.813 80.6662 40.6677V38.5971C80.6719 38.3093 80.4859 38.1891 80.2603 38.3206Z"
      fill="currentColor"
    />
    <path
      d="M94.1011 46.2424C94.0889 46.3872 94.0428 46.5273 93.9665 46.6514C93.8902 46.7756 93.7857 46.8804 93.6614 46.9576L91.8549 47.9943C91.6154 48.1312 91.4209 48.0335 91.4209 47.7764C91.4332 47.6314 91.4792 47.4914 91.5556 47.3672C91.6319 47.243 91.7364 47.1382 91.8606 47.061L93.6671 46.0243C93.9179 45.8874 94.1011 45.9852 94.1011 46.2424Z"
      fill="currentColor"
    />
    <path
      d="M65.916 62.9689C65.9282 62.824 65.9743 62.6839 66.0506 62.5597C66.1269 62.4356 66.2314 62.3307 66.3557 62.2536L68.1622 61.2168C68.4017 61.0799 68.5962 61.1777 68.5962 61.4349C68.5839 61.5797 68.5379 61.7198 68.4615 61.844C68.3852 61.9681 68.2809 62.073 68.1565 62.1501L66.35 63.1869C66.1104 63.3098 65.916 63.2119 65.916 62.9689Z"
      fill="currentColor"
    />
    <path
      d="M79.7564 70.8768C79.8768 70.7943 79.9761 70.6853 80.0465 70.5582C80.1169 70.4312 80.1566 70.2896 80.1622 70.1446V68.074C80.1622 67.7947 79.9818 67.6801 79.7564 67.8086C79.6358 67.8914 79.5363 68.0009 79.4659 68.1285C79.3955 68.256 79.356 68.3982 79.3506 68.5435V70.614C79.3506 70.8879 79.5309 71.0081 79.7564 70.8768Z"
      fill="currentColor"
    />
    <path
      d="M81.9345 54.6L80.7988 54.3541L81.1482 53.4264L85.6123 44.0097L81.9965 54.3458L81.9345 54.6Z"
      fill="currentColor"
    />
    <path
      d="M81.4729 43.5431C81.4729 43.5431 82.4199 52.2416 82.7129 54.2591C82.7434 54.3759 82.7586 54.4961 82.758 54.6169C82.7329 55.0691 82.6007 55.5093 82.3721 55.9017C82.1434 56.294 81.8247 56.6272 81.4418 56.8746C81.3093 56.9697 81.1536 57.0284 80.9908 57.0446C80.828 57.0608 80.6637 57.034 80.5147 56.9668L79.8101 56.5616C79.6746 56.4698 79.5665 56.3435 79.4971 56.196C79.4277 56.0486 79.3995 55.8853 79.4155 55.7234C79.2972 53.6388 80.0778 43.6325 80.0778 43.6325L80.7627 43.1295L81.4729 43.5431Z"
      fill="#37474F"
    />
    <path
      d="M82.7574 54.6169C82.7323 55.0693 82.6002 55.5093 82.3715 55.9016C82.1428 56.2939 81.8241 56.6272 81.4412 56.8746C80.717 57.291 80.128 56.9529 80.128 56.1229C80.0068 54.0384 80.7875 44.032 80.7875 44.032L81.4723 43.5431C81.4723 43.5431 82.7546 53.7813 82.7574 54.6169Z"
      fill="#455A64"
    />
    <path
      d="M82.037 55.0275C82.0263 55.2324 81.9669 55.432 81.8636 55.6097C81.7602 55.7875 81.6159 55.9385 81.4423 56.0502C81.1154 56.2375 80.8477 56.0838 80.8477 55.7093C80.859 55.5049 80.9187 55.3062 81.0221 55.1289C81.1253 54.9517 81.2694 54.8012 81.4423 54.6894C81.7721 54.4995 82.037 54.6531 82.037 55.0275Z"
      fill="#263238"
    />
    <path
      d="M92.0645 54.2347L92.0858 54.4095L89.9704 54.6622L89.9492 54.4874L92.0645 54.2347Z"
      fill="#37474F"
    />
    <path
      d="M85.0315 63.1485L85.8688 65.091L85.7054 65.1602L84.8682 63.2177L85.0315 63.1485Z"
      fill="#37474F"
    />
    <path
      d="M74.38 44.1154L75.2174 46.0579L75.054 46.1271L74.2168 44.1846L74.38 44.1154Z"
      fill="#37474F"
    />
    <path
      d="M70.1172 54.6028L70.1384 54.7776L68.0231 55.0303L68.002 54.8555L70.1172 54.6028Z"
      fill="#37474F"
    />
    <path
      d="M93.4902 39.9438L93.6226 40.061L91.7224 42.1735L91.5898 42.0562L93.4902 39.9438Z"
      fill="#37474F"
    />
    <path
      d="M86.0971 36.1007L86.2657 36.1552L85.3816 38.8416L85.2129 38.7871L86.0971 36.1007Z"
      fill="#37474F"
    />
    <path
      d="M74.8365 70.8397L75.005 70.895L74.109 73.5795L73.9404 73.5243L74.8365 70.8397Z"
      fill="#37474F"
    />
    <path
      d="M68.282 67.0336L68.4146 67.1507L66.5143 69.2632L66.3818 69.146L68.282 67.0336Z"
      fill="#37474F"
    />
    <path
      d="M79.8272 26.7326C79.8165 26.6186 79.7754 26.5097 79.708 26.4169C79.6405 26.3241 79.5494 26.2507 79.444 26.2045C79.1571 26.06 78.8399 25.9846 78.5182 25.9846C78.1963 25.9846 77.8791 26.06 77.5924 26.2045C77.4872 26.2512 77.3964 26.3247 77.329 26.4173C77.2618 26.5101 77.2203 26.6189 77.209 26.7326V31.7092C77.2203 31.8229 77.2618 31.9317 77.329 32.0244C77.3964 32.1172 77.4872 32.1905 77.5924 32.2374C77.8791 32.3819 78.1963 32.4572 78.5182 32.4572C78.8399 32.4572 79.1571 32.3819 79.444 32.2374C79.5494 32.1911 79.6405 32.1177 79.708 32.025C79.7754 31.9321 79.8165 31.8231 79.8272 31.7092V26.7326Z"
      fill="#455A64"
    />
    <path
      d="M77.5974 27.2635C77.4856 27.2262 77.3886 27.1548 77.3197 27.0599C77.2509 26.9649 77.2139 26.8509 77.2139 26.734C77.2139 26.617 77.2509 26.503 77.3197 26.4081C77.3886 26.3131 77.4856 26.2419 77.5974 26.2045C77.8841 26.06 78.2014 25.9846 78.5232 25.9846C78.8449 25.9846 79.1621 26.06 79.449 26.2045C79.5607 26.2419 79.6578 26.3131 79.7265 26.4081C79.7954 26.503 79.8324 26.617 79.8324 26.734C79.8324 26.8509 79.7954 26.9649 79.7265 27.0599C79.6578 27.1548 79.5607 27.2262 79.449 27.2635C79.1621 27.4081 78.8449 27.4834 78.5232 27.4834C78.2014 27.4834 77.8841 27.4081 77.5974 27.2635Z"
      fill="#37474F"
    />
    <path
      d="M82.2622 23.6897C82.2622 23.1308 81.8958 22.5971 81.1687 22.1779C79.7089 21.3396 77.3415 21.3396 75.8845 22.1779C75.1546 22.5971 74.791 23.142 74.791 23.6897V28.2276C74.791 28.7864 75.1546 29.3229 75.8845 29.7393C77.3415 30.5776 79.7089 30.5776 81.1687 29.7393C81.8958 29.3229 82.2622 28.7752 82.2622 28.2276V23.6897Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M75.8815 25.2042C75.1516 24.785 74.7881 24.2373 74.7881 23.6897C74.7881 23.142 75.1516 22.5971 75.8815 22.1779C77.3415 21.3396 79.7059 21.3396 81.1658 22.1779C81.893 22.5971 82.2594 23.142 82.2594 23.6897C82.2594 24.2373 81.893 24.785 81.1658 25.2042C79.7088 26.0368 77.3442 26.0368 75.8815 25.2042Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M76.372 25.4333V29.9656C76.262 29.9209 76.1493 29.8678 76.0479 29.8176V25.2852C76.1493 25.3411 76.262 25.3858 76.372 25.4333ZM77.5162 25.7406V30.2757C77.4062 30.2618 77.2963 30.2423 77.1921 30.2227V25.6848C77.2987 25.7107 77.407 25.7293 77.5162 25.7406ZM75.4616 24.9024V29.4375C75.3416 29.339 75.232 29.2285 75.1348 29.1078V24.581C75.2282 24.7051 75.3382 24.816 75.4616 24.9108V24.9024ZM80.9544 25.302V29.8371C80.8502 29.8873 80.7458 29.9405 80.6304 29.9852V25.4529C80.741 25.4083 80.8492 25.358 80.9544 25.302ZM81.8648 24.6398V29.1581C81.7675 29.2746 81.6589 29.3813 81.5407 29.4767V24.9414C81.6641 24.8555 81.7758 24.7541 81.8732 24.6398H81.8648ZM79.8102 25.6903V30.231C79.706 30.2479 79.5961 30.2674 79.4862 30.2814V25.7491C79.6008 25.7356 79.7146 25.7159 79.8272 25.6903H79.8102ZM78.683 25.8189V30.3597H78.3588V25.8189C78.4674 25.8331 78.5773 25.8331 78.6858 25.8189H78.683Z"
      fill="#1D1D1D"
    />
    <path
      d="M64.9066 37.7393C64.8126 37.6727 64.7022 37.6327 64.587 37.6233C64.4719 37.614 64.3565 37.6357 64.2528 37.6863C63.9563 37.8093 63.6934 38 63.4853 38.2428C63.2772 38.4854 63.1298 38.7735 63.055 39.0833C63.022 39.1936 63.0196 39.3107 63.0478 39.4222C63.076 39.5339 63.1341 39.6359 63.2156 39.7176L67.04 42.9338C67.134 43.0005 67.2444 43.0405 67.3595 43.0499C67.4747 43.0592 67.5901 43.0374 67.6938 42.987C67.9908 42.8646 68.2542 42.6742 68.4623 42.4314C68.6705 42.1884 68.8176 41.9 68.8916 41.5899C68.924 41.4797 68.9264 41.363 68.8988 41.2516C68.871 41.1403 68.8141 41.038 68.7338 40.9556C68.6633 40.894 64.977 37.7952 64.9066 37.7393Z"
      fill="#455A64"
    />
    <path
      d="M63.8696 39.7764C63.7686 39.8365 63.6512 39.8638 63.5337 39.8545C63.4163 39.845 63.3046 39.7992 63.2149 39.7237C63.125 39.6481 63.0611 39.5465 63.0325 39.4331C63.0039 39.3199 63.0118 39.2005 63.0552 39.0918C63.13 38.7819 63.2774 38.4939 63.4855 38.2511C63.6936 38.0085 63.9565 37.8177 64.253 37.6946C64.354 37.6345 64.4714 37.6072 64.5888 37.6165C64.7064 37.6261 64.8179 37.6718 64.9078 37.7473C64.9976 37.8229 65.0615 37.9245 65.0901 38.0379C65.1187 38.1511 65.1107 38.2705 65.0674 38.3792C64.9926 38.6891 64.8452 38.9771 64.637 39.2199C64.429 39.4626 64.166 39.6533 63.8696 39.7764Z"
      fill="#37474F"
    />
    <path
      d="M64.6534 35.9342C64.3715 35.6938 63.9263 35.6408 63.388 35.8307C62.8156 36.0691 62.3079 36.438 61.9064 36.9075C61.5048 37.3771 61.2206 37.934 61.077 38.5328C60.9756 39.0918 61.0995 39.5137 61.3842 39.7539L63.746 41.7379C64.0277 41.9782 64.473 42.0174 65.0113 41.8412C65.5838 41.603 66.0915 41.234 66.493 40.7645C66.8946 40.2949 67.1787 39.7381 67.3222 39.1392C67.4238 38.5804 67.2997 38.1585 67.0151 37.9181L64.6534 35.9342Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M62.6359 39.8546C62.1005 40.0473 61.6551 39.9943 61.3706 39.7539C61.0859 39.5137 60.9619 39.089 61.0633 38.5328C61.2069 37.934 61.4911 37.3771 61.8926 36.9075C62.2942 36.438 62.8019 36.0691 63.3743 35.8307C63.9126 35.6408 64.355 35.6938 64.6397 35.9342C64.9243 36.1745 65.0512 36.5992 64.9497 37.1553C64.8054 37.754 64.5207 38.3104 64.1186 38.7795C63.7166 39.2485 63.2086 39.6169 62.6359 39.8546Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M62.9826 39.7065L65.3415 41.6904L65.1216 41.791L62.7627 39.807C62.838 39.7781 62.9115 39.7445 62.9826 39.7065ZM63.6449 39.2565L66.0038 41.2405L65.8346 41.3831L63.4729 39.3991C63.5334 39.3554 63.5909 39.3079 63.6449 39.2565ZM62.3146 39.944L64.6707 41.928C64.5673 41.945 64.4625 41.9525 64.3578 41.9503L61.999 39.9663C62.1047 39.9737 62.211 39.966 62.3146 39.944ZM64.9244 37.3034L67.2833 39.2873C67.2634 39.3628 67.2465 39.4382 67.2185 39.5165L64.8596 37.5325C64.8867 37.4578 64.9083 37.3812 64.9244 37.3034ZM64.9778 36.5461L67.3283 38.5245C67.3462 38.625 67.3537 38.727 67.3509 38.829L64.9948 36.8452C65.0006 36.7452 64.995 36.6448 64.9778 36.5461ZM64.6256 38.0578L66.9874 40.0557C66.9506 40.1172 66.9141 40.1815 66.8718 40.243L64.5129 38.259C64.5553 38.1947 64.5929 38.1275 64.6256 38.0578ZM64.1973 38.6921L66.5589 40.6789L66.4179 40.8437L64.0563 38.8598C64.1086 38.8085 64.1557 38.7523 64.1973 38.6921Z"
      fill="#1D1D1D"
    />
    <path
      d="M91.534 27.5462L93.7868 28.8358L92.7342 30.6435L90.4814 29.3539L91.534 27.5462Z"
      fill="#455A64"
    />
    <path
      d="M92.3443 24.4651L96.0956 26.6125C96.2802 26.7182 96.3436 26.9523 96.2369 27.1354L95.0745 29.1319C94.9678 29.315 94.7316 29.3777 94.547 29.272L90.7957 27.1246C90.611 27.0188 90.5477 26.7847 90.6543 26.6016L91.8169 24.6052C91.9234 24.4221 92.1597 24.3595 92.3443 24.4651Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M94.7895 25.873L94.9798 25.9819L93.4317 28.6417L93.2412 28.5328L94.7895 25.873Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M96.1727 26.6739L94.6396 29.3089C94.6072 29.3005 94.576 29.2883 94.5466 29.2725L94.4424 29.2111L95.9924 26.5537L96.0938 26.6125C96.1229 26.6291 96.1495 26.6497 96.1727 26.6739Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M95.4558 26.2459L95.6462 26.3549L94.0976 29.0144L93.9072 28.9054L95.4558 26.2459Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M90.6973 27.0428L92.2277 24.4217C92.2693 24.43 92.3094 24.4451 92.346 24.4664L92.4278 24.5111L90.8805 27.1713L90.7988 27.1238C90.7607 27.1025 90.7264 27.0751 90.6973 27.0428Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M94.1014 25.4796L94.2917 25.5886L92.7432 28.248L92.5527 28.139L94.1014 25.4796Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M92.7674 24.7057L92.9579 24.8147L91.4092 27.4742L91.2188 27.3653L92.7674 24.7057Z"
      fill="#1D1D1D"
    />
    <path
      opacity="0.2"
      d="M93.4403 25.0876L93.6306 25.1965L92.0819 27.8561L91.8916 27.7471L93.4403 25.0876Z"
      fill="#1D1D1D"
    />
  </svg>
);
