import ApartmentCard from "../../components/ui/ApartmentCard/ApartmentCard";
import Button from "../../components/ui/Button/Button";
import Card from "../../components/ui/Card/Card";
import ComparisonBlock from "../../components/ui/ComparisonBlock/ComparisonBlock";
import InfoBlock from "../../components/ui/InfoBlock/InfoBlock";
import Label from "../../components/ui/Label/Label";
import Popup from "../../components/ui/Popup/Popup";
import PriceTable from "../../components/ui/Table/PriceTable";
import PurchaseCard from "../../components/ui/PurchaseCard/PurchaseCard";
import QrCode from "../../components/ui/QrCode/QrCode";
import TimerBlock from "../../components/ui/TimerBlock/TimerBlock";
import CardHorizontal from "../../components/ui/Card/CardHorizontal";
import StatusTable from "../../components/ui/Table/StatusTable";
import CardMain from "../../components/ui/Card/CardMain";
import Switcher from "../../components/ui/Switcher/Switcher";
import Banner from "../../components/ui/Banner/Banner";

const Markup = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "25px",
      }}
    >
      <h1 className="h1">Heading H1</h1>

      <Switcher />
      <Switcher gray />

      <Button>Primary 16px</Button>

      <Button size={"m"}>Primary 14px</Button>

      <Button size={"s"} buttonType={"secondary"}>
        Secondary 12px
      </Button>

      <Card />

      <Label labelType="primary">Построено 25%</Label>
      <Label labelType="secondary">Построено 25%</Label>
      <Label labelType="tertiary">Построено 25%</Label>

      <ApartmentCard />

      <InfoBlock />

      <TimerBlock isLarge />
      <TimerBlock />
      <TimerBlock isSmall />

      <ComparisonBlock />

      <Banner />

      <PriceTable />

      <PurchaseCard />
      {/* 
      <PurchaseCard isHorizontal /> */}

      <QrCode />

      <Popup />

      <CardHorizontal />

      <StatusTable />

      <CardMain />
    </div>
  );
};

export default Markup;
