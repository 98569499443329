import classNames from "classnames";
import BottomBar from "./BottomBar/BottomBar";
import "./index.scss";
import TopBar from "./TopBar/TopBar";


const Header = () => {
  const headerClass = classNames({
    "header": true,
  });

  return (
    <header className={headerClass}>
      <TopBar />
      <BottomBar />
    </header>
  );
};

export default Header;
