import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "../../ui/Button/Button";
import CtrrLogo from "../../ui/Logo/CtrrLogo";
import Logo from "../../ui/Logo/Logo";
import RedArrowLogo from "../../ui/Logo/RedArrowLogo";
import Container from "../Container/Container";
import "./index.scss";

const FooterTop = () => {
  const footerTopClass = classNames({
    footer__top: true,
  });

  return (
    <section className={footerTopClass}>
      <div className="footer__logos-col">
        <Logo />
        <div className="footer__logos-box">
          <span className="footer__logos-title">Совместный проект</span>
          <RedArrowLogo />
          <CtrrLogo />
        </div>
      </div>

      <div className="footer__nav">
        <div className="footer__nav-item">
          <a href="#" className="footer__nav-link link link_gray">
            Информация
          </a>

          <a href="#" className="footer__nav-link link link_gray">
            О проекте
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Как работает сервис
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Публичная оферта
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Правовая информация
          </a>
        </div>
        <div className="footer__nav-item">
          <a href="#" className="footer__nav-link link link_gray">
            Новостройки
          </a>

          <a href="#" className="footer__nav-link link link_gray">
            О проекте
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Как работает сервис
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Публичная оферта
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Правовая информация
          </a>
        </div>
        <div className="footer__nav-item">
          <Link to="/user/exchange" className="footer__nav-link link link_gray">
            Мой метр
          </Link>

          <a href="#" className="footer__nav-link link link_gray">
            Как работает сервис
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Публичная оферта
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Правовая информация
          </a>
        </div>
        <div className="footer__nav-item">
          <a href="#" className="footer__nav-link link link_gray">
            Сотрудничество
          </a>

          <a href="#" className="footer__nav-link link link_gray">
            Как работает сервис
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Публичная оферта
          </a>
          <a href="#" className="footer__nav-link link link_gray">
            Правовая информация
          </a>
        </div>
      </div>

      <div className="footer__contacts">
        <span className="footer__contacts-title">
          Есть вопросы и предложения?
        </span>
        <Button size={"s"} buttonType="secondary" isFull>
          Напишите нам
        </Button>

        <div className="footer__contacts-group">
          <div className="footer__contacts-block">
            <a href="tel:+78123636079" className="link">
              +7 (812) 363-60-79
            </a>
            <span className="footer__contacts-sign">Контактный центр</span>
          </div>

          <div className="footer__contacts-block">
            <a href="tel:+78122109435" className="link">
              +7 (812) 210-94-35
            </a>
            <span className="footer__contacts-sign">Отдел продаж</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterTop;
