import { CheckboxIconChecked, CheckboxIconUnchecked } from "./Icons";
import classNames from "classnames";
import "./index.scss";

const Checkbox = ({ checkboxValue, setCheckboxValue, withLabel = null }) => {

  const checkboxClass = classNames({
    "checkbox": true,
    [`checkbox_with-label`]: withLabel,
    [`checkbox_checked`]: checkboxValue,
  });

  return (
    <label className={checkboxClass}>
      <div className="checkbox__control">
        {!checkboxValue ? <CheckboxIconUnchecked /> : <CheckboxIconChecked />}
        <input
          type="checkbox"
          className="checkbox__input"
          checked={checkboxValue}
          onChange={() => setCheckboxValue(!checkboxValue)}
        />
      </div>

      {withLabel && <span>{withLabel}</span>}
    </label>
  );
};

export default Checkbox;
