import Button from "../../ui/Button/Button";
import "./index.scss";

const ShowmoreBtn = ({text = "Показать еще"}) => {
  return (
    <div className="showmore">
      <Button buttonType="secondary" size={"s"}>
        {text}
      </Button>
    </div>
  );
};

export default ShowmoreBtn;