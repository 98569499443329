import "./index.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import classNames from "classnames";

const Select = ({ options, defaultOption, addClass, ...arg }) => {
  const dropdownClasses = classNames({
    "dropdown select": true,
    [`${addClass}`]: addClass,
  });
  return (
    <Dropdown
      className={dropdownClasses}
      options={options}
      // onChange={() => console.log("changed currency")}
      value={defaultOption}
      arrowClassName="select__arrow"
      {...arg}
    />
  );
};

export default Select;
