import Input from "../Input/Input";
import "./CodeField.scss";
import { useRef } from "react";

const CodeField = ({codeFilled, setCodeFilled}) => {
  const inputRef = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);

  return (
    <div className="code-field">
      <span className={"code-field__label"}>
        Введите код из СМС
      </span>
      <div className="code-field__wrapper">
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRef}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            inputRef.current.value = inputRef.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefTwo.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefTwo}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            inputRefTwo.current.value = inputRefTwo.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefThree.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefThree}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            inputRefThree.current.value = inputRefThree.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefFour.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefFour}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            inputRefFour.current.value = inputRefFour.current.value.replace(
              /[^0-9]/g,
              ""
            );

            if (
              inputRef.current.value > 0 &&
              inputRefTwo.current.value > 0 &&
              inputRefThree.current.value > 0 &&
              inputRefFour.current.value > 0
            ) {
              setCodeFilled(true);
            }
          }}
          //onChange={() => replacer(inputRef.current)}
        />
      </div>
    </div>
  );
};

export default CodeField;
