import { useState } from "react";
import Button from "../../ui/Button/Button";
import Input from "../../ui/Input/Input";
import "./PersonalEditForm.scss";
import EditIcon from "./EditIcon";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import RegisterModal from "../../modals/RegisterModal/RegisterModal";

const PersonalEditForm = () => {
  const [editMode, setEditMode] = useState(false);
  const [modalIsOpen, openEditPhoneModal, closeEditPhoneModal] = useModal();
  {
    // Default values for test
  }
  return (
    <>
      <form className="form edit-form">
        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Фамилия"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              withError={"Обязательное поле*"}
              disabled={!editMode}
              value="Гарин"
            />
          </div>
          <div className="form__item">
            <Input
              withLabel="Имя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              withError={"Обязательное поле*"}
              disabled={!editMode}
              value="Пётр"
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Отчество"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              value="Петрович"
            />
          </div>
          <div className="form__item">
            <Input
              withLabel="Дата рождения"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              value="01.01.2024"
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Email"
              withNotification="На новый адрес отправлено письмо для подтверждения."
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              value="psyphys@mail.ru"
            />
          </div>

          <div className="form__item">
            <div className="input-wrapper">
              <span className="input-wrapper__label text-gray body-smaller">
                Номер телефона
              </span>
              <div className="input input_lg form__input input-wrapper__edit-box">
                <span
                  className={`input-wrapper__edit-content${
                    editMode ? "" : " text-gray"
                  }`}
                  placeholder=""
                >
                  +79990000000
                </span>
                {editMode && (
                  <button
                    type="button"
                    className="input-wrapper__edit-btn"
                    onClick={() => openEditPhoneModal()}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__edit">
            {!editMode ? (
              <Button
                type="button"
                buttonType="secondary-gray"
                size={"xs"}
                onClick={() => setEditMode(true)}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="button"
                buttonType="primary"
                size={"xs"}
                onClick={() => setEditMode(false)}
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeEditPhoneModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <RegisterModal closeModal={closeEditPhoneModal} changePhoneRequest />
      </Modal>
    </>
  );
};

export default PersonalEditForm;
