import classNames from "classnames";
import "./SectionTitle.scss";

const SectionTitle = ({ addClass, children, isTitle = false }) => {
  const titleClass = classNames({
    "section-title": !isTitle,
    "title": isTitle,
    [`${addClass}`]: addClass,
  });
  return <h2 className={titleClass}>{children}</h2>;
};

export default SectionTitle;
