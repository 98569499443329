import Button from "../../ui/Button/Button";
import Input from "../../ui/Input/Input";
import "./RegisterForm.scss";

const RegisterForm = () => {
  return (
    <form className="form">
      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Фамилия"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            withError={"Обязательное поле*"}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Имя"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            withError={"Обязательное поле*"}
          />
        </div>
        <div className="form__item">
          <Input
            withLabel="Отчество"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Дата рождения"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
        <div className="form__item">
          <Input
            withLabel="Email"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__submit">
          <Button type="submit">Войти в личный кабинет</Button>
        </div>
      </div>
    </form>
  );
};

export default RegisterForm;
