import SectionTitle from "../../ui/SectionTitle/SectionTitle";
import StatusTable from "../../ui/Table/StatusTable";
import ShowmoreBtn from "../ShowmoreBtn/ShowmoreBtn";

import "./index.scss";

const JkStatus = () => {
  return (
    <section className="jk-status">
      <SectionTitle>Статусы ЖК</SectionTitle>
      <StatusTable />
      <ShowmoreBtn />
    </section>
  );
};

export default JkStatus;
