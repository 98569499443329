import "./Switcher.scss";
import classNames from "classnames";

const Switcher = ({gray = false}) => {
  const switcherClass = classNames({
    "switcher": true,
    "switcher_gray": gray,
  });
  return (
    <label className={switcherClass}>
      <input type="checkbox" className="switcher__input" />
      <span className="switcher__control"></span>
    </label>
  );
};

export default Switcher;