import { ArrowIcon } from "./ArrowIcon";

const Nav = ({ withIcons = false }) => {
  const links = [
    {
      id: 0,
      title: "О проекте",
    },

    {
      id: 1,
      title: "Как работает сервис",
    },

    {
      id: 2,
      title: "Публичная оферта",
    },
    {
      id: 3,
      title: "Правовая информация",
    },
  ];
  return (
    <nav className="nav">
      <ul>
        {links.map((link) => (
          <li key={link.id}>
            <a href="#" className="link">
              {link.title}

              {withIcons && (
                <span className="link__icon link__icon_blue">
                  <ArrowIcon />
                </span>
              )}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
