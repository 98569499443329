import classNames from "classnames";
import Label from "../Label/Label";
import "./index.scss";

const StatusTable = ({withLabels = false, noGap}) => {
  const priceClass = classNames({
    "table table_cols-3 table_status": true,
    "table_no-gap": noGap,
  });

  return (
    <article className={priceClass}>
      {withLabels && (
        <div className="table__col">
          <span className="table__key table__key_hidden">Статус</span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"} labelType="secondary-gray">
              Избранное
            </Label>
          </span>
        </div>
      )}

      <div className="table__col table__col_no-wrap">
        <span className="table__key">Дата</span>
        <span className="table__value">24 июня 2023 года</span>
        <span className="table__value text-gray">12 мая 2023 года</span>
        <span className="table__value text-gray">24 марта 2023 года</span>
      </div>

      <div className="table__col">
        <span className="table__key">Статус</span>
        <span className="table__value">Дом построен</span>
        <span className="table__value text-gray">
          Дом построен. Идет токинизация квартир. Осталось 14 дней.
        </span>
        <span className="table__value text-gray">
          Дом построен. Токинизированные квартиры выставлены на продажу.
          Ожидайте оплату. Вы 25 в очереди.
        </span>
      </div>
    </article>
  );
};

export default StatusTable;
