import { Link } from "react-router-dom";
import "./index.scss";

const Logo = () => {
  return (
    <Link to="/">
      <div className="logo">
        <svg
          width="149"
          height="49"
          viewBox="0 0 149 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2192_4742)">
            <path
              d="M0 26.2507L1.60857e-06 9.98693H4.52383V22.557H9.53498V9.98693H14.0588V26.2507H0ZM12.0869 28.9182V26.2507L9.53498 22.557H16.2513V28.9182H12.0869Z"
              fill="currentColor"
            />
            <path
              d="M18.6631 26.2451V9.97698H23.1597V19.4956L29.8643 9.97698H34.1794V26.2451H29.6555V16.6895L22.9742 26.2451H18.6631Z"
              fill="currentColor"
            />
            <path
              d="M46.5981 25.224C44.4329 25.224 42.6001 24.9458 41.0999 24.3892C39.6152 23.8325 38.4863 23.0208 37.7129 21.954C36.955 20.8716 36.5762 19.5652 36.5762 18.0345C36.5762 16.4884 36.955 15.1819 37.7129 14.1151C38.4863 13.0328 39.623 12.2133 41.1232 11.6567C42.6233 11.1001 44.4562 10.8218 46.6213 10.8218C46.6523 10.8218 46.7529 10.8218 46.9229 10.8218C47.1086 10.8218 47.2942 10.8218 47.4797 10.8218C47.6653 10.8218 47.7659 10.8218 47.7813 10.8218C49.9311 10.8218 51.733 11.1079 53.1867 11.6799C54.6559 12.2365 55.7696 13.056 56.5274 14.1383C57.2853 15.2051 57.6641 16.5038 57.6641 18.0345C57.6641 19.5498 57.293 20.8485 56.5506 21.9307C55.8237 22.9977 54.741 23.8171 53.3027 24.3892C51.8644 24.9458 50.0935 25.224 47.9901 25.224C47.9592 25.224 47.8355 25.224 47.619 25.224C47.4023 25.224 47.1936 25.224 46.9926 25.224C46.7915 25.224 46.66 25.224 46.5981 25.224ZM47.0157 21.6989C47.0621 21.6989 47.1317 21.6989 47.2245 21.6989C47.3328 21.6989 47.4102 21.6989 47.4565 21.6989C48.7866 21.6989 49.8769 21.5675 50.7276 21.3047C51.5782 21.0417 52.2046 20.6398 52.6068 20.0987C53.0088 19.5575 53.2099 18.8618 53.2099 18.0114C53.2099 17.1455 53.0011 16.4498 52.5835 15.924C52.1659 15.3984 51.5396 15.0119 50.7044 14.7645C49.8692 14.5017 48.802 14.3702 47.503 14.3702C47.4565 14.3702 47.3792 14.3702 47.2709 14.3702C47.1782 14.3702 47.0931 14.3702 47.0157 14.3702C45.6238 14.3702 44.4871 14.4938 43.6055 14.7413C42.724 14.9887 42.0744 15.3829 41.6568 15.924C41.2392 16.4498 41.0304 17.1532 41.0304 18.0345C41.0304 18.9004 41.2546 19.6039 41.7031 20.145C42.1671 20.6862 42.84 21.0804 43.7215 21.3278C44.6185 21.5752 45.7166 21.6989 47.0157 21.6989ZM45.0207 26.9357V8.89079H49.2196V26.9357H45.0207Z"
              fill="currentColor"
            />
            <path
              d="M60.05 26.2451V9.97698H67.4738C68.9585 9.97698 70.2344 10.2034 71.3016 10.6827C72.3842 11.162 73.2193 11.8577 73.8071 12.77C74.3948 13.6667 74.6887 14.7335 74.6887 15.9705C74.6887 17.1919 74.3948 18.251 73.8071 19.1478C73.2193 20.0445 72.3842 20.7403 71.3016 21.2351C70.2344 21.7144 68.9585 21.954 67.4738 21.954H62.602L64.6435 19.9595V26.2451H60.05ZM64.6435 20.4465L62.602 18.336H67.1954C68.1543 18.336 68.8658 18.1273 69.3297 17.7098C69.8091 17.2924 70.0488 16.7126 70.0488 15.9705C70.0488 15.2128 69.8091 14.6253 69.3297 14.2079C68.8658 13.7904 68.1543 13.5817 67.1954 13.5817H62.602L64.6435 11.4712V20.4465Z"
              fill="currentColor"
            />
            <path
              d="M84.9767 26.5228C83.6777 26.5228 82.4791 26.3141 81.3809 25.8967C80.2828 25.4791 79.3239 24.8916 78.5042 24.1341C77.6999 23.361 77.0735 22.4642 76.625 21.4437C76.1765 20.4233 75.9523 19.3024 75.9523 18.0809C75.9523 16.8595 76.1765 15.7386 76.625 14.7181C77.0735 13.6976 77.6999 12.8086 78.5042 12.051C79.3239 11.2779 80.2828 10.6827 81.3809 10.2652C82.4791 9.84776 83.6777 9.63903 84.9767 9.63903C86.2913 9.63903 87.4901 9.84776 88.5727 10.2652C89.6708 10.6827 90.6218 11.2779 91.4261 12.051C92.2303 12.8086 92.8567 13.6976 93.3053 14.7181C93.7692 15.7386 94.0013 16.8595 94.0013 18.0809C94.0013 19.3024 93.7692 20.4311 93.3053 21.467C92.8567 22.4874 92.2303 23.3764 91.4261 24.1341C90.6218 24.8916 89.6708 25.4791 88.5727 25.8967C87.4901 26.3141 86.2913 26.5228 84.9767 26.5228ZM84.9767 22.7193C85.5954 22.7193 86.1676 22.6111 86.6935 22.3946C87.2348 22.1782 87.6989 21.869 88.0855 21.467C88.4876 21.0496 88.7969 20.5547 89.0135 19.9826C89.2454 19.4106 89.3614 18.7766 89.3614 18.0809C89.3614 17.3697 89.2454 16.7358 89.0135 16.1791C88.7969 15.6071 88.4876 15.1201 88.0855 14.7181C87.6989 14.3006 87.2348 13.9837 86.6935 13.7672C86.1676 13.5508 85.5954 13.4425 84.9767 13.4425C84.3582 13.4425 83.7781 13.5508 83.2368 13.7672C82.7109 13.9837 82.247 14.3006 81.8448 14.7181C81.4582 15.1201 81.1489 15.6071 80.9169 16.1791C80.7004 16.7358 80.5921 17.3697 80.5921 18.0809C80.5921 18.7766 80.7004 19.4106 80.9169 19.9826C81.1489 20.5547 81.4582 21.0496 81.8448 21.467C82.247 21.869 82.7109 22.1782 83.2368 22.3946C83.7781 22.6111 84.3582 22.7193 84.9767 22.7193Z"
              fill="currentColor"
            />
            <path
              d="M96.4573 26.2451V10.0239H104.345C106.294 10.0239 107.802 10.3817 108.869 11.1238C109.951 11.8505 110.493 12.8864 110.493 14.2316C110.493 15.5458 109.99 16.5817 108.985 17.3392C107.979 18.0815 106.634 18.4525 104.948 18.4525L105.412 17.3625C107.237 17.3625 108.66 17.7258 109.681 18.4525C110.717 19.1637 111.235 20.2151 111.235 21.6066C111.235 23.0291 110.678 24.1578 109.565 24.9927C108.467 25.8275 106.874 26.2451 104.786 26.2451H96.4573ZM100.981 22.9518H104.6C105.265 22.9518 105.76 22.8126 106.085 22.5344C106.425 22.2405 106.595 21.8154 106.595 21.2588C106.595 20.7021 106.425 20.2924 106.085 20.0296C105.76 19.7512 105.265 19.612 104.6 19.612H100.981V22.9518ZM100.981 16.5044H103.904C104.554 16.5044 105.041 16.3729 105.366 16.1101C105.69 15.8319 105.853 15.4299 105.853 14.9041C105.853 14.3785 105.69 13.9842 105.366 13.7213C105.041 13.443 104.554 13.3039 103.904 13.3039H100.981V16.5044Z"
              fill="currentColor"
            />
            <path
              d="M121.701 26.5228C120.402 26.5228 119.203 26.3141 118.105 25.8967C117.007 25.4791 116.048 24.8916 115.229 24.1341C114.424 23.361 113.798 22.4642 113.35 21.4437C112.901 20.4233 112.677 19.3024 112.677 18.0809C112.677 16.8595 112.901 15.7386 113.35 14.7181C113.798 13.6976 114.424 12.8086 115.229 12.051C116.048 11.2779 117.007 10.6827 118.105 10.2652C119.203 9.84776 120.402 9.63903 121.701 9.63903C123.016 9.63903 124.214 9.84776 125.297 10.2652C126.395 10.6827 127.346 11.2779 128.151 12.051C128.955 12.8086 129.581 13.6976 130.03 14.7181C130.494 15.7386 130.726 16.8595 130.726 18.0809C130.726 19.3024 130.494 20.4311 130.03 21.467C129.581 22.4874 128.955 23.3764 128.151 24.1341C127.346 24.8916 126.395 25.4791 125.297 25.8967C124.214 26.3141 123.016 26.5228 121.701 26.5228ZM121.701 22.7193C122.32 22.7193 122.892 22.6111 123.418 22.3946C123.959 22.1782 124.423 21.869 124.81 21.467C125.212 21.0496 125.521 20.5547 125.738 19.9826C125.97 19.4106 126.086 18.7766 126.086 18.0809C126.086 17.3697 125.97 16.7358 125.738 16.1791C125.521 15.6071 125.212 15.1201 124.81 14.7181C124.423 14.3006 123.959 13.9837 123.418 13.7672C122.892 13.5508 122.32 13.4425 121.701 13.4425C121.083 13.4425 120.503 13.5508 119.961 13.7672C119.435 13.9837 118.971 14.3006 118.569 14.7181C118.183 15.1201 117.873 15.6071 117.641 16.1791C117.425 16.7358 117.317 17.3697 117.317 18.0809C117.317 18.7766 117.425 19.4106 117.641 19.9826C117.873 20.5547 118.183 21.0496 118.569 21.467C118.971 21.869 119.435 22.1782 119.961 22.3946C120.503 22.6111 121.083 22.7193 121.701 22.7193Z"
              fill="currentColor"
            />
            <path
              d="M107.452 38.0465H114.992V41.4789H107.452V38.0465ZM107.777 44.4475H116.268V48.0295H103.23V31.7614H115.966V35.3098H107.777V44.4475Z"
              fill="currentColor"
            />
            <path
              d="M122.48 48.0295V34.3821L123.501 35.4026H117.493V31.7614H131.969V35.4026H125.984L127.004 34.3821V48.0295H122.48Z"
              fill="currentColor"
            />
            <path
              d="M134.086 48.0295V31.7614H141.51C142.995 31.7614 144.27 32.0011 145.338 32.4804C146.42 32.9597 147.256 33.6554 147.844 34.5677C148.431 35.4645 148.725 36.5313 148.725 37.7681C148.725 38.9896 148.431 40.0486 147.844 40.9455C147.256 41.8423 146.42 42.538 145.338 43.0327C144.27 43.512 142.995 43.7517 141.51 43.7517H136.638L138.68 41.7573V48.0295H134.086ZM138.68 42.2443L136.638 40.1338H141.231C142.191 40.1338 142.903 39.925 143.366 39.5076C143.845 39.0902 144.085 38.5104 144.085 37.7681C144.085 37.0106 143.845 36.4231 143.366 36.0055C142.903 35.5881 142.191 35.3794 141.231 35.3794H136.638L138.68 33.269V42.2443Z"
              fill="currentColor"
            />
            <path
              d="M132.78 26.2472L132.782 9.98276L137.729 9.97987V19.4939L144.601 9.97987H149V26.2443H144.476V16.6911L137.52 26.2443L132.78 26.2472Z"
              fill="currentColor"
            />
            <path
              d="M83.5542 48.0287V31.7614H87.3046L91.6021 40.7552L95.9161 31.7614H99.7739V48.0287H95.5013L95.4822 39.7987L92.6244 46.3899H90.5964L87.7424 39.7987V48.0287H83.5542Z"
              fill="currentColor"
            />
            <path
              d="M135.101 4.73462H144.601V7.36109H137.721L135.101 4.73462Z"
              fill="currentColor"
            />
            <path
              d="M143.059 0.470459H139.353L135.101 4.73462L136.953 6.59196L143.05 0.479345C143.054 0.476397 143.056 0.473414 143.059 0.470459Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_2192_4742">
              <rect
                width="149"
                height="47.559"
                fill="white"
                transform="translate(0 0.470459)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </Link>
  );
};

export default Logo;
