export const CheckboxIconUnchecked = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0ZM18 4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4Z"
      fill="currentColor"
    />
  </svg>
);

export const CheckboxIconChecked = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14.7372 7.67573C15.1104 7.26861 15.0829 6.63604 14.6757 6.26285C14.2686 5.88966 13.6361 5.91716 13.2629 6.32428L8.53589 11.481L6.76825 9.35982C6.41468 8.93554 5.78412 8.87822 5.35984 9.23178C4.93556 9.58535 4.87824 10.2159 5.2318 10.6402L7.7318 13.6402C7.91652 13.8618 8.18797 13.9929 8.47642 13.9997C8.76487 14.0065 9.04221 13.8884 9.23718 13.6757L14.7372 7.67573Z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="M4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0ZM18 4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4Z" fill="currentColor" />
</svg>

);
