import classNames from "classnames";
import "./index.scss";
import mocImg from "../../../assets/img/card.jpg";
import Label from "../Label/Label";
import LikeButton from "../LikeButton/LikeButton";
import { Link } from "react-router-dom";

const Card = () => {
  const cardClass = classNames({
    card: true,

  });

  return (
    <article className={cardClass}>
      <Link to="/jk">

      
      <div className="card__labels-list">
        <Label labelType="secondary">Старт продаж</Label>
        <Label labelType="secondary">1 токен = 1 м2</Label>
        <Label labelType="primary">Токенов осталось: 432,23</Label>
        <Label labelType="primary">Построено 25%</Label>
      </div>

      <LikeButton />

      <img className="card__img" src={mocImg} alt="" />

      <div className="card__body">
        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__key">ЖК «Аист»</span>
            <p className="sell-info__value base-14 text-gray">СК «Красная стрела»</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__key">1 токен = 185 273 ₽</span>
            <p className="sell-info__value base-14 text-gray">От застройщика</p>
          </div>
        </div>
      </div>

      </Link>
    </article>
  );
};

export default Card;
